import { SWITCH_IDIOMA } from './action'
import { ESP } from '../environments/idioma'

const initialState = {
    idIdioma: ESP
};

function appReducer(state = initialState, action) {
    switch (action.type) {
        case (SWITCH_IDIOMA): {
            return {
                ...state,
                idIdioma: action.payload
            }
        }
        default:
            break;
    }
    return state;
}
export default appReducer;