import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert2';
import Select from 'react-select';
import { getSessionUser } from '../../../services/auth.service';
import { GetServicio, SaveOrUpdateServicio, GetOpcionesByIdioma, DeleteOpcion, SaveOpcion } from '../../../services/servicios.service';
import { Button, FormControlLabel, Checkbox, Grid, IconButton, Fab } from '@material-ui/core';
import { successSwal, confirmSwal } from '../../../assets/resources/swal-config';
import { AddPhotoAlternate, Delete, Cancel, Save, KeyboardArrowDown, Done, ChevronRight, Close } from '@material-ui/icons'
import NoFoundImage from '../../../assets/img/not_found.png'
import './serviciosAbm.css';


const Styles = makeStyles((theme) => ({
    del: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },

    img: {
        width: '140px',
    },

    button: {
        color: '#FEF',
        backgroundColor: '#109ADD',
        '&:hover': {
            backgroundColor: '#0080FF',
            color: '#FEF'
        }
    },
}));

const ServiciosABM = (props) => {
    const classes = Styles();
    const Id = props.match.params.id;
    const history = useHistory();
    const user = getSessionUser();

    const [errors, setErrors] = useState([]);

    const [servicio, setServicio] = useState({
        IdServicio: 0,
        IdIdioma: props.idIdioma,
        IdAeropuerto: props.idAeropuerto,
        DsServicio: '',
        IdCategoria: '',
        LkLogo: '',
        DsDescripcion: '',
        DsUbicacion: [],
        DsTipoMenu: [],
        DsHorario: '',
        IdTipoLocal: '',
        McInactiva: false,
        Celiaco: false,
        Horario: false,
        Open24: false,
        FeCreacion: new Date(),
        FeModificacion: new Date(),
        Usuario: user.DS_Nombre,
        base64Image: null
    })

    const [categorias, setCategorias] = useState([]);
    const [tipoLocal, setTipoLocal] = useState([]);
    const [tipoMenu, setTipoMenu] = useState([]);
    const [ubicacion, setUbicacion] = useState([]);
    const [horario, setHorario] = useState({
        from: '',
        to: ''
    })

    const [image, setImage] = useState({ srcImage: "", hiddenRemoveImage: false });

    const validationSchema = {
        IdServicio: { required: false },
        IdIdioma: { required: false },
        IdAeropuerto: { required: false },
        DsServicio: { required: true },
        IdCategoria: { required: true },
        LkLogo: { required: false },
        DsDescripcion: { required: false },
        DsUbicacion: { required: true },
        DsWeb: { required: false },
        DsTipoMenu: { required: false },
        DsHorario: { required: true },
        IdTipoLocal: { required: false },
        Celiaco: { required: false },
        Horario: { required: false },
        Open24: { required: false },
        FeCreacion: { required: false },
        FeModificacion: { required: false },
        Usuario: { required: false },
        base64Image: { required: false },
        McInactiva: { required: false }
    }

    const isValid = () => {
        let valid = true;
        let errors = {};

        try {
            for (let key of Object.keys(servicio)) {
                let value = servicio[key]
                
                if ((key == 'IdCategoria' || key == 'IdTipoLocal') && validationSchema[key].required) {
                    if (value == 0) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }
                }

                if (key == 'DsHorario') {
                    const time = /^[0-2][0-9]:[0-5][0-9]$/;
                    console.log(horario.from)
                    console.log(horario.to)
                    if (value && (!time.test(horario.from) || !time.test(horario.to)) ) {
                        errors[key] = 'El rango horario debe ser en formato HH:mm y ambos campos son requeridos.';
                        setErrors(errors);
                        valid = false;
                    } else {
                        delete errors.DsHorario;
                    }

                    if (validationSchema[key].required) {
                        if (!value && !servicio.Open24 && !servicio.Horario) {
                            errors[key] = 'Requerido';
                            setErrors(errors);
                            valid = false;
                        }
                    } else {
                        delete errors.DsHorario;
                    }
                }
                
                if (key == 'DsUbicacion' && validationSchema[key].required) {
                    if (value.length == 0) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }
                }
            }
        } catch (err) {
            console.error('Error', err);
        }
        return valid;
    }

    useEffect(() => {
        document.addEventListener('click', event => {
            var elem = event.target.closest('.select-container.open'),
                container = document.querySelectorAll('.select-container.open');
            for (var i = 0; i < container.length; i++) {
                if (elem == null || elem.id != container[i].id) container[i].classList.remove('open');
            }
        });

        cargaInicial();
    }, []);

    const cargaInicial = async () => {
        return await GetOpcionesByIdioma(props.idIdioma)
        .then((resGetOpciones) => {
            if (resGetOpciones.data.length > 0) {
                mapOpciones(resGetOpciones.data);
                if (Id) {
                    getServicioById();
                } else{
                    setImage({ ...image, srcImage: NoFoundImage, hiddenRemoveImage: true });
                }
            } else {
                setServicio({...servicio, IdCategoria: "", IdTipoLocal: ""})
                setCategorias([]);
                setTipoLocal([])
                swal.fire({
                    title: 'Información',
                    icon: 'warning',
                    text: 'No se han encontrado opciones de categoria y tipo de local disponibles',
                    allowOutsideClick: false,
                    confirmButtonText: 'Ok'
                });
                return Promise.reject();
            }
        })
        .catch(() => { });
    }

    const mapOpciones = (data) => {
        let categorias = [], tipoLocal = [], tipoMenu = [], ubicacion = [];
        data.map(item => {
            switch (item.DsOpcion) {
                case 'Categoría':
                    categorias.push({ label: item.DsName, value: item.Id, active: item.FechaBaja == null })
                    setCategorias(categorias);
                    break;
                case 'Tipo de local':
                    tipoLocal.push({ label: item.DsName, value: item.Id, active: item.FechaBaja == null })
                    setTipoLocal(tipoLocal);
                    break;
                case 'Tipo de menu':
                    tipoMenu.push({ label: item.DsName, value: item.Id, active: item.FechaBaja == null })
                    setTipoMenu(tipoMenu);
                    break;
                case 'Ubicación':
                    ubicacion.push({ label: item.DsName, value: item.Id, active: item.FechaBaja == null })
                    setUbicacion(ubicacion);
                    break;
            }
        })
    }

    const getServicioById = () => {
        GetServicio(Id)
            .then((resGetServicio) => {
                if (resGetServicio.data) {
                    const horario = resGetServicio.data.DsHorario ? resGetServicio.data.DsHorario.slice(3).split(" a ") : ["", ""];
                    const tipoMenu = resGetServicio.data.DsTipoMenu ? JSON.parse(resGetServicio.data.DsTipoMenu) : [];
                    const ubicacion = resGetServicio.data.DsUbicacion ? resGetServicio.data.DsUbicacion.split(" > ") : [];
                    setHorario({from: horario[0], to: horario[1] });
                    setServicio({ ...resGetServicio.data, base64Image: resGetServicio.data.base64Image !== null ? resGetServicio.data.base64Image.toString().split(",", 2)[1] : null, DsTipoMenu: tipoMenu, DsUbicacion: ubicacion });
                    if (resGetServicio.data.base64Image !== null)
                        setImage({ ...image, srcImage: resGetServicio.data.base64Image });
                    else
                        setImage({ ...image, srcImage: NoFoundImage, hiddenRemoveImage: true });
                }   
            })
    }

    const handleChange = event => {
        setServicio({ ...servicio, [event.target.name]: event.target.value})
    }

    const handleTimeClick = event => {
        if(servicio.Horario || servicio.Open24){
            setServicio({ ...servicio, Horario: false, Open24: false });
            setTimeout(() => {
                document.getElementById("From").focus();
            });
        }
    }

    const handleFromTimeChange = event => {
        setHorario({ ...horario, from: event.target.value });
        setServicio({...servicio, DsHorario: event.target.value ? `de ${event.target.value} a ${horario.to}` : "" })
    }

    const handleToTimeChange = event => {
        setHorario({ ...horario, to: event.target.value });
        setServicio({...servicio, DsHorario: event.target.value ? `de ${horario.from} a ${event.target.value}` : "" })
    }

    const handleAeropuertoChange = aeropuerto => {
        setServicio({ ...servicio, IdAeropuerto: aeropuerto.value })
    }

    const handleOptionLabel = (name, value) => {
        var result;
        switch (name) {
            case 'IdTipoLocal':
                var local = tipoLocal.filter(item => { return item.value == value });
                result = local.length > 0 ? local[0].label : "";
                break;
            case 'IdCategoria':
                var categoria = categorias.filter(item => { return item.value == value });
                result = categoria.length > 0 ? categoria[0].label : "";
                break;
            case 'DsTipoMenu':
                var menu = tipoMenu.filter(item => { return item.label == value });
                result = menu.length > 0 ? menu[0].label : "";
                break;
        }

        return result;
    }

    const handleOptionsChange = (option, value) => {
        switch (option) {
            case 'IdCategoria':
                setServicio({...servicio, IdCategoria: value})
                break;
            case 'IdTipoLocal':
                setServicio({...servicio, IdTipoLocal: value})
                break;
            case 'DsTipoMenu':
                const tipoMenu = [];
                servicio.DsTipoMenu.map(m => { tipoMenu.push(m) });
                tipoMenu.push(value);
                setServicio({...servicio, DsTipoMenu: tipoMenu});
                break;
            case 'DsUbicacion':
                const ubicacion = [];
                servicio.DsUbicacion.map(u => { ubicacion.push(u) });
                ubicacion.push(value);
                setServicio({...servicio, DsUbicacion: ubicacion});
                break;
        }
    }

    const handleOptionAdd = async (option) => {
        const value = document.getElementById(option).value;
        if (value) {
            const opt = { Id: 0, DsName: value, DsOpcion: option, IdIdioma: props.idIdioma };
            document.getElementById(option).value = "";

            await SaveOpcion(opt)
                .then(async (resSaveOption) => {
                    if (resSaveOption.data.ResultadoExitoso) {
                        cargaInicial();
                    } else {
                        swal.fire({
                            title: 'Información',
                            icon: 'warning',
                            text: 'Ocurrio un error al intentar guardar la opción. Por favor intente nuevamente en unos minutos.',
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                        return Promise.reject();
                    }
                });
        } else {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: `El campo "${option}" es requerido, por favor completarlo antes de guardar.`,
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
        }
    }

    const handleOptionClear = option => {
        document.getElementById(option).value = "";
    }

    const handleOptionDelete = (option) => {
        swal.fire({
            title: 'Eliminar opción',
            text: '¿Está seguro que desea eliminar la opción  ' + option.label + '?',
            ...confirmSwal
        })
        .then((confirm) => {
            if (confirm.value) {
                DeleteOpcion({ Id: option.value })
                    .then((resDeleteOption) => {
                        cargaInicial();
                    })
            }
        });
    }

    const handleCeliacoChange = event => setServicio({ ...servicio, Celiaco: event.target.checked });

    const handleHorarioChange = event => {
        const checked = event.target.checked;
        const dsHorario = checked ? "" : servicio.DsHorario;
        const open24 = checked ? false : servicio.Open24;
        if (checked) setHorario({ from: "", to: "" });
        setServicio({ ...servicio, Horario: checked, Open24: open24, DsHorario: dsHorario });
    }

    const handleOpen24Click = event => {
        const checked = event.target.checked;
        const dsHorario = checked ? "" : servicio.DsHorario;
        const horario = checked ? false : servicio.Horario;
        if (checked) setHorario({ from: "", to: "" });
        setServicio({ ...servicio, Open24: checked, DsHorario: dsHorario, Horario: horario });;
    }

    const handleActiveChange = event => {
        setServicio({ ...servicio, McInactiva: !event.target.checked })
    }

    const handleOpenSelect = event => {
        const container = event.target.closest(".select-container");
        if (container.classList.contains("open"))
            container.classList.remove("open");
        else
            container.classList.add("open");
    }

    const handleUploadImageClick = event => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length && validateImage(event)) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                let img = new Image()
                img.src = reader.result;

                //VALIDA ANCHO Y LARGO DE LA IMAGEN
                img.onload = function () {

                    if (img.width > 380 && img.height > 200) {
                        swal.fire({
                            title: 'Información',
                            icon: 'warning',
                            text: 'La imagen debe ser máximo 97 px de ancho y 25 px de alto',
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    } else {
                        const nameFile = servicio.DsServicio !== '' ? `${servicio.DsServicio}.${file.name.split('.').pop()}` : file.name;
                        const base64Image = reader.result.toString().split(",", 2)[1];
                        setServicio({ ...servicio, LkLogo: nameFile, base64Image: base64Image })
                        setImage({ ...image, srcImage: reader.result, hiddenRemoveImage: false });
                    }
                }
            };
        }
    };

    const validateImage = (event) => {
        var fileName = event.target.files[0].name;
        var fileSize = event.target.files[0].size;

        if (fileSize > 3000000) {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'La imagen no debe superar los 3MB',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
            event.target.value = '';
            return false;
        } else {
            var ext = fileName.split('.').pop();

            ext = ext.toLowerCase();

            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'gif':
                case 'svg':
                    return true;
                    break;
                default:
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'LA Imagen no tiene la extensión adecuada',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                    event.target.value = '';
                    return false;
            }
        }
    }

    const removeImage = () => {
        setServicio({ ...servicio, LkLogo: null, base64Image: null })
        setImage({ ...image, srcImage: NoFoundImage, hiddenRemoveImage: true });
    }

    const handleSubmit = () => {
        if (isValid()) {
            var str = "";
            for (var i = 0; i < servicio.DsUbicacion.length; i++){
                str = i == 0 ? servicio.DsUbicacion[i] : str + " > " + servicio.DsUbicacion[i];
            }

            const obj = {
                IdServicio: servicio.IdServicio,
                IdIdioma: servicio.IdIdioma,
                IdAeropuerto: servicio.IdAeropuerto,
                DsServicio: servicio.DsServicio,
                IdCategoria: servicio.IdCategoria == 0 ? null : servicio.IdCategoria,
                LkLogo: servicio.LkLogo,
                DsDescripcion: servicio.DsDescripcion,
                DsUbicacion: str,
                DsWeb: servicio.DsWeb,
                DsTipoMenu: JSON.stringify(servicio.DsTipoMenu),
                DsHorario: servicio.DsHorario,
                IdTipoLocal: servicio.IdTipoLocal == 0 ? null : servicio.IdTipoLocal,
                McInactiva: servicio.McInactiva,
                Celiaco: servicio.Celiaco,
                Horario: servicio.Horario,
                Open24: servicio.Open24,
                FeCreacion: servicio.FeCreacion,
                FeModificacion: new Date(),
                Usuario: user.DS_Nombre,
                base64Image: servicio.base64Image
            }

            SaveOrUpdateServicio(obj)
                .then((resServicio) => {
                    if (resServicio.data.ResultadoExitoso) {
                        swal.fire({
                            text: 'Se han guardado los cambios con éxito',
                            ...successSwal
                        }).then(() => { history.push('/Servicios'); })
                    } else {
                        swal.fire({
                            title: 'Error al guardar los cambios',
                            icon: 'warning',
                            text: resServicio.data.MensajeError,
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                });
        }
    }

    const handleCancelABMClick = () => { history.push('/Servicios'); }

    const handleTipoMenu = () => {
        const items = [];
        for (var i = 0; i < tipoMenu.length; i++) {
            const menu = tipoMenu[i];
            if (menu.active && !servicio.DsTipoMenu.find(x => x.toLowerCase() == menu.label.toLowerCase())) {
                items.push(menu);
            }
        }
        return items;
    }

    const handleDeleteMenu = index => {
        const items = [];
        servicio.DsTipoMenu.map((menu, i) => { if (i != index) items.push(menu); });
        setServicio({...servicio, DsTipoMenu: items});
    }

    const handleUbicacion = () => {
        const items = [];
        for (var i = 0; i < ubicacion.length; i++) {
            const u = ubicacion[i];
            if (u.active && !servicio.DsUbicacion.find(x => x.toLowerCase() == u.label.toLowerCase())) {
                items.push(u);
            }
        }
        return items;
    }

    const handleDeleteUbicacion = index => {
        const items = [];
        servicio.DsUbicacion.map((u, i) => { if (i != index) items.push(u); });
        setServicio({...servicio, DsUbicacion: items});
    }

    return (
        <div className="main-content">
            <br />

            <div className="row form-title">
                <div className="col-md-6">
                    <h1 className="title">{ Id ? 'Editar servicio' : 'Nuevo servicio' }</h1>
                </div>
                <div className="col-md-6">
                    <div className="active-switch">
                        <label className="label">HABILITADO</label>
                        <input type="checkbox" id="check" checked={!servicio.McInactiva} onChange={handleActiveChange} />
                        <label htmlFor="check" className="switch"></label>
                    </div>
                </div>
            </div>

            <br />
            <br />
            <div className="content">
                <div className="box">
                    <div className="row form-group">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="form-input">
                                <label className="label">Nombre del local o servicio<i className="required">*</i></label>
                                <input name="DsServicio" type="text" maxLength={30} placeholder="ej. Duty Free Shop" defaultValue={servicio.DsServicio} onChange={handleChange} className="form-control" />
                                {errors.DsServicio && (<p className="help is-danger">{errors.DsServicio}</p>)}
                                <p className="help maxlength">{servicio.DsServicio ? servicio.DsServicio.length : 0} / 30</p>
                                <br />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="form-input">
                                <label className="label">Categoría<i className="required">*</i></label>

                                <div className="select-container" id="IdCategoria">
                                    <div className="select-control"  onClick={handleOpenSelect}>
                                        <div className="select-value">
                                            {servicio.IdCategoria != 0 ?
                                                <div>{handleOptionLabel('IdCategoria', servicio.IdCategoria)}</div> :
                                                <div className="placeholder">Seleccione</div>}
                                        </div>
                                        <div className="arrow-container">
                                            <span className="arrow-separator"></span>
                                            <div className="arrow-content">
                                                <KeyboardArrowDown />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-options">
                                        {categorias.filter(x => x.active && x.value != servicio.IdCategoria).map(opt => 
                                            <div className={`select-option${opt.value == servicio.IdCategoria ? ' active' : ''}`} key={opt.value}>
                                                <div className="value" onClick={() => handleOptionsChange("IdCategoria", opt.value)}>{opt.label}</div>
                                                <div className="actions hoverable">
                                                    <Delete className="Delete" onClick={() => handleOptionDelete(opt)} />
                                                </div>
                                            </div>
                                        )}
                                        <div className="select-option">
                                            <input name="cat" placeholder="Agregar" id="Categoría" />
                                            <div className="actions">
                                                <Done className="Check" onClick={ () => handleOptionAdd("Categoría")}/>
                                                <Delete className="Delete" onClick={ () => handleOptionClear("Categoría")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {errors.IdCategoria && (<p className="help is-danger">{errors.IdCategoria}</p>)}
                                <br />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="form-input">
                                <label className="label">Tipo de menú</label>

                                <div className="select-container multiple" id="DsTipoMenu">
                                    <div className="select-control" onClick={handleOpenSelect}>
                                        <div className="select-value">
                                            {servicio.DsTipoMenu.length != 0 ?
                                                <div className="value-container">
                                                    {servicio.DsTipoMenu.map((menu, i) => 
                                                        <div className="value" key={menu}>
                                                            <div className="title">{menu}</div>
                                                            <div className="icon remove" onClick={() => handleDeleteMenu(i)}><Close /></div>
                                                        </div>
                                                    )}
                                                </div>
                                                :
                                                <div className="placeholder">Seleccione</div>
                                            }
                                        </div>
                                        <div className="arrow-container">
                                            <span className="arrow-separator"></span>
                                            <div className="arrow-content">
                                                <KeyboardArrowDown />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-options">
                                        {handleTipoMenu().map(opt => 
                                            <div className={`select-option${opt.value == servicio.DsTipoMenu ? ' active' : ''}`} key={opt.value}>
                                                <div className="value" onClick={() => handleOptionsChange("DsTipoMenu", opt.label)}>{opt.label}</div>
                                                <div className="actions hoverable">
                                                    <Delete className="Delete" onClick={() => handleOptionDelete(opt)} />
                                                </div>
                                            </div>
                                        )}
                                        <div className="select-option">
                                            <input name="cat" placeholder="Agregar" id="Tipo de menu" />
                                            <div className="actions">
                                                <Done className="Check" onClick={ () => handleOptionAdd("Tipo de menu")}/>
                                                <Delete className="Delete" onClick={ () => handleOptionClear("Tipo de menu")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {errors.DsTipoMenu && (<p className="help is-danger">{errors.DsTipoMenu}</p>)}
                                <br />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="form-input">
                                <label className="label">Aeropuerto</label>
                                <Select isDisabled={Id} placeholder={<div>Seleccione</div>} options={props.aeropuertos} onChange={handleAeropuertoChange} value={props.aeropuertos.find(obj => obj.value === servicio.IdAeropuerto)} />
                                {errors.IdAeropuerto && (<p className="help is-danger">{errors.IdAeropuerto}</p>)}
                                <br />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="form-input">
                                <label className="label">Ubicación<i className="required">*</i></label>

                                <div className="select-container multiple" id="DsUbicacion">
                                    <div className="select-control" onClick={handleOpenSelect}>
                                        <div className="select-value">
                                            {servicio.DsUbicacion.length != 0 ?
                                                <div className="value-container">
                                                    {servicio.DsUbicacion.map((u, i) => 
                                                        <div className="value" key={u}>
                                                            {i > 0 ? <div className="icon"><ChevronRight /></div> : ""}
                                                            <div className="title">{u}</div>
                                                            <div className="icon remove" onClick={() => handleDeleteUbicacion(i)}><Close /></div>
                                                        </div>
                                                    )}
                                                </div>
                                                :
                                                <div className="placeholder">Seleccione</div>
                                            }
                                        </div>
                                        <div className="arrow-container">
                                            <span className="arrow-separator"></span>
                                            <div className="arrow-content">
                                                <KeyboardArrowDown />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-options">
                                        {handleUbicacion().map(opt => 
                                            <div className={`select-option${opt.value == servicio.DsUbicacion ? ' active' : ''}`} key={opt.value}>
                                                <div className="value" onClick={() => handleOptionsChange("DsUbicacion", opt.label)}>{opt.label}</div>
                                                <div className="actions hoverable">
                                                    <Delete className="Delete" onClick={() => handleOptionDelete(opt)} />
                                                </div>
                                            </div>
                                        )}
                                        <div className="select-option">
                                            <input name="ubi" placeholder="Agregar" id="Ubicación" />
                                            <div className="actions">
                                                <Done className="Check" onClick={ () => handleOptionAdd("Ubicación")}/>
                                                <Delete className="Delete" onClick={ () => handleOptionClear("Ubicación")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {errors.DsUbicacion && (<p className="help is-danger">{errors.DsUbicacion}</p>)}
                                <br />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="form-input">
                                <label className="label">Sitio Web</label>
                                <input name="DsWeb" type="text" maxLength={70} placeholder="ej. https://tienda.open25.com.ar/" defaultValue={servicio.DsWeb} onChange={handleChange} className="form-control" />
                                {errors.DsWeb && (<p className="help is-danger">{errors.DsWeb}</p>)}
                                <p className="help maxlength">{servicio.DsWeb ? servicio.DsWeb.length : 0} / 70</p>
                                <br />
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="form-input">
                                <label className="label">Descripción</label>
                                <textarea name="DsDescripcion" maxLength={260} placeholder="ej. Venta de comestibles, artículos electrónicos, entre otros." defaultValue={servicio.DsDescripcion} onChange={event => setServicio({...servicio, DsDescripcion: event.target.value})} className="form-control" />
                                {errors.DsDescripcion && (<p className="help is-danger">{errors.DsDescripcion}</p>)}
                                <p className="help maxlength">{servicio.DsDescripcion ? servicio.DsDescripcion.length : 0} / 260</p>
                                <br />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                            <div className="form-input">
                                <label className="label">Horario</label>
                                <div className="horario" onClick={handleTimeClick}>
                                    <span>De</span>
                                    <input name="DsHorario" id="From" type="text" maxLength={5} placeholder="00:00" value={horario.from} onChange={handleFromTimeChange} className="form-control" disabled={servicio.Open24 || servicio.Horario} />
                                    <span> Hasta</span>
                                    <input name="DsHorario" id="To" type="text" maxLength={5} placeholder="00:00" value={horario.to} onChange={handleToTimeChange} className="form-control" disabled={servicio.Open24 || servicio.Horario} />
                                    <span>hs</span>
                                </div>
                                {errors.DsHorario && (<p className="help is-danger">{errors.DsHorario}</p>)}
                                <br />
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4">
                            <div className="form-input">
                                <br />
                                <FormControlLabel control={<Checkbox name="Open24" color="primary" />} label="Abierto las 24hs" checked={servicio.Open24} onChange={handleOpen24Click} />
                                <br />
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4">
                            <div className="form-input">
                                <br />
                                <FormControlLabel control={<Checkbox name="ExcepcionHorario" color="primary" />} label="Abierto según operatoria del Aeropuerto" checked={servicio.Horario} onChange={handleHorarioChange} />
                                <br />
                            </div>
                        </div>

                        <div className="col-md-8">
                            <br/>
                            <label className="label">Seleccione una Imagen</label>
                            <Grid container spacing={3}>
                                <br/>
                                <Grid item>
                                    <img className={classes.img} alt="complex" src={image.srcImage} />
                                </Grid>
                                <Grid item xs container direction="column" spacing={2}>
                                    {image.hiddenRemoveImage ? <Fragment>
                                        <input type="file" accept=".jpg, .jpeg, .png, .gif, .svg" className={classes.input} id="contained-button-file1" onChange={handleUploadImageClick} />

                                        <label className="buttonImg" htmlFor="contained-button-file1">
                                            <Fab component="span" color="primary" >
                                                <AddPhotoAlternate />
                                            </Fab>
                                        </label>
                                    </Fragment> : ""}
                                    <div className={classes.del}>
                                        {!image.hiddenRemoveImage ? <IconButton color="secondary" aria-label="delete" component="span" onClick={removeImage}>
                                            <Delete />
                                        </IconButton> : ""}
                                    </div>
                                </Grid>
                            </Grid>

                            <br/>
                            <label className="label">Requerimientos de la imagen:</label>
                            <ul className="validImages">
                                <li>Tamaño max. 380px x 200px</li>
                                <li>Extensiones validas: JPG, JPEG, PNG, GIF, SVG</li>
                                <li>Max. 3MB</li>
                            </ul>
                        </div>

                        <div className="col-md-4" hidden={servicio.IdServicio == 0}>
                            <p className="last-modification">Última modificación:</p>
                            <span>{new Date(servicio.FeModificacion != "0001-01-01T00:00:00" ? servicio.FeModificacion : servicio.FeCreacion).toLocaleDateString()} - {servicio.Usuario}</span>
                        </div>
                    </div>
                </div>
                    

                <div className="row form-group"> 
                    <div className="form-row">
                        <Button variant="contained" startIcon={<Save />} className="button" onClick={handleSubmit}>Guardar</Button>
                        <Button variant="contained" startIcon={<Cancel />} className="button" onClick={handleCancelABMClick}>Cancelar</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        idIdioma: state.appReducer.idIdioma,
        aeropuertos: state.aeropuertoReducer.aeropuertos,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto
    }
}

export default connect(mapStateToProps)(ServiciosABM);

