import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { GetGruposFull, DeleteGrupoFull } from '../../services/grupo.service';
import { GetArbolConPermisos } from '../../services/componente.service';
import { GetUsers } from '../../services/usuario.service';
import { FormatUserName } from '../../environments/utils';
import { ROL_MASTER, ROL_ADMIN } from '../../services/permisos.service';
import { getSessionUser } from '../../services/auth.service';

import swal from 'sweetalert2';
import { successSwal, confirmSwal } from '../../assets/resources/swal-config';
import { Button, Tooltip, withStyles } from '@material-ui/core';
import { Add, ChevronRight, Delete, Person } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faSearch } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../components/Pagination';
import './grupo.css';


const Grupo = () => {
    const history = useHistory();
    // ROLES Y PERMISOS
    const user = getSessionUser();
    const rolMaster = user.IdRol === ROL_MASTER;
    const rolAdmin = user.IdRol === ROL_ADMIN;

    const [grupos, setGrupos] = useState([]);
    const [usuarios, setUsuarios] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const filteredItems = grupos.filter(item => { return item.show });

    useEffect(() => {cargaInicialGrupos()}, []);

    const cargaInicialGrupos = async () => {
        document.getElementById("searchInput").value = "";
        return await GetGruposFull()
            .then(async (resGrupos) => {
                if (resGrupos.data) {
                    const gruposArr = []
                    for (var i = 0; i < resGrupos.data.length; i++){
                        const grupo = resGrupos.data[i];
                        if (rolMaster || (rolAdmin && grupo.IdUsuariosList.indexOf(user.Id) != -1))
                            grupo["show"] = true;
                            gruposArr.push(grupo);
                    }
                    
                    setGrupos(gruposArr);
                    return await GetUsers()
                }
            })
            .then((resGetUsers) => {
                if (resGetUsers.data) {
                    mapUsers(resGetUsers.data);
                }
            })
            .catch(err => console.error(err))
    }

    const mapUsers = (users) => {
        const usersArr = []
        users.map(user => {
            let obj = { id: user.Id, name: user.DS_Nombre }
            usersArr.push(obj);
        })
        setUsuarios(usersArr);
    }

    const mapUserList = (userList) => {
        const users = [];

        for (var i = 0; i < userList.length; i++){
            const user = usuarios.filter(usuario => { return usuario.id == userList[i] });
            if (user.length > 0)
                users.push(user[0].name);
        }

        return users;
    }

    const handleNewGroupClick = () => history.push("/Grupo");

    const handleViewClick = (idGrupo) => history.push(`/Grupo/${idGrupo}`);

    const handleDeleteClick = (grupo) => {
        swal.fire({
            title: 'Eliminar Grupo',
            text: '¿Está seguro que desea eliminar el grupo  ' + grupo.Nombre + '?',
            ...confirmSwal
        })
            .then(async (confirm) => {
                if (confirm.value) {
                    const componentes = await MapArbolPermisos(grupo.Id);
                    let grupoSave = {
                        Id: grupo.Id,
                        Nombre: grupo.Nombre,
                        Descripcion: grupo.Descripcion,
                        IdUsuariosList: grupo.IdUsuariosList,
                        Componentes: componentes 
                    }
                    deleteGrupo(grupoSave);
                }
            });
    }

    const deleteGrupo = (grupo) => {
        DeleteGrupoFull(grupo)
            .then(() => {
                swal.fire({
                    text: 'Se ha eliminado el grupo con éxito',
                    ...successSwal
                })
                    .then(() => {
                        cargaInicialGrupos();
                    });
            })
            .catch((e) => {console.log(e)});
    }

    const MapArbolPermisos = async (id) => {
        const items = [];
        return await GetArbolConPermisos({ Id: id })
            .then((resGetArbol) => {
                for (let index = 0; index < resGetArbol.data.length; index++){
                    for (let i = 0; i < resGetArbol.data[index].Hijos.length; i++) {
                        var Hijos = resGetArbol.data[index].Hijos[i];
                        if (Hijos.Hijos) {
                            Hijos.Hijos.map(item => {
                                if(item.Chequeado) items.push(item.Id)
                            })
                        } else {
                            if(Hijos.Chequeado) items.push(Hijos.Id)
                        }
                    }
                }
                return items;
            })
    }

    const handleSearch = (e) => {
        const search = e ? e.target.value.toLowerCase().trim() : document.getElementById("searchInput").value.toLowerCase().trim();
        const keys = ["Nombre"];
        const newRows = [];

        if(search.length >= 2) {
            for(var i = 0; i < grupos.length; i++) {
                let bool;
                let row = grupos[i];
                for(var index = 0; index < keys.length; index++) {
                    let value = row[keys[index]];
                    if(value) {
                        value = value.toLowerCase();
                        bool = index == 0 ? value.indexOf(search) != -1 : bool || value.indexOf(search) != -1;
                    }
                }
                row.show = bool;
                newRows.push(row);
            }
        } else {
            for(var i = 0; i < grupos.length; i++) {
                grupos[i].show = true;
                newRows.push(grupos[i]);
            }
        }
        setPage(0)
        setGrupos(newRows)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    return (
        <div className="main-content">
            <br/>
            <h1 className="title">Grupos</h1>
            <div className="content">
                <div className="row form-group">
                    <div className="col-md-4">
                        <br/>
                        <div className="search-box">
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="type" name="search" id="searchInput" placeholder="Buscar grupo" onKeyUp={handleSearch} />
                        </div>
                        <br/>
                    </div>

                    <div className="col-md-8">
                        <br/>
                        <div className="pull-right">
                            <Button variant="contained" color="primary" className="btnAdd" onClick={handleNewGroupClick} startIcon={<Add />}>Nuevo</Button>
                        </div>
                    </div>
                </div>

                <div className="row form-group">
                    {(rowsPerPage > 0 ? filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredItems).map((group, index) => (
                        <div className="col-md-3" key={index + "-" + group.Id}>
                            <div className="group-box">
                                <p className="group-name">{group.Nombre}</p>
                                <div className="group-logo">
                                    <FontAwesomeIcon icon={faUsers} />
                                    {mapUserList(group.IdUsuariosList).length > 0 ?
                                        <div className="group-info">
                                            <span className="members">{mapUserList(group.IdUsuariosList).length}</span>
                                            <UserList IdUsuariosList={mapUserList(group.IdUsuariosList)} handleViewClick={() => handleViewClick(group.Id)} />
                                        </div> : ""}
                                </div>
                                <div className="group-description">{group.Descripcion}</div>
                                <div className="bottom">
                                    <LightTooltip title="Eliminar grupo" placement="top">
                                        <Delete className="delete-user" onClick={() => handleDeleteClick(group)}/>
                                    </LightTooltip>
                                    <div className="details" onClick={() => handleViewClick(group.Id)}>
                                        <span>VER</span>
                                        <ChevronRight/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {filteredItems.length > rowsPerPage ? <Pagination count={filteredItems.length} page={page} rowsPerPage={rowsPerPage} onChangePage={handleChangePage} /> : ""}
        </div>
    );
}

const UserList = (props) => {
    const { IdUsuariosList, handleViewClick } = props;

    return (
        <ul className="members-info">
            {IdUsuariosList.slice(0,3).map((user, index) => (
                <li key={user + "-" + index}>
                    <Person />
                    <span onClick={() => handleViewClick}>{FormatUserName(user)}</span>
                </li>
            ))}
            <li onClick={handleViewClick}>
                <span>Ver mas</span>
            </li>
        </ul>
    )
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        letterSpacing: 0,
    },
}))(Tooltip);

export default Grupo