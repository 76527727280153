import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { GetLineasAereasByIdioma, DeleteLineaAerea } from '../../services/lineasAereas.service'
import { GetPDFTemplate, menuEnable, menuLocate, menuShow, menuIsActive, menuHide } from '../../environments/utils'

import swal from 'sweetalert2';
import { successSwal, confirmSwal,  } from '../../assets/resources/swal-config';
import { Table, TableBody, TableCell, TableFooter, TablePagination, TableRow, Paper, IconButton, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Print, MoreVert, LocationOn, Add } from '@material-ui/icons';
import { ESP } from '../../environments/idioma';

import SwitcherIdioma from '../../components/switcherIdioma';
import TablePaginationActions from '../../components/tablePagination'
import NoFoundImage from '../../assets/img/not_found.png';

var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const LineasAereas = (props) => {
    const history = useHistory();
    const [state, setState] = useState({
        lineasAereas: [],
        idIdioma: ESP
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [idRow, SetIdRow] = useState(null);

    const filteredRow = state.lineasAereas.filter(row => { return row.show })
    const MENU_SELECTOR = "#contextMenu";
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.lineasAereas.length - page * rowsPerPage);

    useEffect(() => {
        loadLineasAereas(props.idIdioma);
    }, [props.idIdioma, props.userArpt]);

    const loadLineasAereas = (selectIdioma) => {
        setPage(0);
        document.getElementById("searchInput").value = "";
        GetLineasAereasByIdioma(selectIdioma)
            .then((resLineasAereas) => {
                if (resLineasAereas.data) {
                    const lineas = [];
                    for (var i = 0; i < resLineasAereas.data.length; i++) {
                        const linea = resLineasAereas.data[i]
                        linea["show"] = true;
                        lineas.push(linea);
                    }
                    setState({ ...state, lineasAereas: lineas });
                }
            });
    }

    const showABM = () => { history.push("/LineaAerea"); }

    const handleTableOptions = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const id = e.target.closest('tr').id;
        if (menuIsActive(MENU_SELECTOR) && idRow != null) {
            SetIdRow(null);
            menuHide(MENU_SELECTOR);
        } else {
            SetIdRow(id);
            menuEnable(MENU_SELECTOR);
            menuLocate(MENU_SELECTOR, e.pageX, e.pageY);
            menuShow(MENU_SELECTOR);
        }
    }

    const handleEditClick = () => {
        if (idRow != null) {
            history.push(`/LineaAerea/${idRow}`);
        } else {
            swal.fire({
                text: 'Ha ocurrido un error, intente nuevamente.',
                ...confirmSwal
            })
        }
    }

    const handleRemoveClick = () => {
        if (idRow != null) {
            const lineaAerea = state.lineasAereas.find(x => x.IdLinea == parseInt(idRow));
            const objLineaAerea = { idLinea: lineaAerea.IdLinea, dsLinea: lineaAerea.DsLinea }

            swal.fire({
                title: 'Eliminar Línea Aerea',
                text: '¿Está seguro que desea eliminar la Línea Aerea  ' + objLineaAerea.dsLinea + '?',
                ...confirmSwal
            })
                .then((confirm) => {
                    if (confirm.value) {
                        deleteLineaAerea(objLineaAerea);
                    }
                });
        } else {
            swal.fire({
                text: 'Ha ocurrido un error, intente nuevamente.',
                ...confirmSwal
            })
        }
    }

    const deleteLineaAerea = (lineaAerea) => {

        DeleteLineaAerea(lineaAerea)
            .then(async () => {
                await loadLineasAereas(props.idIdioma);
                swal.fire({
                    text: 'Se ha eliminado la Linea Aerea con éxito',
                    ...successSwal
                })
            });
    }

    const handleTipoLinea = (tipolinea) => {
        switch (tipolinea) {
            case 'I':
                return "Internacional"
            case 'C':
                return "Cabotaje"
            case 'A':
                return "Internacional/ Cabotaje"
        }
    }

    const handlePrintTable = () => {
        const PDF = GetPDFTemplate();
        PDF.content[0].columns[1].text = "Lineas Aéreas";
        var tableToAdd = {
            style: 'table',
            table: {
                body: []
            },
            layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 != 0) ? '#F8F8F8' : null;
                },
                hLineWidth: function (i, node) {
                    return (i === 1) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return '#C2C2C2';
                },
            }
        };
        
        tableToAdd.table.body.push([{ text: 'Aerolinea', style: 'tableHeader' },{ text: 'Tipo de Aerolinea', style: 'tableHeader'},{ text: 'Código IATA', style: 'tableHeader'},{ text: 'Página Web', style: 'tableHeader'},{ text: 'Télefono', style: 'tableHeader'}])
        
        for (var i = 0; i < filteredRow.length; i++){
            const current = filteredRow[i]
            tableToAdd.table.body.push([current.DsLinea, handleTipoLinea(current.IdTipoLinea), current.DsIATA, current.DsWeb, current.DsTelefono])
        }

        PDF.content.push(tableToAdd)

        pdfMake.createPdf(PDF).open();
    }

    const handlePrintClick = () => {
        if (idRow != null) {
            const lineaAerea = state.lineasAereas.find(x => x.IdLinea == parseInt(idRow));
            const PDF = GetPDFTemplate();
            var tableToAdd = {
                style: 'table',
                table: {
                    widths: ['30%', '70%'],
                    body: [
                        [
                            { text: 'Aerolinea', style: 'tableHeader' },
                            { text: `${lineaAerea.DsLinea}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Tipo de Linea', style: 'tableHeader' },
                            { text: `${lineaAerea.IdTipoLinea ? handleTipoLinea(lineaAerea.IdTipoLinea) : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Código IATA', style: 'tableHeader' },
                            { text: `${lineaAerea.DsIATA ? lineaAerea.DsIATA : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Sitio Web', style: 'tableHeader' },
                            { text: `${lineaAerea.DsWeb ? lineaAerea.DsWeb : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Télefono', style: 'tableHeader' },
                            { text: `${lineaAerea.DsTelefono ? lineaAerea.DsTelefono : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Correo Electronico', style: 'tableHeader' },
                            { text: `${lineaAerea.DsMail ? lineaAerea.DsMail : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Dirección', style: 'tableHeader' },
                            { text: `${lineaAerea.DsDireccion ? lineaAerea.DsDireccion : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Ciudad', style: 'tableHeader' },
                            { text: `${lineaAerea.DsCiudad ? lineaAerea.DsCiudad : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Provincia', style: 'tableHeader' },
                            { text: `${lineaAerea.DsProvincia ? lineaAerea.DsProvincia : ""}`, style: 'tableData' },
                        ],
                    ]
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (columnIndex % 2 == 0) ? '#193b73' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        return '#193b73';
                    },
                    vLineColor: function (i, node) {
                        return '#193b73';
                    },
                }
            };
            var styles = {
                header: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 30],
                },
                table: {
                    widht: '100%',
                    margin: [0, 30],
                    alignment: 'center',
                    borderRadius: 33
                },
                tableHeader: {
                    color: 'white',
                    bold: true,
                    fontSize: 13,
                    margin: [0, 2.5]
                },
                tableData: {
                    margin: 5,
                    alignment: 'justify'
                }
            }

            PDF.content[0].columns[0].fit = [150, 100];
            PDF.content[0].columns[1].text = "Detalle de Línea Aérea";
            PDF.content.push(tableToAdd)
            PDF.styles = styles;

            pdfMake.createPdf(PDF).open()
        } else {
            swal.fire({
                text: 'Ha ocurrido un error, intente nuevamente.',
                ...confirmSwal
            })
        }
    }

    // TABLA-PRINCIPIO

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleSearch = (e) => {
        const search = e ? e.target.value.toLowerCase().trim() : document.getElementById("searchInput").value.toLowerCase().trim();
        const keys = ["DsLinea", "DsCiudad", "DsMail", "DsTelefono", "DsWeb", "DsDireccion"];
        const newRows = [];

        if(search.length >= 2) {
            for(var i = 0; i < state.lineasAereas.length; i++) {
                let bool;
                let row = state.lineasAereas[i];
                for(var index = 0; index < keys.length; index++) {
                    let value = row[keys[index]];
                    if(value) {
                        value = value.toLowerCase();
                        bool = index == 0 ? value.indexOf(search) != -1 : bool || value.indexOf(search) != -1;
                    }
                }
                row.show = bool;
                newRows.push(row);
            }
        } else {
            for(var i = 0; i < state.lineasAereas.length; i++) {
                state.lineasAereas[i].show = true;
                newRows.push(state.lineasAereas[i]);
            }
        }
        setPage(0)
        setState({ ...state, lineasAereas: newRows })
    }

    const clearSearch = () => {
        document.getElementById("searchInput").value = "";
        handleSearch(undefined)
    }

    // TABLA-FIN

    return (
        <div className="main-content">
            <br />
            <h1 className="title">Líneas Aéreas</h1>
            <br/>
            <div className="content">
                <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4">
                        <div className="search-box">
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="type" name="search" id="searchInput" placeholder="Buscar línea aérea" onKeyUp={handleSearch} />
                        </div>
                        <br/>
                    </div>

                    <div className="col-sm-6 col-md-2 col-lg-2 offset-md-2 offset-lg-3">
                        <SwitcherIdioma />
                        <br/>
                    </div>
                    
                    <div className="col-sm-6 col-md-3 col-lg-3">
                        <Button variant="contained" color="primary" className="btnAdd" startIcon={<Add />} onClick={showABM}>Nuevo</Button>
                    </div>
                </div>

                <div className="row">
                    <Paper className="table-root">
                        <div className="table-wrapper">
                            <div className="actions-table">
                                <Print onClick={() => handlePrintTable()}/>
                            </div>
                            <Table>
                                <TableBody>
                                    {(rowsPerPage > 0 ? filteredRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRow).map((row) => (
                                        <TableRow key={row.IdLinea} id={row.IdLinea}>
                                            <TableCell component="th" scope="row" className="logo">
                                                <img src={row.Base64ImageGde ? row.Base64ImageGde : NoFoundImage} alt="LogoLocal" />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div className="information">
                                                    <p>{row.DsLinea}</p>
                                                    <p hidden={!row.DsWeb}>{row.DsWeb}</p>
                                                    <p hidden={!row.DsTelefono}>{row.DsTelefono}</p>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div className="information">
                                                    <p>Tipo { handleTipoLinea(row.IdTipoLinea) }</p>
                                                    <p hidden={!row.DsIATA}>Código IATA {row.DsIATA}</p>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div className="direction">
                                                    <p>
                                                        <LocationOn />
                                                        {row.DsDireccion}</p>
                                                    <p hidden={!row.DsCiudad}>{row.DsCiudad}</p>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <IconButton className="table-btns" onClick={(e) => handleTableOptions(e)}>
                                                    <MoreVert />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    <TableRow style={{ height: "48px" }}>
                                        <TableCell colSpan={7} />
                                    </TableRow>
                                    
                                </TableBody>
                                <TableFooter className="table-footer">
                                    <TableRow>
                                        <TablePagination
                                            count={filteredRow.length}
                                            labelRowsPerPage="Filas por página"
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            rowsPerPageOptions={[5, 10, 25]}
                                            colSpan={5}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            labelDisplayedRows={(from=page) => (`${from.from}-${from.to === -1 ? from.count : from.to} de ${from.count}`)}>
                                        </TablePagination>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </div>
                    </Paper>
                </div>

                <div className='menugrid' id="contextMenu" hidden>
                    <ul>
                        <li><Button onClick={() => handleEditClick()}>Modificar</Button></li>
                        <li><Button onClick={() => handleRemoveClick()}>Eliminar</Button></li>
                        <li><Button onClick={() => handlePrintClick()}>Imprimir</Button></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        idIdioma: state.appReducer.idIdioma
    }
}

export default connect(mapStateToProps)(LineasAereas);