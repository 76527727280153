import { SAVE_SESSION } from './action'
import { LOAD_SESSION } from './action'
import { CLEAR_SESSION } from './action'

const initialState = {

    Id: 0,
    DS_Nombre: '',
    DS_NombreUsuario: '',
    DS_Email: '',
    IdRol: 0,
    Token: ''
};
const loadFromLocalStorage = () => {

    try {
        const serialState = sessionStorage.getItem('state');
        if (serialState === null) {
            return initialState;
        }
        return JSON.parse(serialState);
    } catch (e) {
        console.log(e);
    }
}
function userReducer(state = loadFromLocalStorage(), action) {

    switch (action.type) {
        case SAVE_SESSION:
            return {
                ...state,
                Id: action.payload.Id,
                DS_Nombre: action.payload.DS_Nombre,
                DS_NombreUsuario: action.payload.DS_NombreUsuario,
                DS_Email: action.payload.DS_Email,
                IdRol: action.payload.IdRol,
                Token: action.payload.Token,
            }

        case LOAD_SESSION:
            return {
                ...state,
                Id: action.payload.Id,
                DS_Nombre: action.payload.DS_Nombre,
                DS_NombreUsuario: action.payload.DS_NombreUsuario,
                DS_Email: action.payload.DS_Email,
                IdRol: action.payload.IdRol,
                Token: action.payload.Token,
            }
        case CLEAR_SESSION: {

            return {
                ...initialState
            }
        }

        default:
            break;
    }
    return state;
};

export default userReducer;