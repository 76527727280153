import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getSessionUser } from '../../services/auth.service';
import { GetPDFTemplate, EventListener } from '../../environments/utils';
import { GetTaxiRemisByAeropuerto, DeleteTaxiRemis, SaveOrUpdateTaxiRemis } from '../../services/taxiRemis.service';

import swal from 'sweetalert2';
import { successSwal, confirmSwal } from '../../assets/resources/swal-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Table, TableBody, TableCell, TableFooter, TablePagination, TableRow, Paper, Button, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { MoreVert, Add, Visibility, VisibilityOff } from '@material-ui/icons';

import SwitcherIdioma from '../../components/switcherIdioma';
import SelectAeropuerto from '../../components/SelectAeropuerto'
import TablePaginationActions from '../../components/tablePagination';
import NoFoundImage from '../../assets/img/not_found.png'
import AutitoTarifasColor from '../../assets/img/autito-tarifas-color.svg'
import AutitoTarifasBN from '../../assets/img/autito-tarifas-BN.svg'

import './taxiRemis.css'

var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #59C576',
        padding: 10,
    },
}))

const HtmlTooltip = (props) => {
    const classes = TooltipStyles();

    return <Tooltip classes={classes} {...props} />;
}

const TaxiRemis = (props) => {
    const [state, setState] = useState({
        isEditing: false,
        idTaxiRemis: 0,
        taxisRemises: [],
        idIdioma: props.idIdioma
    });

    const history = useHistory();
    const user = getSessionUser();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const filteredRow = state.taxisRemises.filter(row => { return row.show })    
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.taxisRemises.length - page * rowsPerPage);

    useEffect(() => {
        loadTaxisRemises(props.idAeropuerto);
        EventListener();
    }, [props.idIdioma, props.idAeropuerto])

    const loadTaxisRemises = aeropuerto => {
        setPage(0);
        document.getElementById("searchInput").value = "";
        const taxisRemises = [];
        GetTaxiRemisByAeropuerto(props.idIdioma, aeropuerto)
            .then(async(resGetTaxiRemis) => {
                if (resGetTaxiRemis.data) {
                    for (var i = 0; i < resGetTaxiRemis.data.length; i++){
                        const taxiRemis = resGetTaxiRemis.data[i];
                        taxiRemis['show'] = true;
                        taxisRemises.push(taxiRemis);
                    }
                    setState({ ...state, taxisRemises: taxisRemises });
                }
            })
    }

    const handleAddClick = () => history.push("/TaxiRemis");

    const handleEditClick = (id) => {
        const ul = document.querySelector("div.tools ul.show");
        if (ul) ul.classList.remove("show");
        history.push(`/TaxiRemis/${id}`);
    }

    const handleTableOptions = (e) => {
        const icon = e.currentTarget;
        const ul = icon.nextSibling;
        if (ul.classList.contains("show")) {
            ul.classList.remove("show")
        } else {
            ul.classList.add("show")
        }
    }

    const handleActiveClick = (taxiRemis) => {
        const obj = {
            id: taxiRemis.id,
            idAeropuerto: taxiRemis.idAeropuerto,
            idIdioma: taxiRemis.idIdioma,
            dsTransporte: taxiRemis.dsTransporte,
            dsTipoTransporte: taxiRemis.dsTipoTransporte,
            dsUbicacion: taxiRemis.dsUbicacion,
            dsHorario: taxiRemis.dsHorario,
            open24: taxiRemis.open24,
            dsTelefono1: taxiRemis.dsTelefono1,
            dsTelefono2: taxiRemis.dsTelefono2,
            dsEmail: taxiRemis.dsEmail,
            lkImagen: taxiRemis.lkImagen,
            mcInactiva: !taxiRemis.mcInactiva,
            base64Image: null,
            tarifas: taxiRemis.tarifas,
            dsUsuario: user.DS_Nombre,
            feCreacion: taxiRemis.feCreacion,
            feModificacion: new Date().toISOString().replace("Z", ""),
        }

        SaveOrUpdateTaxiRemis(obj)
            .then((resTaxiRemis) => {
                loadTaxisRemises(props.idAeropuerto);
            })
    }

    const handleRemoveClick = (taxiRemis) => {
        const ul = document.querySelector("div.tools ul.show");
        if (ul) ul.classList.remove("show");

        swal.fire({
            title: 'Eliminar servicio',
            text: '¿Está seguro que desea eliminar el transporte  ' + taxiRemis.dsTransporte + '?',
            ...confirmSwal
        })
        .then((confirm) => {
            if (confirm.value) {
                DeleteTaxiRemis({ id: taxiRemis.id })
                    .then(async () => {
                        await loadTaxisRemises(props.idAeropuerto);
                        swal.fire({
                            text: 'Se ha eliminado el transporte con éxito',
                            ...successSwal
                        })
                    });
            }
        });
    }

    const handlePrintClick = (taxiRemis) => {
        const ul = document.querySelector("div.tools ul.show");
        if (ul) ul.classList.remove("show");
        const PDF = GetPDFTemplate();

        var tableToAdd = {
            style: 'table',
            table: {
                widths: ['30%', '70%'],
                body: [
                    [
                        { text: 'Taxi o Remis', style: 'tableHeader' },
                        { text: `${taxiRemis.dsTransporte}`, style: 'tableData' },
                    ],
                    [
                        { text: 'Tipo de transporte', style: 'tableHeader' },
                        { text: `${taxiRemis.dsTipoTransporte}`, style: 'tableData' },
                    ],
                    [
                        { text: 'Horario', style: 'tableHeader' },
                        { text: `${taxiRemis.open24 ? 'Funciona las 24 hs.' : `Funciona ${taxiRemis.dsHorario}hs`}`, style: 'tableData' },
                    ],
                    [
                        { text: 'Tarifas preacordadas', style: 'tableHeader' },
                        { text: `${taxiRemis.tarifas.length == 0 ? 'No' : 'Sí'}`, style: 'tableData' },
                    ],
                    [
                        { text: 'Teléfono', style: 'tableHeader' },
                        { text: `${taxiRemis.dsTelefono1 ? taxiRemis.dsTelefono1 : ""} ${taxiRemis.dsTelefono2 ? '- ' + taxiRemis.dsTelefono2 : ''}`, style: 'tableData' },
                    ],
                    [
                        { text: 'Ubicación en el Aeropuerto', style: 'tableHeader' },
                        { text: `${taxiRemis.dsUbicacion ? taxiRemis.dsUbicacion : ""}`, style: 'tableData' },
                    ],
                    [
                        { text: 'Email', style: 'tableHeader' },
                        { text: `${taxiRemis.dsEmail ? taxiRemis.dsEmail : ""}`, style: 'tableData' },
                    ],
                    [
                        { text: 'Última modificación:', style: 'tableHeader' },
                        { text: `${new Date(taxiRemis.feModificacion ? taxiRemis.feModificacion : taxiRemis.feCreacion).toLocaleDateString()} ${taxiRemis.dsUsuario ? taxiRemis.dsUsuario : ""}`, style: 'tableData' },
                    ],
                ]
            },
            layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                    return (columnIndex % 2 == 0) ? '#193b73' : null;
                },
                hLineWidth: function (i, node) {
                    return 1;
                },
                vLineWidth: function (i, node) {
                    return 1;
                },
                hLineColor: function (i, node) {
                    return '#193b73';
                },
                vLineColor: function (i, node) {
                    return '#193b73';
                },
            }
        };
        var styles = {
            header: {
                fontSize: 16,
                bold: true,
                alignment: 'center',
                margin: [0, 30],
            },
            table: {
                widht: '100%',
                margin: [0, 30],
                alignment: 'center',
                borderRadius: 33
            },
            tableHeader: {
                color: 'white',
                bold: true,
                fontSize: 13,
                margin: [0, 2.5]
            },
            tableData: {
                margin: 5,
                alignment: 'justify'
            }
        }

        PDF.content[0].columns[0].fit = [150, 100];
        PDF.content[0].columns[1].text = "Detalle de Taxi o Remis";
        PDF.content.push(tableToAdd)
        PDF.styles = styles;

        pdfMake.createPdf(PDF).open()
    }

    // TABLA-PRINCIPIO

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleSearch = (e) => {
        const search = e ? e.target.value.toLowerCase().trim() : document.getElementById("searchInput").value.toLowerCase().trim();
        const keys = ["dsTransporte"];
        const newRows = [];

        if(search.length >= 2) {
            for(var i = 0; i < state.taxisRemises.length; i++) {
                let bool,
                    row = state.taxisRemises[i];
                for(var index = 0; index < keys.length; index++) {
                    let value = row[keys[index]];
                    if(value) {
                        value = value.toLowerCase();
                        bool = index == 0 ? value.indexOf(search) != -1 :  bool || value.indexOf(search) != -1;
                    }
                }
                row.show = bool;
                newRows.push(row);
            }
        } else {
            for(var i = 0; i < state.taxisRemises.length; i++) {
                state.taxisRemises[i].show = true;
                newRows.push(state.taxisRemises[i]);
            }
        }
        setPage(0)
        setState({ ...state, taxisRemises: newRows})
    }

    const clearSearch = () => {
        document.getElementById("searchInput").value = "";
        handleSearch(undefined)
    }

    // TABLA-FIN

    return (
        <div className="main-content">
            <br />
            <h1 className="title">Transporte - <b className="green-text">TAXIS Y REMISES</b></h1>
            <br />
            <br />
            <div className="content">
                <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-6">
                        <SelectAeropuerto />
                        <br/>
                    </div>
                    <div className="col-sm-6 col-md-2 col-lg-2 offset-md-2 offset-lg-1">
                        <SwitcherIdioma />
                        <br/>
                    </div>
                    <div className="col-sm-6 col-md-3 col-lg-3">
                        <Button variant="contained" color="primary" className="btnAdd" startIcon={<Add />} onClick={handleAddClick}>Nuevo</Button>
                        <br/>
                    </div>
                    <br/>
                </div>

                <div className="row">
                    <div className="col-md-5">
                        <div className="search-box">
                            <br />
                            <br />
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="type" name="search" id="searchInput" placeholder="Buscar por nombre remisería o taxi" onKeyUp={handleSearch} />
                        </div>
                        <br/>
                    </div>
                </div>
                <Paper className="table-root">
                    <div className="table-wrapper">
                        <Table>
                            <TableBody>
                                {(rowsPerPage > 0 ? filteredRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRow).map((row) => (
                                    <TableRow key={row.id} >
                                        <TableCell component="th" scope="row" className="visibility">
                                            <HtmlTooltip
                                            placement="top"
                                            title={
                                                <div>
                                                    {!row.mcInactiva ?
                                                        <span><strong>Ocultar: </strong>Este elemento dejará de estar visible para el lector pero no se eliminará.</span>
                                                        :
                                                        <span><strong>Mostrar: </strong>Este elemento estará visible para el lector.</span> 
                                                    }
                                                </div>
                                            }>
                                                {row.mcInactiva ?
                                                    <VisibilityOff className="gray" onClick={() => handleActiveClick(row)} />
                                                    :
                                                    <Visibility className="green" onClick={() => handleActiveClick(row)} />
                                                }
                                            </HtmlTooltip>
                                        </TableCell>
                                        <TableCell component="th" scope="row" className="logo">
                                            <img src={row.base64Image ? row.base64Image : NoFoundImage} alt="LogoServicio" />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div className="information">
                                                <p>{row.dsTransporte}</p>
                                                <p>{row.dsTipoTransporte}</p>
                                                <p className="green-text">{row.open24 ? 'Funciona las 24 hs.' : `Funciona ${row.dsHorario}hs`}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row" className="tarifas-logo">
                                            <img src={row.tarifas.length == 0 ? AutitoTarifasBN : AutitoTarifasColor } alt="AutitoLogo"/>
                                            <p>{ row.tarifas.length == 0 ? 'Sin Tarifas preacordadas' : 'Tarifas preacordadas'}</p>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div className="information">
                                                <p>Ultima modificación</p>
                                                <p>{new Date(row.feModificacion ? row.feModificacion : row.feCreacion).toLocaleDateString()}</p>
                                                <p className="link" onClick={() => handleEditClick(row.id)}>Ver mas detalles</p>
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div className="tools">
                                                <IconButton onClick={(e) => handleTableOptions(e)}>
                                                    <MoreVert />
                                                </IconButton>
                                                <ul>
                                                    <li><Button onClick={() => handleEditClick(row.id)}>Modificar</Button></li>
                                                    <li><Button onClick={() => handleRemoveClick(row)}>Eliminar</Button></li>
                                                    <li><Button onClick={() => handlePrintClick(row)}>Imprimir</Button></li>
                                                </ul>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                <TableRow style={{ height: "48px" }}>
                                    <TableCell colSpan={7} />
                                </TableRow>

                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={filteredRow.length}
                                        labelRowsPerPage="Filas por página"
                                        page={page}
                                        onChangePage={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={7}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        labelDisplayedRows={(from=page) => (`${from.from}-${from.to === -1 ? from.count : from.to} de ${from.count}`)}>
                                    </TablePagination>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        idIdioma: state.appReducer.idIdioma,
        aeropuertos: state.aeropuertoReducer.aeropuertos,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto
    }
}

export default connect(mapStateToProps)(TaxiRemis);