import Axios from "axios";
import './axiosConfig';

const GETALQUILERDEAUTOBYID_URL = 'AlquilerAutos/GetAlqAutosById';
const GETALQUILERDEAUTOBYAEROPUERTO_URL = 'AlquilerAutos/GetAlqAutosByAeropuerto';
const DELETEALQUILERDEAUTO_URL = 'AlquilerAutos/Delete';
const SAVEORUPDATEALQUILERDEAUTO_URL = 'AlquilerAutos/SaveOrUpdate';

export const GetAlquilerAutoById = (id) => {
    return Axios.get(GETALQUILERDEAUTOBYID_URL, { params: { id } });
}

export const GetAlquilerAutoByAeropuerto = (idIdioma, idAeropuerto) => {
    return Axios.get(GETALQUILERDEAUTOBYAEROPUERTO_URL, { params: { idIdioma, idAeropuerto } });
}

export const DeleteAlquilerAuto = (alquiler) => {
    return Axios.post(DELETEALQUILERDEAUTO_URL, alquiler);
}

export const SaveOrUpdate = (alquiler) => {
    return Axios.post(SAVEORUPDATEALQUILERDEAUTO_URL, alquiler);
}