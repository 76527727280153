import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { handleSelectAero } from '../redux/action';

const SelectAeropuerto = (props) => {
    
    const handleChangeAeropuerto = (aeropuerto) => {
        props.handleSelectAero(aeropuerto.value);
    }
    
    const filtradosAeropuertos = () => (props.aeropuertos.filter(aero => aero.label !== "SNA")) 
    return (
        <div className="select-aeropuerto">
            <label className="label">Seleccione un Aeropuerto</label>
            <Select placeholder={<div>Seleccione</div>} styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} options={filtradosAeropuertos()} onChange={handleChangeAeropuerto} value={filtradosAeropuertos().find(x => x.value === props.idAeropuerto)} />
        </div>
    )
}

function mapStateProps(state) {
    return {
        idIdioma: state.appReducer.idIdioma,
        aeropuertos: state.aeropuertoReducer.aeropuertos,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto
    }
}

export default connect(mapStateProps, { handleSelectAero })(SelectAeropuerto)