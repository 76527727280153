import Axios from 'axios';
import './axiosConfig';

const GETBUSESBYID_URL = 'Buses/GetBusesById';
const GETBUSESBYAEROPUERTO_URL = 'Buses/GetBusesByAeropuerto';
const DELETEBUSES_URL = 'Buses/Delete';
const SAVEORUPDATEBUSES = 'Buses/SaveOrUpdate';

export const GetBusesById = (id) => {
    return Axios.get(GETBUSESBYID_URL, { params: { id } });
}

export const GetBusesByAeropuerto = (idIdioma, idAeropuerto) => {
    return Axios.get(GETBUSESBYAEROPUERTO_URL, { params: { idIdioma, idAeropuerto } });
}

export const DeleteBus = (buses) => {
    return Axios.post(DELETEBUSES_URL, buses);
}

export const SaveOrUpdate = (buses) => {
    return Axios.post(SAVEORUPDATEBUSES, buses);
}