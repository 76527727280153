import { LOAD_ITEMS_USUARIO, CLEAR_COMPONENTES_PERMITIDOS } from './action';

const initialState = {
    permisos: [],
    aeropuertos: []
}

function permisosReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_ITEMS_USUARIO:
            return {
                ...state,
                permisos: action.payload.ComponentesPermitidos,
                aeropuertos: action.payload.Aeropuertos
            }

        case CLEAR_COMPONENTES_PERMITIDOS:
            return {
                ...initialState
            }
        default:
            break;
    }
    return state;
};

export default permisosReducer;