import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from '@material-ui/icons'

const Styles = makeStyles((theme) => ({
    pagination: {
        display: 'block !important',
        paddingTop: 24,
    },
    pageItems: {
        alignItems: 'center',
        display: 'flex',
        width: 'auto',
        float: 'right',
    },
    pageItem: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #707070',
        borderRadius: 4,
        margin: '0 5px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        width: 35,
        height: 35,
        cursor: 'pointer',
        transition: '.4s all',
        "& > div": {
            color: '#6C6C6C',
            textAlign: 'center',
            margin: 'auto',
        },
        "&.active, &:hover:not(.disabled)": {
            background: '#1068A0',
            "& > div": {
                color: 'white',
            }
        },
        "&.disabled": {
            background: '#b8b8b8',
        }
    }
}))

export default function Pagination(props) {
    const classes = Styles();
    const [pages, setPages] = useState([1,2,3]);
    const { count, page, rowsPerPage, onChangePage } = props;

    useEffect(() => {
        const number = Math.ceil(count / rowsPerPage) < 3 ? Math.ceil(count / rowsPerPage) : 3;
        const pages = []
        for (var i = 0; i < number; i++) {
            const p = page == 0 ? (page + 1) + i : page == Math.ceil(count / rowsPerPage) - 1 && Math.ceil(count / rowsPerPage) >= 3 ? (page - 1) + i : page + i;
            pages.push(p);
        }
        setPages(pages);
    }, [props])

    const handleFirstPageButtonClick = (event) => {
        if (!event.currentTarget.classList.contains("disabled"))
            onChangePage(event, 0);
    };

    const handlePageButtonClick = (event, p) => {
        if (!event.currentTarget.classList.contains("disabled"))
        onChangePage(event, p);
    }

    const handleLastPageButtonClick = (event) => {
        if (!event.currentTarget.classList.contains("disabled"))
            onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.pagination}>
            <div className={classes.pageItems}>
                <div className={`${classes.pageItem} ${page == 0 ? 'disabled' : ''}`} onClick={handleFirstPageButtonClick} hidden={4 > Math.ceil(count / rowsPerPage)}>
                    <div>
                        <FirstPage />
                    </div>
                </div>
                <div className={`${classes.pageItem} ${page == 0 ? 'disabled' : ''}`} onClick={(event) => handlePageButtonClick(event, page - 1)} hidden={4 > Math.ceil(count / rowsPerPage)}>
                    <div>
                        <NavigateBefore />
                    </div>
                </div>
                {(pages).map((item, i) => (
                    <div key={"pagina" + i} className={`${classes.pageItem} ${page == item - 1 ? 'active': ''}`} onClick={(event) => handlePageButtonClick(event, item - 1)} aria-label={"Pagina" + item}>
                        <div>{item}</div>
                    </div>
                ))}
                <div className={`${classes.pageItem} ${page == Math.ceil(count / rowsPerPage) - 1 ? 'disabled' : ''}`} onClick={(event) => handlePageButtonClick(event, page + 1)} hidden={4 > Math.ceil(count / rowsPerPage)}>
                    <div>
                        <NavigateNext />
                    </div>
                </div>
                <div className={`${classes.pageItem} ${page == Math.ceil(count / rowsPerPage) - 1 ? 'disabled' : ''}`} onClick={handleLastPageButtonClick} hidden={4 > Math.ceil(count / rowsPerPage)}>
                    <div><LastPage /></div>
                </div>
            </div>
        </div>
    );

}