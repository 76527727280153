export const SAVE_SESSION = 'SAVE_SESSION';
export const LOAD_SESSION = 'LOAD_SESSION';
export const LOGOUT = "LOGOUT";
export const CLEAR_SESSION = "CLEAR_SESSION";
export const TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const IS_LOADING = "IS_LOADING";
export const LOAD_ITEMS_USUARIO = "LOAD_ITEMS_USUARIO";
export const CLEAR_COMPONENTES_PERMITIDOS = "CLEAR_COMPONENTES_PERMITIDOS";
export const SWITCH_IDIOMA = "SWITCH_IDIOMA";
export const SELECT_AEROPUERTOS = "SELECT_AEROPUERTOS";
export const SELECT_ID_AEROPUERTOS = "SELECT_ID_AEROPUERTOS";
export const SELECT_LIST_AEROPUERTOS = "SELECT_LIST_AEROPUERTOS";
export const SELECT_IDIOMA_AEROPUERTOS = "SELECT_IDIOMA_AEROPUERTOS";

export const setSessionUser = usuario => {

	return {
		type: SAVE_SESSION,
		payload: usuario
	}
};

export const loadSessionUser = usuario => {

	return {
		type: LOAD_SESSION,
		payload: usuario
	}
};

export const clearSession = () => {
	return {
		type: CLEAR_SESSION,
		payload: ''
	}
}

export const clearComponentesPermitidos = () => {
	return {
		type: CLEAR_COMPONENTES_PERMITIDOS,
		payload: ''
	}
}

export const isLoading = (isLoading) => {
	return {
		type: IS_LOADING,
		payload: isLoading
	}
}
//ASI UTILIZA CUANDO LUEGO CUANDO SE QUIERE LLAMAR A LA API Y EN EL RESPONSE DISPARAR LA ACCION. 
//PARA ESTO TAMBIEN HAY QUE AGREGAR EL REDUX-THUNK EN EL STORE
// export const getComponentesPermitidos = (idUsuario) => {
// 	return dispatch => {
// 		return ObtenerComponentesPermitidos(idUsuario)
// 			.then(response => {
// 				dispatch({
// 					type: LOAD_ITEMS_USUARIO,
// 					payload: response.data
// 				});
// 			});
// 	};

// }

export const setItemsUsuario = (componentes) => {
	return {
		type: LOAD_ITEMS_USUARIO,
		payload: componentes
	}
}

export const switchIdioma = (idIdioma) => {
	return {
		type: SWITCH_IDIOMA,
		payload: idIdioma
	}
}

export const selectAeropuerto = (data) => {
	return {
		type: SELECT_AEROPUERTOS,
		payload: data
	}
}

export const handleAeropuertos = (data) => {
	return {
		type: SELECT_LIST_AEROPUERTOS,
		payload: data
	}
}

export const handleAeropuertosByIdioma = (data) => {
	return {
		type: SELECT_IDIOMA_AEROPUERTOS,
		payload: data
	}
}

export const handleSelectAero = (idAeropuerto) => {
	return {
		type: SELECT_ID_AEROPUERTOS,
		payload: idAeropuerto
	}
}