import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from "react-router-dom"
import { getSessionUser, logout } from '../../services/auth.service'
import { clearSession, clearComponentesPermitidos } from '../../redux/action'
import { connect } from 'react-redux'

import "./componentStyles.css"
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Divider } from '@material-ui/core'
import { AccountCircle, ExpandLess, ExpandMore, ExitToApp, Home } from '@material-ui/icons'
import logoMenu from '../../assets/img/logoMenu_aa2000.svg'

import { ROL_MASTER, ROL_ADMIN, ROL_EDITOR } from '../../services/permisos.service'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: { paddingLeft: theme.spacing(5) + 'px !important' },
    nestedInside: { paddingLeft: theme.spacing(7) + 'px !important' },
})
);

const Sidebar = (props) => {
    const [sidebarClass, setSidebarClass] = useState("sidebar");

    const history = useHistory();

    // GET ESTADO DEL USUARIO STORE
    const user = getSessionUser();

    // PERMISOS DE MENU ROLES
    const rolMaster = user.IdRol === ROL_MASTER;
    const rolAdmin = user.IdRol === ROL_ADMIN;
    const rolEditor = user.IdRol === ROL_EDITOR;

    const [profileImg, setProfileImg] = useState(null)

    // PERMISOS DE MENU ROLES
    const [componentes, setComponentes] = useState({
        comoLlego: false,
        comoLlegoMapa: false,
        comoLlegoMapaInterno:false,
        estacionamiento: false,
        estLongStay: false,
        estDesLateral: false,
        estMapaUbicacion: false,
        estPSur: false,
        estPNorte: false,
        estPSarm: false,
        estTextoDiscap: false,
        estCEstadia: false,
        lineasAereas: false,
        noticias: false,
        notas: false,
        novedades: false,
        traficos:false,
        organismos: false,
        pregFrecuentes: false,
        servicios: false,
        transporte: false,
        transporteTaxis: false,
        transporteBuses: false,
        transporteAquiler: false,
        transporteBanner: false,
        vuelos: false,
        vuelosArribos: false,
        vuelosPartidas: false,
        vuelosBanners: false,
        alertas: false,
        cierresAeropuertos: false
    })

    useEffect(() => {
        if (user.LkImagen)
            setProfileImg(user.base64Image)
        if (props.permisos)
            setComponentes(props.permisos);
    })

    const classes = useStyles();

    const [openUsuario, setOpenUsuario] = useState(false);
    const [openComoLlego, setOpenComoLlego] = useState(false);
    const [openEstacionamiento, setOpenEstacionamiento] = useState(false);
    const [openNoticias, setOpenNoticias] = useState(false);
    const [openTransporte, setOpenTransporte] = useState(false);
    const [openUsuarios, setOpenUsuarios] = useState(false);
    const [openVuelos, setOpenVuelos] = useState(false);
    const [openAlertas, setOpenAlertas] = useState(false);

    const linkTo = (page) => {
        switch (page) {
            case 'logOut':
                props.clearComponentesPermitidos();
                props.clearSession();
                logout(history);
                break;
            default:
                history.push(`/${page}`);
        }
    }


    return (
        <div className="sidebar">
            <ListItem>
                <div className="logoMenu">
                    <img src={logoMenu} alt="Aeropuertos Argentina 2000" />
                </div>
            </ListItem>

            <Divider />

            <ListItem button onClick={() => setOpenUsuario(!openUsuario)}>
                <ListItemIcon>{profileImg ? <div className="user-icon" style={{backgroundImage: "url(" + profileImg + ")"}} ></div> : <AccountCircle />}</ListItemIcon>
                <ListItemText primary={user.DS_Nombre} />
                {openUsuario ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openUsuario} timeout="auto" unmountOnExit>
                <List disablePadding dense>
                    <ListItem button onClick={() => linkTo("logOut")} className={classes.nested} >
                        <ListItemIcon><ExitToApp /></ListItemIcon>
                        <ListItemText primary="Cerrar Sesión" />
                    </ListItem>
                </List>
            </Collapse>

            <Divider />

            <List disablePadding dense>
                <ListItem button onClick={() => linkTo("Home")} >
                    <ListItemIcon><Home /></ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
            </List>

            <Divider />

            <List disablePadding dense>

                {/* {componentes.comoLlego || rolMaster ? <ListItem button onClick={() => setOpenComoLlego(!openComoLlego)} className={classes.nested}>
                    <ListItemText primary="Como LLego" />
                    {openComoLlego ? <ExpandLess /> : <ExpandMore />}
                </ListItem> : ""}

                <Collapse in={openComoLlego} timeout="auto" unmountOnExit>
                    <List disablePadding dense>
                        {componentes.comoLlegoMapa || rolMaster ? <ListItem button className={classes.nestedInside}>
                            <ListItemText primary="Mapa" />
                        </ListItem> : ""}

                        {componentes.comoLlegoMapaInterno || rolMaster ? <ListItem button className={classes.nestedInside} >
                            <ListItemText primary="Mapa Aeropuerto" />
                        </ListItem> : ""}
                    </List>
                </Collapse> */}

                {componentes.estacionamiento || rolMaster ? <ListItem button onClick={() => linkTo('Estacionamientos')} className={classes.nested}>
                    <ListItemText primary="Estacionamiento" />
                </ListItem> : ""}

                {/* <Collapse in={openEstacionamiento} timeout="auto" unmountOnExit>
                    <List disablePadding dense>
                        {componentes.estLongStay || rolMaster ? <ListItem button className={classes.nestedInside}>
                            <ListItemText primary="Parking Long Stay" />
                        </ListItem> : ""}

                        {componentes.estDesLateral || rolMaster ? <ListItem button className={classes.nestedInside} >
                            <ListItemText primary="Texto descriptivo lateral" />
                        </ListItem> : ""}

                        {componentes.estMapaUbicacion || rolMaster ? <ListItem button className={classes.nestedInside} >
                            <ListItemText primary="Mapa ubicación estacionamiento" />
                        </ListItem> : ""}

                        {componentes.estPSur || rolMaster ? <ListItem button className={classes.nestedInside} >
                            <ListItemText primary="Especificaciones Parking Sur" />
                        </ListItem> : ""}

                        {componentes.estPNorte || rolMaster ? <ListItem button className={classes.nestedInside} >
                            <ListItemText primary="Especificaciones Parking Norte" />
                        </ListItem> : ""}

                        {componentes.estPSarm ? <ListItem button className={classes.nested} >
                            <ListItemText primary="Especificaciones Parking Sarmiento" />
                        </ListItem> : ""}

                        {componentes.estTextoDiscap || rolMaster ? <ListItem button className={classes.nestedInside} >
                            <ListItemText primary="Texto personas con discapacidad" />
                        </ListItem> : ""}

                        {componentes.estCEstadia || rolMaster ? <ListItem button className={classes.nestedInside} >
                            <ListItemText primary="Calculo de estadia" />
                        </ListItem> : ""}
                    </List>
                </Collapse> */}
                
                {componentes.lineasAereas || rolMaster ? <ListItem button onClick={() => linkTo("LineasAereas")} className={classes.nested}>
                    <ListItemText primary="Lineas Aereas" />
                </ListItem> : ""}

                {componentes.noticias || rolMaster ? <ListItem button onClick={() => setOpenNoticias(!openNoticias)} className={classes.nested}>
                    <ListItemText primary="Prensa" />
                    {openNoticias ? <ExpandLess /> : <ExpandMore />}
                </ListItem> : ""}

                <Collapse in={openNoticias} timeout="auto" unmountOnExit>
                    <List disablePadding dense>
                        {/* {componentes.notas || rolMaster ? <ListItem button className={classes.nestedInside} onClick={() => linkTo("Notas")}>
                            <ListItemText primary="Notas" />
                        </ListItem> : ""} */}
                        {componentes.novedades || rolMaster ? <ListItem button className={classes.nestedInside} onClick={() => linkTo("Novedades")}>
                            <ListItemText primary="Noticias" />
                        </ListItem> : ""}
                        {componentes.traficos || rolMaster ? <ListItem button className={classes.nestedInside} onClick={() => linkTo("Traficos")}>
                            <ListItemText primary="Reportes de tráfico" />
                        </ListItem> : ""}
                    </List>
                </Collapse>

                {componentes.organismos || rolMaster ? <ListItem button onClick={() => linkTo("Organismos")} className={classes.nested}>
                    <ListItemText primary="Organismos" />
                </ListItem> : ""}

                {componentes.pregFrecuentes || rolMaster ? <ListItem button onClick={() => linkTo("PreguntasFrecuentes")} className={classes.nested}>
                    <ListItemText primary="Preguntas Frecuentes" />
                </ListItem> : ""}

                {/* {componentes.servicios || rolMaster ? <ListItem button onClick={() => linkTo("Servicios")} className={classes.nested}>
                    <ListItemText primary="Servicios" />
                </ListItem> : ""}

                {componentes.transporte || rolMaster ? <ListItem button onClick={() => setOpenTransporte(!openTransporte)} className={classes.nested}>
                    <ListItemText primary="Transporte" />
                    {openTransporte ? <ExpandLess /> : <ExpandMore />}
                </ListItem> : ""}

                <Collapse in={openTransporte} timeout="auto" unmountOnExit>
                    <List disablePadding dense>
                        {componentes.transporteTaxis || rolMaster ? <ListItem button onClick={() => linkTo("TaxisRemises")} className={classes.nestedInside}>
                            <ListItemText primary="Taxis y Remises" />
                        </ListItem> : ""}

                        {componentes.transporteBuses || rolMaster ? <ListItem button onClick={() => linkTo("Buses")} className={classes.nestedInside} >
                            <ListItemText primary="Buses" />
                        </ListItem> : ""}

                        {componentes.transporteAquiler || rolMaster ? <ListItem button onClick={() => linkTo("Alquileres")} className={classes.nestedInside} >
                            <ListItemText primary="Alquiler de autos" />
                        </ListItem> : ""} */}

                        {/* {componentes.transporteBanner || rolMaster ? <ListItem button className={classes.nestedInside} >
                            <ListItemText primary="Banner advertencia" />
                        </ListItem> : ""} */}
                    {/* </List>
                </Collapse> */}

                { rolMaster || rolAdmin ?
                    <ListItem button onClick={() => setOpenUsuarios(!openUsuarios)} className={classes.nested}>
                    <ListItemText primary="Usuarios" />
                    {openUsuarios ? <ExpandLess /> : <ExpandMore />}
                </ListItem> : ""}
                
                <Collapse in={openUsuarios} timeout="auto" unmountOnExit>
                    <List disablePadding dense>
                        {rolMaster ?
                            <ListItem button onClick={() => linkTo("Usuarios")} className={classes.nestedInside}>
                                <ListItemText primary="Usuarios" />
                            </ListItem> : ""
                        }

                        <ListItem button onClick={() => linkTo("Grupos")} className={classes.nestedInside}>
                            <ListItemText primary="Grupos" />
                        </ListItem>
                    </List>
                </Collapse>
                
                {/* {componentes.vuelos || rolMaster ? <ListItem button onClick={() => setOpenVuelos(!openVuelos)} className={classes.nested}>
                    <ListItemText primary="Vuelos" />
                        {openVuelos ? <ExpandLess /> : <ExpandMore />}
                </ListItem> : ""}

                <Collapse in={openVuelos} timeout="auto" unmountOnExit>
                    <List disablePadding dense>
                        {componentes.vuelosArribos || rolMaster ? <ListItem button className={classes.nestedInside}>
                            <ListItemText primary="Arribos" />
                        </ListItem> : ""}

                        {componentes.vuelosPartidas || rolMaster ? <ListItem button className={classes.nestedInside} >
                            <ListItemText primary="Partidas" />
                        </ListItem> : ""}

                        {componentes.vuelosBanners || rolMaster ? <ListItem button className={classes.nestedInside} >
                            <ListItemText primary="Banners" />
                        </ListItem> : ""}
                    </List>
                </Collapse> */}

                {componentes.alertas  || rolEditor || rolAdmin || rolMaster ? <ListItem button onClick={() => setOpenAlertas(!openAlertas)} className={classes.nested}>
                    <ListItemText primary="Alertas" />
                        {openAlertas ? <ExpandLess /> : <ExpandMore />}
                </ListItem> : "" }

                <Collapse in={openAlertas} timeout="auto" unmountOnExit>
                    <List disablePadding dense>
                        {componentes.cierresAeropuertos || rolEditor || rolAdmin || rolMaster ? <ListItem button className={classes.nestedInside} onClick={() => linkTo("CierresAeropuertos")}>
                            <ListItemText primary="Cierres de Aeropuertos" />
                        </ListItem> : ""}

                    </List>
                </Collapse>
            </List>
        </div>
    )
}

function mapStateToProps(state) {

    return {
        permisos: state.permisosReducer.permisos,
    }
}

export default connect(mapStateToProps, { clearSession, clearComponentesPermitidos })(Sidebar)