import Axios from 'axios';
// import { baseURLDev } from '../environments/environment.env';
import swal from 'sweetalert2';
import { throwError } from 'rxjs';
import { isAuthenticated, getSessionUser } from './auth.service';

require('dotenv').config()

Axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

Axios.interceptors.request.use(
  request => requestHandler(request),
  error => errorResponseHandler(error)
)

Axios.interceptors.response.use(
  response => responseHandler(response),
  error => errorResponseHandler(error)
)

const requestHandler = async (request) => {

  document.body.classList.add('loading-app');
  const headers = { Token: '' };

  if (isAuthenticated()) {

    const userSession = getSessionUser()
    headers.Token = userSession.Token;
    request.headers = { Token: headers.Token };
  }
  return request
}

const responseHandler = async (response) => {

  document.body.classList.remove('loading-app');
  return response
}

//MANEJO DE ERRORES
const errorResponseHandler = (error) => {
  console.log("error", error.response, error.message)
  document.body.classList.remove('loading-app');
  let errMsg

  if (error.message === "Network Error") {
    errMsg = 'Ha ocurrido un error en la conexión. Reintente nuevamente por favor';
  }

  else {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 408 || error.response.status === 26 || error.response.status === 19) {
        return showSecurityError(error);
      }
      if (error.response.status === 0) {
        errMsg = 'Ha ocurrido un error en la conexión. Reintente nuevamente por favor';
      } else {
        errMsg = `${error.response.status} - ${error.response.statusText || ''} - ${error.response.data.ExceptionMessage || error.response.data.Message || ''}`
      }
    } else {
      errMsg = error.response.data.Message ? error.response.data.Message : error.toString();
    }
  }
  swal.fire({
    title: 'Ocurrió un error inesperado',
    icon: 'error',
    text: errMsg,
    allowOutsideClick: false,
    confirmButtonText: 'Ok'
  }).then(() => {
    if (error.response == undefined || (error.response.status != 500 && error.response.status != 0))
      window.location = '/login';
  });
  return throwError(error.response);
}

const showSecurityError = (error) => {

  let errMsg;
  if (error.response.status === 401) {
    errMsg = 'Token Inválido';
  }
  if (error.response.status === 408) {
    errMsg = 'Su sesión ha expirado';
  }
  if (error.response.status === 26 || error.response.status === 19) {
    errMsg = 'Problemas con conectarse a la red';
  }
  swal.fire({
    title: 'Información de Seguridad',
    icon: 'warning',
    text: errMsg,
    allowOutsideClick: false,
    confirmButtonText: 'Ok'
  }).then(() => {
      window.location = '/login';
    }
  );
  return throwError(error.response);
}
