import Axios from "axios"
import './axiosConfig';

const GETORGANISMOSOFICIALESBYIDIOMA_URL = 'OrganismoOficial/GetOrganismosByIdioma';
const GETORGANISMOSOFICIALESBYAEROPUERTO_URL = 'OrganismoOficial/GetOrganismosByAeropuerto';
const POSTORGANISMOOFICIAL_URL = 'OrganismoOficial/SaveOrUpdateOrganismo';
const GETORGANISMOOFICIAL_URL = 'OrganismoOficial/GetOrganismo';
const DELETEORGANISMOOFICIAL_URL = 'OrganismoOficial/DeleteOrganismo';

export const GetOrganismosByIdioma=(idIdioma) => {
    return Axios.get(GETORGANISMOSOFICIALESBYIDIOMA_URL,{ params: { idIdioma } });
}

export const GetOrganismosByAeropuerto=(idAeropuerto) => {
    return Axios.get(GETORGANISMOSOFICIALESBYAEROPUERTO_URL,{ params: { idAeropuerto } });
}

export const SaveOrganismo=(organimoOficial)=> {
    return Axios.post(POSTORGANISMOOFICIAL_URL, organimoOficial);
}

export const GetOrganismo=(idOrganismo)=> {
    return Axios.get(GETORGANISMOOFICIAL_URL,{params: {idOrganismo}});
}

export const DeleteOrganismo=(organimoOficial)=> {
    return Axios.post(DELETEORGANISMOOFICIAL_URL, organimoOficial);
}