import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './pages/login/login';
import Home from './pages/home/home';
import Usuario from './pages/usuarios/usuario'
import UsuarioABM from './pages/usuarios/usuariosAbm/usuarioAbm'
import Grupo from './pages/grupos/grupo'
import GrupoABM from './pages/grupos/gruposAbm/grupoAbm'
import { PrivateRoute } from './services/auth.guard'
import Layout from './pages/layout/layout';
import Estacionamiento from './pages/estacionamientos/estacionamientos';
import EstacionamientosABM from './pages/estacionamientos/estacionamientosABM/estacionamientosABM';
import Notas from './pages/noticias/notas/notas';
import NotasABM from './pages/noticias/notas/notasABM';
import Novedades from './pages/noticias/novedades/novedades';
import NovedadesABM from './pages/noticias/novedades/novedadesABM';
import Traficos from './pages/noticias/traficos/traficos';
import TraficosABM from './pages/noticias/traficos/traficosABM';
import Servicios from './pages/servicios/servicios';
import ServiciosABM from './pages/servicios/serviciosAbm/serviciosAbm';
import LineasAereas from './pages/lineasAereas/lineasAereas';
import LineasAereasAbm from './pages/lineasAereas/lineasAereasAbm/lineasAereasAbm';
import OrganismosOficiales from './pages/organismosOficiales/organismosOficiales';
import OrganismosOficialesAbm from './pages/organismosOficiales/organismosOficialesAbm/organismosOficialesAbm';
import PreguntasFrecuentes from './pages/preguntasFrecuentes/preguntasFrecuentes';
import PreguntasFrecuentesABM from './pages/preguntasFrecuentes/preguntasFrecuentesABM/preguntasFrecuentesABM';
import TaxisRemises from './pages/taxiRemis/taxiRemis';
import TaxisRemisesABM from './pages/taxiRemis/taxiRemisABM/taxiRemisABM';
import Buses from './pages/buses/buses';
import BusesABM from './pages/buses/busesABM/busesABM';
import AlquilerAutos from './pages/alquilerdeautos/alquilerdeautos';
import AlquilerAutosABM from './pages/alquilerdeautos/alquilerdeautosABM/alquilerdeautosABM';
import CierresAeropuertos from './pages/alertas/cierreAeropuertos/cierresAeropuertos';
import CierreAeropuertoABM from './pages/alertas/cierreAeropuertos/cierreAeropuertosABM/cierreAeropuertosABM';

const AppRoutes = () =>

    <Switch>
        <Route path="/login" component={Login} />
        <Route exact path="/" component={Login} />
        <Layout>
            <PrivateRoute exact path="/Estacionamientos" component={Estacionamiento} />
            <PrivateRoute path="/Estacionamiento/:id?" component={EstacionamientosABM} />
            <PrivateRoute exact path="/Home" component={Home}/>
            <PrivateRoute exact path="/Usuarios" component={Usuario} />
            <PrivateRoute path="/Usuario/:id?" component={UsuarioABM} />
            <PrivateRoute exact path="/Grupos" component={Grupo} />
            <PrivateRoute path="/Grupo/:id?" component={GrupoABM} />
            {/* <PrivateRoute exact path="/Notas" component={Notas} />
            <PrivateRoute path="/Nota/:id?" component={NotasABM} /> */}
            <PrivateRoute exact path="/Novedades" component={Novedades} />
            <PrivateRoute path="/Novedad/:id?" component={NovedadesABM} />
            <PrivateRoute exact path="/Traficos" component={Traficos} />
            <PrivateRoute path="/Trafico/:id?" component={TraficosABM} />
            <PrivateRoute exact path="/Servicios" component={Servicios} />
            <PrivateRoute path="/Servicio/:id?" component={ServiciosABM} />
            <PrivateRoute exact path="/LineasAereas" component={LineasAereas} />
            <PrivateRoute exact path="/LineaAerea/:id?" component={LineasAereasAbm} />
            <PrivateRoute exact path="/Organismos" component={OrganismosOficiales} />
            <PrivateRoute exact path="/Organismo/:id?" component={OrganismosOficialesAbm} />
            <PrivateRoute exact path="/PreguntasFrecuentes" component={PreguntasFrecuentes} />
            <PrivateRoute exact path="/PreguntaFrecuente/:id?" component={PreguntasFrecuentesABM} />
            <PrivateRoute exact path="/TaxisRemises" component={TaxisRemises} />
            <PrivateRoute exact path="/TaxiRemis/:id?" component={TaxisRemisesABM} />
            <PrivateRoute exact path="/Buses" component={Buses} />
            <PrivateRoute exact path="/Bus/:id?" component={BusesABM} />
            <PrivateRoute exact path="/Alquileres" component={AlquilerAutos} />
            <PrivateRoute exact path="/Alquiler/:id?" component={AlquilerAutosABM} />
            <PrivateRoute exact path="/CierresAeropuertos" component={CierresAeropuertos} />
            <PrivateRoute exact path="/CierreAeropuerto/:id?" component={CierreAeropuertoABM} />
        </Layout>
    </Switch>

export default AppRoutes;