import axios from 'axios'
import './axiosConfig'

const GET_NOTAS_URL = 'Nota/GetNotas'
const GET_NOTAS_BY_IDIOMA_URL = 'Nota/GetNotasByIdioma'
const GET_NOTA_BY_ID_URL = 'Nota/GetNotaById'
const SAVE_NOTA_URL = 'Nota/SetNota'
const DEL_NOTA_URL = 'Nota/DelNota'

export const GetNotas = () => {
    return axios.get(GET_NOTAS_URL)
}

export const GetNotasByIdioma = (IdIdioma) => {
    return axios.get(GET_NOTAS_BY_IDIOMA_URL, { params: { IdIdioma } })
}

export const GetNotaById = id => {
    return axios.get(GET_NOTA_BY_ID_URL, { params: { id } })
}

export const SaveNota = nota => {
    return axios.post(SAVE_NOTA_URL, nota)
}

export const DelNota = nota => {
    return axios.post(DEL_NOTA_URL, nota);
}
