import Axios from 'axios';
import './axiosConfig';

const VALIDATEUSER_URL = 'Usuario/ValidateUSer';
const VALIDATEUSERANDPASSWORDINAD_URL = 'Usuario/ValidateUserAndPassword';
const SETEARUSUARIOTOKEN_URL = 'Usuario/SetearUsuarioToken';
const OBTENERCOMPONENTESBYUSUARIO_URL = 'Usuario/ObtenerComponentesPermitidosByUsuario';
const OBTENERITEMDEUSUARIO_URL = 'Usuario/GetUsuarioItems';

export const ValidateUser = (usuario, captcha) => {
    const params = { usuario: usuario, captcha: captcha }
    return Axios.post(VALIDATEUSER_URL, params);
}

export const ValidateUserAndPasswordInAd = (usuario, contrasena) => {
    const params = { usuario: usuario, contrasena: contrasena }
    return Axios.post(VALIDATEUSERANDPASSWORDINAD_URL, params);
}

export const SetearUsuarioToken = (usuario) => {
    const params = { usuario: usuario }
    return Axios.post(SETEARUSUARIOTOKEN_URL, params);
}

export const ObtenerComponentesPermitidos = (idUsuario) => {
    const params = { idUsuario: idUsuario }
    return Axios.get(OBTENERCOMPONENTESBYUSUARIO_URL, { params });
}


export const ObtenerItemsUsuario = (idUsuario) => {
    const params = { idUsuario: idUsuario }
    return Axios.get(OBTENERITEMDEUSUARIO_URL, { params });
}