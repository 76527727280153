import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { GetLineasAereasById, SaveOrUpdateLineaAerea, GetLineaAereaByIataIdioma } from '../../../services/lineasAereas.service';

import Select from 'react-select';
import swal from 'sweetalert2';
import { successSwal } from '../../../assets/resources/swal-config';
import { Button, Grid, IconButton, Fab, makeStyles } from '@material-ui/core';
import { AddPhotoAlternate, Delete, Cancel, Save } from '@material-ui/icons';
import NoFoundImage from '../../../assets/img/not_found.png'
import '../../../assets/css/table-miu-center.css';

const Styles = makeStyles((theme) => ({
    del: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    img: {
        width: '140px',
    },
}));

const LineasAereasAbm = (props) => {
    const history = useHistory();
    const Id = props.match.params.id;
    const classes = Styles();

    const [errors, setErrors] = useState([]);

    const [lineaAerea, setLineaAerea] = useState({
        IdLinea: 0,
        IdIdioma: props.idIdioma,
        IdTipoLinea: '',
        DsLinea: '',
        LkLineaGde: null,
        DsIATA: '',
        DsWeb: '',
        DsMail: '',
        DsTelefono: '',
        DsDireccion: '',
        DsCiudad: '',
        DsProvincia: '',
        McInactiva: 0,
        Base64ImageGde: null,
        IdAeropuertosList: []
    });

    const [selectAeropuerto, setSelectAeropuerto] = useState([]);

    const [imageGde, setImageGde] = useState({ srcImage: "", hiddenRemoveImage: false });

    useEffect(() => {
        if (Id) {
            GetLineasAereasById(Id)
                .then(response => {
                    if (response.data) {
                        let aeropuertos = [];

                        for (var i = 0; i < response.data.IdAeropuertosList.length; i++){
                            const aeropuerto = props.aeropuertos.filter(aero => { return aero.value == response.data.IdAeropuertosList[i] });
                            if (aeropuerto.length > 0)
                                aeropuertos.push(aeropuerto[0].value);
                        }
                        setSelectAeropuerto(response.data.IdAeropuertosList);
                        setLineaAerea({
                            ...response.data,
                            IdAeropuertosList: aeropuertos,
                            Base64ImageGde: response.data.Base64ImageGde !== null ? response.data.Base64ImageGde.toString().split(",", 2)[1] : null
                        });

                        if (response.data.Base64ImageGde !== null)

                            setImageGde({ ...imageGde, srcImage: response.data.Base64ImageGde });
                        else
                            setImageGde({ ...imageGde, srcImage: NoFoundImage, hiddenRemoveImage: true });
                    }
                })
                .catch(err => console.error(err))

        } else {
            setImageGde({ ...imageGde, srcImage: NoFoundImage, hiddenRemoveImage: true })
        }
    }, [])

    const validationSchema = {
        IdLinea: { required: false },
        IdIdioma: { required: false },
        IdTipoLinea: { required: true },
        DsLinea: { required: true },
        LkLineaGde: { required: false },
        DsIATA: { required: true },
        DsWeb: { required: true },
        DsMail: { required: false },
        DsTelefono: { required: true },
        DsDireccion: { required: true },
        DsCiudad: { required: true },
        DsProvincia: { required: true },
        McInactiva: { required: false },
        Base64ImageGde: { required: false },
        IdAeropuertosList: { required: true }
    }

    const isValid = () => {
        let valid = true;
        let errors = {};

        try {
            for (let key of Object.keys(lineaAerea)) {
                let value = lineaAerea[key]

                if (validationSchema[key].required) {

                    if (!value) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }

                    if (key === "DsMail" && !/\S+@\S+\.\S+/.test(value)) {
                        errors[key] = 'El Email es inválido';
                        setErrors(errors);
                        valid = false;
                    }

                    if (key == 'IdAeropuertosList' && value.length == 0) {
                        errors[key] = 'Debe tener al menos un aeropuerto seleccionado.';
                        setErrors(errors);
                        valid = false;
                    }
                }
            }
        } catch (err) {
            console.error('Error', err);
        }
        return valid;
    }

    const handleChange = event => {
        setLineaAerea({ ...lineaAerea, [event.target.name]: event.target.name === "DsIATA" ? event.target.value.toUpperCase() : event.target.value })
    }

    const handleTipoLineaChange = tipo => {
        setLineaAerea({ ...lineaAerea, IdTipoLinea: tipo.value })
    }

    const handleInactivaChange = event => {
        setLineaAerea({ ...lineaAerea, McInactiva: event.target.checked ? 0 : 1 })
    }

    const handleUploadImageGdeClick = event => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length && validateImageGral(event)) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                let img = new Image()
                img.src = reader.result;

                //VALIDA ANCHO Y ALTO DE LA IMAGEN
                img.onload = function () {
                    if (img.width > 350 && img.height > 230) {
                        swal.fire({
                            title: 'Información',
                            icon: 'warning',
                            text: 'La imagen debe ser máximo 97 px de ancho y 87 px de alto',
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok',
                        });
                    }
                    else {
                        const nameFileGde = lineaAerea.DsLinea !== '' ? `${lineaAerea.DsLinea}Gde.${file.name.split('.').pop()}` : file.name,
                            base64ImageGde = reader.result.toString().split(",", 2)[1];
                        setLineaAerea({ ...lineaAerea, LkLineaGde: nameFileGde, Base64ImageGde: base64ImageGde })
                        setImageGde({ ...imageGde, srcImage: reader.result, hiddenRemoveImage: false });
                    }
                }
            };
        }
    };

    const handleAeropuertosChange = aeropuertos => {
        let value = []
        if (aeropuertos) {
            aeropuertos.map(aeropuerto => {
                value.push(aeropuerto.value);
                setLineaAerea({ ...lineaAerea, IdAeropuertosList: value })
            })
        } else {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'La línea aérea debe tener al menos un aeropuerto asignado.',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
        }
    }

    const handleAeropuertosValue = () => {
        let aeropuertos = [];
        for (var i = 0; i < lineaAerea.IdAeropuertosList.length; i++){
            const aeropuerto = props.aeropuertos.filter(aero => { return aero.value == lineaAerea.IdAeropuertosList[i] });
            if (aeropuerto.length > 0)
                aeropuertos.push(aeropuerto[0]);
        }

        return aeropuertos;
    }

    const validateImageGral = (event) => {
        var fileName = event.target.files[0].name;
        var fileSize = event.target.files[0].size;

        if (fileSize > 3000000) {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'La imagen no debe superar los 3MB',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
            event.target.value = '';
            return false;
        } else {
            var ext = fileName.split('.').pop();

            ext = ext.toLowerCase();

            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'svg':
                case 'gif':
                    return true;
                    break;
                default:
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'LA imagen no tiene la extensión adecuada',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                    event.target.value = '';
                    return false;
            }
        }
    }

    const handleSubmit = () => {
        if (isValid()) {
            GetLineaAereaByIataIdioma(lineaAerea.DsIATA, lineaAerea.IdIdioma)
                .then(response => {
                    if (response.data) {
                        if (response.data.IdLinea !== lineaAerea.IdLinea) {

                            swal.fire({
                                title: 'Información',
                                icon: 'warning',
                                text: 'El código IATA ya existe en la Línea Aerea ' + response.data.DsLinea,
                                allowOutsideClick: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        else
                            guardarLineaAerea();
                    }
                    else
                        guardarLineaAerea();
                })
                .catch(err =>
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'No se ha encontrado la Línea Aerea' + err,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        confirmButtonText: 'Ok'
                    }))
        }
    }

    const guardarLineaAerea = () => {
        const aeropuertos = handleAeropuertosToSave(),
            lineaAereaToSave = {
                IdLinea: lineaAerea.IdLinea,
                IdIdioma: lineaAerea.IdIdioma,
                IdTipoLinea: lineaAerea.IdTipoLinea,
                DsLinea: lineaAerea.DsLinea,
                LkLineaGde: lineaAerea.LkLineaGde,
                LkLineaCh: lineaAerea.LkLineaCh,
                DsIATA: lineaAerea.DsIATA,
                DsWeb: lineaAerea.DsWeb,
                DsMail: lineaAerea.DsMail,
                DsTelefono: lineaAerea.DsTelefono,
                DsDireccion: lineaAerea.DsDireccion,
                DsCiudad: lineaAerea.DsCiudad,
                DsProvincia: lineaAerea.DsProvincia,
                McInactiva: lineaAerea.McInactiva,
                Base64ImageGde: lineaAerea.Base64ImageGde,
                Base64ImageCh: lineaAerea.Base64ImageCh,
                IdAeropuertosList: aeropuertos
            }

        SaveOrUpdateLineaAerea(lineaAereaToSave)
            .then(
                (resLineaAerea) => {
                    if (resLineaAerea.data.ResultadoExitoso) {
                        swal.fire({
                            text: 'Se han guardado los cambios con éxito',
                            ...successSwal
                        }).then(() => { history.push('/LineasAereas'); })
                    } else {
                        swal.fire({
                            title: 'Error al guardar los cambios',
                            icon: 'warning',
                            text: resLineaAerea.data.MensajeError,
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                });
    }

    const cancelaABM = () => { history.push('/LineasAereas'); }

    const handleAeropuertosToSave = () => {
        let aeropuertos = [];

        lineaAerea.IdAeropuertosList.map(aero => {
            aeropuertos.push(aero)
        })

        selectAeropuerto.map(aeropuerto => {
            if(aeropuertos.indexOf(aeropuerto) == -1 && props.aeropuertos.filter(aero => { return aero.value == aeropuerto}).length == 0){
                aeropuertos.push(aeropuerto)
            }
        })
        return aeropuertos;
    }

    const removeImageGrande = () => {
        setLineaAerea({ ...lineaAerea, LkLineaGde: null, Base64ImageGde: null })
        setImageGde({ ...imageGde, srcImage: NoFoundImage, hiddenRemoveImage: true });
    }

    const tipoLineas = [
        { value: 'I', label: 'Internacional' },
        { value: 'C', label: 'Cabotaje' },
        { value: 'A', label: 'Int./Cabotaje' }
    ]

    return (
        <div className="main-content">
            <br />
            <div className="row form-title">
                <div className="col-md-6">
                    <h1 className="title">{ Id ? 'Editar línea aérea' : 'Nueva línea aérea' }</h1>
                </div>
                <div className="col-md-6">
                    <div className="active-switch">
                        <label className="label">HABILITADO</label>
                        <input type="checkbox" id="check" checked={!lineaAerea.McInactiva} onChange={handleInactivaChange} />
                        <label htmlFor="check" className="switch"></label>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="row form-group">
                    <div className="col-md-12">
                        <br/>
                        <label className="label">Idioma: {props.idIdioma == 'ES-AR' ? 'Español' : 'Inglés'}</label>
                        <div className="box">
                            <div className="form-group row">
                                
                                <div className="col-md-6">
                                    <label className="label">Nombre<i className="required">*</i></label>
                                    <input name="DsLinea" type="text" maxLength={30} placeholder="ej. Aerolíneas Argentinas" value={lineaAerea.DsLinea !== null ? lineaAerea.DsLinea : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsLinea && (<p className="help is-danger">{errors.DsLinea}</p>)}
                                    <p className="help maxlength">{lineaAerea.DsLinea ? lineaAerea.DsLinea.length : 0} / 30</p>
                                </div>

                                <div className="col-md-6">
                                    <label className="label">IATA<i className="required">*</i></label>
                                    <input name="DsIATA" type="text" maxLength={3} placeholder="ej. AR" value={lineaAerea.DsIATA !== null ? lineaAerea.DsIATA : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsIATA && (<p className="help is-danger">{errors.DsIATA}</p>)}
                                    <p className="help maxlength">{lineaAerea.DsIATA ? lineaAerea.DsIATA.length : 0} / 3</p>
                                </div>

                            </div>

                            <div className="form-group row">
                                
                                <div className="col-md-6">
                                    <label className="label">Tipo de Linea<i className="required">*</i></label>
                                    <Select placeholder={<div>Seleccione</div>} onChange={handleTipoLineaChange} options={tipoLineas} value={tipoLineas.filter(obj => obj.value === lineaAerea.IdTipoLinea)} />
                                    {errors.IdTipoLinea && (<p className="help is-danger">{errors.IdTipoLinea}</p>)}
                                </div>

                                <div className="col-md-6">
                                    <label className="label">Aeropuertos<i className="required">*</i></label>
                                    <Select isMulti placeholder={<div>Seleccione</div>} value={handleAeropuertosValue()} closeMenuOnSelect={false} name="Aeropuertos" options={props.aeropuertos} className="basic-multi-select" classNamePrefix="select" onChange={handleAeropuertosChange} />
                                    {errors.IdAeropuertosList && (<p className="help is-danger">{errors.IdAeropuertosList}</p>)}
                                </div>

                            </div>

                            <div className=" form-group row">
                                <div className="col-md-6">
                                    <label className="label">Sitio Web<i className="required">*</i></label>
                                    <input name="DsWeb" type="text" maxLength={30} placeholder="ej. www.aerolineas.com.ar" value={lineaAerea.DsWeb !== null ? lineaAerea.DsWeb : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsWeb && (<p className="help is-danger">{errors.DsWeb}</p>)}
                                    <p className="help maxlength">{lineaAerea.DsWeb ? lineaAerea.DsWeb.length : 0} / 30</p>
                                </div>
                                
                                <div className="col-md-6">
                                    <label className="label">Teléfono<i className="required">*</i></label>
                                    <input name="DsTelefono" type="text" maxLength={30} placeholder="ej. 0810-222-86527" value={lineaAerea.DsTelefono !== null ? lineaAerea.DsTelefono : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsTelefono && (<p className="help is-danger">{errors.DsTelefono}</p>)}
                                    <p className="help maxlength">{lineaAerea.DsTelefono ? lineaAerea.DsTelefono.length : 0} / 30</p>
                                </div>

                            </div>
                                    
                            <div className="form-group row">
                                
                                <div className="col-md-6">
                                    <label className="label">E-Mail</label>
                                    <input name="DsMail" type="text" maxLength={30} placeholder="ej. contacto@aerolineas.com.ar" value={lineaAerea.DsMail !== null ? lineaAerea.DsMail : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsMail && (<p className="help is-danger">{errors.DsMail}</p>)}
                                    <p className="help maxlength">{lineaAerea.DsMail ? lineaAerea.DsMail.length : 0} / 30</p>
                                </div>

                                <div className="col-md-6">
                                    <label className="label">Dirección<i className="required">*</i></label> 
                                    <input name="DsDireccion" type="text" maxLength={30} placeholder="ej.  Perú 2" value={lineaAerea.DsDireccion !== null ? lineaAerea.DsDireccion : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsDireccion && (<p className="help is-danger">{errors.DsDireccion}</p>)}
                                    <p className="help maxlength">{lineaAerea.DsDireccion ? lineaAerea.DsDireccion.length : 0} / 30</p>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label className="label">Ciudad<i className="required">*</i></label>
                                    <input name="DsCiudad" type="text" maxLength={35} placeholder="ej. Ciudad Autónoma de Buenos Aires" value={lineaAerea.DsCiudad !== null ? lineaAerea.DsCiudad : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsCiudad && (<p className="help is-danger">{errors.DsCiudad}</p>)}
                                    <p className="help maxlength">{lineaAerea.DsCiudad ? lineaAerea.DsCiudad.length : 0} / 35</p>
                                </div>

                                <div className="col-md-6">
                                    <label className="label">Provincia<i className="required">*</i></label>
                                    <input name="DsProvincia" type="text" maxLength={30} placeholder="ej. Buenos Aires" value={lineaAerea.DsProvincia !== null ? lineaAerea.DsProvincia : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsProvincia && (<p className="help is-danger">{errors.DsProvincia}</p>)}
                                    <p className="help maxlength">{lineaAerea.DsProvincia ? lineaAerea.DsProvincia.length : 0} / 30</p>
                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <br />
                                    <label className="label">Seleccione una Imagen</label>
                                    <Grid container spacing={2}>
                                        <br/>
                                        <Grid item>
                                            <img className={classes.img} alt="complex" src={imageGde.srcImage} />
                                        </Grid>

                                        <Grid item xs container direction="column" spacing={2}>
                                            {imageGde.hiddenRemoveImage ? <Fragment>
                                                <input type="file" accept=".jpg, .jpeg, .png, .gif, .svg" className={classes.input} id="contained-button-file2" onChange={handleUploadImageGdeClick} />

                                                <label className="buttonImg" htmlFor="contained-button-file2">
                                                    <Fab component="span" color="primary" >
                                                        <AddPhotoAlternate />
                                                    </Fab>
                                                </label>
                                            </Fragment> : ""}
                                            <div className={classes.del}>
                                                {!imageGde.hiddenRemoveImage ? <IconButton color="secondary" aria-label="delete" component="span" onClick={removeImageGrande}>
                                                    <Delete />
                                                </IconButton> : ""}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {errors.LkLineaGde && (<p className="help is-danger">{errors.LkLineaGde}</p>)}

                                    <br/>
                                    <label className="label">Requerimientos de la imagen:</label>
                                    <ul className="validImages">
                                        <li>Tamaño max. max 97 px X 87 px</li>
                                        <li>Extensiones validas: JPG, JPEG, PNG, GIF, SVG</li>
                                        <li>Max. 3MB</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <Button variant="contained" color="primary" className="button" onClick={handleSubmit} startIcon={<Save />}>Guardar</Button>
                            <Button variant="contained" startIcon={<Cancel />} className="button" onClick={cancelaABM}>Cancelar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        idIdioma: state.appReducer.idIdioma,
        aeropuertos: state.aeropuertoReducer.aeropuertos
    }
}

export default connect(mapStateToProps)(LineasAereasAbm)