import { SELECT_AEROPUERTOS, SELECT_ID_AEROPUERTOS, SELECT_LIST_AEROPUERTOS, SELECT_IDIOMA_AEROPUERTOS } from './action';

const initialState = {
    listAeropuertos: [],
    aeropuertos: [],
    idAeropuerto: -1
};

function aeropuertoReducer(state = initialState, action) {
    switch (action.type) {
        case SELECT_AEROPUERTOS:
            return {
                ...state,
                aeropuertos: action.payload.aeropuertos,
                idAeropuerto: action.payload.idAeropuerto
            }
            break;
        case SELECT_ID_AEROPUERTOS:
            return {
                ...state,
                idAeropuerto: action.payload
            }
            break;
        case SELECT_LIST_AEROPUERTOS:
            return {
                ...state,
                listAeropuertos: action.payload
            }
            break;
        case SELECT_IDIOMA_AEROPUERTOS:
            return {
                ...state,
                aeropuertos: action.payload
            }
            break;
        default:
            break;
    }
    return state;
}

export default aeropuertoReducer;