import Axios from "axios"
import './axiosConfig';

const GETROLES_URL = 'Usuario/GetRoles';
const GETUSUARIOS_URL='Usuario/GetUsuarios'
const SAVEUSUARIOFULL_URL='Usuario/SaveOrUpdateUsuarioFull'
const SEARCHUSERS_URL = 'Usuario/SearchUsuariosInAD';
const GETUSUARIOFULL_URL = 'Usuario/GetUsuarioFull';
const DELETEUSUARIO_URL = 'Usuario/DeleteUsuarioFull';

const GETAEROPUERTOSBYUSUARIOIDOMA_URL = 'Usuario/AeropuertosByUsuarioIdioma';

export const GetRoles = () => {
    return Axios.get(GETROLES_URL)
}

export const GetUsers = () => {
    return Axios.get(GETUSUARIOS_URL)
}

export const SaveUserFull = (request) => { 
    return Axios.post(SAVEUSUARIOFULL_URL, request)
}

export const SearchUsers = (usuario) => {
    const params = {DS_nombreUsuario: usuario }
    return Axios.get(SEARCHUSERS_URL, {params});
}

export const GetUserFull=(idUsuario) => {
    const params = {id: idUsuario }
    return Axios.get(GETUSUARIOFULL_URL, {params});
}

export const DeleteUser= (request) =>{
    return Axios.post(DELETEUSUARIO_URL, request);
}

export const GetAeropuertosByUsuarioIdioma=(idUsuario, idIdioma)=> {
    return Axios.get(GETAEROPUERTOSBYUSUARIOIDOMA_URL,{params: {idUsuario, idIdioma}});
}
