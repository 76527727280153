import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { GetServiciosByAeropuerto, SaveOrUpdateServicio, GetOpcionesByIdioma, DeleteServicio } from '../../services/servicios.service';
import { getSessionUser } from '../../services/auth.service';
import { GetPDFTemplate, menuEnable, menuLocate, menuShow, menuIsActive, menuHide } from '../../environments/utils'
import { ESP } from '../../environments/idioma';

import swal from 'sweetalert2';
import { successSwal, confirmSwal } from '../../assets/resources/swal-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Visibility, VisibilityOff, MoreVert, Add } from '@material-ui/icons'
import { Table, TableBody, TableCell, TableFooter, TablePagination, TableRow, Paper, IconButton, Button, Tooltip, makeStyles } from '@material-ui/core'

import SwitcherIdioma from '../../components/switcherIdioma'
import SelectAeropuerto from '../../components/SelectAeropuerto'
import TablePaginationActions from '../../components/tablePagination'
import GlutenFREE from '../../assets/img/gluten-free.svg'
import Vegetariano from '../../assets/img/vegetarian.png'
import Vegano from '../../assets/img/vegan.png'
import Infantil from '../../assets/img/fast-food.png'
import Kosher from '../../assets/img/kosher.png'
// import Menu from '../../assets/img/food-menu.svg'
import Menu from '../../assets/img/menu_black.svg'
import NoFoundImage from '../../assets/img/not_found.png'
import './servicios.css';

var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #59C576',
        padding: 10,
    },
}))

const HtmlTooltip = (props) => {
    const classes = TooltipStyles();

    return <Tooltip classes={classes} {...props} />;
}

const Servicios = (props) => {
    const history = useHistory();

    const [state, setState] = useState({
        servicios: [],
        opciones: [],
        idIdioma: ESP
    });

    const user = getSessionUser();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [idRow, SetIdRow] = useState(null);

    const filteredRow = state.servicios.filter(row => { return row.show });
    const MENU_SELECTOR = "#contextMenu";
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.servicios.length - page * rowsPerPage);

    useEffect(() => {
        loadServicios(props.idAeropuerto);
    }, [props.idAeropuerto]);

    const handleIdServicio = (name, value) => {
        var result;
        switch (name) {
            case 'IdCategoria':
                var categoria = state.opciones.filter(opt => { return opt.Id == value && opt.DsOpcion == 'Categoría' })
                result = categoria.length > 0 ? categoria.DsName : "";
                break;
            case 'IdTipoLocal':
                var tipolocal = state.opciones.filter(opt => { return opt.Id == value && opt.DsOpcion == 'Tipo de local' })
                result = tipolocal.length ? tipolocal[0].DsName : "";
                break;
        }
        return result;
    }

    const loadServicios = async (aeropuerto) => {
        setPage(0);
        document.getElementById("searchInput").value = "";
        const servicios = [];
        return await GetOpcionesByIdioma(props.idIdioma)
        .then(async(resGetOpciones) => {
            if (resGetOpciones.data) {
                setState({ ...state, opciones: resGetOpciones.data });
                return await GetServiciosByAeropuerto(props.idIdioma, aeropuerto)
            }
        })
        .then(async (resGetServicios) => {
            if(resGetServicios.data){
                for (var i = 0; i < resGetServicios.data.length; i++){
                    const servicio = resGetServicios.data[i];
                    servicio.DsTipoMenu = servicio.DsTipoMenu ? JSON.parse(servicio.DsTipoMenu) : []
                    servicio['show'] = true;
                    servicios.push(servicio);
                }
                setState({ ...state, servicios: servicios });
            }
        })

    }

    const handleAddClick = () => { history.push(`/Servicio`); };

    const handleTableOptions = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const id = e.target.closest('tr').id;
        if (menuIsActive(MENU_SELECTOR) && idRow != null) {
            SetIdRow(null);
            menuHide(MENU_SELECTOR);
        } else {
            SetIdRow(id);
            menuEnable(MENU_SELECTOR);
            menuLocate(MENU_SELECTOR, e.pageX, e.pageY);
            menuShow(MENU_SELECTOR);
        }
    }

    const handleEditClick = () => {
        if (idRow != null) {
            history.push(`/Servicio/${idRow}`);
        } else {
            swal.fire({
                text: 'Ha ocurrido un error, intente nuevamente.',
                ...confirmSwal
            })
        }
    }

    const handleActiveClick = (servicio) => {
        const obj = {
            IdServicio: servicio.IdServicio,
            IdIdioma: servicio.IdIdioma,
            IdAeropuerto: servicio.IdAeropuerto,
            DsServicio: servicio.DsServicio,
            IdCategoria: servicio.IdCategoria,
            LkLogo: servicio.LkLogo,
            DsDescripcion: servicio.DsDescripcion,
            DsUbicacion: servicio.DsUbicacion,
            DsTipoMenu: JSON.stringify(servicio.DsTipoMenu),
            DsHorario: servicio.DsHorario,
            IdTipoLocal: servicio.IdTipoLocal,
            McInactiva: !servicio.McInactiva,
            Celiaco: servicio.Celiaco,
            Open24: servicio.Open24,
            FeCreacion: servicio.FeCreacion,
            FeModificacion: new Date().toISOString().replace("Z", ""),
            Usuario: user.DS_Nombre,
            base64Image: null
        }
        SaveOrUpdateServicio(obj)
            .then((resServicio) => {
                loadServicios(props.idAeropuerto);
        })
    }

    const handleRemoveClick = () => {
        if (idRow != null) {
            const servicio = state.servicios.find(x => x.IdServicio == parseInt(idRow));

            swal.fire({
                title: 'Eliminar servicio',
                text: '¿Está seguro que desea eliminar el servicio  ' + servicio.DsServicio + '?',
                ...confirmSwal
            })
            .then((confirm) => {
                if (confirm.value) {
                    deleteServicio(servicio);
                }
            });
        } else {
            swal.fire({
                text: 'Ha ocurrido un error, intente nuevamente.',
                ...confirmSwal
            })
        }
    }

    const deleteServicio = (servicio) => {
        DeleteServicio(servicio)
            .then(async () => {
                await loadServicios(props.idAeropuerto);
                swal.fire({
                    text: 'Se ha eliminado el servicio con éxito',
                    ...successSwal
                })
            });
    }

    const handlePrintClick = () => {
        if (idRow != null) {
            const servicio = state.servicios.find(x => x.IdServicio == parseInt(idRow));
            const PDF = GetPDFTemplate();

            var tableToAdd = {
                style: 'table',
                table: {
                    widths: ['30%', '70%'],
                    body: [
                        [
                            { text: 'Local o Servicio', style: 'tableHeader' },
                            { text: `${servicio.DsServicio}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Categoría', style: 'tableHeader' },
                            { text: `${state.opciones.filter(opt => { return opt.Id == servicio.IdCategoria }).length > 0 ? state.opciones.filter(opt => { return opt.Id == servicio.IdCategoria })[0].DsName : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Tipo de Local', style: 'tableHeader' },
                            { text: `${state.opciones.filter(opt => { return opt.Id == servicio.IdTipoLocal }).length > 0 ? state.opciones.filter(opt => { return opt.Id == servicio.IdTipoLocal })[0].DsName : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Descripción', style: 'tableHeader' },
                            { text: `${servicio.DsDescripcion ? servicio.DsDescripcion : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Ubicación', style: 'tableHeader' },
                            { text: `${servicio.DsUbicacion ? servicio.DsUbicacion : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Tipo de menú', style: 'tableHeader' },
                            { text: `${servicio.DsTipoMenu ? servicio.DsTipoMenu : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Horario', style: 'tableHeader' },
                            { text: `${servicio.DsHorario ? 'Abierto ' + servicio.DsHorario : servicio.Horario ? "Abierto según operatoria del Aeropuerto" : ""}`, style: 'tableData' },
                        ],
                        [
                            { text: 'Abierto 24hs', style: 'tableHeader' },
                            { text: `${servicio.Open24 ? "Si" : "No"}`, style: 'tableData' },
                        ],
                        // [
                        //     { text: 'Apto para celíacos', style: 'tableHeader' },
                        //     { text: `${servicio.Celiaco ? "Si" : "No"}`, style: 'tableData' },
                        // ],
                        [
                            { text: 'Última modificación:', style: 'tableHeader' },
                            { text: `${new Date(servicio.FeModificacion != "0001-01-01T00:00:00" ? servicio.FeModificacion : servicio.FeCreacion).toLocaleDateString()} ${servicio.Usuario}`, style: 'tableData' },
                        ],
                    ]
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (columnIndex % 2 == 0) ? '#193b73' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        return '#193b73';
                    },
                    vLineColor: function (i, node) {
                        return '#193b73';
                    },
                }
            };
            var styles = {
                header: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 30],
                },
                table: {
                    widht: '100%',
                    margin: [0, 30],
                    alignment: 'center',
                    borderRadius: 33
                },
                tableHeader: {
                    color: 'white',
                    bold: true,
                    fontSize: 13,
                    margin: [0, 2.5]
                },
                tableData: {
                    margin: 5,
                    alignment: 'justify'
                }
            }

            PDF.content[0].columns[0].fit = [150, 100];
            PDF.content[0].columns[1].text = "Detalle de Servicio o Local";
            PDF.content.push(tableToAdd)
            PDF.styles = styles;

            pdfMake.createPdf(PDF).open()
        } else {
            swal.fire({
                text: 'Ha ocurrido un error, intente nuevamente.',
                ...confirmSwal
            })
        }
    }

    // TABLA-PRINCIPIO

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleSearch = (e) => {
        const search = e ? e.target.value.toLowerCase().trim() : document.getElementById("searchInput").value.toLowerCase().trim();
        const keys = ["DsServicio", "IdCategoria", "DsDescripcion", "DsUbicacion", "DsTipoMenu", "DsHorario", "Open24", "Horario"];
        const newRows = [];

        if(search.length > 0) {
            for(var i = 0; i < state.servicios.length; i++) {
                let bool,
                    row = state.servicios[i],
                    categoria = state.opciones.find(opt => opt.Id == row.IdCategoria) ? state.opciones.find(opt => opt.Id == row.IdCategoria).DsName : "";
                for (var index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    let value = key == "IdCategoria" ? categoria : key == "Open24" && row[key] ? "Abierto las 24hs." : key == "Horario" && row[key] ? "Abierto según operatoria del Aeropuerto" : row[key];
                    if(value) {
                        value = typeof value != "object" ? value.toLowerCase() : value;
                        bool = index == 0 ? key == "DsTipoMenu" ? value.find(x => x.toLowerCase().indexOf(search) != -1) : value.indexOf(search) != -1 :  key == "DsTipoMenu" ? bool || value.find(x => x.toLowerCase().indexOf(search) != -1) : bool || value.indexOf(search) != -1;
                    }
                }
                row.show = bool;
                newRows.push(row);
            }
        } else {
            for(var i = 0; i < state.servicios.length; i++) {
                state.servicios[i].show = true;
                newRows.push(state.servicios[i]);
            }
            setPage(0)
        }
        //setPage(0)
        setState({ ...state, servicios: newRows})
    }

    const clearSearch = () => {
        document.getElementById("searchInput").value = "";
        handleSearch(undefined)
    }

    // TABLA-FIN

    const handleIconMenu = (menu) => {
        var icon = "";

        switch (menu) {
            case "Vegetariano":
                icon = Vegetariano;
                break;
            case "Vegano":
                icon = Vegano;
                break;
            case "Infantil":
                icon = Infantil;
                break;
            case "Apto celíacos":
                icon = GlutenFREE;
                break;
            case "Kosher":
                icon = Kosher;
                break;
        }

        return icon;
    }

    return (
        <div className="main-content">
            <br />
            <h1 className="title">Servicios</h1>
            <br />
            <br />
            <div className="content">
                <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-6">
                        <SelectAeropuerto />
                        <br/>
                    </div>
                    <div className="col-sm-6 col-md-2 col-lg-2 offset-md-2 offset-lg-1">
                        <SwitcherIdioma />
                        <br/>
                    </div>
                    <div className="col-sm-6 col-md-3 col-lg-3">
                        <Button variant="contained" color="primary" className="btnAdd" startIcon={<Add />} onClick={handleAddClick}>Nuevo</Button>
                        <br/>
                    </div>
                    <br/>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="search-box">
                            <br />
                            <br />
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="type" name="search" id="searchInput" placeholder="Buscar servicio o local" onKeyUp={handleSearch} onChange={handleSearch} />
                        </div>
                        <br/>
                    </div>
                </div>
                <Paper className="table-root">
                    <div className="table-wrapper">
                        <Table>
                            <TableBody>
                                {(rowsPerPage > 0 ? filteredRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRow).map((row) => (
                                    <TableRow key={row.IdServicio} id={row.IdServicio}>
                                        <TableCell component="th" scope="row" className="visibility">
                                            <HtmlTooltip
                                            placement="top"
                                            title={
                                                <div>
                                                    {!row.McInactiva ?
                                                        <span><strong>Ocultar: </strong>Este elemento dejará de estar visible para el lector pero no se eliminará.</span>:
                                                        <span><strong>Mostrar: </strong>Este elemento estará visible para el lector.</span> 
                                                    }
                                                </div>
                                            }
                                            >
                                                { row.McInactiva ? <VisibilityOff className="gray" onClick={() => handleActiveClick(row)} /> : <Visibility className="green" onClick={() => handleActiveClick(row)} />}
                                            </HtmlTooltip>
                                            {/* <Visibility className={`${!row.McInactiva ? 'green' : 'gray'}`} onClick={() => handleActiveClick(row)}/> */}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className="logo">
                                            <img src={row.base64Image ? row.base64Image : NoFoundImage} alt="LogoServicio" />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div className="information">
                                                <p>{row.DsServicio}</p>
                                                <p>{handleIdServicio('IdCategoria', row.IdCategoria)}</p>
                                                {/* <p>{handleIdServicio('IdTipoLocal', row.IdTipoLocal)}</p> */}
                                                <p className="green-text">{row.Open24 ? 'Abierto las 24hs.' : row.DsHorario ? `Abierto ${row.DsHorario}` : row.Horario ? "Abierto según operatoria del Aeropuerto" : ""}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div className="information">
                                                <p hidden={!row.DsUbicacion || row.DsUbicacion == 'NULL'}>{row.DsUbicacion}</p>
                                                <p hidden={!row.DsDescripcion || row.DsDescripcion == 'NULL'}>{row.DsDescripcion}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row" className="tipo-menu">
                                            {/* {row.DsTipoMenu.sort().map(menu => <img key={menu} alt={menu} title={menu} src={menu == "Vegetariano" ? Vegetariano : menu == "Vegano" ? Vegano : menu == "Infantil" ? Infantil : menu == "Apto celíacos" ? GlutenFREE : menu == "Kosher" ? Kosher : ""} />)} */}
                                            {row.DsTipoMenu.sort().map(menu => <img key={menu} alt={menu} title={menu} src={handleIconMenu(menu)} onError={({ target }) => target.src = Menu /*target.style.display = 'none'*/} />)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div className="information">
                                                <p>Ultima modificación</p>
                                                <p>{new Date(row.FeModificacion != "0001-01-01T00:00:00" ? row.FeModificacion : row.FeCreacion).toLocaleDateString()}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <IconButton className="table-btns" onClick={(e) => handleTableOptions(e)}>
                                                <MoreVert />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                <TableRow style={{ height: "48px" }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={filteredRow.length}
                                        labelRowsPerPage="Filas por página"
                                        page={page}
                                        onChangePage={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={7}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        labelDisplayedRows={(from=page) => (`${from.from}-${from.to === -1 ? from.count : from.to} de ${from.count}`)}>
                                    </TablePagination>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper>

                <div className='menugrid' id="contextMenu" hidden>
                    <ul>
                        <li><Button onClick={() => handleEditClick()}>Modificar</Button></li>
                        <li><Button onClick={() => handleRemoveClick()}>Eliminar</Button></li>
                        <li><Button onClick={() => handlePrintClick()}>Imprimir</Button></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        idIdioma: state.appReducer.idIdioma,
        aeropuertos: state.aeropuertoReducer.aeropuertos,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto
    }
}

export default connect(mapStateToProps)(Servicios);