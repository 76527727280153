import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom"
import swal from 'sweetalert2';
import Sidebar from './sidebar';
import { setItemsUsuario, selectAeropuerto, handleAeropuertos, clearComponentesPermitidos, clearSession } from '../../redux/action';
import { getSessionUser, isAuthenticated, logout } from '../../services/auth.service';
import { ObtenerItemsUsuario } from '../../services/login.service';
import { EventListener } from '../../environments/utils';

import "./componentStyles.css"

import {
    COMPONENTE_COMOLLEGO_MAPA,
    COMPONENTE_COMOLLEGO_MINTERNO,

    COMPONENTE_EST_LONGSTAY,
    COMPONENTE_EST_TEXTODESCLATERAL,
    COMPONENTE_EST_MAPA,
    COMPONENTE_EST_EPSUR,
    COMPONENTE_EST_EPNORTE,
    COMPONENTE_EST_EPSARM,
    COMPONENTE_EST_TEXTODISCAP,
    COMPONENTE_EST_CESTADIA,

    COMPONENTE_LINEASAREAS,

    COMPONENTE_NOTAS,
    COMPONENTE_NOVEDADES,
    COMPONENTE_TRAFICOS,

    COMPONENTE_ORGANISMOS,

    COMPONENTE_PREGUNTAS_FRECUENTES,

    COMPONENTE_SERVICIOS,

    COMPONENTE_TRANS_TAXIS,
    COMPONENTE_TRANS_BUSES,
    COMPONENTE_TRANS_ALQ,
    COMPONENTE_TRANS_BANNER,

    COMPONENTE_VUELOS_ARRIBOS,
    COMPONENTE_VUELOS_PARTIDAS,
    COMPONENTE_VUELOS_BANNERS
} from '../../services/permisos.service';

import '../../assets/css/componentStyles.css';

//las props incluyen children, permisos y setItemsUsuario
const Layout = (props) => {

    let sidebar
    const history = useHistory();

    const [sidebarOpen, setsidebarOpen] = useState(false);

    const openHandler = () => {
        if (!sidebarOpen)
            setsidebarOpen(true)
        else
            setsidebarOpen(false)
    }

    useEffect(() => {
        EventListener();
        if (isAuthenticated()) {
            //COMO ES ASYNC SE DEBE DECLARAR FUERA DEL useEffect
            reloadItemsDeUsuario().then(() => {
                openHandler();
            });
        }
    }, []);

    const reloadItemsDeUsuario = async () => {
        let items = await ObtenerItemsUsuario(getSessionUser().Id);
        if (items.data.ComponentesPermitidos && items.data.Aeropuertos) {
            items.data.ComponentesPermitidos = await setPermisosComponentes(items.data.ComponentesPermitidos.filter(function (item, pos) { return items.data.ComponentesPermitidos.indexOf(item) == pos; }).sort())
            // SE DISPARA LA ACCION DE SETEAR LOS ITEMS
            props.setItemsUsuario(items.data);
            if (items.data.Aeropuertos.length > 0) {
                mapAeropuertos(items.data.Aeropuertos);
            }
        } else {
            swal.fire({
                text: 'Su sesión ha expirado, por favor ingrese nuevamente.',
                icon: 'error',
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonText: 'Ok',
            }).then((e) => { 
                props.clearComponentesPermitidos();
                props.clearSession();
                logout(history);
            });
        }
        
        return;
    }

    const setPermisosComponentes = (permisos) => {
        const componentes = {
            comoLlego: false,
            comoLlegoMapa: false,
            comoLlegoMapaInterno:false,
            estacionamiento: false,
            estLongStay: false,
            estDesLateral: false,
            estMapaUbicacion: false,
            estPSur: false,
            estPNorte: false,
            estPSarm: false,
            estTextoDiscap: false,
            estCEstadia: false,
            lineasAereas: false,
            noticias: false,
            notas: false,
            novedades: false,
            traficos:false,
            organismos: false,
            pregFrecuentes: false,
            servicios: false,
            transporte: false,
            transporteTaxis: false,
            transporteBuses: false,
            transporteAquiler: false,
            transporteBanner: false,
            vuelos: false,
            vuelosArribos: false,
            vuelosPartidas: false,
            vuelosBanners: false
        }

        for (let i = 0; i < permisos.length; i++) {
            if (permisos[i] === COMPONENTE_COMOLLEGO_MAPA) componentes.comoLlegoMapa = true;
            if (permisos[i] === COMPONENTE_COMOLLEGO_MINTERNO) componentes.comoLlegoMapaInterno = true;

            if (permisos[i] === COMPONENTE_EST_LONGSTAY) componentes.estLongStay = true;
            if (permisos[i] === COMPONENTE_EST_TEXTODESCLATERAL) componentes.estDesLateral = true;
            if (permisos[i] === COMPONENTE_EST_MAPA) componentes.estMapaUbicacion = true;
            if (permisos[i] === COMPONENTE_EST_EPSUR) componentes.estPSur = true;
            if (permisos[i] === COMPONENTE_EST_EPNORTE) componentes.estPNorte = true;
            if (permisos[i] === COMPONENTE_EST_EPSARM) componentes.estPSarm = true;
            if (permisos[i] === COMPONENTE_EST_TEXTODISCAP) componentes.estTextoDiscap = true;
            if (permisos[i] === COMPONENTE_EST_CESTADIA) componentes.estCEstadia = true;

            if (permisos[i] === COMPONENTE_LINEASAREAS) componentes.lineasAereas = true;

            if (permisos[i] === COMPONENTE_NOTAS) componentes.notas = false;
            if (permisos[i] === COMPONENTE_NOVEDADES) componentes.novedades = true;
            if (permisos[i] === COMPONENTE_TRAFICOS) componentes.traficos = true;

            if (permisos[i] === COMPONENTE_ORGANISMOS) componentes.organismos = true;

            if (permisos[i] === COMPONENTE_PREGUNTAS_FRECUENTES) componentes.pregFrecuentes = true;

            if (permisos[i] === COMPONENTE_SERVICIOS) componentes.servicios = true;

            if (permisos[i] === COMPONENTE_TRANS_TAXIS) componentes.transporteTaxis = true;
            if (permisos[i] === COMPONENTE_TRANS_BUSES) componentes.transporteBuses = true;
            if (permisos[i] === COMPONENTE_TRANS_ALQ) componentes.transporteAquiler = true;
            if (permisos[i] === COMPONENTE_TRANS_BANNER) componentes.transporteBanner = true;

            if (permisos[i] === COMPONENTE_ORGANISMOS) componentes.organismos = true;

            if (permisos[i] === COMPONENTE_VUELOS_ARRIBOS) componentes.vuelosArribos = true;
            if (permisos[i] === COMPONENTE_VUELOS_PARTIDAS) componentes.vuelosPartidas = true;
            if (permisos[i] === COMPONENTE_VUELOS_BANNERS) componentes.vuelosBanners = true;
        }

        componentes.comoLlego = componentes.comoLlegoMapa || componentes.comoLlegoMapaInterno;
        componentes.estacionamiento = componentes.estLongStay || componentes.estDesLateral || componentes.estMapaUbicacion || componentes.estPSur || componentes.estPNorte || componentes.estPSarm || componentes.estTextoDiscap || componentes.estCEstadia;
        componentes.noticias = componentes.notas || componentes.novedades || componentes.traficos;
        componentes.transporte = componentes.transporteAquiler || componentes.transporteBanner || componentes.transporteBuses || componentes.transporteTaxis;
        componentes.vuelos = componentes.vuelosArribos || componentes.vuelosPartidas || componentes.vuelosBanners;

        return componentes;
    }

    const mapAeropuertos = (aeropuertosData) => {
        let arpt = aeropuertosData.map(arpt => { return { label: arpt.dsIATA, value: arpt.idAeropuerto, idIdioma: arpt.idIdioma } });
        let arptByIdioma = arpt.filter(aero => aero.idIdioma == props.idIdioma);
        const data = {
            aeropuertos: arptByIdioma,
            idAeropuerto: arptByIdioma[0].value
        }
        props.selectAeropuerto(data);
        props.handleAeropuertos(arpt);
    }

    if (sidebarOpen) sidebar = <Sidebar />

    return (
        <Fragment>
            {sidebar}
            {props.children}
        </Fragment>
    )
}
function mapStateToProps(state) {

    return {
        idIdioma: state.appReducer.idIdioma,
        permisos: state.permisosReducer
    }
}

export default connect(mapStateToProps, { setItemsUsuario, selectAeropuerto, handleAeropuertos, clearComponentesPermitidos, clearSession })(Layout)