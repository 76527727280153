import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { SavePreguntaFrecuente, GetPreguntaFrecuente } from '../../../services/preguntasFrecuentes.service';

import swal from 'sweetalert2';
import { successSwal } from '../../../assets/resources/swal-config';
import ReactQuill from 'react-quill';
import { Button } from '@material-ui/core';
import { Cancel, Publish } from '@material-ui/icons';
import SwitcherIdioma from '../../../components/switcherIdioma';
import './preguntasFrecuentesABM.css';

const PreguntasFrecuentesABM = (props) => {
    const Id = props.match.params.id;
    const history = useHistory();
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            ['link', 'image', 'video'],
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ]
    };
    const [errors, setErrors] = useState([]);
    const [pregunta, setPregunta] = useState({
        IdPregunta: 0,
        IdIdioma: props.idIdioma,
        DsPregunta: null,
        DsRespuesta: '',
        DsOrden: 0,
        McInactiva: 0
    });
    const [editing, setEditing] = useState(false);

    const validationSchema = {
        IdPregunta: { required: false },
        IdIdioma: { required: false },
        DsPregunta: { required: true },
        DsRespuesta: { required: true },
        DsOrden: { required: false },
        McInactiva: { required: false }
    }

    useEffect(() => {
        if (Id) {
            GetPreguntaFrecuente(Id)
                .then((resGetPregunta) => {
                    if (resGetPregunta.data) {
                        setPregunta({ ...resGetPregunta.data });
                        setEditing(true);
                    }
                })
                .catch((err) => { console.log(err) })
        } else {
            setPregunta({ ...pregunta, IdIdioma: props.idIdioma });
            setEditing(true);
        }
    }, [props]);

    const isValid = () => {
        let valid = true;
        let errors = {};
        try {
            for (let key of Object.keys(pregunta)) {
                let value = pregunta[key];
                if (validationSchema[key].required) {
                    if (!value) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }

                    if (!errors[key] && key === 'DsRespuesta') {
                        if (pregunta.DsRespuesta.trim() === '') {                            
                            errors[key] = 'Requerido';
                            setErrors(errors);
                            valid = false;
                        }
                    }
                }
            }
        } catch (err) {
            console.log('Error', err)
        }

        return valid;
    }

    const handleSubmit = () => {
        if (isValid()) {
            SavePreguntaFrecuente(pregunta)
                .then((resPregunta) => {
                    if (resPregunta.data.ResultadoExitoso) {
                        swal.fire({
                            text: 'Se han guardado los cambios con éxito',
                            ...successSwal
                        }).then(() => { history.push('/PreguntasFrecuentes'); })
                    } else {
                        swal.fire({
                            title: 'Error al guardar los cambios',
                            icon: 'warning',
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                })
        }
    }

    const cancelABM = () => history.push("/PreguntasFrecuentes");

    const handleChange = event => setPregunta({ ...pregunta, [event.target.name]: event.target.value });

    const handleChangeEditor = value => {
        if (editing) {
            const limit = 10000;
            const text = value == "<p><br></p>" ? "" : value.length < limit ? value : value.substring(0, limit)
            setPregunta({ ...pregunta, DsRespuesta: text });
        }
    }

    return (
        <div className="main-content">
            <br />
            <h1 className="title">{Id ? 'Editar Pregunta Frecuente' : 'Nueva Pregunta Frecuente'}</h1>
            <br />
            <br />

            <div className="content">
                <div className="row form-group">
                    <div className="col-md-3">
                        <label className="label">Idioma: { props.idIdioma  == 'ES-AR' ? 'Español' : 'Inglés'}</label>
                    </div>

                    <div className="col-md-9" hidden={Id}>
                        <SwitcherIdioma />
                    </div>
                </div>

                <div className="row form-group">    
                    <div className="col-md-12 box">
                        <div className="col-md-12">
                            <input type="text" name="DsPregunta" placeholder="Pregunta" maxLength={150} value={pregunta.DsPregunta ? pregunta.DsPregunta : ""} onChange={handleChange} className="form-control" />
                            {errors.DsPregunta && (<p className="help is-danger">{errors.DsPregunta}</p>)}
                            <p className="help maxlength">{pregunta.DsPregunta ? pregunta.DsPregunta.length : 0} / 150</p>
                            <br/>
                        </div>

                        <div className="col-md-12">
                            <div className="editor">
                                <ReactQuill name="DsRespuesta" value={pregunta.DsRespuesta} onChange={handleChangeEditor} theme="snow" placeholder="Respuesta" modules={modules} />
                                <p className="help maxlength">{pregunta.DsRespuesta ? pregunta.DsRespuesta.length : 0} / 10000</p>
                            </div>
                            {errors.DsRespuesta && (<p className="help is-danger">{errors.DsRespuesta}</p>)}
                        </div>
                    </div>
                </div>

                <div className="row form-group"> 
                    <div className="form-row">
                        <Button variant="contained" startIcon={<Publish />} className="button" onClick={handleSubmit}>Publicar</Button>
                        <Button variant="contained" startIcon={<Cancel />} className="button" onClick={cancelABM}>Cancelar</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        idIdioma: state.appReducer.idIdioma,
    }
}

export default connect(mapStateToProps)(PreguntasFrecuentesABM);