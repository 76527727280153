import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import useForm from "./useForm";
import validate from './validation';
import { setSessionUser, clearSession } from '../../redux/action'
import { setSessionUserLocalStorage } from '../../services/auth.service'
import { ValidateUser, ValidateUserAndPasswordInAd, SetearUsuarioToken } from '../../services/login.service'
import swal from 'sweetalert2';
import { Visibility, VisibilityOff } from '@material-ui/icons'
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../../assets/img/logo.svg';
import "./login.css";



const Login = ({ setSessionUser, clearSession }) => {

  const history = useHistory();

  const [hidePassword, setHidePassword] = useState(true);

  const { values, errors, handleChange, handleSubmit } = useForm(login, validate);

  const recaptcha = useRef();

  clearSession();

  function login() {

    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      swal.fire({
        title: 'Información',
        icon: 'warning',
        text: 'Por favor, Verificar reCAPTCHA',
        allowOutsideClick: false,
        confirmButtonText: 'Ok'
      });

    } else {

    return ValidateUser(values.usuario.toLowerCase(), captchaValue)
      .then(
        async (resValidateUser) => {
          let resp = resValidateUser.data;
          if (resp.ResultadoExitoso) {
            return await ValidateUserAndPasswordInAd(values.usuario.toLowerCase(), values.password);
          } else {
            if (resp.MensajeError === "") {
              swal.fire({
                title: "Información",
                text: "Error de credenciales. Intentelo nuevamente",
                icon: "warning",
              });
              
            }else{
              swal.fire({
                title: "Información",
                text: "Error de validación de Captcha. Intentelo nuevamente",
                icon: "warning",
              });
              window.grecaptcha.reset();
            }
            return Promise.reject();
          }
        }
      )
      .then(
        async (resValidateUserAndPasswordInAd) => {
          if (resValidateUserAndPasswordInAd.data) {
            return await SetearUsuarioToken(values.usuario);
          } else {
            if (resValidateUserAndPasswordInAd.data !== undefined) {
              swal.fire({
                title: 'Login BackOffice AA2000',
                icon: 'warning',
                text: 'Usuario o contraseña inválida. Intentelo nuevamente',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
              });
              return Promise.reject();
            }
          }
        }
      )
      .then(
        async (resLogin) => {
          setSessionUserLocalStorage(resLogin.data);
          setSessionUser(resLogin.data);
          history.push("/home");
        }
      )
      .catch(() => { });
    }
  }

  function ShowHidePassword() {
    setHidePassword(!hidePassword);
  }

  return (
    <div className="container">
      <div className="logo">
        <img src={logo} alt="Aeropuertos Argentina 2000"></img>
      </div>
      <h2 className="titleLogin">Acceso a CMS</h2>
      <div className="column is-4 is-offset-4">
        <div className="box">
          <form onSubmit={handleSubmit} noValidate>
            <div className="field">
              <label className="label">Usuario</label>
              <div className="control">
                <input autoComplete="on" className={`input ${errors.usuario && 'is-danger'}`} type="text" placeholder="&#xf007;" name="usuario" onChange={handleChange} value={values.usuario || ''} required />
                {errors.usuario && (
                  <p className="help is-danger">{errors.usuario}</p>
                )}
              </div>
            </div>
            <div className="field">
              <label className="label">Password</label>
              <div className="control password">
                <input className={`input ${errors.password && 'is-danger'}`} type={hidePassword ? "password" : "text"} placeholder="&#xf023;" name="password" onChange={handleChange} value={values.password || ''} required />
                {hidePassword ? <Visibility onClick={ShowHidePassword}/> : <VisibilityOff onClick={ShowHidePassword}/>}
              </div>
              {errors.password && (
                <p className="help is-danger">{errors.password}</p>
              )}
            </div>
            <div className="field">
              <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
            </div>
            <button type="submit" className="button is-block is-info is-fullwidth">Ingresar <span className="fa fa-sign-in"></span></button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { setSessionUser, clearSession })(Login)