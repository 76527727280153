import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { DeleteTraficoFile, GetTraficoById, SaveTrafico, GetPeriodosByIdioma, SaveTraficoFile} from '../../../services/traficos.service';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';

import swal from 'sweetalert2';
import { successSwal } from '../../../assets/resources/swal-config';
import ReactQuill from 'react-quill';
import { Button, TextField, Grid, makeStyles, Fab, IconButton } from '@material-ui/core';
import { Delete, NoteAdd } from '@material-ui/icons';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Publish, Cancel, AddPhotoAlternate } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import SwitcherIdioma from '../../../components/switcherIdioma';
import 'react-quill/dist/quill.snow.css';
import '../noticias.css';
import NotFile  from '../../../assets/img/NotFile.png';
import PdfFile from '../../../assets/img/PdfFile.png';
import NoFoundImage from '../../../assets/img/not_found.png';

const Styles = makeStyles((theme) => ({
    del: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    img: {
        width: '100px',
    },
    button: {
        color: '#FEF',
        backgroundColor: '#109ADD',
        '&:hover': {
            backgroundColor: '#0080FF',
            color: '#FEF'
        }
    }
}));

const TraficoABM = (props) => {
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            ['link', 'image', 'video'],
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ]
    }

    

    const classes = Styles();

    const [errors, setErrors] = useState([]);
    const [periodos, setPeriodos] = useState([])
    const [trafico, setTrafico] = useState({
        Id: 0,
        Activa: true,
        FechaCreacion:  new Date(),
        Periodo: '',
        FechaBaja: null,
        IdIdioma: props.idIdioma,
        LinkArchivo: null,
        FileArchivo: null,
        LinkCaratula: null,
        Base64Caratula: null,
    })
    const [editing, setEditing] = useState(false);
    const Id = props.match.params.id;
    const history = useHistory();
    const [archivo, setArchivo] = useState({ srcArchivo: NotFile, name:'', hiddenRemoveArchivo: true });
    const [caratula, setCaratula] = useState({ srcCaratula: NoFoundImage, name:'', hiddenRemoveCaratula: true });

    const validationSchema = {
        Id: { required: false },
        Activa: { required: false },
        FechaCreacion: { required: false },
        Periodo: { required: true },
        FechaBaja: { required: false },
        IdIdioma: { required: false },
        LinkArchivo: { required: true },
        LinkCaratula: { required: true },
        FileArchivo: { required: true },
        Base64Caratula: { required: true },
    }

    useEffect(() => {
        GetPeriodosByIdioma(props.idIdioma).then((resGetPeriodos) => {
            if(resGetPeriodos.data){
                // const periodos = ["", (new Date().getFullYear() + 1).toString(), (new Date().getFullYear()).toString() ,(new Date().getFullYear() - 1).toString()];
                // const periodos =[];
                // for (var i = 0; i < resGetPeriodos.data.length; i++){
                //     if (periodos.indexOf(resGetPeriodos.data[i]) == -1)
                //         periodos.push(resGetPeriodos.data[i]);
                // }
                setPeriodos(resGetPeriodos.data)
            }
        })
        if (Id) {
            GetTraficoById(Id)
                .then(response => {
                    if (response.data) {
                        // setTrafico({...response.data});
                        setTrafico({...response.data, Base64Caratula: response.data.Base64Caratula !== null ? response.data.Base64Caratula.toString().split(",", 2)[1] : null});
                        var LinkArchivo = response.data.LinkArchivo ;
                        var LinkCaratula = response.data.LinkCaratula;

                        setArchivo({ ...archivo, srcArchivo: LinkArchivo != "" ? PdfFile: NotFile, name:LinkArchivo, hiddenRemoveArchivo: LinkArchivo != "" ? false : true });
                        setCaratula({ ...caratula, srcCaratula: LinkCaratula !== "" ? response.data.Base64Caratula : NoFoundImage, name:LinkCaratula, hiddenRemoveCaratula: LinkCaratula !== "" ? false : true  });

                    }
                })
                .catch(err => console.error(err))
        } else {
            setTrafico({ ...trafico, IdIdioma: props.idIdioma });
            setEditing(true)
        }
    }, [props.idIdioma])

    const isValid = () => {
        let valid = true;
        let errors = {};

        try {
            for (let key of Object.keys(trafico)) {
                let value = trafico[key]
                if (validationSchema[key].required) {

                    if (!value) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }
                    // if (!errors[key] && key === 'Fecha' && value.toString() === 'Invalid Date') {
                    //     errors[key] = 'formato de fecha no válido';
                    //     setErrors(errors);
                    //     valid = false;
                    // }
                }
            }
        } catch (err) {
            console.error('Error', err);
        }

        return valid;
    }

    const handleSubmit = () => {
        if (isValid()) {
            SaveTrafico(trafico)
                .then((resTrafico) => {
                    if (resTrafico.data) {
                        SaveTraficoFile(trafico.FileArchivo).then((resTrafico)=>{
                        console.log(resTrafico.data);
                        swal.fire({
                            text: 'Se han guardado los cambios con éxito',
                            ...successSwal
                        }).then(() => { history.push('/traficos'); })
                    });
                    } else {
                        swal.fire({
                            title: 'Error al guardar los cambios',
                            icon: 'warning',
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                });
        }
    }

    const handleCancelClick = () => history.push('/Traficos');

    const handleChange = e => {
        if ( e.target.value != "")
            setErrors({...errors, [e.target.name] : ""});
        setTrafico({ ...trafico, [e.target.name]: e.target.value})
    }

    const handleActivaChange = event => {
        setTrafico({ ...trafico, Activa: event.target.checked })
    }

    const handleEditClick = () => {
        if (!editing){
            setEditing(true);
        }
            
    }

    const handleUploadArchivoClick = event => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            reader.readAsDataURL(file);

            if (validateFileGral(event)){
                reader.onload = () => {

                    setTrafico({ ...trafico, LinkArchivo: file.name, FileArchivo: file })
                    setArchivo({ ...archivo, srcArchivo: PdfFile, name:file.name, hiddenRemoveArchivo: false });
                    if ( file.name != "")
                        setErrors({...errors, ["LinkArchivo"] : ""});
                };
            }

            
        }
        event.target.value = "";
    };

    const handleUploadCaratulaClick = event => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length && validateImageGral(event)) {
            const file = event.target.files[0];
            reader.readAsDataURL(file);

            //  if (validateImageGral(event)){
            //     reader.onload = () => {

            //         // const base64Archivo = reader.result.toString().split(",", 2)[1];
            //         setTrafico({ ...trafico, LinkZip: file.name, FileZip: file })
            //         setCaratula({ ...caratula, srcCaratula: CaratulaFile, name:file.name, hiddenRemoveCaratula: false });
            //     };
            //  }

             reader.onload = () => {
                console.log("Entro reader")
                let img = new Image()
                img.src = reader.result;

                //VALIDA ANCHO Y ALTO DE LA IMAGEN
                img.onload = () => {
                    console.log("Entro img")
                    // if ((img.width >= 300 && img.width <= 350) && (img.height >= 200 && img.height <= 250)) {
                    const base64Caratula = reader.result.toString().split(",", 2)[1];
                    setTrafico({ ...trafico, LinkCaratula: file.name, FileCaratula: file, Base64Caratula: base64Caratula })
                    setCaratula({ ...caratula, srcCaratula: reader.result, name:file.name, hiddenRemoveCaratula: false });
                    if ( file.name != "")
                        setErrors({...errors, ["LinkCaratula"] : ""});
                    // } else {
                    //     swal.fire({
                    //         title: 'Información',
                    //         icon: 'warning',
                    //         text: 'Las dimensiones de la imagen deben ser entre 300px/350px de ancho y 200px/250px de alto',
                    //         allowOutsideClick: false,
                    //         confirmButtonText: 'Ok',
                    //     });
                    // }
                    }
            };
            
        }
        event.target.value = "";
    };

    const validateImageGral = (event) => {
        var fileName = event.target.files[0].name;
        var fileSize = event.target.files[0].size;

        if (fileSize > 3000000) {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'La imagen no debe superar los 3MB',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
            event.target.value = '';
            return false;
        } else {
            var ext = fileName.split('.').pop();

            ext = ext.toLowerCase();

            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'svg':
                case 'gif':
                    return true;
                    break;
                default:
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'LA imagen no tiene la extensión adecuada',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                    event.target.value = '';
                    return false;
            }
        }
    }

    const validateFileGral = (event) => {
        var fileName = event.target.files[0].name;
        var fileSize = event.target.files[0].size;

        // if (fileSize > 3000000) {
        //     swal.fire({
        //         title: 'Información',
        //         icon: 'warning',
        //         text: 'El archivo no debe superar los 3MB',
        //         allowOutsideClick: false,
        //         confirmButtonText: 'Ok'
        //     });
        //     event.target.value = '';
        //     return false;
        // } else {
            var ext = fileName.split('.').pop();

            ext = ext.toLowerCase();

            switch (ext) {
                case 'pdf':
                    return true;
                    break;
                default:
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'El archivo no tiene la extensión adecuada',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                    event.target.value = '';
                    return false;
            }
        // }
    }

    const removeArchivo = () => {
        DeleteTraficoFile(trafico.LinkArchivo);
        setTrafico({ ...trafico, LinkArchivo: null, FileArchivo: null })
        setArchivo({ ...archivo, srcArchivo: NotFile, name:'', hiddenRemoveArchivo: true });
    }

    const removeCaratula = () => {
        // DeleteTraficoFile(trafico.LinkCaratula);
        setTrafico({ ...trafico, LinkCaratula: null, Base64Caratula: null })
        setCaratula({ ...caratula, srcCaratula: NoFoundImage, name:'', hiddenRemoveCaratula: true });
    }

    return (
        <div className="main-content">
            {Id ?
                <h1 className="title">Reporte de Tráfico - Período: <b className="greentext">{trafico.Periodo}</b></h1>
                :
                <h1 className="title">Nuevo Reporte de Tráfico</h1>
            }

            <br />
            <br />
            
            <div className="content">
                <div className="row form-group">
                    <div className="col-md-6">
                        <label className="label">Idioma: {props.idIdioma == 'ES-AR' ? 'Español' : 'Inglés'}</label>
                    </div>

                    <div className={`col-md-${Id ? '6' : '3'}`}>
                        <div className="active-switch">
                            <label className="label">HABILITADA</label>
                            <input type="checkbox" id="check" checked={trafico.Activa} onChange={handleActivaChange} disabled={!editing} />
                            <label htmlFor="check" className="switch"></label>
                        </div>
                    </div>

                    <div className="col-md-3" hidden={Id}>
                        <SwitcherIdioma />
                    </div>
                </div>

                <div className="row form-group" hidden={!Id}>
                        <div className="col-md-12">
                            <Button variant="contained" className={`btnAdd ${editing ? 'gray' : ''}`} color="primary" startIcon={<FontAwesomeIcon icon={faEdit} />} onClick={handleEditClick}>Editar</Button>
                        </div>
                </div>
                
                <div className="row form-group">
                    <div className="col-md-12">
                        
                        <div className="box">
                            <div className="row form-group">
                                <div className="col-md-4">
                                    <TextField
                                        disabled={!editing}
                                        select
                                        label="Período"
                                        name="Periodo"
                                        value={trafico.Periodo}
                                        onChange={(e) =>handleChange(e)}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        helperText={errors.Periodo}
                                        error={errors.Periodo ? true : false}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        >
                                        <option>Periodo</option>
                                        {periodos.map((option) => (
                                            <option key={option} value={option}>
                                            {option}
                                            </option>
                                        ))}
                                        </TextField>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                        <label className="label">Seleccione una Imágen</label>
                                        <Grid container spacing={2}>
                                            <br/>
                                            <Grid item>
                                                <img className={classes.img} alt="complex" src={caratula.srcCaratula} />
                                            </Grid>

                                            <Grid item xs container direction="column" spacing={2}>
                                                {caratula.hiddenRemoveCaratula ? <Fragment>
                                                    <input type="file" accept=".jpg, .jpeg, .png, .gif, .svg"  className={classes.input} id="contained-button-file1" disabled={!editing}
                                                        onChange={handleUploadCaratulaClick} />

                                                    <label className="buttonImg" htmlFor="contained-button-file1">
                                                        <Fab component="span" color="primary" disabled={!editing}>
                                                            <AddPhotoAlternate />
                                                        </Fab>
                                                    </label>
                                                </Fragment> : ""}
                                                <div className={classes.del}>
                                                    {!caratula.hiddenRemoveCaratula ? <IconButton color="secondary" aria-label="delete" component="span" disabled={!editing} onClick={removeCaratula}>
                                                        <Delete />
                                                    </IconButton> : ""}
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <label>{caratula.name}</label>
                                        {errors.LinkCaratula && (<p className="help is-danger">{errors.LinkCaratula}</p>)}
                                    </div>
                                <div className="col-md-6">
                                    <label className="label">Seleccione archivo pdf</label>
                                    <Grid container spacing={3}>
                                        <br/>
                                        <Grid item>
                                            <img className={classes.img} alt="complex" src={archivo.srcArchivo} />
                                        </Grid>
                                        <Grid item xs container direction="column" spacing={2}>
                                            {archivo.hiddenRemoveArchivo ? <Fragment>
                                                <input type="file" accept=".pdf" className={classes.input} id="contained-button-file2" disabled={!editing} onChange={handleUploadArchivoClick} />

                                                <label className="buttonImg" htmlFor="contained-button-file2">
                                                    <Fab component="span" color="primary" disabled={!editing}>
                                                        <NoteAdd />
                                                    </Fab>
                                                </label>
                                            </Fragment> : ""}
                                            <div className={classes.del}>
                                                {!archivo.hiddenRemoveArchivo ? <IconButton color="secondary" aria-label="delete" component="span" disabled={!editing} onClick={removeArchivo}>
                                                    <Delete />
                                                </IconButton> : ""}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <label>{archivo.name}</label>
                                    {errors.LinkArchivo && (<p className="help is-danger">{errors.LinkArchivo}</p>)}
                                </div>

                                <div className="col-md-6">
                                    <br/>
                                    <label className="label">Requerimientos de las imágenes:</label>
                                    <ul className="validImages">
                                        {/* <li>Tamaño de la imagen de listado 300px/350px ancho 200px/250px alto</li>
                                        <li>Tamaño de la imagen máx. 1000px de ancho</li>*/}
                                        <li>Extensiones validas: JPG, JPEG, PNG, GIF, SVG</li> 
                                        <li>Max. 3MB</li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <br/>
                                    <label className="label">Requerimientos de los archivos:</label>
                                    <ul className="validImages">
                                        {/* <li>Tamaño de la imagen de listado 300px/350px ancho 200px/250px alto</li>
                                        <li>Tamaño de la imagen máx. 1000px de ancho</li>*/}
                                        <li>Extensiones validas: PDF</li> 
                                        {/* <li>Max. 3MB</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <Button variant="contained" color="primary" className="button" disabled={!editing} onClick={handleSubmit} startIcon={<Publish />}>Publicar</Button>
                            <Button variant="contained" startIcon={<Cancel />} className="button" onClick={handleCancelClick}>Cancelar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        idIdioma: state.appReducer.idIdioma
    }
}

export default connect(mapStateToProps)(TraficoABM);