import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { ESP } from '../../../environments/idioma';
import { GetCierresByAeropuerto, SaveOrUpdate, DeleteCierreAeropuerto } from '../../../services/cierreAeropuertos.service';
import { getSessionUser } from '../../../services/auth.service';
import swal from 'sweetalert2';
import { successSwal, confirmSwal } from '../../../assets/resources/swal-config';
import { Button, Tooltip, withStyles } from '@material-ui/core';
import { Add, ChevronRight, Delete, Visibility, VisibilityOff } from '@material-ui/icons';
import { GiControlTower } from '@react-icons/all-files/gi/GiControlTower';
import Pagination from '../../../components/Pagination';
import SelectAeropuerto from '../../../components/SelectAeropuerto';
import moment from 'moment';
import './cierresAeropuertos.css';

const CierresAeropuertos = (props) => {
    const history = useHistory();
    const [state, setState] = useState({
        cierreAeropuertos: [],
        idIdioma: ESP
    });

    const user = getSessionUser();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(16);

    const filteredRow = state.cierreAeropuertos.filter(row => { return row.show })    
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.cierreAeropuertos.length - page * rowsPerPage);

    useEffect(() => {
        loadCierreAeropuertos()
    }, [props.idIdioma, props.idAeropuerto]);

    const loadCierreAeropuertos = () => {
        const CierreAeropuertos = [];
        setPage(0);
        GetCierresByAeropuerto(props.idAeropuerto)
            .then((response) => {
                if (response.data) {
                    for (var i = 0; i < response.data.length; i++){
                        const cierresAeropuertos = response.data[i];
                        cierresAeropuertos["show"] = true;
                        CierreAeropuertos.push(cierresAeropuertos)
                    }
                    setState({ ...state, cierreAeropuertos: CierreAeropuertos, isEditing: false });
                }
            })
    }

    const handleAddClick = () => history.push("/CierreAeropuerto");

    const handleEditClick = id => history.push(`/CierreAeropuerto/${id}`);

    const handleDeleteClick = (cierreAeropuerto) => {
        swal.fire({
            title: 'Eliminar Cierre de Aeropuerto',
            text: '¿Está seguro que desea eliminar el cierre de aeropuerto?',
            ...confirmSwal
        })
            .then(confirm => {
                if (confirm.value) {
                    console.log(cierreAeropuerto);
                    DeleteCierreAeropuerto(cierreAeropuerto)
                        .then(async() => {
                            await loadCierreAeropuertos();
                            swal.fire({
                                text: 'Se ha eliminado el cierre de aeropuerto con éxito',
                                ...successSwal
                            })
                        })
                }
            })
    }

    const handleActiveClick = cierreAeropuerto => {
        const obj = {
            id: cierreAeropuerto.Id,
            idAeropuerto: cierreAeropuerto.IdAeropuerto,
            ca_Texto: cierreAeropuerto.CA_Texto,
            ca_FechaInicio: cierreAeropuerto.CA_FechaInicio,
            ca_FechaFin: cierreAeropuerto.CA_FechaFin,
            ca_Activo: !cierreAeropuerto.CA_Activo
        }

        SaveOrUpdate(obj)
            .then((response) => {
                loadCierreAeropuertos();
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    return (
        <div className="main-content">
            <br />
            <h1 className="title">Alertas - <b className="green-text">CIERRE DE AEROPUERTOS</b></h1>
            <br />
            <br />
            <div className="content">
                <div className="row form-group">
                    <div className="col-sm-6">
                        <SelectAeropuerto />
                        <br/>
                    </div>

                    <div className="col-sm-3 offset-sm-3">
                        <Button variant="contained" color="primary" className="btnAdd" startIcon={<Add />} onClick={handleAddClick}>Nuevo</Button>
                        <br/>
                    </div>
                </div>

                <div className="row form-group">
                    {(rowsPerPage > 0 ? filteredRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRow).map((row, index) => (
                        <div className="col-md-3" key={row.Id}>
                            <div className="cierreAeropuerto-box">
                                <div className="top">
                                    {!row.CA_Activo ?
                                        <LightTooltip
                                        placement="top"
                                            title={<span><strong>Mostrar: </strong>Este elemento pasará a estar visible para el lector.</span>}>
                                            <VisibilityOff className="inactivo" onClick={() => handleActiveClick(row)} />
                                        </LightTooltip>
                                        :
                                        <LightTooltip
                                        placement="top"
                                        title={<span><strong>Ocultar: </strong>Este elemento dejará de estar visible para el lector pero no se eliminará.</span>}>
                                            <Visibility className="activo" onClick={() => handleActiveClick(row)} />
                                        </LightTooltip>
                                    }
                                </div>
                                <p className="cierreAeropuerto-name"><span><b>Desde:</b> {moment(row.CA_FechaInicio).format('L')}</span></p> 
                                <p className="cierreAeropuerto-name"><span><b>Hasta:</b> {moment(row.CA_FechaFin).format('L')}</span></p>
                                <div className="cierreAeropuerto-logo">
                                    <GiControlTower />
                                </div>
                                <div className="bottom">
                                    <LightTooltip title="Eliminar aviso de cierre" placement="top">
                                        <Delete className="del" onClick={() => handleDeleteClick(row)}/>
                                    </LightTooltip>
                                    <div className="details" onClick={() => handleEditClick(row.Id)}>
                                        <span>VER</span>
                                        <ChevronRight/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {filteredRow.length > rowsPerPage ? <Pagination count={filteredRow.length} page={page} rowsPerPage={rowsPerPage} onChangePage={handleChangePage} /> : ""}
        </div>
    )
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        letterSpacing: 0,
    },
}))(Tooltip);

const mapStateToProps = state => {
    return {
        id: state.appReducer.id,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto,
        idIdioma: state.appReducer.idIdioma
    }
}

export default connect(mapStateToProps)(CierresAeropuertos);