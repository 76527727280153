import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { GetPreguntasFrecuentesByIdioma, DeletePreguntaFrecuente, SavePreguntaFrecuente } from '../../services/preguntasFrecuentes.service.js';
import { ESP } from '../../environments/idioma';

import swal from 'sweetalert2'
import { successSwal, confirmSwal } from '../../assets/resources/swal-config'
import { Container, Draggable } from 'react-smooth-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { Add, Visibility, VisibilityOff, Edit, Delete, DragIndicator } from '@material-ui/icons';

import Pagination from '../../components/Pagination';
import SwitcherIdioma from '../../components/switcherIdioma';
import './preguntasFrecuentes.css'


const TooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #59C576',
        padding: 10,
    },
}))

const HtmlTooltip = (props) => {
    const classes = TooltipStyles();

    return <Tooltip classes={classes} {...props} />;
}

const PreguntasFrecuentes = (props) => {
    const history = useHistory();
    const [state, setState] = useState({
        preguntasFrecuentes: [],
        idIdioma: ESP
    });
    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(state.preguntasFrecuentes.length);

    const filteredItems = state.preguntasFrecuentes.filter(item => { return item.show });

    useEffect(() => {
        loadPreguntas(props.idIdioma);
    }, [props.idIdioma, state.isEditing]);

    const loadPreguntas = (idIdioma) => {
        // setPage(0);
        document.getElementById("searchInput").value = "";
        GetPreguntasFrecuentesByIdioma(idIdioma)
            .then((resPreguntasFrecuentes) => {
                if (resPreguntasFrecuentes.data) {
                    const preguntas = [];
                    for (var i = 0; i < resPreguntasFrecuentes.data.length; i++) {
                        const pregunta = resPreguntasFrecuentes.data[i];
                        pregunta["show"] = true;
                        preguntas.push(pregunta);
                    }
                    setState({ ...state, preguntasFrecuentes: preguntas })
                }
            })
    }

    const handleAddClick = () => history.push("/PreguntaFrecuente");

    const handleEditClick = id => history.push(`/PreguntaFrecuente/${id}`);

    const handleDeleteClick = preguntaFrecuente => {
        swal.fire({
            title: 'Eliminar Pregunta',
            text: '¿Está seguro que desea eliminar la pregunta  ' + preguntaFrecuente.DsPregunta + '?',
            ...confirmSwal
        })
            .then(async (confirm) => {
                if (confirm.value) {
                    await DeletePreguntaFrecuente(preguntaFrecuente);
                    loadPreguntas(props.idIdioma);
                }
            });
    }

    const handleVisibilityClick = (preguntaFrecuente) => {
        delete preguntaFrecuente.show;
        preguntaFrecuente.McInactiva = preguntaFrecuente.McInactiva == 0 ? 1 : 0;
        SavePreguntaFrecuente(preguntaFrecuente)
            .then((resSavePreguntaFrecuente) => {
                loadPreguntas(props.idIdioma);
            })
    }

    const handleCancelClick = () => { setState({ ...state, isEditing: false, idPregunta: null }) };

    const handleFinalizaABMClick = () => {
        swal.fire({
            text: 'Se han guardado los cambios con éxito',
            ...successSwal
        })
            .then(() => { setState({ ...state, isEditing: false, idPregunta: null }) });
    }

    const handleSearch = (e) => {
        const search = e ? e.target.value.toLowerCase().trim() : document.getElementById("searchInput").value.toLowerCase().trim();
        const keys = ["DsPregunta", "DsRespuesta"];
        const newRows = [];

        if(search.length >= 2) {
            for(let i = 0; i < state.preguntasFrecuentes.length; i++) {
                let bool;
                let row = state.preguntasFrecuentes[i];
                for(var index = 0; index < keys.length; index++) {
                    let value = row[keys[index]];
                    if(value) {
                        value = value.toLowerCase();
                        bool = index == 0 ? value.indexOf(search) != -1 : bool || value.indexOf(search) != -1;
                    }
                }
                row.show = bool;
                newRows.push(row);
            }
        } else {
            for(let i = 0; i < state.preguntasFrecuentes.length; i++) {
                state.preguntasFrecuentes[i].show = true;
                newRows.push(state.preguntasFrecuentes[i]);
            }
        }
        // setPage(0)
        setState({ ...state, localesComerciales: newRows });
    }

    const handleFinishDrag = async (result) => {
        for (let i = 0; i < result.length; i++){
            const item = { IdPregunta: result[i].IdPregunta, IdIdioma: result[i].IdIdioma, DsPregunta: result[i].DsPregunta, DsRespuesta: result[i].DsRespuesta, DsOrden: i, McInactiva: result[i].McInactiva }
            await SavePreguntaFrecuente(item);
        }
        loadPreguntas(props.idIdioma);
    }

    const applyDrag = (arr, dragResult) => {    
        const { removedIndex, addedIndex, payload } = dragResult;
        if (removedIndex === null && addedIndex === null) return arr;
        const result = [...arr];
        let itemToAdd = payload;
        if (removedIndex !== null) {
            itemToAdd = result.splice(removedIndex, 1)[0];
        }
        if (addedIndex !== null) {
            result.splice(addedIndex, 0, itemToAdd);
        }
        return result;
    }

    return (
        <div className="main-content">
            <h1 className="title titleFAQ">Preguntas Frecuentes</h1>
            <div className="content">
                <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4">
                        <div className="search-box">
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="type" name="search" id="searchInput" placeholder="Buscar pregunta o tema" onKeyUp={handleSearch} />
                        </div>
                        <br/>
                    </div>
                    <div className="col-sm-6 col-md-2 col-lg-2 offset-md-2 offset-lg-3">
                        <SwitcherIdioma/>
                        <br />
                    </div>
                    <div className="col-sm-6 col-md-3 col-lg-3">
                        <Button variant="contained" color="primary" className="btnAdd" startIcon={<Add />} onClick={handleAddClick}>Nuevo</Button>
                        <br/>
                    </div>
                </div>
                {filteredItems.length > 0 ?
                    <div className="simple-page-scroller">
                        <div className="position-items">
                            { filteredItems.map((row, index) => (
                                <div className="position-item" style={{margin: '20px'}} key={"p-" + row.DsOrden + index}>
                                    {state.preguntasFrecuentes.indexOf(row) + 1}
                                </div>
                            ))}
                        </div>
                        <div>
                            <Container dragHandleSelector=".column-drag-handle" onDrop={e => handleFinishDrag(applyDrag(state.preguntasFrecuentes, e))}>
                                { filteredItems.map((row, index) => (
                                    <Draggable key={"i-" + row.IdPregunta}>
                                        <div className="draggable-item">
                                            <span className="column-drag-handle">
                                                <DragIndicator />
                                            </span>
                                            <span className="item-text">{row.DsPregunta}</span>
                                            <div className="actions">
                                                <HtmlTooltip
                                                    placement="top"
                                                    title={
                                                        <div>
                                                            {!row.McInactiva ?
                                                                <span><strong>Ocultar: </strong>Este elemento dejará de estar visible para el lector pero no se eliminará.</span>:
                                                                <span><strong>Mostrar: </strong>Este elemento estará visible para el lector.</span> 
                                                            }
                                                        </div>
                                                    }
                                                >
                                                    {row.McInactiva === 1 ? <VisibilityOff onClick={() => handleVisibilityClick(row)} /> : <Visibility className="visible" onClick={() => handleVisibilityClick(row)} />}
                                                </HtmlTooltip>
                                                <Edit onClick={() => handleEditClick(row.IdPregunta)}/>
                                                <Delete onClick={() => handleDeleteClick(row)} />
                                            </div>
                                        </div>
                                    </Draggable>
                                ))}
                            </Container>
                        </div>
                    </div>
                    :
                    <span className="message" >No se encontraron preguntas en el idioma seleccionado.</span>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        idIdioma: state.appReducer.idIdioma,
        userArpt: state.permisosReducer.aeropuertos
    }
}


export default connect(mapStateToProps)(PreguntasFrecuentes);
