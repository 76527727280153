import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { GetGrupoFull, SaveOrUpdate, DeleteGrupoFull } from '../../../services/grupo.service'
import { GetUsers } from '../../../services/usuario.service';
import { getSessionUser } from '../../../services/auth.service';
import { GetArbolComponentes, GetArbolConPermisos } from '../../../services/componente.service';
import { FormatUserName } from '../../../environments/utils';
import { ROL_MASTER, ROL_ADMIN } from '../../../services/permisos.service';

import swal from 'sweetalert2';
import { confirmSwal, successSwal } from '../../../assets/resources/swal-config';
import { withStyles } from '@material-ui/core/styles';
import { Button, Collapse, Tooltip} from '@material-ui/core';
import { Add, Save, Cancel, Delete, AccountCircle, ExpandLess, ExpandMore, Help } from '@material-ui/icons';

import './grupoAbm.css';

const GrupoAbm = (props) => {
    const history = useHistory();
    const Id = props.match.params.id;

    // ROLES Y PERMISOS
    const user = getSessionUser();
    const rolMaster = user.IdRol === ROL_MASTER;
    const rolAdmin = user.IdRol === ROL_ADMIN;

    //ARRAY DE NODOS CARGADOS
    let componentes = [];
    const [usuarios, setUsuarios] = useState([]);

    //BUSQUEDA DE USUARIOS
    const [userSearch, setUserSearch] = useState("");
    const [userSearchList, setUserSearchList] = useState([]);
    const [showUserList, setShowUserList] = useState(false);
    const [showUserAdd, setShowUserAdd] = useState(true);

    //SETEO ESTADO DE ERRORES SOBRE VALIDACIONES
    const [errors, setErrors] = useState("");

    //SETEO ESTADO VALORES DE LOS CAMPOS
    const [nombre, setNombre] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [usuarioList, setUsuarioList] = useState([]);
    const [usuarioIList, setUsuarioIList] = useState([]);

    //SETEO  ESTADOS ARBOL DE COMPONENTES
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    //SETERO ESTADO DE NODOS PADRES E HIJOS
    const [nodos, setNodos] = useState([]);

    useEffect(() => { cargaInicial(); }, []);

    const cargaInicial = async () => {
        return await GetUsers()
        .then(async(resGetUsers) => {
            if (resGetUsers.data) {
                const activeUsers = mapUsers(resGetUsers.data);
                setUsuarios(activeUsers);
                if (Id) {
                    getGrupoById(activeUsers);
                } else {
                    GetArbolComponentes().then((res) => { mapComponentes(res.data) })
                }
            }
        })
        .catch(e => {console.log(e)})
    }

    const mapUsers = (users) => {
        const usersArr = []
        users.map(user => {
            let obj = { id: user.Id, name: user.DS_Nombre }
            usersArr.push(obj);
        })
        return usersArr;
    }

    const getGrupoById = async(activeUsers) => {
        return await GetGrupoFull(Id)
        .then(async(resGetGrupo) => {
            const usuariosList = resGetGrupo.data.IdUsuariosList;
            const mapUsuarios = mapUserList(usuariosList, activeUsers);
            setNombre(resGetGrupo.data.Nombre)
            setDescripcion(resGetGrupo.data.Descripcion);
            setUsuarioList(mapUsuarios);
            setUsuarioIList(usuariosList);
            let grupo = { Id: Id }
            return await GetArbolConPermisos(grupo)
        })
        .then((res) => { mapComponentes(res.data) })
        .catch(() => {});
    }

    const mapComponentes = (data) => {
        let all = [];
        let modulos = [];
        let aeropuertos = [];
        let paginaPrincipal = [];
        let vuelos = [];
        let comoLlego = [];
        let transporte = [];
        let estacionamiento = [];
        let noticias = [];

        //COMO LLEGO       
        if (data[0].Hijos[0].Hijos) {
            data[0].Hijos[0].Hijos.map(item => {
                let obj = {
                    label: item.Nombre,
                    value: item.Id,
                }
                if (item.Chequeado) componentes.push(item.Id);
                comoLlego.push(obj);
            });
        }

        //ESTACIONAMIENTO
        if (data[0].Hijos[1].Hijos) {
            data[0].Hijos[1].Hijos.map(item => {

                let obj = {
                    label: item.Nombre,
                    value: item.Id,
                }
                if (item.Chequeado) componentes.push(item.Id);
                estacionamiento.push(obj);
            });
        }

        //NOTICIAS
        if (data[0].Hijos[3].Hijos) {
            data[0].Hijos[3].Hijos.map(item => {

                let obj = {
                    label: item.Nombre,
                    value: item.Id,
                }
                if (item.Chequeado) componentes.push(item.Id);
                noticias.push(obj);
            });
        }

        //TRANPORTE   
        if (data[0].Hijos[7].Hijos) {
            data[0].Hijos[7].Hijos.map(item => {
                let obj = {
                    label: item.Nombre,
                    value: item.Id,
                }
                if (item.Chequeado) componentes.push(item.Id);
                transporte.push(obj);
            });
        }

        //VUELOS
        if(data[0].Hijos[8].Hijos) {
            data[0].Hijos[8].Hijos.map(item => {
                let obj = {
                    label: item.Nombre,
                    value: item.Id,
                }
                if (item.Chequeado) componentes.push(item.Id);
                vuelos.push(obj);
            });
        }

        //SECCIONES AEROPUERTO
        data[0].Hijos.map(item => {
            let obj = {
                label: item.Nombre,
                value: item.Id,
                children: []
            }
            if (item.Chequeado) componentes.push(item.Id);
            aeropuertos.push(obj);
        });

        //SECCIONES PAGINA PRINCIPAL
        data[1].Hijos.map(item => {
            let obj = {
                label: item.Nombre,
                value: item.Id,
            }
            if (item.Chequeado) componentes.push(item.Id);
            paginaPrincipal.push(obj);
        });

        for (let index = 0; index < aeropuertos.length; index++) {
            if (aeropuertos[index].value === 4) aeropuertos[index].children = vuelos;
            if (aeropuertos[index].value === 5) aeropuertos[index].children = comoLlego;
            if (aeropuertos[index].value === 6) aeropuertos[index].children = transporte;
            if (aeropuertos[index].value === 7) aeropuertos[index].children = estacionamiento;
            if (aeropuertos[index].value === 48) aeropuertos[index].children = noticias;
        }

        //MODULOS
        data.map(item => {
            let obj = {
                label: item.Nombre,
                value: item.Id,
                children: []
            }
            modulos.push(obj);
        });

        for (let index = 0; index < modulos.length; index++) {
            if (modulos[index].value === 1) modulos[index].children = aeropuertos;
            if (modulos[index].value === 2) modulos[index].children = paginaPrincipal;
        }

        all = modulos
        setNodos(all);
        setChecked(componentes)
    }

    const mapUserList = (users, activeUsers) => {
        const list = [];
        for (var i = 0; i < users.length; i++){
            const user = activeUsers.find(u => u.id == users[i]);
            if (user) list.push(user);
        }
        return list;
    } 

    const onSubmit = () => {
        let array = [];// luego modificar esto

        usuarioList.map(usuario => {
            array.push(usuario.id);
        })

        for (let index = 0; index < checked.length; index++) {
            let obj = { Id: parseInt(checked[index]) }
            componentes.push(obj);
        }

        let grupoSave = {
            Id: Id,
            Nombre: nombre,
            Descripcion: descripcion,
            IdUsuariosList: array,
            Componentes: componentes
        }

        if (validateGrupo(grupoSave)) {
            SaveOrUpdate(grupoSave)
                .then((resGrupo) => {
                    if (resGrupo.data) {
                        swal.fire({
                            text: 'Se han guardado los cambios con éxito',
                            ...successSwal
                        }).then(() => { history.push('/Grupos'); });
                    } else {
                        swal.fire({
                            title: 'Error al guardar los cambios',
                            icon: 'warning',
                            text: resGrupo.data.MensajeError,
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                });
        }
    };

    const handleCancelClick = () => history.push('/Grupos');

    const validateGrupo = (grupo) => {

        let errors = {};
        let valido = true;

        if (!grupo.Nombre) {
            errors.nombre = 'El Nombre debe ser requerido';
            setErrors(errors);
            valido = false;
        }
        else if (grupo.Nombre.length < 3) {
            errors.nombre = 'El Nombre debe ser mayor a 3 caracteres';
            setErrors(errors);
            valido = false;
        }

        if (!grupo.Descripcion) {
            errors.descripcion = 'La Descripción debe ser requerida';
            setErrors(errors);
            valido = false;
        }
        else if (grupo.Descripcion.length < 10) {
            errors.descripcion = 'La Descripción debe ser mayor a 10 caracteres';
            setErrors(errors);
            valido = false;
        }

        if (grupo.Componentes.length === 0) {
            errors.componentes = 'El Componente debe ser requerido';
            setErrors(errors);
            valido = false;
        }
        return valido;
    }

    const handleDeleteClick = () => {
        swal.fire({
            title: 'Eliminar Grupo',
            text: '¿Está seguro que desea eliminar el grupo  ' + nombre + '?',
            ...confirmSwal
        })
            .then((confirm) => {
                if (confirm.value) {
                    let users = [];

                    usuarioList.map(u => {
                        users.push(u.id)
                    })

                    usuarioIList.map(u => {
                        if (users.filter(user => { return user == u }).length == 0)
                            users.push(u)
                    })

                    for (let index = 0; index < checked.length; index++) {
                        let obj = { Id: parseInt(checked[index]) }
                        componentes.push(obj);
                    }

                    let grupoDelete = {
                        Id: Id,
                        Nombre: nombre,
                        Descripcion: descripcion,
                        IdUsuariosList: users,
                        Componentes: componentes
                    }

                    deleteGrupo(grupoDelete);
                }
            });
    }

    const deleteGrupo = (grupo) => {
        DeleteGrupoFull(grupo)
            .then(() => {
                swal.fire({
                    text: 'Se ha eliminado el grupo con éxito',
                    ...successSwal
                })
                .then(() => history.push('/Grupos'));
            })
            .catch((e) => {console.log(e)});
    }

    const handleSearch = () => {
        setShowUserList(false);
        const keys = ["name"],
            newRows = [],
            search = userSearch.toLowerCase().trim();
        
        if(search.length >= 2) {
            for(var i = 0; i < usuarios.length; i++) {
                let bool;
                let row = usuarios[i];
                for(var index = 0; index < keys.length; index++) {
                    let value = row[keys[index]];
                    if(value) {
                        value = value.toLowerCase();
                        bool = index == 0 ? value.indexOf(search) != -1 : bool || value.indexOf(search) != -1;
                    }
                }
                if(bool) newRows.push(row);
            }
        }

        if(newRows.length > 0){
            setUserSearchList(newRows);
            setShowUserList(true);
        } else if(newRows.length == 0 && rolMaster){
            swal.fire({
                title: 'No se encontraron usuarios activos',
                text: '¿Desea agregar un nuevo usuario?',
                ...confirmSwal
            })
                .then((confirm) => {
                    if (confirm.value) {
                        history.push({
                            pathname: '/usuario',
                            search: '?editing=true'
                        })
                    }
                });
        } else if (newRows.length == 0 && rolAdmin) {
            setUserSearch("");
            swal.fire({
                title: 'No se encontraron usuarios activos',
                icon: 'warning',
                text: 'Para agregar un nuevo usuario debe solicitarlo a los administradores del sistema.',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
        }
    }

    const handleAddUser = (user) => {
        console.log(user)
        const index = usuarioList.indexOf(user)
        if(index == -1){
            const users = []
            users.push(user);

            usuarioList.map(u => {
                users.push(u)
            })
            setShowUserAdd(true);
            setUsuarioList(users);
        } else {
            swal.fire({
                title: 'Ya existe este usuario.',
                icon: 'warning',
                text: `Este usuario ya pertenece al grupo "${nombre}".`,
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
        }

        setShowUserList(false);
        setUserSearch("");
    }

    const handleDeleteUser = (user) => {
        const users = []
        usuarioList.map(u => {
            if(u.id != user.id)
                users.push(u)
        })
        setUsuarioList(users);
    }

    const handleExpanded = (items) => setExpanded(items);

    const handleChecked = (items) => setChecked(items);

    const handleUserSearch = e => {
        if (e.target.value.length == 0) {
            setUserSearchList([]);
            setShowUserList(false);
        }
        setUserSearch(e.target.value)
    }

    return (
        <div className="main-content">
            <br />
            <h1 className="title">{!Id ? 'Nuevo Grupo' : 'Grupo '}<span className="groupName" hidden={!Id}>{nombre}</span></h1>

            <div className="content">

                <div className="row form-group">
                    <div className="col-md-12">
                        {Id ?
                            <div className="pull-right">
                                <Button variant="contained" className="btnDelete" color="primary" endIcon={<Delete />} onClick={handleDeleteClick}>Eliminar grupo</Button>
                            </div> :
                            <br />
                        }
                    </div>
                </div>

                <div className="row form-group g-info">
                    <div className="col-md-4">
                        <div className="box">
                            <div className="form">
                                <label className="label">Nombre del grupo</label>
                                {Id ?
                                    <span>{nombre}</span>
                                    :
                                    <div className="input-box">
                                        <div className="width">
                                            <input nam="nombre" type="text" className="form-control" value={nombre} onChange={e =>{ setShowUserList(false); setNombre(e.target.value)}} />
                                            {errors.nombre && (<p className="help is-danger">{errors.nombre}</p>)}
                                        </div>
                                        <LightTooltip title='Personalizá el grupo ingresando un nombre representativo. Por ejemplo "Servicios-EZE"' placement="top-start">
                                            <Help className={errors.nombre ? 'margin-bottom' : ''} />
                                        </LightTooltip>
                                    </div>
                                }
                            </div>

                            <div className="form">
                                <label className="label">Descripción</label>
                                {Id ?
                                    <span>{descripcion}</span>
                                    :
                                    <div className="input-box">
                                        <div className="width">
                                            <input nam="descripcion" type="text" className="form-control" value={descripcion} onChange={e => setDescripcion(e.target.value)} />
                                            {errors.descripcion && (<p className="help is-danger">{errors.descripcion}</p>)}
                                        </div>
                                        <LightTooltip title="Detallá las acciones que podés realizar. Por ejemplo: Puede editar y dar de alta un servicio del Aeropuerto de Ezeiza" placement="top-start">
                                            <Help className={errors.descripcion ? 'margin-bottom' : ''} />
                                        </LightTooltip>
                                    </div>
                                }
                            </div>

                            <div className="form">
                                <label className="label">Usuarios</label>
                                <div className="users">
                                    {usuarioList.map((user, index) => (
                                        <div className="user" key={index}>
                                            <AccountCircle className="icon" />
                                            <div>{FormatUserName(user.name)}</div>
                                            <Delete className="delete" onClick={() => handleDeleteUser(user)}/>
                                        </div>
                                    ))}
                                </div>
                                <div className="user-search" hidden={showUserAdd}>
                                    <div className="search">
                                        <input name="usuario" type="text" className="form-control" placeholder="Buscar por nombre o apellido" value={userSearch} onChange={e => handleUserSearch(e)} />
                                        <button type="button" onClick={handleSearch} className="button is-block is-info">Buscar</button>
                                    </div>
                                    {userSearchList.length > 0 ?
                                        <div className={`user-list ${showUserList ? 'show' : ''}`}>
                                            <div>
                                                {userSearchList.map((user, index) => (
                                                    <div className="user" key={index} onClick={() => handleAddUser(user)}>
                                                        <AccountCircle className="icon" />
                                                        <div>{user.name}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                </div>

                                <button type="button" className="button is-block is-info user-add" hidden={!showUserAdd} onClick={()=> setShowUserAdd(false)}>
                                    <Add />
                                    Agregar usuario
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8">
                        <div className="box">
                            <div className="form">
                                <label className="label">Asignación de componentes</label>
                                {errors.componentes && (<p className="help is-danger">{errors.componentes}</p>)}
                                <div>
                                    <Nodos nodos={nodos} expanded={expanded} checked={checked} handleExpanded={handleExpanded} handleChecked={handleChecked} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <Button variant="contained" startIcon={<Save />} className="button" onClick={onSubmit} >Guardar</Button>
                    <Button variant="contained" startIcon={<Cancel />} className="button" onClick={handleCancelClick}>Cancelar</Button>
                </div>

            </div>
        </div>
    )
}

const Nodos = (props) => {
    const { nodos, expanded, checked, handleExpanded, handleChecked } = props;
    const [items, setItems] = useState([]);

    useEffect(() => {
        const arr = [];
        for (let index = 0; index < nodos.length; index++) {
            var nodo = nodos[index];
            if (nodo.children) {
                for (let i = 0; i < nodo.children.length; i++) {
                    var children = nodo.children[i];
                    children["expanded"] = expanded.filter(expand => { return expand == children.value }).length > 0;
                    if (children.children) {
                        for (let num = 0; num < children.children.length; num++) {
                            const subchildren = children.children[num];
                            subchildren["checked"] = checked.filter(check => { return check == subchildren.value }).length > 0;
                        }
                        children["indeterminate"] = children.children.filter(children => { return children.checked }).length > 0 && children.children.filter(children => { return children.checked }).length < children.children.length;
                    }
                    children["checked"] = children.children != undefined && children.children.length > 0 ? children.children.filter(children => { return children.checked }).length == children.children.length : checked.filter(check => { return check == children.value }).length > 0;
                    nodo["indeterminate"] = 0 < nodo.children.filter(children => { return children.checked || children.indeterminate }).length && nodo.children.filter(children => { return children.checked || children.indeterminate }).length < nodo.children.length;
                }
            }
            nodo["expanded"] = expanded.filter(expand => { return expand == nodo.value }).length > 0;
            nodo["checked"] = nodo.children.filter(children => { return children.checked }).length == nodo.children.length;
            arr.push(nodo);
        }
        setItems(arr);
    }, [props])

    const handleExpandedClick = async(nodo) => {
        const items = [],
            value = nodo.value;
        if (expanded.indexOf(value) == -1) items.push(value);
        expanded.map(item => {
            if (item != value)
                items.push(item)
        })
        handleExpanded(items)
    }

    const handleSwitch = (value) => {
        const items = [];
        if (checked.indexOf(value) == -1) items.push(value);

        checked.map(check => {
            if (check != value)
                items.push(check)
        })
        handleChecked(items)
    }

    const handleCheckClick = (check, nodo) => {
        const arr = [];

        for (let index = 0; index < items.length; index++) {
            for (let i = 0; i < items[index].children.length; i++) {
                if (items[index].children[i].children != undefined && items[index].children[i].children.length > 0) {
                    for (let num = 0; num < items[index].children[i].children.length; num++) {
                        const isCheck = items.indexOf(nodo) == index || items[index].children.indexOf(nodo) == i ? check : items[index].children[i].children[num].checked;
                        if(isCheck) arr.push(items[index].children[i].children[num].value)
                    }
                } else {
                    const isCheck = items.indexOf(nodo) == index || items[index].children.indexOf(nodo) == i ? check : items[index].children[i].checked;
                    if(isCheck) arr.push(items[index].children[i].value)
                }
            }
        }
        handleChecked(arr)
    }

    return (
        <div className="nodos">
            {(items).map((nodo, index) => (
                <div className="nodo" key={nodo.label + index}>
                    <div className="nodo-title">
                        <div className="form-group">
                            <input type="checkbox" id={nodo.label} className={nodo.indeterminate ? 'indeterminate' : ''} checked={nodo.checked} onChange={e => handleCheckClick(e.target.checked, nodo)}/>
                            <label htmlFor={nodo.label}></label>
                        </div>

                        <div className="form-label">{nodo.label}</div>

                        <div className="icon" onClick={(e) => handleExpandedClick(nodo)} hidden={!nodo.children || nodo.children.length == 0}>
                            {nodo.expanded ? <ExpandLess /> : <ExpandMore />}
                        </div>
                    </div>

                    <Collapse in={nodo.expanded} timeout="auto">
                        <div className="nodo-content">
                            <div className="active-switch">
                                <input type="checkbox" id={nodo.label + "-TODOS"} checked={nodo.checked} onChange={e => handleCheckClick(e.target.checked, nodo)}/>
                                <label htmlFor={nodo.label + "-TODOS"} className="switch"></label>
                                <label className="label">Todos</label>
                            </div>

                            {nodo.children.map((children, i) => {
                                return (
                                    <div className="nodo" key={children.label + i}>
                                        <div className="nodo-title">
                                            <div className="form-group">
                                                <input type="checkbox" id={children.label} className={children.indeterminate ? 'indeterminate' : ''} checked={children.checked} onChange={e => handleCheckClick(e.target.checked, children)}/>
                                                <label htmlFor={children.label}></label>
                                            </div>

                                            <div className="form-label">{children.label}</div>

                                            {children.children ?
                                                <div className="icon" onClick={(e) => handleExpandedClick(children)} hidden={!children.children || children.children.length == 0}>
                                                    {children.expanded ? <ExpandLess /> : <ExpandMore />}
                                                </div>
                                                : ""
                                            }
                                        </div>

                                        {children.children ?
                                            <Collapse in={children.expanded} timeout="auto">
                                                <div className="nodo-content">
                                                    {children.children.map((c, n) => {
                                                        return (
                                                            <div className="active-switch" key={c.label + "-" + n}>
                                                                <input type="checkbox" id={c.label} checked={c.checked} onChange={() => handleSwitch(c.value)} />
                                                                <label htmlFor={c.label} className="switch"></label>
                                                                <label className="label">{c.label}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </Collapse>:
                                            ""
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </Collapse>
                </div>
            ))}
        </div>
    )
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        letterSpacing: 0,
    },
}))(Tooltip);

export default GrupoAbm