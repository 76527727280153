import { useState } from "react"

export const useArancel = () => {
    const [focusOut, setFocusOut] = useState(true)
    const [focusOutCol, setFocusOutCol] = useState(true)
    const arancelesColumnas = [
        {id: 0, value: "", inputName: "", colName: ""},
        {id: 1 , value: "Tipo de vehículo", inputName: "", colName: ""}, 
        {id: 2 , value: "Por Hora", inputName: "", colName: ""}
    ];
    const [hiddenBtnAddColArancel, setHiddenBtnAddColArancel] = useState(false) 
    const [hiddenBtnAddRowArancel, setHiddenBtnAddRowArancel] = useState(false)     

    const isFocusOut = (e, index, maxRow = 7) => {
        if(e.target.value.length === 0) {  
            setFocusOut(false)
        }else if(index !== maxRow){
            setHiddenBtnAddRowArancel(false)
            setFocusOut(true)
        }else{
            setFocusOut(true)
        }
    }

    const isFocusOutCol = (e) => {
        if(e.target.value.length === 0) {  
            setFocusOut(false)
        }else {
            setFocusOutCol(true)
            setHiddenBtnAddColArancel(false)
        }
    }

   const setInputName = (columnLength) => {
        switch (columnLength) {
            case 3:
                return {inputName: "dsEstadia0", colName: "dsColumn0"}
            case 4:
                return {inputName: "dsEstadia1", colName: "dsColumn1"}
            case 5:
                return {inputName: "dsEstadia2", colName: "dsColumn2"}
            case 6:
                return {inputName: "dsEstadia3", colName: "dsColumn3"}
            case 7:
                return {inputName: "dsEstadia4", colName: "dsColumn4"}
        
            default:
                break;
        }
   }


   const arancelesColumnasObj = (columnArancel, arancelColumns) => {
        let contadorCol = 0;
        var nuevasCol = [];
        for(let i = 0; i < arancelColumns.length; i++) {
            if(arancelColumns[i].length > 0){
                const {inputName, colName} = setInputName(columnArancel.length + i)
                contadorCol++;
                nuevasCol.push({id: 2 + contadorCol , value: arancelColumns[i], inputName, colName})
            }
        }
        return columnArancel.concat(nuevasCol)
    }

    return {
        arancelesColumnas,
        focusOut,
        hiddenBtnAddColArancel,
        hiddenBtnAddRowArancel,
        isFocusOut,
        isFocusOutCol,
        arancelesColumnasObj,
        setFocusOut,
        setFocusOutCol,
        setInputName,
        setHiddenBtnAddRowArancel,
        setHiddenBtnAddColArancel
    }
}