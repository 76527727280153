import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { GetNotaById, SaveNota } from '../../../services/nota.service';
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns';

import swal from 'sweetalert2';
import ReactQuill from 'react-quill';
import { Button, TextField, Grid, IconButton, Fab, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Videocam, Publish, Cancel, AddPhotoAlternate, Delete } from '@material-ui/icons';
import { successSwal } from '../../../assets/resources/swal-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';

import NoFoundImage from '../../../assets/img/not_found.png';
import SwitcherIdioma from '../../../components/switcherIdioma';
import 'react-quill/dist/quill.snow.css';
import '../noticias.css';

const Styles = makeStyles((theme) => ({
    del: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    img: {
        width: '140px',
    },
    button: {
        color: '#FEF',
        backgroundColor: '#109ADD',
        '&:hover': {
            backgroundColor: '#0080FF',
            color: '#FEF'
        }
    }
}));

const NotaABM = (props) => {
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            ['link', 'image', 'video'],
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ]
    }
    const classes = Styles();
    const Id = props.match.params.id;
    const history = useHistory();

    const [image, setImage] = useState({ srcImage: NoFoundImage, hiddenRemoveImage: true });

    const [imageList, setImageList] = useState({ srcImage: NoFoundImage, hiddenRemoveImage: true });

    const [errors, setErrors] = useState([]);

    const [nota, setNota] = useState({
        Id: 0,
        Activa: true,
        Fecha: new Date(),
        Titulo: '',
        Resumen: '',
        Cuerpo: '',
        LinkImagenListado: null,
        LinkImagen: null,
        Video: null,
        FechaBaja: null,
        Base64Image: null,
        Base64ImageList: null,
        IdIdioma: props.idIdioma,
        Orden: 0
    })

    const [editing, setEditing] = useState(false)

    const validationSchema = {
        Id: { required: false },
        Activa: { required: false },
        Fecha: { required: true },
        Titulo: { required: true, maxLength: 500 },
        Resumen: { required: true, maxLength: 150 },
        Cuerpo: { required: true },
        LinkImagenListado: { required: true },
        LinkImagen: { required: false },
        Video: { required: false },
        FechaBaja: { required: false },
        Base64Image: { required: false },
        Base64ImageList: { required: false },
        IdIdioma: { required: false },
        Orden: { required: false }
    }

    useEffect(() => {
        if (Id) {
            GetNotaById(Id)
                .then(response => {
                    if (response.data) {
                        setNota({...response.data, Base64Image: response.data.Base64Image !== null ? response.data.Base64Image.toString().split(",", 2)[1] : null, Base64ImageList: response.data.Base64ImageList !== null ? response.data.Base64ImageList.toString().split(",", 2)[1] : null});
                        setImage({ ...image, srcImage: response.data.Base64Image !== null ? response.data.Base64Image : NoFoundImage, hiddenRemoveImage: response.data.Base64Image !== null ? false : true });
                        setImageList({ ...imageList, srcImage: response.data.Base64ImageList !== null ? response.data.Base64ImageList : NoFoundImage, hiddenRemoveImage: response.data.Base64ImageList !== null ? false : true });
                    }
                })
                .catch(err => console.error(err))
        } else {
            setNota({ ...nota, IdIdioma:  props.idIdioma});
            setEditing(true);
        }
    }, [props.idIdioma])

    const isValid = () => {
        let valid = true;
        let errors = {};

        try {
            for (let key of Object.keys(nota)) {
                let value = nota[key]
                if (validationSchema[key].required) {

                    if (!value) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }
                    if ((key === 'Titulo' || key === 'Resumen') && !errors[key] && validationSchema[key].maxLength && value.length > validationSchema[key].maxLength) {
                        errors[key] = `el valor máximo es ${validationSchema[key].maxLength} caracteres`;
                        setErrors(errors);
                        valid = false;
                    }
                    if (!errors[key] && key === 'Cuerpo') {
                        if (nota.Cuerpo.trim() === '') {                            
                            errors[key] = 'Requerido';
                            setErrors(errors);
                            valid = false;
                        }
                    }
                    if (!errors[key] && key === 'Fecha' && value.toString() === 'Invalid Date') {
                        errors[key] = 'formato de fecha no válido';
                        setErrors(errors);
                        valid = false;
                    }
                }
            }
        } catch (err) {
            console.error('Error', err);
        }

        return valid;
    }

    const handleSubmit = () => {
        if (isValid()) {
            SaveNota(nota)
                .then((resNota) => {
                    if (resNota.data) {
                        console.log(resNota.data)
                        swal.fire({
                            text: 'Se han guardado los cambios con éxito',
                            ...successSwal
                        }).then(() => { history.push('/Notas'); })
                    } else {
                        swal.fire({
                            title: 'Error al guardar los cambios',
                            icon: 'warning',
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                });
        }
    }

    const cancelEdit = () => history.push('/Notas');

    const handleChange = e => {
        setNota({ ...nota, [e.target.name.replace(" *", "")]: e.target.value })
    }

    const handleFechaChange = value => {
        setNota({ ...nota, Fecha: value })
    }

    const handleActivaChange = event => {
        setNota({ ...nota, Activa: event.target.checked })
    }

    const handleUploadImageClick = event => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            validateImageGral(event);
            reader.onload = () => {
                let img = new Image()
                img.src = reader.result;

                //VALIDA ANCHO DE LA IMAGEN
                img.onload = function () {

                    if (img.width > 1000) {
                        swal.fire({
                            title: 'Información',
                            icon: 'warning',
                            text: 'La imagen debe ser máximo 1000 px de ancho',
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    else {
                        const base64Image = reader.result.toString().split(",", 2)[1];
                        setNota({ ...nota, LinkImagen: file.name, Base64Image: base64Image })
                        setImage({ ...image, srcImage: reader.result, hiddenRemoveImage: false });
                    }
                }
            };
        }
        event.target.value = "";
    };

    const handleUploadImageListClick = event => {
        let reader = new FileReader();

        if (event.target.files && event.target.files.length && validateImageGral(event)) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                console.log("Entro reader")
                let img = new Image()
                img.src = reader.result;

                //VALIDA ANCHO Y ALTO DE LA IMAGEN
                img.onload = () => {
                    console.log("Entro img")
                    if ((img.width >= 300 && img.width <= 350) && (img.height >= 200 && img.height <= 250)) {
                        const base64ImageList = reader.result.toString().split(",", 2)[1];
                        setNota({ ...nota, LinkImagenListado: file.name, Base64ImageList: base64ImageList })
                        setImageList({ ...imageList, srcImage: reader.result, hiddenRemoveImage: false });
                    } else {
                        swal.fire({
                            title: 'Información',
                            icon: 'warning',
                            text: 'Las dimensiones de la imagen deben ser entre 300px/350px de ancho y 200px/250px de alto',
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok',
                        });
                    }
                }
            };
        }
        event.target.value = "";
    };

    const validateImageGral = (event) => {
        var fileName = event.target.files[0].name;
        var fileSize = event.target.files[0].size;

        if (fileSize > 3000000) {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'La imagen no debe superar los 3MB',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
            event.target.value = '';
            return false;
        } else {
            var ext = fileName.split('.').pop();

            ext = ext.toLowerCase();

            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'svg':
                case 'gif':
                    return true;
                    break;
                default:
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'LA imagen no tiene la extensión adecuada',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                    event.target.value = '';
                    return false;
            }
        }
    }

    const removeImage = () => {
        setNota({ ...nota, LinkImagen: null, Base64Image: null })
        setImage({ ...image, srcImage: NoFoundImage, hiddenRemoveImage: true });
    }

    const removeImageList = () => {
        setNota({ ...nota, LinkImagenListado: null, Base64ImageList: null })
        setImageList({ ...imageList, srcImage: NoFoundImage, hiddenRemoveImage: true });
    }

    const handleEditClick = () => {
        if (!editing)
            setEditing(true);
    }

    const handleBodyChange = value => {
        if (editing)
            setNota({ ...nota, Cuerpo: value })
    }

    return (
        <div className="main-content">
            
            <div className="content">
                {Id ?
                    <h1 className="title">Nota: <b className="greentext">{nota.Titulo}</b></h1>
                    :
                    <h1 className="title">Nueva Nota</h1>
                }

                <br />
                <br/>

                <div className="row form-group">
                    <div className="col-md-6">
                        <label className="label">Idioma: {props.idIdioma == 'ES-AR' ? 'Español' : 'Inglés'}</label>
                    </div>

                    <div className={`col-md-${Id ? '6' : '3'}`}>
                        <div className="active-switch">
                            <label className="label">HABILITADA</label>
                            <input type="checkbox" id="check" checked={nota.Activa} onChange={handleActivaChange} />
                            <label htmlFor="check" className="switch"></label>
                        </div>
                    </div>

                    <div className="col-md-3" hidden={Id}>
                        <SwitcherIdioma />
                    </div>
                </div>

                <div className="row form-group" hidden={!Id}>
                        <div className="col-md-12">
                            <Button variant="contained" className={`btnAdd ${editing ? 'gray' : ''}`} color="primary" startIcon={<FontAwesomeIcon icon={faEdit} />} onClick={handleEditClick}>Editar</Button>
                        </div>
                </div>
                
                <div className="row form-group">
                    <div className="col-md-12">
                        
                        <div className="box">
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            disabled={!editing}
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            id="Fecha"
                                            label="Fecha de publicación"
                                            value={nota.Fecha}
                                            onChange={(e) => handleFechaChange(e)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            helperText={errors.Fecha}
                                            error={errors.Fecha ? true : false}
                                            size="small"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <TextField
                                        disabled={!editing}
                                        label="Título"
                                        variant="outlined"
                                        name="Titulo *"
                                        value={nota.Titulo}
                                        onChange={(e) => handleChange(e)}
                                        helperText={errors.Titulo}
                                        error={errors.Titulo ? true : false}
                                        size="small"
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <TextField
                                        disabled={!editing}
                                        label="Resumen"
                                        variant="outlined"
                                        name="Resumen"
                                        value={nota.Resumen}
                                        onChange={(e) => handleChange(e)}
                                        helperText={errors.Resumen}
                                        error={errors.Resumen ? true : false}
                                        size="small"
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <div className="editor">
                                        <ReactQuill
                                            readOnly={!editing}
                                            name='Cuerpo'
                                            value={nota.Cuerpo}
                                            theme='snow'
                                            placeholder='Cuerpo'
                                            modules={modules}
                                            onChange={handleBodyChange}
                                        />
                                    </div>
                                    {errors.Cuerpo && (<p className="help is-danger">{errors.Cuerpo}</p>)}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <div className="video-input">
                                        <Videocam />
                                        <TextField
                                            disabled={!editing}
                                            placeholder="Copiá y pegá aquí el link del video, Código embebido, URL del video"
                                            variant="outlined"
                                            fullWidth
                                            rows={1}
                                            value={nota.Video !== null ? nota.Video : ""}
                                            name="Video"
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                    {errors.Video && (<p className="help is-danger">{errors.Video}</p>)}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="label">Seleccione una Imagen listado</label>
                                    <Grid container spacing={2}>
                                        <br/>
                                        <Grid item>
                                            <img className={classes.img} alt="complex" src={imageList.srcImage} />
                                        </Grid>

                                        <Grid item xs container direction="column" spacing={2}>
                                            {imageList.hiddenRemoveImage ? <Fragment>
                                                <input type="file" accept=".jpg, .jpeg, .png, .gif, .svg" className={classes.input} id="contained-button-file2"
                                                    onChange={handleUploadImageListClick} />

                                                <label className="buttonImg" htmlFor="contained-button-file2">
                                                    <Fab component="span" color="primary" >
                                                        <AddPhotoAlternate />
                                                    </Fab>
                                                </label>
                                            </Fragment> : ""}
                                            <div className={classes.del}>
                                                {!imageList.hiddenRemoveImage ? <IconButton color="secondary" aria-label="delete" component="span" onClick={removeImageList}>
                                                    <Delete />
                                                </IconButton> : ""}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {errors.LinkImagenListado && (<p className="help is-danger">{errors.LinkImagenListado}</p>)}
                                </div>
                                <div className="col-md-6">
                                    <label className="label">Seleccione una Imagen</label>
                                    <Grid container spacing={3}>
                                        <br/>
                                        <Grid item>
                                            <img className={classes.img} alt="complex" src={image.srcImage} />
                                        </Grid>

                                        <Grid item xs container direction="column" spacing={2}>
                                            {image.hiddenRemoveImage ? <Fragment>
                                                <input type="file" accept=".jpg, .jpeg, .png, .gif, .svg" className={classes.input} id="contained-button-file1"
                                                    onChange={handleUploadImageClick} />

                                                <label className="buttonImg" htmlFor="contained-button-file1">
                                                    <Fab component="span" color="primary" >
                                                        <AddPhotoAlternate />
                                                    </Fab>
                                                </label>
                                            </Fragment> : ""}
                                            <div className={classes.del}>
                                                {!image.hiddenRemoveImage ? <IconButton color="secondary" aria-label="delete" component="span" onClick={removeImage}>
                                                    <Delete />
                                                </IconButton> : ""}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div className="col-md-6">
                                    <br/>
                                    <label className="label">Requerimientos de las imagenes:</label>
                                    <ul className="validImages">
                                        <li>Tamaño de la imagen de listado 300px/350px ancho 200px/250px alto</li>
                                        <li>Tamaño de la imagen máx. 1000px de ancho</li>
                                        <li>Extensiones validas: JPG, JPEG, PNG, GIF, SVG</li>
                                        <li>Max. 3MB</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <Button variant="contained" color="primary" className="button" onClick={handleSubmit} startIcon={<Publish />}>Publicar</Button>
                            <Button variant="contained" startIcon={<Cancel />} className="button" onClick={cancelEdit}>Cancelar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        idIdioma: state.appReducer.idIdioma
    }
}

export default connect(mapStateToProps)(NotaABM);
