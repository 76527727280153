import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { GetBusesById, SaveOrUpdate } from '../../../services/buses.service';
import { FormatUserName } from '../../../environments/utils.js';
import { getSessionUser } from '../../../services/auth.service';

import swal from 'sweetalert2';
import { successSwal } from '../../../assets/resources/swal-config';
import { Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { Save, Cancel, Done, Clear } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import './busesABM.css'

const BusesABM = (props) => {
    const Id = props.match.params.id;
    const user = getSessionUser();
    const history = useHistory();
    const [bus, setBus] = useState({
        id: 0,
        idAeropuerto: 0,
        idIdioma: "ES-AR",
        dsTransporte: "",
        dsRecorrido: "",
        dsUbicacion: "",
        dsTelefono1: "",
        dsTelefono2: "",
        dsTarifa1: "",
        dsTarifa2: "",
        dsSube: false,
        dsIngresa: false,
        dsUsuario: "",
        feCreacion: null,
        feModificacion: null,
        mcInactiva: false,
    });

    const validationSchema = {
        id: { required: false },
        idAeropuerto: { required: false },
        idIdioma: { required: false },
        dsTransporte: { required: true },
        dsRecorrido: { required: true },
        dsUbicacion: { required: true },
        dsTelefono1: { required: true },
        dsTelefono2: { required: false },
        dsTarifa1: { required: false },
        dsTarifa2: { required: false },
        dsSube: { required: false },
        dsIngresa: { required: false },
        dsUsuario: { required: false },
        feCreacion: { required: false },
        feModificacion: { required: false },
        mcInactiva: { required: false },
    }

    const [errors, setErrors] = useState([]);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (Id > 0) {
            getBusById()
        } else {
            setBus({ ...bus, idIdioma: props.idIdioma, idAeropuerto: props.idAeropuerto });
            setEditing(true);
        }
    }, []);

    const getBusById = () => {
        GetBusesById(Id)
            .then((resBus) => {
                if (resBus.data) {
                    setBus(resBus.data);
                }
            })
            .catch(err => console.error(err))
    }

    const handleCheckChange = ev => setBus({ ...bus, [ev.target.name]: ev.target.name == 'mcInactiva' ? !ev.target.checked : ev.target.checked });

    const handleChange = ev => setBus({ ...bus, [ev.target.name]: ev.target.value });

    const handleEditClick = () => {
        if (!editing)
            setEditing(true);
    }

    const handleSubmit = () => {
        if(isValid()) {
            const obj = {
                id: bus.id,
                idAeropuerto: bus.idAeropuerto,
                idIdioma: bus.idIdioma,
                dsTransporte: bus.dsTransporte,
                dsRecorrido: bus.dsRecorrido,
                dsUbicacion: bus.dsUbicacion,
                dsTelefono1: bus.dsTelefono1,
                dsTelefono2: bus.dsTelefono2,
                dsTarifa1: bus.dsTarifa1,
                dsTarifa2: bus.dsTarifa2,
                dsSube: bus.dsSube,
                dsIngresa: bus.dsIngresa,
                dsUsuario: user.DS_Nombre,
                feCreacion: bus.feCreacion ? bus.feCreacion : new Date().toISOString().replace("Z", ""),
                feModificacion: Id == 0 ? null : new Date().toISOString().replace("Z", ""),
                mcInactiva: bus.mcInactiva,
            }

            SaveOrUpdate(obj)
                .then((resBuses) => {
                    if (resBuses.data.ResultadoExitoso) {
                        swal.fire({
                            text: 'Se han guardado los cambios con éxito',
                            ...successSwal
                        }).then(() => { history.push('/Buses'); })
                    } else {
                        swal.fire({
                            title: 'Error al guardar los cambios',
                            icon: 'warning',
                            text: resBuses.data.MensajeError,
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                })
        }
    }

    const handleCancelABMClick = () => history.push('/Buses');

    const isValid = () => {
        let valid = true;
        let errors = {};
        setErrors(errors);
        
        try {
            for (let key of Object.keys(bus)) {
                let value = bus[key]
                if (validationSchema[key].required) {
                    if (value == 0) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }
                    
                }
            }
        } catch (err) {
            console.error('Error', err);
        }
        return valid;
    }

    const handleValidField = ev => {
        console.log(validationSchema[ev.target.name].required && !ev.target.value)
        let errorsItems = {};

        for (let key of Object.keys(errors)) {
            if (key != ev.target.name) {
                errorsItems[key] = errors[key];
                setErrors(errorsItems);
            } else {
                setErrors(errorsItems)
            }
        }

        if (!ev.target.value && validationSchema[ev.target.name].required) {
            errorsItems[ev.target.name] = 'Requerido';
            setErrors(errorsItems);
        }
    }

    return (
        <div className="main-content">
            <br />
            {Id > 0 ?
                <h1 className="title">{bus.dsTransporte}</h1>
                :
                <h1 className="title">Nueva línea de transporte urbano de pasajeros</h1>
            }

            <br />
            <br />
            <div className="content">

                <div className="row form-group">
                    <div className="col-md-3">
                        <label className="label">Idioma: {props.idIdioma == 'ES-AR' ? 'Español' : 'Inglés'}</label>
                    </div>

                    <div className={`col-md-${Id == 0 ? '9' : '6'}`}>
                        <div className="active-switch">
                            <label className="label">HABILITADA</label>
                            <input type="checkbox" id="check" onChange={handleCheckChange} name="mcInactiva" checked={!bus.mcInactiva} />
                            <label htmlFor="check" className="switch"></label>
                        </div>
                    </div>

                    <div className="col-md-3" hidden={Id == 0}>
                        <Button variant="contained" className={`btnAdd ${editing ? 'gray' : ''}`} color="primary" startIcon={<FontAwesomeIcon icon={faEdit} />} onClick={handleEditClick}>Editar</Button>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-12">
                        
                        <div className="box">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Nombre - línea - Número</label>
                                        {editing ?
                                            <div className="input-check">
                                                <input name="dsTransporte" type="text" placeholder="ej. Línea 160" defaultValue={bus.dsTransporte} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                <div className="valid-box">
                                                    {errors.dsTransporte ?
                                                        <Clear className="wrong" />
                                                        :
                                                        <Done className="check" />
                                                    }
                                                </div>
                                            </div>
                                        :
                                            <span className="text">{bus.dsTransporte}</span>
                                        }
                                        {errors.dsTransporte && (<p className="help is-danger">{errors.dsTransporte}</p>)}
                                        <br />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-input">
                                        <br />
                                        <FormControlLabel control={<Checkbox name="dsIngresa" color="primary" checked={bus.dsIngresa == true} />} label="No ingresa al aeropuerto" disabled={!editing} onChange={handleCheckChange} />
                                        <br />
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Recorrido</label>
                                        {editing ?
                                            <div className="input-check">
                                                <textarea name="dsRecorrido" placeholder="Indicar los principales puntos del recorrido de la línea" defaultValue={bus.dsRecorrido} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                <div className="valid-box big">
                                                    {errors.dsRecorrido ?
                                                        <Clear className="wrong" />
                                                        :
                                                        <Done className="check" />
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <span className="text">{bus.dsRecorrido}</span>
                                        }
                                        {errors.dsRecorrido && (<p className="help is-danger">{errors.dsRecorrido}</p>)}
                                        <br />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Precio tarifa (opcional)</label>
                                        <div className="tarifas">
                                            <span>Entre</span>
                                            {editing ?
                                                <input name="dsTarifa1" type="text" placeholder="$" defaultValue={bus.dsTarifa1} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                :
                                                <span className="text">$ {bus.dsTarifa1}</span>
                                            }
                                            <span>y</span>
                                            {editing ?
                                                <input name="dsTarifa2" type="text" placeholder="$" defaultValue={bus.dsTarifa2} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                :
                                                <span className="text">$ {bus.dsTarifa2}</span>
                                            }
                                        </div>
                                        <br />
                                        <FormControlLabel className="SUBE" control={<Checkbox name="dsSube" color="primary" checked={bus.dsSube == true} />} label="Requiere SUBE" disabled={!editing} onChange={handleCheckChange} />
                                        <br />
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Dónde ubicarlos en el aeropuerto</label>
                                        {editing ?
                                            <div className="input-check">
                                                <textarea name="dsUbicacion" placeholder="ej: Dentro del estacionamiento" defaultValue={bus.dsUbicacion} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                <div className="valid-box big">
                                                    {errors.dsUbicacion ?
                                                        <Clear className="wrong" />
                                                        :
                                                        <Done className="check" />
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <span className="text">{bus.dsUbicacion}</span>
                                        }
                                        {errors.dsUbicacion && (<p className="help is-danger">{errors.dsUbicacion}</p>)}
                                        <br />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Teléfono</label>
                                        {editing ?
                                            <div className="telefono">
                                                <input name="dsTelefono1" placeholder="1122334455" type="text" defaultValue={bus.dsTelefono1} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                <input name="dsTelefono2"  placeholder="1122334455" type="text" defaultValue={bus.dsTelefono2} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                <div className="input-check inline" hidden={!editing}>
                                                    <br />
                                                    <div className="valid-box">
                                                        {errors.dsTelefono1 || errors.dsTelefono2 ?
                                                            <Clear className="wrong" />
                                                            :
                                                            <Done className="check" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="telefono">
                                                <span className="text">{bus.dsTelefono1}</span>
                                                <span className="text">{bus.dsTelefono2}</span>
                                            </div>
                                        }
                                        {errors.dsTelefono1 && (<p className="help is-danger">{errors.dsTelefono1 }</p>)}
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group" hidden={Id == 0}>
                                <div className="col-md-6 offset-md-6">
                                    <div className="form-input">
                                        <label className="label">Última modificación</label>
                                        <p>{new Date(bus.feModificacion ? bus.feModificacion : bus.feCreacion).toLocaleDateString()} {FormatUserName(bus.dsUsuario)}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row form-group"> 
                    <div className="form-row">
                        <Button variant="contained" startIcon={<Save />} className="button" onClick={handleSubmit}>Guardar</Button>
                        <Button variant="contained" startIcon={<Cancel />} className="button" onClick={handleCancelABMClick}>Cancelar</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        idIdioma: state.appReducer.idIdioma,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto
    }
}

export default connect(mapStateToProps)(BusesABM);