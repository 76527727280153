import Axios from "axios"
import './axiosConfig';

const GETARBOLCOMPONENTES_URL = 'Componente/ObtenerArbolComponentes';
const GETARBOLCONPERMISOS_URL = 'Componente/ObtenerArbolPorGrupo';

export const GetArbolComponentes=()=> {
    return Axios.get(GETARBOLCOMPONENTES_URL, {});
}

export const GetArbolConPermisos=(grupo)=>{
    return Axios.post(GETARBOLCONPERMISOS_URL, grupo);
}