import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ESP } from '../../environments/idioma';
import { GetEstacionamientos, DeleteEst, GetTerminales } from '../../services/estacionamientos.service';

import swal from 'sweetalert2';
import { successSwal, confirmSwal } from '../../assets/resources/swal-config';
import { Button, Tooltip, withStyles } from '@material-ui/core';
import { Add, ChevronRight, Delete } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import Pagination from '../../components/Pagination';
import SelectAeropuerto from '../../components/SelectAeropuerto';
import SwitcherIdioma from '../../components/switcherIdioma';
import './estacionamientos.css';

const Estacionamientos = (props) => {
    const [state, setState] = useState({
        estacionamientos: [],
        idIdioma: ESP,
    })

    const history = useHistory();
    const [terminales, setTerminales] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(16);

    const filteredRow = state.estacionamientos.filter(row => { return row.show })
    
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.estacionamientos.length - page * rowsPerPage);

    useEffect(() => {
        mapTerminales(props.idAeropuerto);
        loadTerminales(props.idAeropuerto);
        loadEstacionamientos(props.idAeropuerto);
    }, [props.idAeropuerto]);

    const mapTerminales = async(airpt) => {
        await GetTerminales(airpt).then(async (resTerminales) => {
            if (resTerminales.data) {
                let term = resTerminales.data.map(terminal => {
                    return {
                        label: terminal.dsTerminal,
                        value: terminal.id
                    }
                });
                setTerminales(term);
            }
        })
    }

    const loadTerminales = async(airpt) => {
        await GetTerminales(airpt).then(async (resTerminales) => {
            if (resTerminales.data) {
                let term = resTerminales.data.map(terminal => {
                    return {
                        label: terminal.dsTerminal,
                        value: terminal.id
                    }
                });
                setTerminales(term);
            }
        })
    }

    const loadEstacionamientos = (aeropuerto) => {
        setPage(0);
        document.getElementById("searchInput").value = "";
        const estacionamientos = []
        GetEstacionamientos(aeropuerto, props.idIdioma)
            .then((resEstacionamientos) => {
                if(resEstacionamientos.data){
                    for (var i = 0; i < resEstacionamientos.data.length; i++){
                        const estacionamiento = resEstacionamientos.data[i];
                        estacionamiento['show'] = true;
                        estacionamientos.push(estacionamiento);
                    }
                    setState({ ...state, estacionamientos: estacionamientos });
                }
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleAddEstacionamiento = () => { history.push(`./Estacionamiento`); }

    const handleEditClick = id => { history.push(`/Estacionamiento/${id}`); }
    
    const handleDeleteClick = estacionamiento => {
        swal.fire({
            title: 'Eliminar Estacionamiento',
            text: '¿Está seguro que desea eliminar el Estacionamiento ' + estacionamiento.dsEstacionamiento + '?',
            ...confirmSwal
        })
            .then((response) => {
                if (response.value) {
                    DeleteEst({ id: estacionamiento.id })
                    .then(async() => {
                        await loadEstacionamientos(props.idAeropuerto);
                        swal.fire({
                            text: 'Se ha eliminado el Estacionamiento con éxito',
                            ...successSwal
                        })
                    });
                }
            });
    }

    const handleSearch = (e) => {
        const search = e ? e.target.value.toLowerCase().trim() : document.getElementById("searchInput").value.toLowerCase().trim();
        const keys = ["dsEstacionamiento", "idTerminal"];
        const newRows = [];

        if(search.length >= 2) {
            for(var i = 0; i < state.estacionamientos.length; i++) {
                let bool;
                let row = state.estacionamientos[i];
                let terminal = terminales.filter(terminal => { return terminal.value == row.idTerminal}).length > 0 ? terminales.filter(terminal => { return terminal.value == row.idTerminal})[0].label : "";
                for(var index = 0; index < keys.length; index++) {
                    let value = keys[index] == "idTerminal" ? terminal : row[keys[index]];
                    if(value) {
                        value = value.toLowerCase();
                        bool = index == 0 ? value.indexOf(search) != -1 : bool || value.indexOf(search) != -1;
                    }
                }
                row.show = bool;
                newRows.push(row);
            }
        } else {
            for(var i = 0; i < state.estacionamientos.length; i++) {
                state.estacionamientos[i].show = true;
                newRows.push(state.estacionamientos[i]);
            }
        }
        setPage(0)
        setState({ ...state, estacionamientos: newRows})
    }

    const handleTerminal = id => {
        const value = terminales.filter(terminal => terminal.value == id);
        return value.length > 0 ? value[0].label : "";
    }

    return (
        <div className="main-content">
            <br/>
            <h1 className="title">Estacionamiento</h1>
            <br/>
            <br/>
            <br />
            <div className="content">
                <div className="row form-group">
                    <div className="col-sm-12 col-md-5 col-lg-6">
                        <SelectAeropuerto />
                        <br/>
                    </div>
                    <div className="col-sm-6 col-md-2 col-lg-2 offset-md-2 offset-lg-1">
                        <SwitcherIdioma />
                        <br/>
                    </div>
                    <div className="col-sm-6 col-md-3 col-lg-3">
                        <Button variant="contained" color="primary" className="btnAdd" startIcon={<Add />} onClick={handleAddEstacionamiento}>Nuevo</Button>
                        <br/>
                    </div>
                    <br/>
                </div>

                <div className="row form-group">
                    <div className="col-md-5">
                        <div className="search-box">
                            <br />
                            <br />
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="type" name="search" id="searchInput" placeholder="Buscar por Nombre o Terminal" onKeyUp={handleSearch} />
                        </div>
                        <br/>
                    </div>
                </div>

                <div className="row form-group">
                    {(rowsPerPage > 0 ? filteredRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRow).map((row, index) => (
                        <div className="col-md-3" key={row.id}>
                            <div className="estacionamiento-box">
                                <p className="estacionamiento-name">{row.dsEstacionamiento}</p>
                                <div className="estacionamiento-logo">
                                    <Cocheras />
                                    <span className="count">{row.coCubierta + row.coDescubierta + row.coDiscapacitados}</span>
                                </div>
                                <div className="middle" hidden={row.idTerminal == 0}>
                                    <span>{handleTerminal(row.idTerminal)}</span>
                                </div>
                                <div className="bottom">
                                    <LightTooltip title="Eliminar estacionamiento" placement="top">
                                        <Delete className="del" onClick={() => handleDeleteClick(row)}/>
                                    </LightTooltip>
                                    <div className="details" onClick={() => handleEditClick(row.id)}>
                                        <span>VER</span>
                                        <ChevronRight/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {filteredRow.length > rowsPerPage ? <Pagination count={filteredRow.length} page={page} rowsPerPage={rowsPerPage} onChangePage={handleChangePage} /> : ""}
        </div>
    )
}

const Cocheras = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="surface1" width="47" height="50" viewBox="0 0 38.25 38.25">
            <path id="Trazado_183" data-name="Trazado 183" d="M9.525,31.5H6.537A6.544,6.544,0,0,0,0,38.037V62.69a2.8,2.8,0,0,0,2.8,2.8H13.26a2.8,2.8,0,0,0,2.8-2.8V38.037A6.544,6.544,0,0,0,9.525,31.5Zm-3.6,1.156a5.573,5.573,0,0,1-4.768,4.768A5.425,5.425,0,0,1,5.924,32.656ZM1.121,47.3a6.869,6.869,0,0,1,.622-2.865l1.473,2.1a1.668,1.668,0,0,0-.228.843v8.218a1.672,1.672,0,0,0,.16.715l-1.71,2.434a1.671,1.671,0,0,1-.318-.982Zm3.549,9.973h6.724a1.668,1.668,0,0,0,.716-.161L13.7,59.381a1.674,1.674,0,0,1-.442.06H2.8a1.674,1.674,0,0,1-.442-.06l1.594-2.268A1.668,1.668,0,0,0,4.669,57.274Zm-.56-1.681V47.375a.561.561,0,0,1,.56-.56h6.724a.561.561,0,0,1,.56.56v8.218a.561.561,0,0,1-.56.56H4.669A.561.561,0,0,1,4.109,55.593Zm7.879-9.79a1.675,1.675,0,0,0-.6-.109H4.669a1.674,1.674,0,0,0-.6.109L2.357,43.361a6.9,6.9,0,0,1,11.347,0ZM14.941,62.69a1.683,1.683,0,0,1-1.681,1.681H2.8A1.683,1.683,0,0,1,1.121,62.69V60a2.787,2.787,0,0,0,1.681.562H13.26A2.787,2.787,0,0,0,14.941,60Zm0-4.931a1.67,1.67,0,0,1-.318.982l-1.71-2.433a1.671,1.671,0,0,0,.16-.715V47.375a1.668,1.668,0,0,0-.228-.843l1.473-2.1a6.868,6.868,0,0,1,.622,2.865Zm0-14.546a8.026,8.026,0,0,0-13.821,0V38.557a6.692,6.692,0,0,0,5.936-5.936H9.229a6.695,6.695,0,0,0,5.712,5.908ZM10.367,32.687a5.427,5.427,0,0,1,4.534,4.7A5.626,5.626,0,0,1,10.367,32.687Zm0,0" transform="translate(0 -29.147)"></path>
            <path id="Trazado_184" data-name="Trazado 184" d="M306.525,31.5h-2.988A6.544,6.544,0,0,0,297,38.037V62.69a2.8,2.8,0,0,0,2.8,2.8H310.26a2.8,2.8,0,0,0,2.8-2.8V38.037A6.544,6.544,0,0,0,306.525,31.5Zm5.376,5.887a5.626,5.626,0,0,1-4.534-4.7A5.427,5.427,0,0,1,311.9,37.387Zm-8.977-4.73a5.573,5.573,0,0,1-4.768,4.768A5.425,5.425,0,0,1,302.924,32.656Zm-4.8,14.644a6.868,6.868,0,0,1,.622-2.865l1.473,2.1a1.667,1.667,0,0,0-.228.843v8.218a1.671,1.671,0,0,0,.16.715l-1.71,2.434a1.671,1.671,0,0,1-.318-.982Zm3.549,9.973h6.724a1.668,1.668,0,0,0,.716-.161l1.594,2.268a1.674,1.674,0,0,1-.442.06H299.8a1.675,1.675,0,0,1-.442-.06l1.594-2.268A1.668,1.668,0,0,0,301.669,57.274Zm-.56-1.681V47.375a.561.561,0,0,1,.56-.56h6.724a.561.561,0,0,1,.56.56v8.218a.561.561,0,0,1-.56.56h-6.724A.561.561,0,0,1,301.109,55.593Zm7.879-9.79a1.675,1.675,0,0,0-.6-.109h-6.724a1.675,1.675,0,0,0-.6.109l-1.716-2.443a6.9,6.9,0,0,1,11.347,0Zm2.953,16.887a1.683,1.683,0,0,1-1.681,1.681H299.8a1.683,1.683,0,0,1-1.681-1.681V60a2.787,2.787,0,0,0,1.681.562H310.26A2.787,2.787,0,0,0,311.941,60Zm0-4.931a1.67,1.67,0,0,1-.318.982l-1.71-2.433a1.671,1.671,0,0,0,.16-.715V47.375a1.667,1.667,0,0,0-.228-.843l1.473-2.1a6.868,6.868,0,0,1,.622,2.865Zm0-14.546a8.026,8.026,0,0,0-13.821,0V38.557a6.692,6.692,0,0,0,5.936-5.936h2.172a6.695,6.695,0,0,0,5.712,5.908Zm0,0" transform="translate(-274.812 -29.147)"></path>
            <path id="Trazado_185" data-name="Trazado 185" d="M249,497h1.121v1.121H249Zm0,0" transform="translate(-230.398 -459.871)"></path>
            <path id="Trazado_186" data-name="Trazado 186" d="M249,30h1.121V63.768H249Zm0,0" transform="translate(-230.398 -27.759)"></path>
            <path id="Trazado_187" data-name="Trazado 187" d="M249,0h1.121V1.121H249Zm0,0" transform="translate(-230.398)"></path>
            <path id="Trazado_188" data-name="Trazado 188" d="M29.5,441.5h1.121v1.121H29.5Zm0,0" transform="translate(-27.296 -408.517)"></path>
            <path id="Trazado_189" data-name="Trazado 189" d="M170.5,441.5h1.121v1.121H170.5Zm0,0" transform="translate(-157.762 -408.517)"></path>
            <path id="Trazado_190" data-name="Trazado 190" d="M328.5,441.5h1.121v1.121H328.5Zm0,0" transform="translate(-303.959 -408.517)"></path>
            <path id="Trazado_191" data-name="Trazado 191" d="M469.5,441.5h1.121v1.121H469.5Zm0,0" transform="translate(-434.425 -408.517)"></path>
        </svg>
    )
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        letterSpacing: 0,
    },
}))(Tooltip);

const mapStateToProps = state => {
    return {
        idIdioma: state.appReducer.idIdioma,
        aeropuertos: state.aeropuertoReducer.aeropuertos,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto
    }
}

export default connect(mapStateToProps)(Estacionamientos);