import axios from 'axios'
import './axiosConfig'

const GET_TRAFICOS_URL = 'Trafico/GetTraficos'
const GET_TRAFICOS_BY_IDIOMA_URL = 'Trafico/GetTraficosByIdioma'
const GET_TRAFICO_BY_ID_URL = 'Trafico/GetTraficoById'
const SAVE_TRAFICO_URL = 'Trafico/SaveTrafico'
const SAVE_TRAFICO_FILE_URL = 'Trafico/SaveFileTrafico'
const DELETE_TRAFICO_URL = 'Trafico/DeleteTrafico'
const DELETE_TRAFICO_FILE_URL = 'Trafico/DeleteFileTrafico';
const GET_PERIODOS_URL = 'Trafico/GetPeriodos'
const GET_PERIODOS_BY_IDIOMA_URL = 'Trafico/GetPeriodosByIdioma'

export const GetTraficos = () => {
    return axios.get(GET_TRAFICOS_URL)
}

export const GetTraficosByIdioma = (IdIdioma) => {
    return axios.get(GET_TRAFICOS_BY_IDIOMA_URL, { params: { IdIdioma } })
}

export const GetTraficoById = id => {
    return axios.get(GET_TRAFICO_BY_ID_URL, { params: { id } })
}

export const GetPeriodos = () => {
    return axios.get(GET_PERIODOS_URL)
}

export const GetPeriodosByIdioma = (idioma) => {
    return axios.get(GET_PERIODOS_BY_IDIOMA_URL, { params: { idioma } })
}

export const SaveTrafico = (trafico)  => {
    return axios.post(SAVE_TRAFICO_URL, trafico);
}

export const SaveTraficoFile = ( FileArchivo)  => {

    const formData = new FormData();    
    formData.append("FileArchivo", FileArchivo)     
    const config = {    
        headers: {'content-type': 'multipart/form-data',}}; 

    return axios.post(SAVE_TRAFICO_FILE_URL, formData, config);
}

export const DeleteTraficoFile = name  => {
    return axios.get(DELETE_TRAFICO_FILE_URL, { params: { name } })
}

export const DeleteTrafico = trafico => {
    return axios.post(DELETE_TRAFICO_URL, trafico);
}