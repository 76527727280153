import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { GetNovedadesByIdioma, DeleteNovedad, SaveNovedad } from '../../../services/novedad.service';

import swal from 'sweetalert2';
import { successSwal, confirmSwal } from '../../../assets/resources/swal-config'
import { Button, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox, Tooltip, makeStyles } from '@material-ui/core';
import { DragIndicator, Visibility, VisibilityOff, Delete, ChevronRight, Close } from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons';

import SwitcherIdioma from '../../../components/switcherIdioma';
import Pagination from '../../../components/Pagination';
import '../noticias.css';

const HideTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        boxShadow: '0px 3px 6px #00000029',
        border: '1px solid #9CF2BF',
        padding: 10,
    }
}))

const HideTooltip = (props) => {
    const classes = HideTooltipStyles();
    return <Tooltip classes={classes}  {...props} />;
}

const DeleteTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        boxShadow: '0px 3px 8px #00000029',
        border: '0.5px solid #E9E9E9',
        padding: 10,
    }
}))

const DeleteTooltip = (props) => {
    const classes = DeleteTooltipStyles();
    return <Tooltip classes={classes} {...props} />;
}

const Novedades = (props) => {
    const [state, setState] = useState({
        novedades: []
    });
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(9);
    const [openFilter, setOpenFilter] = useState(false);
    const [fechaValue, setFechaValue] = useState("Todos");
    const [activaValue, setActivaValue] = useState(null);
    const [fechas, setFechas] = useState(["Ayer", "Última semana", "Último mes", "Últimos 6 meses", "Último año", "Todos"]);
    const [activas, setActivas] = useState([{ label: "Visibles", active: true }, { label: "No Visibles", active: true }]);

    //DRAG AND DROP
    // const [dragdrop, setDragdrop] = useState({
    //     over: {},
    //     startLoc: 0,
    //     dragging: false,
    //     dragFrom: {},
    // })

    const filteredRow = state.novedades.filter(row => { return row.show })
    
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.novedades.length - page * rowsPerPage);

    useEffect(() => {
        loadNovedades(props.idIdioma)
    }, [props.idIdioma]);

    const loadNovedades = (selectIdioma) => {
        setPage(0);
        document.getElementById("searchInput").value = "";
        GetNovedadesByIdioma(selectIdioma)
            .then(response => {
                if(response.data){
                    const novedades = [];

                    for (var i = 0; i < response.data.length; i++){
                        const novedad = response.data[i];
                        novedad["show"] = true;
                        novedades.push(novedad);
                    }
                    setState({ ...state, novedades: novedades })
                }
            })
            .catch(err => console.error(err))
    }

    const handleAddClick = () => history.push("/Novedad");

    const handleEditClick = id => history.push(`/Novedad/${id}`);

    const handleRemoveClick = novedad => {
        const payload = { Id: novedad.Id, Titulo: novedad.Titulo }
        swal.fire({
            title: 'Eliminar Noticia',
            text: `¿Desea eliminar la noticia "${novedad.Titulo}"?`,
            ...confirmSwal
        })
            .then(async confirm => {
                if (confirm.value) {
                    document.getElementById("searchInput").value = "";
                    await DeleteNovedad(payload);
                    loadNovedades(props.idIdioma);
                }
            });
    }

    const handleDateFormat = (date) => {
        const format = date.split("-");
        return format.length > 0 ? format[2].split("T")[0] + "/" + format[1] + "/" + format[0] : "";
    }

    const handleSearch = (e) => {
        const search = e.target.value ? e.target.value.toLowerCase().trim() : document.getElementById("searchInput").value.toLowerCase().trim(),
            keys = ["Fecha", "Titulo"],
            newRows = [];

        if(search.length >= 2) {
            for(var i = 0; i < state.novedades.length; i++) {
                let bool,
                    row = state.novedades[i],
                    fecha = handleDateFormat(row.Fecha);
                for(var index = 0; index < keys.length; index++) {
                    let value = row[keys[index]];
                    if(value) {
                        value = value.toLowerCase();
                        bool = index == 0 ? fecha.indexOf(search) != -1 : bool || value.indexOf(search) != -1;
                    }
                }
                row.show = bool;
                newRows.push(row);
            }
        } else {
            for(var i = 0; i < state.novedades.length; i++) {
                state.novedades[i].show = true;
                newRows.push(state.novedades[i]);
            }
        }
        setPage(0)
        setState({ ...state, novedades: newRows })
        handleFilterClick();
    }

    const handleActivarNovedad = row => {
        const item = {
            Activa: !row.Activa,
            Cuerpo: row.Cuerpo,
            Fecha: row.Fecha,
            FechaBaja: row.FechaBaja,
            Id: row.Id,
            IdIdioma: props.idIdioma,
            Orden: row.Orden,
            Titulo: row.Titulo,
            // Periodo: row.Periodo
        }

        SaveNovedad(item)
            .then((resSaveNovedad) => {
                loadNovedades(props.idIdioma)
        })
    }

    const handleChangeFechas = event => setFechaValue(event.target.value);

    const handleChangeActiva = event => {
        const items = [];
        activas.map(activa => {
            items.push({ label: activa.label, active: event.target.value == activa.label ? event.target.checked : activa.active })
            setActivas(items);
        })
        var result = items.filter(item => { return item.active }),
            value = result.length == 2  || result.length == 0 ? null : result.length == 1 && result[0].label == 'Visibles' ? true : false;
        setActivaValue(value)
    }

    const handleFilterClick = () => {
        var actives = activas.filter(act => { return act.active }),
            aValue = actives.length == 1 && actives[0].label == 'Visibles' ? true : actives.length == 1 && actives[0].label == 'No Visibles' ? false : null,
            newRows = [];
        for (var i = 0; i < state.novedades.length; i++) {
            var novedad = state.novedades[i],
                date = new Date();
            if(novedad.show){
                if (fechaValue == 'Ayer' && aValue != null) {
                    date.setDate(date.getDate() - 1)
                    novedad.show = Date.parse(date.toISOString().split("T")[0]) === Date.parse(novedad.Fecha.split("T")[0]) && novedad.Activa == aValue;
                } else if (fechaValue == 'Ayer' && aValue == null) {
                    date.setDate(date.getDate() - 1)
                    novedad.show = Date.parse(date.toISOString().split("T")[0]) === Date.parse(novedad.Fecha.split("T")[0]);
                } else if (fechaValue == 'Última semana' && aValue != null) {
                    date.setDate(date.getDate() - 7);
                    novedad.show = Date.parse(date.toISOString().split("T")[0]) <= Date.parse(novedad.Fecha.split("T")[0]) && novedad.Activa == aValue;
                } else if (fechaValue == 'Última semana' && aValue == null) {
                    date.setDate(date.getDate() - 7);
                    novedad.show = Date.parse(date.toISOString().split("T")[0]) <= Date.parse(novedad.Fecha.split("T")[0]);
                } else if (fechaValue == 'Último mes' && aValue != null) {
                    date.setMonth(date.getMonth() - 1);
                    novedad.show = Date.parse(date.toISOString().split("T")[0]) <= Date.parse(novedad.Fecha.split("T")[0]) && novedad.Activa == aValue;
                } else if (fechaValue == 'Último mes' && aValue == null) {
                    date.setMonth(date.getMonth() - 1);
                    novedad.show = Date.parse(date.toISOString().split("T")[0]) <= Date.parse(novedad.Fecha.split("T")[0]);
                } else if (fechaValue == 'Últimos 6 meses' && aValue != null) {
                    date.setMonth(date.getMonth() - 6);
                    novedad.show = Date.parse(date.toISOString().split("T")[0]) <= Date.parse(novedad.Fecha.split("T")[0]) && novedad.Activa == aValue;
                } else if (fechaValue == 'Últimos 6 meses' && aValue == null) {
                    date.setMonth(date.getMonth() - 6);
                    novedad.show = Date.parse(date.toISOString().split("T")[0]) <= Date.parse(novedad.Fecha.split("T")[0]);
                } else if (fechaValue == 'Último año' && aValue != null) {
                    date.setMonth(date.getMonth() - 12);
                    novedad.show = Date.parse(date.toISOString().split("T")[0]) <= Date.parse(novedad.Fecha.split("T")[0]) && novedad.Activa == aValue;
                } else if (fechaValue == 'Último año' && aValue == null) {
                    date.setMonth(date.getMonth() - 12);
                    novedad.show = Date.parse(date.toISOString().split("T")[0]) <= Date.parse(novedad.Fecha.split("T")[0]);
                } else if (fechaValue == 'Todos' && aValue != null) {
                    novedad.show = novedad.Activa == aValue
                } else if (fechaValue == 'Todos' && aValue == null) {
                    novedad.show = true;
                }
            }
            newRows.push(novedad);
        }
        setState({ ...state, notas: newRows });
        setOpenFilter(false);
    }

        // const dragStart = (item, i, e) => setDragdrop({ ...dragdrop, startLoc: e.clientY, dragging: true, dragFrom: item });
    // const dragOver = (item, pos, e) => {
    //     const dir = (dragdrop.startLoc < e.clientY) ? 'down' : 'up';
    //     setDragdrop({ ...dragdrop, over: { item, pos, dir } });
    // }
    // const dragEnd = async(item, pos, e) => {
    //     const items = state.novedades
    //     items.splice(pos, 1);
    //     items.splice(dragdrop.over.pos, 0, item);
    //     for (var i = 0; i < items.length; i++){
    //         const item = {
    //             Id: items[i].Id,
    //             Activa: items[i].Activa,
    //             Fecha: items[i].Fecha,
    //             // Periodo: items[i].Periodo,
    //             Titulo: items[i].Titulo,
    //             Cuerpo: items[i].Cuerpo,
    //             FechaBaja: items[i].FechaBaja,
    //             IdIdioma: props.idIdioma,
    //             Orden: i
    //         }
    //         await SaveNovedad(item)
    //     }
    //     setDragdrop({ ...dragdrop, over: {} });
    //     loadNovedades(props.idIdioma);
    // }

    const handleChangePage = (event, newPage) => setPage(newPage);

    return (
        <div className="main-content">
            
            <div className="content">
                <br />
                <h1 className="title">Noticias</h1>
                <div className="row form-group">
                    <div className="col-sm-6 col-md-2 col-lg-2 offset-md-7 offset-lg-8">
                        <SwitcherIdioma />
                        <br/>
                    </div>
                    <div className="col-sm-6 col-md-3 col-lg-2">
                        <Button variant="contained" color="primary" className="btnAdd" onClick={handleAddClick}>Nuevo</Button>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-5">
                        <div className="search-box">
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="type" name="search" id="searchInput" placeholder="Buscar noticia" onKeyUp={handleSearch} />
                        </div>
                        <br/>
                    </div>

                    <div className="col-md-7">
                        <div className="filter" onClick={() => setOpenFilter(true)}>
                            <FontAwesomeIcon icon={faSlidersH} />
                            <span>Filtrar y ordenar</span>
                        </div>
                    </div>
                </div>

                <div className="row form-group">
                    {(rowsPerPage > 0 ? filteredRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRow).map((row, index) => (
                        <div className="col-sm-6 col-md-4" key={'Novedad-' + row.Id} draggable={false}>
                            <div className={`noticia`} >
                                <div className="top">
                                    {/* <DragIndicator /> */}
                                    <div className="position">{index + 1}</div>
                                </div>
                                
                                <div className="center">
                                    <span>{handleDateFormat(row.Fecha)}</span>
                                    {row.Activa ?
                                        <HideTooltip
                                            placement="top"
                                            title={<span><strong>Ocultar: </strong>Este elemento dejará de estar visible para el lector pero no se eliminará.</span>}>
                                            <Visibility className="visible" onClick={() => { handleActivarNovedad(row) }} />
                                        </HideTooltip>
                                        :
                                        <HideTooltip
                                            placement="top"
                                            title={<span><strong>Mostrar: </strong>Este elemento estará visible para el lector.</span>}>
                                            <VisibilityOff className="hide" onClick={() => { handleActivarNovedad(row) }} />
                                        </HideTooltip>
                                    }
                                </div>
                                <p>{row.Titulo}</p>
                                <div className="bottom">
                                    <DeleteTooltip placement="top" title="Eliminar noticia">
                                        <Delete onClick={() => handleRemoveClick(row)}/>
                                    </DeleteTooltip>
                                    <div className="more" onClick={() => handleEditClick(row.Id)}>
                                        <span>VER</span>
                                        <ChevronRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {filteredRow.length > rowsPerPage ? <Pagination count={filteredRow.length} page={page} rowsPerPage={rowsPerPage} onChangePage={handleChangePage} /> : ""}
            <div className={`popUp-wrapper ${openFilter ? 'show' : ''}`}>
                <div className="popUp box">
                    <div className="popUp-body">
                        <div className="head">
                            <h2 className="title">Filtrar y ordenar</h2>
                            <Close onClick={() => setOpenFilter(false)}/>
                        </div>

                        <div className="content">
                            <div className="filter-box">
                                <h6 className="title">Ordenar por</h6>
                                <div className="filters">
                                    <div className="text">Fechas</div>
                                    <RadioGroup aria-label="fechas" name="fechas" value={fechaValue} onChange={handleChangeFechas}>
                                        {fechas.map((fecha) => (
                                            <div className="option" key={fecha}>
                                                <FormControlLabel value={fecha} control={<Radio checked={fecha == fechaValue} />} label={fecha} />
                                            </div>
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>

                            <div className="filter-box">
                                <div className="filters">
                                    <div className="text">Activa</div>
                                    <FormGroup>
                                        {activas.map((activa, index ) => (
                                            <div className="option" key={index}>
                                                <FormControlLabel control={<Checkbox value={activa.label} checked={activa.active} onChange={handleChangeActiva} name={activa.label} />} label={activa.label} />
                                            </div>
                                        ))}
                                    </FormGroup>
                                </div>
                            </div>
                        </div>

                        <div className="bottom">
                            <Button variant="contained" color="primary" className="btnAdd" onClick={handleSearch}>Aplicar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        idIdioma: state.appReducer.idIdioma,
        userArpt: state.permisosReducer.aeropuertos
    }
}

export default connect(mapStateToProps)(Novedades);