import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { SaveOrganismo, GetOrganismo } from '../../../services/organismosOficiales.service';

import Select from 'react-select';
import swal from 'sweetalert2';
import { Button, Fab, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Cancel, Save, Delete, AddPhotoAlternate } from '@material-ui/icons';
import { successSwal } from '../../../assets/resources/swal-config';
import '../../../assets/css/table-miu-center.css';
import NoFoundImage from '../../../assets/img/not_found.png';

const Styles = makeStyles((theme) => ({
    del: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    img: {
        width: '140px',
    },
    button: {
        margin: '1rem auto',
        "&:nth-child(2)": {
            color: '#FEF',
            backgroundColor: '#109ADD',
            '&:hover': {
                backgroundColor: '#0080FF',
                color: '#FEF'
            }
        },
    },
    h3: {
        textAlign: 'center'
    }
}));

const OrganismosOficialesAbm = (props) => {
    const classes = Styles();
    const Id = props.match.params.id;
    const history = useHistory();

    const [errors, setErrors] = useState([]);

    const [image, setImage] = useState({ srcImage: NoFoundImage, hiddenRemoveImage: true });

    const [organismoOficial, setOrganismoOficial] = useState({
        IdOrgOficial: 0,
        IdIdioma: props.idIdioma,
        IdAeropuerto: props.idAeropuerto,
        DsOrgOficial: '',
        RtInformation: '',
        DsWeb: null,
        DsMail: null,
        DsTelefono: null,
        DsDireccion1: null,
        DsTelefono1: null,
        DsHorario1: null,
        DsDireccion2: null,
        DsTelefono2: null,
        DsHorario2: null,
        LkImagen: '',
        base64Image: null
    })

    useEffect(() => {
        cargaInicial();
    }, [])

    const cargaInicial = async () => {
        if (Id) {
            GetOrganismo(Id)
                .then(response => {
                    if (response.data) {
                        setOrganismoOficial({ ...response.data, base64Image: response.data.base64Image !== null ? response.data.base64Image.toString().split(",", 2)[1] : null });

                        if (response.data.base64Image !== null) {
                            setImage({ ...image, srcImage: response.data.base64Image, hiddenRemoveImage: false })
                        }
                    }
                })
                .catch(err => console.error(err))
        }
    }

    const validationSchema = {
        IdOrgOficial: { required: false },
        IdIdioma: { required: false },
        IdAeropuerto: { required: true },
        DsOrgOficial: { required: true },
        RtInformation: { required: false },
        DsWeb: { required: false },
        DsMail: { required: false },
        DsTelefono: { required: false },
        DsDireccion1: { required: false },
        DsTelefono1: { required: true },
        DsHorario1: { required: false },
        DsDireccion2: { required: false },
        DsTelefono2: { required: false },
        DsHorario2: { required: false },
        base64Image: { required: false },
        LkImagen: { required: false },
    }

    const isValid = () => {
        let valid = true;
        let errors = {};

        try {
            for (let key of Object.keys(organismoOficial)) {
                let value = organismoOficial[key]

                if (validationSchema[key].required) {

                    if (!value) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }

                    if (key === "DsMail" && !/\S+@\S+\.\S+/.test(value)) {
                        errors[key] = 'El Email es inválido';
                        setErrors(errors);
                        valid = false;
                    }
                }
            }
        } catch (err) {
            console.error('Error', err);
        }
        return valid;
    }

    const handleChange = event => setOrganismoOficial({ ...organismoOficial, [event.target.name]: event.target.value });

    const handleAeropuertoChange = aeropuerto => setOrganismoOficial({ ...organismoOficial, IdAeropuerto: aeropuerto.value });

    const handleSubmit = () => {
        if (isValid()) {

            SaveOrganismo(organismoOficial)
                .then(
                    (resOrganismo) => {
                        if (resOrganismo.data.ResultadoExitoso) {
                            swal.fire({
                                text: 'Se han guardado los cambios con éxito',
                                ...successSwal
                            }).then(() => { history.push('/Organismos'); })
                        } else {
                            swal.fire({
                                title: 'Error al guardar los cambios',
                                icon: 'warning',
                                text: resOrganismo.data.MensajeError,
                                allowOutsideClick: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                    });
        }
    }

    const cancelaABM = () => history.push("/Organismos");

    const handleUploadImageClick = async(event) => {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length && validateImage(event)) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                const nameFile = organismoOficial.DsOrgOficial !== '' ? `${organismoOficial.DsOrgOficial}.${file.name.split('.').pop()}` : file.name;
                const base64Image = reader.result.toString().split(",", 2)[1];
                setOrganismoOficial({ ...organismoOficial, lkImagen: nameFile, base64Image: base64Image });
                setImage({ ...image, srcImage: reader.result, hiddenRemoveImage: false });
            };
        }
    };

    const validateImage = (event) => {
        var fileName = event.target.files[0].name;
        var fileSize = event.target.files[0].size;

        if (fileSize > 3000000) {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'La imagen no debe superar los 3MB',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
            event.target.value = '';
            return false;
        } else {
            var ext = fileName.split('.').pop();

            ext = ext.toLowerCase();

            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'svg':
                case 'gif':
                    return true;
                    break;
                default:
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'LA Imagen no tiene la extensión adecuada',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                    event.target.value = '';
                    return false;
            }
        }
    }

    const removeImage = () => {
        setOrganismoOficial({ ...organismoOficial, lkImagen: null, base64Image: null })
        setImage({ ...image, srcImage: NoFoundImage, hiddenRemoveImage: true });
    }

    return (
        <div className="main-content">
            <br />
            
            <h1 className="title">{Id ? 'Editar organismo oficial' : 'Nuevo organismo oficial'}</h1>
            
            <br />
            <br/>
            <div className="content">
                <div className="row form-group">
                    <div className="col-md-12">
                        <label className="label">Idioma: {props.idIdioma}</label>
                        <div className="box">
                            <div className=" form-group row">
                                <div className="col-md-6">
                                    <label className="label">Nombre<i className="required">*</i></label>
                                    <input name="DsOrgOficial" type="text" maxLength={100} placeholder="ej. ANAC - Administración Nacional de Aviación Civil" value={organismoOficial.DsOrgOficial !== null ? organismoOficial.DsOrgOficial : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsOrgOficial && (<p className="help is-danger">{errors.DsOrgOficial}</p>)}
                                    <p className="help maxlength">{organismoOficial.DsOrgOficial ? organismoOficial.DsOrgOficial.length : 0} / 100</p>

                                </div>

                                <div className="col-md-6">
                                    <label className="label">Aeropuerto<i className="required">*</i></label>
                                    <Select placeholder={<div>Seleccione</div>} options={props.aeropuertos} onChange={handleAeropuertoChange} value={props.aeropuertos.filter(obj => obj.value === organismoOficial.IdAeropuerto)} />
                                    {errors.IdAeropuerto && (<p className="help is-danger">{errors.IdAeropuerto}</p>)}
                                </div>

                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label className="label">E-Mail</label>
                                    <input name="DsMail" type="text" maxLength={30} placeholder="ej. info@anac.gov.ar" value={organismoOficial.DsMail !== null ? organismoOficial.DsMail : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsMail && (<p className="help is-danger">{errors.DsMail}</p>)}
                                    <p className="help maxlength">{organismoOficial.DsMail ? organismoOficial.DsMail.length : 0} / 30</p>
                                </div>
                                <div className="col-md-6">
                                    <label className="label">Sitio Web</label>
                                    <input name="DsWeb" type="text" maxLength={30} placeholder="ej. https://www.argentina.gob.ar/justicia/anmac" value={organismoOficial.DsWeb !== null ? organismoOficial.DsWeb : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsWeb && (<p className="help is-danger">{errors.DsWeb}</p>)}
                                    <p className="help maxlength">{organismoOficial.DsWeb ? organismoOficial.DsWeb.length : 0} / 30</p>
                                </div>
                            </div>

                            <div className=" form-group row">

                                <div className="col-md-12">
                                    <label className="label">Información</label>
                                    <textarea style={{ resize: 'none' }} name="RtInformation" maxLength={260} placeholder="ej. ANAC - Administración Nacional de Aviación Civil"  rows="2" type="text" value={organismoOficial.RtInformation !== null ? organismoOficial.RtInformation : ''} onChange={handleChange} className="form-control"></textarea>
                                    {errors.RtInformation && (<p className="help is-danger">{errors.RtInformation}</p>)}
                                    <p className="help maxlength">{organismoOficial.RtInformation ? organismoOficial.RtInformation.length : 0} / 260</p>
                                </div>

                            </div>

                            <h3 className={classes.h3}>Datos Principales</h3>
                            <hr/>

                            <div className="form-group row">
                                
                                <div className="col-md-4">
                                    <label className="label">Dirección</label>
                                    <input name="DsDireccion1" type="text" placeholder="ej. Balcarce 290" maxLength={30} value={organismoOficial.DsDireccion1 !== null ? organismoOficial.DsDireccion1 : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsDireccion1 && (<p className="help is-danger">{errors.DsDireccion1}</p>)}
                                    <p className="help maxlength">{organismoOficial.DsDireccion1 ? organismoOficial.DsDireccion1.length : 0} / 30</p>
                                </div>

                                <div className="col-md-4">
                                    <label className="label">Horario</label>
                                    <input name="DsHorario1" type="text" placeholder="ej. Lunes a Viernes de 9:30 a 16 hs." maxLength={50}  value={organismoOficial.DsHorario1 !== null ? organismoOficial.DsHorario1 : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsHorario1 && (<p className="help is-danger">{errors.DsHorario1}</p>)}
                                    <p className="help maxlength">{organismoOficial.DsHorario1 ? organismoOficial.DsHorario1.length : 0} / 50</p>
                                </div>

                                <div className="col-md-4">
                                    <label className="label">Teléfono<i className="required">*</i></label>
                                    <input name="DsTelefono1" type="text" placeholder="ej. (+54 11) 5941-3000" maxLength={30} value={organismoOficial.DsTelefono1 !== null ? organismoOficial.DsTelefono1 : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsTelefono1 && (<p className="help is-danger">{errors.DsTelefono1}</p>)}
                                    <p className="help maxlength">{organismoOficial.DsTelefono1 ? organismoOficial.DsTelefono1.length : 0} / 30</p>
                                </div>

                            </div>

                            <h3 className={classes.h3}>Datos Secundarios</h3>
                            <hr/>

                            <div className="form-group row">
                                
                                <div className="col-md-4">
                                    <label className="label">Dirección</label>
                                    <input name="DsDireccion2" type="text" placeholder="ej. Balcarce 290" maxLength={30} value={organismoOficial.DsDireccion2 !== null ? organismoOficial.DsDireccion2 : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsDireccion2 && (<p className="help is-danger">{errors.DsDireccion2}</p>)}
                                    <p className="help maxlength">{organismoOficial.DsDireccion2 ? organismoOficial.DsDireccion2.length : 0} / 30</p>
                                </div>

                                <div className="col-md-4">
                                    <label className="label">Horario</label>
                                    <input name="DsHorario2" type="text" placeholder="ej. Lunes a Viernes de 9:30 a 16 hs." maxLength={50} value={organismoOficial.DsHorario2 !== null ? organismoOficial.DsHorario2 : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsHorario2 && (<p className="help is-danger">{errors.DsHorario2}</p>)}
                                    <p className="help maxlength">{organismoOficial.DsHorario2 ? organismoOficial.DsHorario2.length : 0} / 50</p>
                                </div>

                                <div className="col-md-4">
                                    <label className="label">Teléfono</label>
                                    <input name="DsTelefono2" type="text" placeholder="ej. (+54 11) 5941-3000" maxLength={30} value={organismoOficial.DsTelefono2 !== null ? organismoOficial.DsTelefono2 : ''} onChange={handleChange} className="form-control" />
                                    {errors.DsTelefono2 && (<p className="help is-danger">{errors.DsTelefono2}</p>)}
                                    <p className="help maxlength">{organismoOficial.DsTelefono2 ? organismoOficial.DsTelefono2.length : 0} / 30</p>
                                </div>

                            </div>

                            {/* <div className="form-group row">
                                <div className="col-md-6">
                                    <br />
                                    <label className="label">Seleccione una Imagen</label>

                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <img className={classes.img} alt="complex" src={image.srcImage} />
                                        </Grid>

                                        <Grid item xs container direction="column" spacing={2}>
                                            {image.hiddenRemoveImage ? <Fragment>
                                                <input type="file" accept=".jpg, .jpeg, .png, .gif, .svg" className={classes.input} id="contained-button-file1" onChange={handleUploadImageClick} />
                                                <label className="buttonImg" htmlFor="contained-button-file1">
                                                    <Fab component= "span" color="primary" >
                                                        <AddPhotoAlternateIcon />
                                                    </Fab>
                                                </label>
                                            </Fragment> : ""}
                                            <div className={classes.del}>
                                                {!image.hiddenRemoveImage ? <IconButton color="secondary" aria-label="delete" component="span" onClick={removeImage}>
                                                    <DeleteIcon />
                                                </IconButton> : ""}
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <br/>
                                    <label className="label">Requerimientos de la imagen:</label>
                                    <ul className="validImages">
                                        <li>Tamaño </li>
                                        <li>Extensiones validas: JPG, JPEG, PNG, GIF, SVG</li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>

                        <div className="form-row">
                            <Button variant="contained" color="primary" className="button" onClick={handleSubmit} startIcon={<Save />}>Guardar</Button>
                            <Button variant="contained" startIcon={<Cancel />} className="button" onClick={cancelaABM}>Cancelar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        idIdioma: state.appReducer.idIdioma,
        aeropuertos: state.aeropuertoReducer.aeropuertos,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto
    }
}

export default connect(mapStateToProps)(OrganismosOficialesAbm)