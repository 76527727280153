export default function validate(values) {
    
    let errors = {};
    if (!values.usuario) {
      errors.usuario = 'El Usuario es requerido';
    } else if (values.usuario.length < 3) {
      errors.usuario = 'El Usuario tiene que ser mayor a 3 caracteres';
    }
    if (!values.password) {
      errors.password = 'El Password es requerido';
    } else if (values.password.length < 8) {
      errors.password = 'El Password tiene que ser mayor a 8 caracteres';
    }
    return errors;
  };