import Axios from "axios"
import './axiosConfig';

const GETLINEASAEREASBYIDIOMA_URL = 'LineaAerea/GetLineasAereaByIdioma';
const POSTLINEAAEREA_URL = 'LineaAerea/SaveOrUpdateLineaAerea';
const GETLINEAAEREA_URL = 'LineaAerea/GetLineaAerea';
const DELETELINEAAREA_URL = 'LineaAerea/DeleteLineaAerea';
const GETLINEAAEREABYIATAIDOMA_URL = 'LineaAerea/GetLineaAereaByIataIdioma';
const GETLINEAAEREAFULL_URL = 'LineaAerea/GetLineaAereaFull';
const DELETELINEAAREAFULL_URL = 'LineaAerea/DeleteLineaAereaFull';
const SAVEORUPDATELINEAAEREA_URL = 'LineaAerea/SaveOrUpdateLineaAereaFull'

export const GetLineasAereasByIdioma=(idIdioma) => {
    return Axios.get(GETLINEASAEREASBYIDIOMA_URL,{ params: { idIdioma } });
}

export const SaveLineaAerea=(lineaAerea)=> {
    return Axios.post(POSTLINEAAEREA_URL, lineaAerea);
}

export const GetLineaAerea=(idLineaAerea)=> {
    return Axios.get(GETLINEAAEREA_URL,{params: {idLineaAerea}});
}

export const DeleteLineaAerea=(lineaAerea)=> {
    return Axios.post(DELETELINEAAREA_URL, lineaAerea);
}

export const GetLineaAereaByIataIdioma=(iata, idIdioma) => {
    return Axios.get(GETLINEAAEREABYIATAIDOMA_URL,{ params: {iata, idIdioma } });
}

export const GetLineasAereasById=(idLineaAerea) => {
    return Axios.get(GETLINEAAEREAFULL_URL,{ params: { idLineaAerea } });
}

export const SaveOrUpdateLineaAerea=(lineaAerea)=> {
    return Axios.post(SAVEORUPDATELINEAAEREA_URL, lineaAerea);
}

export const DeleteLineaAereaFull=(lineaAerea)=> {
    return Axios.post(DELETELINEAAREAFULL_URL, lineaAerea);
}
