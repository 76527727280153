import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { GetAlquilerAutoById, SaveOrUpdate } from '../../../services/alquilerautos.service';
import { getSessionUser } from '../../../services/auth.service';

import swal from 'sweetalert2';
import { successSwal, confirmSwal } from '../../../assets/resources/swal-config';
import { Button, IconButton, Grid, Fab } from '@material-ui/core';
import { Save, Cancel, Done, Clear, AddPhotoAlternate, Delete } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import NoFoundImage from '../../../assets/img/not_found.png';
import SwitcherIdioma from '../../../components/switcherIdioma';

import './alquilerdeautosABM.css';

const AlquilerAutosABM = (props) => {
    const Id = props.match.params.id;
    const history = useHistory();
    const user = getSessionUser();
    const [alquiler, setAlquiler] = useState({
        id: 0,
        idAeropuerto: 0,
        idIdioma: "ES-AR",
        dsTransporte: "",
        dsUbicacion: "",
        dsTelefono1: "",
        dsTelefono2: "",
        dsEmail: "",
        dsWeb: "",
        dsURL: "",
        lkImagen: null,
        dsUsuario: "",
        feCreacion: null,
        feModificacion: null,
        base64Image: null,
        mcInactiva: false,
    });

    const validationSchema = {
        id: { required: false },
        idAeropuerto: { required: false },
        idIdioma: { required: false },
        dsTransporte: { required: true },
        dsUbicacion: { required: true },
        dsTelefono1: { required: true },
        dsTelefono2: { required: false },
        dsEmail: { required: false },
        dsWeb: { required: false },
        dsURL: { required: false },
        lkImagen: { required: false },
        dsUsuario: { required: false },
        feCreacion: { required: false },
        feModificacion: { required: false },
        base64Image: { required: false },
        mcInactiva: { required: false },
    }

    const [errors, setErrors] = useState({});
    const [editing, setEditing] = useState(false);
    const [image, setImage] = useState({ name: null, srcImage: NoFoundImage, hiddenRemoveImage: true });

    useEffect(() => {
        if (Id > 0) {
            getAlquilerById()
        } else {
            setAlquiler({ ...alquiler, idIdioma: props.idIdioma, idAeropuerto: props.idAeropuerto });
            setEditing(true);
        }
    }, []);

    const getAlquilerById = () => {
        GetAlquilerAutoById(Id)
            .then((resAlquiler) => {
                setAlquiler({ ...resAlquiler.data, base64Image: resAlquiler.data.base64Image !== null ? resAlquiler.data.base64Image.toString().split(",", 2)[1] : null });
                setImage({ ...image, name: resAlquiler.data.lkImagen, srcImage: resAlquiler.data.base64Image !== null ? resAlquiler.data.base64Image : NoFoundImage, hiddenRemoveImage: resAlquiler.data.base64Image !== null ? false : true })
            })
            .catch(err => console.error(err))
    }

    const handleEditClick = () => {
        if (!editing)
            setEditing(true);
    }

    const handleActivaChange = event => setAlquiler({ ...alquiler, mcInactiva: !event.target.checked });

    const handleChange = event => setAlquiler({ ...alquiler, [event.target.name]: event.target.value });

    const handleUploadImageClick = event => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length && validateImage(event)) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                let img = new Image()
                img.src = reader.result;

                //VALIDA ANCHO Y LARGO DE LA IMAGEN
                img.onload = function () {
                    const nameFile = alquiler.dsTransporte !== '' ? `${alquiler.dsTransporte}.${file.name.split('.').pop()}` : file.name;
                    console.log(nameFile)
                    const base64Image = reader.result.toString().split(",", 2)[1];
                    setAlquiler({ ...alquiler, lkImagen: nameFile, base64Image: base64Image })
                    setImage({ ...image, name: nameFile, srcImage: reader.result, hiddenRemoveImage: false });
                }
            };
        }
    };

    const validateImage = (event) => {
        var fileName = event.target.files[0].name;
        var fileSize = event.target.files[0].size;

        if (fileSize > 3000000) {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'La imagen no debe superar los 3MB',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
            event.target.value = '';
            return false;
        } else {
            var ext = fileName.split('.').pop();

            ext = ext.toLowerCase();

            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'gif':
                case 'svg':
                    return true;
                    break;
                default:
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'LA Imagen no tiene la extensión adecuada',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                    event.target.value = '';
                    return false;
            }
        }
    }
    
    const removeImage = () => {
        swal.fire({
            title: 'Eliminar servicio',
            text: '¿Está seguro que desea eliminar la imagen ?',
            ...confirmSwal
        })
            .then((confirm) => {
                if (confirm.value) {
                    setAlquiler({ ...alquiler, lkImagen: null, base64Image: null })
                    setImage({ ...image, srcImage: NoFoundImage, hiddenRemoveImage: true });
                }
            })
    }

    const handleSubmit = () => {
        if(isValid()){
            const obj = {
                id: alquiler.id,
                idAeropuerto: alquiler.idAeropuerto,
                idIdioma: alquiler.idIdioma,
                dsTransporte: alquiler.dsTransporte,
                dsUbicacion: alquiler.dsUbicacion,
                dsTelefono1: alquiler.dsTelefono1,
                dsTelefono2: alquiler.dsTelefono2,
                dsEmail: alquiler.dsEmail,
                dsWeb: alquiler.dsWeb,
                dsURL: alquiler.dsURL,
                lkImagen: alquiler.lkImagen,
                dsUsuario: user.DS_Nombre,
                feCreacion: Id == 0 ? new Date().toISOString().replace("Z", "") : alquiler.feCreacion,
                feModificacion: new Date().toISOString().replace("Z", ""),
                base64Image: alquiler.base64Image,
                mcInactiva: alquiler.mcInactiva,
            }

            SaveOrUpdate(obj)
                .then((resAlquiler) => {
                    if (resAlquiler.data.ResultadoExitoso) {
                        swal.fire({
                            text: 'Se han guardado los cambios con éxito',
                            ...successSwal
                        }).then(() => { history.push('/Alquileres'); })
                    } else {
                        swal.fire({
                            title: 'Error al guardar los cambios',
                            icon: 'warning',
                            text: resAlquiler.data.MensajeError,
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                })
        }
    }

    const handleCancelABMClick = () => history.push('/Alquileres');

    const isValid = () => {
        let valid = true;
        let email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let errors = {};
        setErrors(errors);

        try {
            for (let key of Object.keys(alquiler)) {
                let value = alquiler[key]
                if (key == "dsEmail" && value && !email.test(value)) {
                    errors[key] = 'El email ingresado no tiene un formato correcto.';
                    setErrors(errors);
                    valid = false;
                    
                } else if (validationSchema[key].required) {
                    if (value == 0) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }
                    
                }
            }
        } catch (err) {
            console.error('Error', err);
        }
        return valid;
    }

    const handleValidField = ev => {
        let errorsItems = {};

        for (let key of Object.keys(errors)) {
            if (key != ev.target.name) {
                errorsItems[key] = errors[key];
                setErrors(errorsItems);
            } else {
                setErrors(errorsItems)
            }
        }
        
        if (!ev.target.value && validationSchema[ev.target.name].required) {
            errorsItems[ev.target.name] = 'Requerido';
            setErrors(errorsItems);
        }
    }

    return (
        <div className="main-content">
            <br />
            {Id > 0 ?
                <h1 className="title">EDITAR AQUILER DE VEHÍCULO - <b className="greentext">{alquiler.dsTransporte}</b></h1>
                :
                <h1 className="title">Nuevo ALQUILER DE VEHÍCULOS</h1>
            }

            <br />
            <br />
            
            <div className="content">
                
                <div className="row form-group">
                    <div className="col-md-9">
                        <div className="active-switch">
                            <label className="label">HABILITADA</label>
                            <input type="checkbox" id="check" onChange={handleActivaChange} checked={!alquiler.mcInactiva} />
                            <label htmlFor="check" className="switch"></label>
                        </div>
                    </div>

                    <div className="col-md-3">
                        {Id == 0 ?
                            <SwitcherIdioma />
                            :
                            <Button variant="contained" className={`btnAdd ${editing ? 'gray' : ''}`} color="primary" startIcon={<FontAwesomeIcon icon={faEdit} />} onClick={handleEditClick}>Editar</Button>
                        }
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-12">

                        <div className="box">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Nombre</label>
                                        {editing ?
                                            <div className="input-check">
                                                <input name="dsTransporte" type="text" placeholder="Nombre de la Empresa" defaultValue={alquiler.dsTransporte} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                <div className="valid-box">
                                                    {errors.dsTransporte ?
                                                        <Clear className="wrong" />
                                                        :
                                                        <Done className="check" />
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <span className="text">{alquiler.dsTransporte}</span>
                                        }
                                        {errors.dsTransporte && (<p className="help is-danger">{errors.dsTransporte}</p>)}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Teléfono</label>
                                        {editing ?
                                            <div className="telefono">
                                                <input name="dsTelefono1" type="text" placeholder="1122334455" defaultValue={alquiler.dsTelefono1} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                <input name="dsTelefono2" type="text" placeholder="1122334455" defaultValue={alquiler.dsTelefono2} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                <div className="input-check inline" hidden={!editing}>
                                                    <br />
                                                    <div className="valid-box">
                                                        {errors.dsTelefono1 || errors.dsTelefono2 ?
                                                            <Clear className="wrong" />
                                                            :
                                                            <Done className="check" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="telefono">
                                                <span className="text">{alquiler.dsTelefono1}</span>
                                                <span className="text">{alquiler.dsTelefono2}</span>
                                            </div>
                                        }
                                        {errors.dsTelefono1 && (<p className="help is-danger">{errors.dsTelefono1 }</p>)}
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Ubicación en el Aeropuerto</label>
                                        {editing ?
                                            <div className="input-check">
                                                <textarea name="dsUbicacion" placeholder="Ej:  sector Arribos Nacionales" defaultValue={alquiler.dsUbicacion} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                <div className="valid-box big">
                                                    {errors.dsUbicacion ?
                                                        <Clear className="wrong" />
                                                        :
                                                        <Done className="check" />
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <span className="text">{alquiler.dsUbicacion}</span>
                                        }
                                        {errors.dsUbicacion && (<p className="help is-danger">{errors.dsUbicacion}</p>)}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Email</label>
                                        {editing ?
                                            <div className="input-check">
                                                <input name="dsEmail" type="text" placeholder="nombre@dominio.com.ar" defaultValue={alquiler.dsEmail} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                <div className="valid-box">
                                                    {errors.dsEmail ?
                                                        <Clear className="wrong" />
                                                        :
                                                        <Done className="check" />
                                                    }
                                                </div>
                                            </div>
                                        :
                                            <span className="text">{alquiler.dsEmail}</span>
                                        }
                                        {errors.dsEmail && (<p className="help is-danger">{errors.dsEmail}</p>)}
                                        <br />
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 offset-md-6">
                                    <div className="form-input">
                                        <label className="label">Sitio Web</label>
                                        {editing ?
                                            <div className="input-check">
                                                <input name="dsWeb" type="text" placeholder="Sitio Web" defaultValue={alquiler.dsWeb} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                <div className="valid-box">
                                                    {errors.dsWeb ?
                                                        <Clear className="wrong" />
                                                        :
                                                        <Done className="check" />
                                                    }
                                                </div>
                                            </div>
                                        :
                                            <span className="text">{alquiler.dsWeb}</span>
                                        }
                                        {errors.dsWeb && (<p className="help is-danger">{errors.dsWeb}</p>)}
                                        <br />
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Seleccione una Imagen</label>
                                        <br/>
                                        <Grid container spacing={3}>
                                            <Grid item>
                                                <img className="abmImage" alt="complex" src={image.srcImage} />
                                            </Grid>
                                            <Grid item>
                                                {image.hiddenRemoveImage ? <Fragment>
                                                    <input type="file" accept="image/*" id="contained-button-file1"
                                                        onChange={handleUploadImageClick} />

                                                    <label htmlFor="contained-button-file1">
                                                        <Fab component="span" color="primary" >
                                                            <AddPhotoAlternate />
                                                        </Fab>
                                                    </label>
                                                </Fragment> : ""}
                                                <div className="abmImageDel">
                                                    {!image.hiddenRemoveImage ? <IconButton color="secondary" aria-label="delete" component="span" onClick={removeImage}>
                                                        <Delete />
                                                    </IconButton> : ""}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">URL</label>
                                        {editing ?
                                            <div className="input-check">
                                                <input name="dsURL" type="text" placeholder="https://dominio.com.ar/reservas" defaultValue={alquiler.dsURL} onChange={handleChange} className="form-control" />
                                                <div className="valid-box">
                                                    {errors.dsURL ?
                                                        <Clear className="wrong" />
                                                        :
                                                        <Done className="check" />
                                                    }
                                                </div>
                                            </div>
                                        :
                                            <span className="text">{alquiler.dsURL}</span>
                                        }
                                        {errors.dsURL && (<p className="help is-danger">{errors.dsURL}</p>)}
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row form-group"> 
                    <div className="form-row">
                        <Button variant="contained" startIcon={<Save />} className="button" onClick={handleSubmit}>Guardar</Button>
                        <Button variant="contained" startIcon={<Cancel />} className="button" onClick={handleCancelABMClick}>Cancelar</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        idIdioma: state.appReducer.idIdioma,
        aeropuertos: state.aeropuertoReducer.aeropuertos,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto
    }
}

export default connect(mapStateToProps)(AlquilerAutosABM);