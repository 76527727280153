export const successSwal = {
    title: 'Operación exitosa',
    icon: 'success',
    allowOutsideClick: false,
    allowEscapeKey: false,
    showConfirmButton: false,
    timer: '1500'
}
export const confirmSwal = {
    icon: 'warning',
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonText: 'Ok',
    cancelButtonText: 'Cancelar'
}