import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { GetCierreAeropuertoById, SaveOrUpdate } from '../../../../services/cierreAeropuertos.service';
import swal from 'sweetalert2';
import { successSwal } from  '../../../../assets/resources/swal-config';
import { Button } from '@material-ui/core';
import { Save, Cancel } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';

import './cierreAeropuertosABM.css'

const CierreAeropuertosABM = (props) => {
    const Id = props.match.params.id;
    const history = useHistory();

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ 'font': [] }],
            ['link']
        ]
    }

    const [cierreAeropuerto, setCierreAeropuerto] = useState({
        Id: 0,
        IdAeropuerto: 0,
        CA_Texto: "",
        CA_FechaInicio: null,
        CA_FechaFin: null,
        CA_Activo: false
    });

    const validationSchema = {
        Id: { required: false },
        IdAeropuerto: { required: true },
        CA_Texto: { required: true },
        CA_FechaInicio: { required: true },
        CA_FechaFin: { required: true },
        CA_Activo: { required: false }
    }

    const [errors, setErrors] = useState([]);
    const [editing, setEditing] = useState(false);
    const [iataCode, setIATACode] = useState("");

    useEffect(() => {
        if (Id > 0) {
            getCierreAeropuertoById(Id);
        } else {
            setCierreAeropuerto({ ...cierreAeropuerto, IdAeropuerto: props.idAeropuerto });
            setEditing(true);
            setIATACode(getIATACode(props.idAeropuerto));
        }
    }, []);

    const getIATACode = (idAeropuerto) => {
        let iataCode = props.aeropuertos.filter(ad => ad.value === idAeropuerto)[0];

        if (iataCode === undefined)
        {
            iataCode = "";
        }
        else
        {
            iataCode = " - " + iataCode.label;
        }

        return iataCode;
    }

    const getCierreAeropuertoById = (Id) => {
        GetCierreAeropuertoById(Id)
            .then((response) => {
                if (response.data) {
                    let auxData = response.data;
                    setCierreAeropuerto({...auxData, CA_FechaInicio: auxData.CA_FechaInicio.split('T')[0], CA_FechaFin: auxData.CA_FechaFin.split('T')[0]});
                    setIATACode(getIATACode(auxData.IdAeropuerto));
                }
            })
            .catch(err => console.error(err))
    }

    const handleCheckChange = ev => setCierreAeropuerto({ ...cierreAeropuerto, [ev.target.name]: ev.target.checked });

    const handleChange = ev => {
        setCierreAeropuerto({ ...cierreAeropuerto, [ev.target.name]: ev.target.value });
    }

    const handleBodyChange = value => {
        if (editing)
            setCierreAeropuerto({ ...cierreAeropuerto, CA_Texto: value });
    }

    const handleEditClick = () => {
        if (!editing)
            setEditing(true);
    }

    const handleSubmit = () => {
        if(isValid()) {
            const obj = {
                Id: cierreAeropuerto.Id,
                IdAeropuerto: cierreAeropuerto.IdAeropuerto,
                CA_Texto: cierreAeropuerto.CA_Texto,
                CA_FechaInicio: cierreAeropuerto.CA_FechaInicio ? cierreAeropuerto.CA_FechaInicio : new Date().toLocaleDateString(),
                CA_FechaFin: cierreAeropuerto.CA_FechaFin ? cierreAeropuerto.CA_FechaFin : new Date().toLocaleDateString(),
                CA_Activo: cierreAeropuerto.CA_Activo
            }

            console.log(obj);
            SaveOrUpdate(obj)
                .then((response) => {
                    console.log(response.data);
                    if (response.data) {
                        swal.fire({
                            text: 'Se han guardado los cambios con éxito',
                            ...successSwal
                        }).then(() => { history.push('/CierresAeropuertos'); })
                    } else {
                        swal.fire({
                            title: 'Error al guardar los cambios',
                            icon: 'warning',
                            text: response.data.MensajeError,
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                })
        }
    }

    const handleCancelABMClick = () => history.push('/CierresAeropuertos');

    const isValid = () => {
        let valid = true;
        let errors = {};
        setErrors(errors);
        
        try {
            for (let key of Object.keys(cierreAeropuerto)) {
                let value = cierreAeropuerto[key]
                if (validationSchema[key].required) {
                    if (value == 0) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }
                    
                }
            }

            if (cierreAeropuerto.CA_FechaFin <= cierreAeropuerto.CA_FechaInicio)
            {
                swal.fire({title: 'Error al guardar los cambios',
                            icon: 'warning',
                            text: "La Fecha Fin no puede ser menor a Fecha Inicio",
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'});

                valid = false;
            }
        } catch (err) {
            console.error('Error', err);
        }
        return valid;
    }

    const handleValidField = ev => {
        console.log(validationSchema[ev.target.name].required && !ev.target.value)
        let errorsItems = {};

        for (let key of Object.keys(errors)) {
            if (key != ev.target.name) {
                errorsItems[key] = errors[key];
                setErrors(errorsItems);
            } else {
                setErrors(errorsItems)
            }
        }

        if (!ev.target.value && validationSchema[ev.target.name].required) {
            errorsItems[ev.target.name] = 'Requerido';
            setErrors(errorsItems);
        }
    }
    
    return (
        <div className="main-content">
            <br />
            <h1 className="title">Cierre de Aeropuerto {iataCode}</h1>
            <br />
            <br />
            <div className="content">

                <div className="row form-group">
                    <div className="col-md-3">
                        <label className="label">Idioma: {props.idIdioma == 'ES-AR' ? 'Español' : 'Inglés'}</label>
                    </div>

                    <div className={`col-md-${Id == 0 ? '9' : '6'}`}>
                        <div className="active-switch">
                            <label className="label">Activo</label>
                            <input type="checkbox" id="check" onChange={handleCheckChange} name="CA_Activo" checked={cierreAeropuerto.CA_Activo} disabled={!editing}/>
                            <label htmlFor="check" className="switch"></label>
                        </div>
                    </div>

                    <div className="col-md-3" hidden={Id == 0}>
                        <Button variant="contained" className={`btnAdd ${editing ? 'gray' : ''}`} color="primary" startIcon={<FontAwesomeIcon icon={faEdit} />} onClick={handleEditClick}>Editar</Button>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-12">
                        <div className="box">
                            <div className="row form-group">
                                <label className="label">Descripción del cierre</label>
                                <div className="col-md-12">
                                    <div className="editorCierreAeropuerto" >
                                        <ReactQuill
                                            readOnly={!editing}
                                            name='CA_Texto'
                                            value={cierreAeropuerto.CA_Texto}
                                            theme='snow'
                                            placeholder='Descripción del cierre'
                                            modules={modules}
                                            onChange={handleBodyChange} />
                                    </div>
                                    {errors.CA_Texto && (<p className="help is-danger">{errors.CA_Texto}</p>)}
                                    <br />
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Fecha Inicio</label>
                                        <div className="periodos">
                                            {editing ?
                                                <input name="CA_FechaInicio" type="date" placeholder="Fecha Inicio" defaultValue={cierreAeropuerto.CA_FechaInicio} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                :
                                                <span className="text">{cierreAeropuerto.CA_FechaInicio}</span>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Fecha Fin</label>
                                        <div className="periodos">
                                            {editing ?
                                                <input name="CA_FechaFin" type="date" placeholder="Fecha Fin" defaultValue={cierreAeropuerto.CA_FechaFin} onChange={handleChange} onKeyUp={handleValidField} className="form-control" />
                                                :
                                                <span className="text">{cierreAeropuerto.CA_FechaFin}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row form-group"> 
                    <div className="form-row">
                        <Button variant="contained" startIcon={<Save />} className="button" onClick={handleSubmit}>Guardar</Button>
                        <Button variant="contained" startIcon={<Cancel />} className="button" onClick={handleCancelABMClick}>Cancelar</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        idIdioma: state.appReducer.idIdioma,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto,
        aeropuertos: state.aeropuertoReducer.aeropuertos
    }
}

export default connect(mapStateToProps)(CierreAeropuertosABM);