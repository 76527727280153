import { combineReducers, createStore, applyMiddleware } from 'redux';
import userReducer from './userReducer';
import appReducer from './appReducer'
import permisosReducer from './permisosReducer'
import aeropuertoReducer from './aeropuertoReducer'

// UTLIZAR EN CASO DE LLAMADAS ASINCRONICAS PARA  LUEGO DISPARAR LA ACCION
import thunk from 'redux-thunk'

const reducer = combineReducers({
  userReducer: userReducer,
  permisosReducer: permisosReducer,
  appReducer: appReducer,
  aeropuertoReducer: aeropuertoReducer
});

const store = createStore(
  reducer,
  // UTLIZAR EN CASO DE LLAMAS ASINCRONICAS Y LUEGO DISPARAR LA ACCION
  //applyMiddleware(thunk)
);

export default store;