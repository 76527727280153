import Axios from 'axios'
import './axiosConfig'

const GETTAXIREMISBYID_URL = 'TaxiRemis/GetTaxiRemisById';
const GETTAXIREMISBYAEROPUERTO_URL = 'TaxiRemis/GetTaxiRemisByAeropuerto';
const DELETETAXIREMIS_URL = 'TaxiRemis/DeleteTaxiRemis';
const SAVEORUPDATETAXIREMIS_URL = 'TaxiRemis/SaveOrUpdateTaxiRemis';

const DELETETARIFA_URL = 'TaxiRemis/DeleteTarifa';
const SAVEORUPDATETARIFA_URL = 'TaxiRemis/SaveOrUpdateTarifa';

export const GetTaxiRemisById = (id) => {
    return Axios.get(GETTAXIREMISBYID_URL, { params: { id } });
}

export const GetTaxiRemisByAeropuerto = (idIdioma, idAeropuerto) => {
    return Axios.get(GETTAXIREMISBYAEROPUERTO_URL, { params: { idIdioma, idAeropuerto } });
}

export const DeleteTaxiRemis = (taxiRemis) => {
    return Axios.post(DELETETAXIREMIS_URL, taxiRemis);
}

export const DeleteTarifa = (tarifa) => {
    return Axios.post(DELETETARIFA_URL, tarifa);
}

export const SaveOrUpdateTaxiRemis = (taxiRemis) => {
    return Axios.post(SAVEORUPDATETAXIREMIS_URL, taxiRemis);
}

export const SaveOrUpdateTarifa = (tarifa) => {
    return Axios.post(SAVEORUPDATETARIFA_URL, tarifa);
}