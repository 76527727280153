import Axios from 'axios'
import './axiosConfig'

const GETPREGUNTASFRECUENTESBYIDIOMA_URL = 'PreguntaFrecuente/GetPreguntasFrecuentesByIdioma';
const GETPREGUNTAFRECUENTE_URL = 'PreguntaFrecuente/GetPreguntaFrecuente';
const DELETEPREGUNTAFRECUENTE_URL = 'PreguntaFrecuente/DeletePreguntaFrecuente';
const POSTPREGUNTAFRECUENTE_URL = 'PreguntaFrecuente/SaveOrUpdatePreguntaFrecuente';

export const GetPreguntasFrecuentesByIdioma = (idIdioma) => {
    return Axios.get(GETPREGUNTASFRECUENTESBYIDIOMA_URL, { params: { idIdioma } });
}

export const GetPreguntaFrecuente = (idPregunta) => {
    return Axios.get(GETPREGUNTAFRECUENTE_URL, { params: { idPregunta } });
}

export const DeletePreguntaFrecuente = (preguntaFrecuente) => {
    return Axios.post(DELETEPREGUNTAFRECUENTE_URL, preguntaFrecuente);
}

export const SavePreguntaFrecuente = (preguntaFrecuente) => {
    return Axios.post(POSTPREGUNTAFRECUENTE_URL, preguntaFrecuente);
}