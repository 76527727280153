import Axios from 'axios'
import './axiosConfig'

const GETSERVICIOSBYAEROPUERTO_URL = 'Servicios/GetServiciosByAeropuerto';
const GETSERVICIO_URL = 'Servicios/GetServiciosById';
const DELETESERVICIO_URL = 'Servicios/DeleteServicio';
const SAVEORUPDATESERVICIO_URL = 'Servicios/SaveOrUpdateServicio';

const GETOPCIONBYIDIOMA_URL = 'Servicios/GetOpcionesByIdioma';
const DELETEOPCION_URL = 'Servicios/DeleteOpcion';
const SAVEOPCION_URL = 'Servicios/SaveOpcion';

export const GetServiciosByAeropuerto = (IdIdioma, IdAeropuerto) => {
    return Axios.get(GETSERVICIOSBYAEROPUERTO_URL, { params: { IdIdioma, IdAeropuerto } });
}

export const GetServicio = (IdServicio) => {
    return Axios.get(GETSERVICIO_URL, { params: { IdServicio } });
}

export const DeleteServicio = (servicio) => {
    return Axios.post(DELETESERVICIO_URL, servicio)
}

export const SaveOrUpdateServicio = (servicio) => {
    return Axios.post(SAVEORUPDATESERVICIO_URL, servicio);
}

export const GetOpcionesByIdioma = (IdIdioma) => {
    return Axios.get(GETOPCIONBYIDIOMA_URL, { params: { IdIdioma } });
}

export const DeleteOpcion = (opcion) => {
    return Axios.post(DELETEOPCION_URL, opcion)
}

export const SaveOpcion = (opcion) => {
    return Axios.post(SAVEOPCION_URL, opcion);
}

