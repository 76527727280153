export const ROL_MASTER = 1;
export const ROL_ADMIN  = 2;
export const ROL_GESTOR = 3;
export const ROL_EDITOR = 4;

export const COMPONENTE_AEROPUERTOS=1;
export const COMPONENTE_PAGPRINCIPAL = 2;

export const COMPONENTE_COMOLLEGO = 5;
export const COMPONENTE_COMOLLEGO_MAPA=18;
export const COMPONENTE_COMOLLEGO_MINTERNO = 19;

export const COMPONENTE_ESTACIONAMIENTO = 7;
export const COMPONENTE_EST_LONGSTAY=24;
export const COMPONENTE_EST_TEXTODESCLATERAL=25;
export const COMPONENTE_EST_MAPA=26;
export const COMPONENTE_EST_EPSUR=27;
export const COMPONENTE_EST_EPNORTE=28;
export const COMPONENTE_EST_EPSARM=29;
export const COMPONENTE_EST_TEXTODISCAP=30;
export const COMPONENTE_EST_CESTADIA = 31;

export const COMPONENTE_LINEASAREAS = 8;

export const COMPONENTE_NOTICIAS = 48;
export const COMPONENTE_NOTAS = 44;
export const COMPONENTE_NOVEDADES = 43;
export const COMPONENTE_TRAFICOS = 49;

export const COMPONENTE_ORGANISMOS = 10;

export const COMPONENTE_PREGUNTAS_FRECUENTES = 47;

export const COMPONENTE_SERVICIOS= 9;

export const COMPONENTE_TRANSPORTE=6;
export const COMPONENTE_TRANS_TAXIS=20;
export const COMPONENTE_TRANS_BUSES=21;
export const COMPONENTE_TRANS_ALQ=22;
export const COMPONENTE_TRANS_BANNER=23;

export const COMPONENTE_VUELOS=4;
export const COMPONENTE_VUELOS_ARRIBOS=15;
export const COMPONENTE_VUELOS_PARTIDAS=16;
export const COMPONENTE_VUELOS_BANNERS=17;