import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { GetUsers, DeleteUser } from '../../services/usuario.service';
import { ROL_MASTER } from '../../services/permisos.service';
import { getSessionUser } from '../../services/auth.service';

import swal from 'sweetalert2';
import { successSwal, confirmSwal } from '../../assets/resources/swal-config'
import { withStyles } from '@material-ui/core/styles';

import { Button, Tooltip } from '@material-ui/core';
import { Delete, ChevronRight, Add } from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import Pagination from '../../components/Pagination';
import UsuarioAbm from './usuariosAbm/usuarioAbm'
import UserDefault from '../../assets/img/icon-user-white.svg';
import './usuarios.css'


const Usuario = () => {
    const history = useHistory();
    const user = getSessionUser();
    const rolMaster = user.IdRol === ROL_MASTER;

    const location = useLocation();
    const [usuarios, setUsuarios] = useState([]);
    const [hiddenABM, setHiddenABM] = useState(true);
    const [idUsuario, setIdUsuario] = useState(0);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const filteredItems = usuarios.filter(item => { return item.show });

    useEffect(() => {
        cargaInicialUsuarios();
        if (location.search.split("=")[1] == "true") setHiddenABM(false);
    }, []);

    const cargaInicialUsuarios = async () => {
        document.getElementById("searchInput").value = "";
        try {
            return await GetUsers()
                .then(
                    (resUsuarios) => {
                        if (resUsuarios.data) {
                            const usuarios = [];
                            for (var i = 0; i < resUsuarios.data.length; i++) {
                                const usuario = resUsuarios.data[i];
                                usuario["show"] = true;
                                usuarios.push(usuario);
                            }
                            setUsuarios(resUsuarios.data);
                        }
                    })
        }
        catch (e) {
            swal.fire({
                title: 'Informacion',
                icon: 'warning',
                text: 'No se han encontrado usuarios disponibles' + e,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: 'Ok'
            })
        }
    }

    const showABM = () => history.push("/Usuario");

    const cancelar = () => {

        setHiddenABM(true);
    }

    const finalizar = () => {
        swal.fire({
            text: 'Se han guardado los cambios con éxito',
            ...successSwal
        })
            .then(() => {
                cargaInicialUsuarios();
                setHiddenABM(true);
            });
    }

    const editClick = (idUsuario) => history.push(`/Usuario/${idUsuario}`);

    const removeClick = (usuario) => {
        const objUsuario = { Id: usuario[0], NombreUsuario: usuario[1] };

        swal.fire({
            title: 'Eliminar usuario',
            text: '¿Está seguro que desea eliminar el usuario ' + objUsuario.NombreUsuario + '?',
            ...confirmSwal
        })
            .then((confirm) => {
                if (confirm.value) {
                    deleteUsuario(objUsuario);
                }
            });
    }

    const deleteUsuario = (usuario) => {
        swal.fire({
            title: 'Eliminar usuario',
            text: '¿Está seguro que desea eliminar el usuario  ' + handleFormatName(usuario.DS_Nombre) + '?',
            ...confirmSwal
        })
        .then((confirm) => {
            if (confirm.value) {
                DeleteUser(usuario)
                    .then(() => {
                        swal.fire({
                            text: 'Se ha eliminado el usuario con éxito',
                            ...successSwal
                        })
                            .then(() => {
                                cargaInicialUsuarios();
                            });
                    });
            }
        });
    }

    const handleFormatName = (name) => {
        name = name.split(", ");

        return name[1] + " " + name[0].charAt(0) + name[0].slice(1).toLowerCase()
    }

    const handleFormatImage = (image) => {
        return image ? image : UserDefault
    }

    const handleSearch = (e) => {
        const search = e ? e.target.value.toLowerCase().trim() : document.getElementById("searchInput").value.toLowerCase().trim();
        const keys = ["DS_Nombre", "DS_NombreUsuario", "DS_Email", "DS_NombreRol"];
        const newRows = [];

        if(search.length >= 2) {
            for(var i = 0; i < usuarios.length; i++) {
                let bool;
                let row = usuarios[i];
                for(var index = 0; index < keys.length; index++) {
                    let value = row[keys[index]];
                    if(value) {
                        value = value.toLowerCase();
                        bool = index == 0 ? value.indexOf(search) != -1 : bool || value.indexOf(search) != -1;
                    }
                }
                row.show = bool;
                newRows.push(row);
            }
        } else {
            for(var i = 0; i < usuarios.length; i++) {
                usuarios[i].show = true;
                newRows.push(usuarios[i]);
            }
        }
        setPage(0)
        setUsuarios(newRows)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    return (
        <div className="main-content">
            <br />
            <h1 className="title">Usuarios</h1>
            
            <div className="content">
                <div className="row form-group">
                    <div className="col-md-4">
                        <br/>
                        <div className="search-box">
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="type" name="search" id="searchInput" placeholder="Buscar usuario" onKeyUp={handleSearch} />
                        </div>
                        <br/>
                    </div>
                    <div className="col-md-8">
                        <br/>
                        <div className="pull-right">
                            <Button variant="contained" className="btnAdd" color="primary" startIcon={<Add />} onClick={showABM} hidden={!rolMaster}>Nuevo</Button>
                        </div>
                        <br/>
                    </div>
                </div>

                <div className="row form-group">
                    {(rowsPerPage > 0 ? filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredItems).map((user, index) => (
                        <div className="col-sm-6 col-md-4 col-lg-3" key={index + "-" + user.Id}>
                            <div className="user-box">
                                <div className="img" style={{backgroundImage: "url(" + handleFormatImage(user.base64Image) + ")", backgroundSize: user.base64Image ? 'cover' : '75%'}}></div>
                                <div className="info">
                                    <p className="name">{handleFormatName(user.DS_Nombre)}</p>
                                    <p className="rol">{user.DS_NombreRol.charAt(0).toUpperCase() + user.DS_NombreRol.slice(1).toLowerCase()}</p>
                                </div>
                                <div className="bottom" hidden={!rolMaster}>
                                    <LightTooltip title="Eliminar usuario" placement="top">
                                        <Delete className="delete-user" onClick={() => deleteUsuario(user)} />
                                    </LightTooltip>
                                    <div className="details" onClick={() => editClick(user.Id)}>
                                        <span>VER</span>
                                        <ChevronRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {filteredItems.length > rowsPerPage ? <Pagination count={filteredItems.length} page={page} rowsPerPage={rowsPerPage} onChangePage={handleChangePage} /> : ""}
        </div>
    );
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        letterSpacing: 0,
    },
}))(Tooltip);

export default Usuario

