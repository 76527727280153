import Axios from 'axios';
import './axiosConfig';

const GETCIERREAEROPUERTOBYID_URL = '/CierreAeropuertos/CierreById';
const GETCIERREAEROPUERTOSBYAEROPUERTO_URL = '/CierreAeropuertos/CierresByAeropuerto';
const GETCIERREAEROPUERTOS_URL = '/CierreAeropuertos';
const DELETE_URL = '/CierreAeropuertos/Delete';
const SAVEORUPDATE = '/CierreAeropuertos/SaveOrUpdate';

export const GetCierreAeropuertoById = (id) => {
    return Axios.get(GETCIERREAEROPUERTOBYID_URL, { params: { id } });
}

export const GetCierreAeropuertos = () => {
    return Axios.get(GETCIERREAEROPUERTOS_URL);
}

export const GetCierresByAeropuerto = (idAeropuerto) => {
    return Axios.get(GETCIERREAEROPUERTOSBYAEROPUERTO_URL, { params: { idAeropuerto } });
}

export const DeleteCierreAeropuerto = (cierreAeropuerto) => {
    return Axios.post(DELETE_URL, cierreAeropuerto);
}

export const SaveOrUpdate = (cierreAeropuerto) => {
    return Axios.post(SAVEORUPDATE, cierreAeropuerto);
}