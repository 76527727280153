import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { GetEstacionamientoById, SaveOrUpdate, GetTerminales, DeleteServicio, DeleteDetallesServicio, GetServiciosByEstacionamiento } from '../../../services/estacionamientos.service';
import { GetMediosDePago, GetTipoMediosDePago } from '../../../services/mediosdepago.service';

import Select from 'react-select';
import swal from 'sweetalert2';
import { confirmSwal, successSwal  } from '../../../assets/resources/swal-config';
import { Button, FormControlLabel, Radio, Checkbox, Grid, Fab, IconButton, Divider, Tooltip} from '@material-ui/core';
import { ChevronRight, LocationOn, PlaylistAdd, AddPhotoAlternate, Delete, ArrowBack, Save, Cancel, Add } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import NoFoundImage from '../../../assets/img/not_found.png';
import './estacionamientosABM.css';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { useArancel } from '../hooks/useArancel';

const EstacionamientosABM = (props) => {
    const [estacionamiento, setEstacionamiento] = useState({
        id: 0,
        idIdioma: 'ES-AR',
        idAeropuerto: 0,
        dsEstacionamiento: '',
        idTerminal: 0,
        Arancelado: false,
        Reserva: false,
        largaEst: false,
        coCubierta: 0,
        coDescubierta: 0,
        coDiscapacitados: 0,
        dsUbicacion: '',
        dsDetalle: '',
        lkMapa: '',
        excDiscapacitados: false,
        dsDescDiscapacitado: '',
        mediosPago: false,
        lkQR: '',
        mcInactiva: false,
        Servicios: false,
        base64Mapa: '',
        base64QR: '',
        aranceles: [],
        arancelesColumnas: [],
        largaEstadia: [],
        mediosPagoHabilitados: [],
        servicios: []
    })
    const validationSchema = {
        id: { required: false, section: null },
        idIdioma: { required: false, section: null  },
        idAeropuerto: { required: false, section: "datos" },
        dsEstacionamiento: { required: true, section: "datos" },
        idTerminal: { required: false, section: "datos" },
        Arancelado: { required: false, section: "datos" },
        Reserva: { required: false, section: "datos" },
        largaEst: { required: false, section: "datos" },
        coCubierta: { required: true, section: "datos"  },
        coDescubierta: { required: true, section: "datos"  },
        coDiscapacitados: { required: true, section: "datos"  },
        dsUbicacion: { required: true, section: "datos"  },
        dsDetalle: { required: true, section: "datos"  },
        lkMapa: { required: false, section: "datos"  },
        excDiscapacitados: { required: false, section: "datos"  },
        dsDescDiscapacitado: { required: false, section: "datos"  },
        mediosPago: { required: false, section: "mediosPago"  },
        lkQR: { required: false, section: "mediosPago"  },
        mcInactiva: { required: false, section: null  },
        Servicios: { required: false, section: "servicios"  },
        base64Mapa: { required: false, section: null  },
        base64QR: { required: false, section: null  },
        aranceles: { required: false, section: "datos"  },
        arancelesColumnas: { required: false, section: "datos"  },
        largaEstadia: { required: false, section: "datos"  },
        mediosPagoHabilitados: { required: true, section: "mediosPago"  },
        servicios:  { required: true, section: "servicios"  }
    }

    const [confirm, setConfirm] = useState({
        datos: false,
        mediosPago: false,
        servicios: false
    });
    const [terminales, setTerminales] = useState([]);
    const [tiposMediosPago, setTipoMediosPago] = useState([]);
    const [mediosPago, setMediosPago] = useState([]);
    const [errors, setErrors] = useState({});
    const [editing, setEditing] = useState(false);
    const [mapa, setMapa] = useState({ srcImage: NoFoundImage, hiddenRemoveImage: true });
    const [qr, setQr] = useState({ srcImage: NoFoundImage, hiddenRemoveImage: true });
    const tiposMediosPagoFilter = tiposMediosPago.filter(tipo => { return tipo.dsTipoMedioPago != 'Otros' });

    const columnsMedioPago = parseInt(12 / tiposMediosPagoFilter.length);
    const otrosMediosDePago = mediosPago.filter(medioPago => { return tiposMediosPago.filter(tipo => { return tipo.dsTipoMedioPago === 'Otros' })[0].id === medioPago.idTipoMedio });
    const Id = props.match.params.id;
    const history = useHistory();

    const { 
        arancelesColumnas, 
        hiddenBtnAddColArancel, 
        hiddenBtnAddRowArancel,  
        setHiddenBtnAddRowArancel,
        setHiddenBtnAddColArancel,
        isFocusOut,
        isFocusOutCol,
        setInputName,
        setFocusOutCol,
        arancelesColumnasObj,
        setFocusOut
        } = useArancel()

    useEffect(() => {
        cargaInicial();
    }, [props]);
    //#region Metodos generales
    const cargaInicial = async() => {
        await mapTerminales(null);
        return await GetTipoMediosDePago()
            .then(async (resTipoMedioPago) => {
                if (resTipoMedioPago.data) {
                    setTipoMediosPago(resTipoMedioPago.data);
                    return await GetMediosDePago(false)
                }
            })
            .then((resMedioPago) => {
                if (resMedioPago.data) {
                    setMediosPago(resMedioPago.data);
                    const aranceles = [
                        { id: 0, idEstacionamiento: estacionamiento.id, dsArancel: "Moto", dsHora: 0},
                        { id: 1, idEstacionamiento: estacionamiento.id, dsArancel: "Auto", dsHora: 0},
                        { id: 2, idEstacionamiento: estacionamiento.id, dsArancel: "Camioneta/PickUp/Suv", dsHora: 0},
                        { id: 3, idEstacionamiento: estacionamiento.id, dsArancel: "Minibús", dsHora: 0},
                        { id: 4, idEstacionamiento: estacionamiento.id, dsArancel: "Bus", dsHora: 0}
                    ],
                    largaEst = [
                        { id: 0, idEstacionamiento: estacionamiento.id, dsPeriodo: 7, dsTarifa: 0 },
                        { id: 1, idEstacionamiento: estacionamiento.id, dsPeriodo: 14, dsTarifa: 0 },
                        { id: 2, idEstacionamiento: estacionamiento.id, dsPeriodo: 21, dsTarifa: 0 }
                    ],
                    
                    mediosHabilitados = getMediosHabilitados(resMedioPago?.data) 

                    if (Id > 0) {
                        getEstacionamientoById(aranceles, arancelesColumnas, largaEst, mediosHabilitados);
                    } else {
                        setEstacionamiento({ ...estacionamiento, idIdioma: props.idIdioma, idAeropuerto: props.idAeropuerto, aranceles, largaEstadia: largaEst, arancelesColumnas, mediosPagoHabilitados: mediosHabilitados })
                        setEditing(true);
                    }
                }
            })
            .catch( err => console.error(err))
    }

    const getMediosHabilitados = (resMedioPago) => {
        return resMedioPago.map( medioPago => {
           return {
               id: 0,
               idEstacionamiento: estacionamiento.id,
               idMedioPago : medioPago.id,
               habilitado: false
           }
       })
    }

    const mapTerminales = async(airpt) => {
        const terminales = await GetTerminales(airpt ? airpt : props.idAeropuerto)
        if(terminales?.data){
            let term = terminales.data.map(terminal => {
                return {
                    label: terminal.dsTerminal,
                    value: terminal.id
                }
            });
            setTerminales(term);
        }
    }


    const getEstacionamientoById = (aranceles, arancelesColumnas, largaEst, mediosHabilitados) => {
        GetEstacionamientoById(Id)
            .then((resEstacionamiento) => {
                const base64Mapa = resEstacionamiento.data.base64Mapa !== null ? resEstacionamiento.data.base64Mapa.toString().split(",", 2)[1] : null,
                    base64QR = resEstacionamiento.data.base64QR !== null ? resEstacionamiento.data.base64QR.toString().split(",", 2)[1] : null,
                    servicios = [];
                for (var i = 0; i < resEstacionamiento.data.servicios.length; i++){
                    var servicio = resEstacionamiento.data.servicios[i];
                    servicio["srcImage"] = servicio.base64Image;
                    servicio["base64Image"] = null;
                    servicios.push(servicio);
                }

                setEstacionamiento({ ...resEstacionamiento.data, base64Mapa: null, base64QR: null, servicios: servicios, 
                    aranceles: resEstacionamiento.data.aranceles?.filas?.length === 0 ? aranceles : arancelesFilas(resEstacionamiento.data.aranceles.filas), 
                    arancelesColumnas: resEstacionamiento.data.aranceles?.columnas?.length === 0 ? arancelesColumnas : arancelesColumnasObj(arancelesColumnas, resEstacionamiento.data.aranceles.columnas),
                    largaEstadia: resEstacionamiento.data.largaEstadia.length === 0 ? largaEst : resEstacionamiento.data.largaEstadia, 
                    mediosPagoHabilitados: resEstacionamiento.data.mediosPagoHabilitados.length === 0 ? mediosHabilitados : resEstacionamiento.data.mediosPagoHabilitados});
                setMapa({ ...mapa, name: resEstacionamiento.data.lkMapa, srcImage: resEstacionamiento.data.base64Mapa !== null ? resEstacionamiento.data.base64Mapa : NoFoundImage, hiddenRemoveImage: resEstacionamiento.data.base64Mapa !== null ? false : true })
                setQr({ ...qr, name: resEstacionamiento.data.lkQR, srcImage: resEstacionamiento.data.base64QR !== null ? resEstacionamiento.data.base64QR : NoFoundImage, hiddenRemoveImage: resEstacionamiento.data.base64QR !== null ? false : true })             
            })
            .catch(err => console.error(err));
    }

    const handleEditClick = () => {
        if (!editing) setEditing(true);
    }
    
    const handleInputFile = id => {
        const input = document.getElementById(id);
        if (input) input.click();
    }
    
    const validateImage = (event) => {
        var fileName = event.target.files[0].name;
        var fileSize = event.target.files[0].size;

        if (fileSize > 3000000) {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'La imagen no debe superar los 3MB',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
            event.target.value = '';
            return false;
        } else {
            var ext = fileName.split('.').pop();

            ext = ext.toLowerCase();

            if (event.target.id.indexOf("qr") !== -1 && (ext === "svg" || ext === "jpg" || ext === "jpeg" || ext === "png")) {
                return true;
            }

            switch (ext) {
                case 'svg':
                    return true;
                default:
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'La imagen no tiene la extensión adecuada',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                    event.target.value = '';
                    return false;
            }
        }
    }

    const confirmSection = section => {
        const con = confirm[section];
        if ((!con && isValid(section)) || con) {
            setConfirm({...confirm, [section]: !con})
        }
    }

   
    const isValid = (section) => {
        let valid = true;
        let errors = {};
        setErrors(errors)

        try {
            for (let key of Object.keys(estacionamiento)) {
                let value = estacionamiento[key]

                if(section && section === validationSchema[key].section){

                    if ((key === "coCubierta" || key === "coDescubierta" || key === "coDiscapacitados") && !value) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }

                    if (key === "dsDescDiscapacitado" && !value && estacionamiento.excDiscapacitados) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }
                    if (key === "mediosPagoHabilitados" && estacionamiento.mediosPago && estacionamiento.mediosPagoHabilitados.filter(m => { return !m.habilitado}).length === estacionamiento.mediosPagoHabilitados.length) {
                        errors[key] = 'Al menos uno de los medios de pago debe estar habilitado.';
                        setErrors(errors);
                        valid = false;
                    }

                    if (key === "servicios") {
                        if (estacionamiento.Servicios && estacionamiento.servicios.length === 0) {
                            errors[key] = 'Debe agregar al menos un servicio.';
                            setErrors(errors);
                            valid = false;
                        } else if (estacionamiento.Servicios && estacionamiento.servicios.filter(servicio => { return !servicio.dsServicio}).length !== 0) {
                            errors[key] = 'El tipo de servicio es requerido.';
                            setErrors(errors);
                            valid = false;
                        }

                        for (var i = 0; i < estacionamiento.servicios.length; i++){
                            if (estacionamiento.servicios[i].detalles.filter(detalle => { return !detalle.dsDetalle }).length !== 0) {
                                errors[key] = 'Los detalles del servicio agregado deben estar completados..';
                                setErrors(errors);
                                valid = false;
                            }
                        }
                    }

                    if (key === "idTerminal" && terminales.length > 0 && (!value || value === 0)) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }

                    if (validationSchema[key].required) {
                        if (!value) {
                            errors[key] = 'Requerido';
                            setErrors(errors);
                            valid = false;
                        }
                    }
                }
            }
        } catch (err) {
            console.error('Error', err);
        }
        return valid;
    }

    const handleSubmit = () => {
        const {id, idIdioma, idAeropuerto, dsEstacionamiento, idTerminal, Arancelado, Reserva, largaEst, coCubierta, coDescubierta, coDiscapacitados, dsUbicacion,
        dsDetalle, lkMapa, excDiscapacitados, dsDescDiscapacitado, mediosPago, lkQR, mcInactiva, Servicios, base64Mapa, base64QR, aranceles, arancelesColumnas, largaEstadia, mediosPagoHabilitados} = estacionamiento
        const arrayArancelesColumnas = setArancelesColumns(arancelesColumnas)
        const arrayArancelesFilas = setArancelesRows(aranceles, arancelesColumnas)
        console.log(arrayArancelesFilas)
                
        if (confirm.datos && confirm.servicios && (confirm.mediosPago || !estacionamiento.Arancelado)) {
            const est = {
                id,
                idIdioma,
                idAeropuerto,
                dsEstacionamiento,
                idTerminal,
                Arancelado,
                Reserva,
                largaEst,
                coCubierta,
                coDescubierta,
                coDiscapacitados,
                dsUbicacion,
                dsDetalle,
                lkMapa,
                excDiscapacitados,
                dsDescDiscapacitado,
                mediosPago,
                lkQR,
                mcInactiva,
                Servicios,
                base64Mapa,
                base64QR,
                aranceles : { 
                    columnas: arrayArancelesColumnas,
                    filas: arrayArancelesFilas
                },
                largaEstadia,
                mediosPagoHabilitados,
                servicios: []
            }
           
            for (var i = 0; i < estacionamiento.servicios.length; i++){
                const servicio = estacionamiento.servicios[i];
                delete servicio.srcImage;
                est.servicios.push(servicio);
            }


            SaveOrUpdate(est)
                .then(async (resSaveOrUpdate) => {
                    if (resSaveOrUpdate.data.ResultadoExitoso) {
                        swal.fire({
                            text: 'Se han guardado los cambios con éxito',
                            ...successSwal
                        }).then(() => { history.push('/Estacionamientos'); })
                    } else {
                        swal.fire({
                            title: 'Error al guardar los cambios',
                            icon: 'warning',
                            text: resSaveOrUpdate.data.MensajeError,
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                })
        } else {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'Debe realizar la confirmacion de las secciones.',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            })
        }
    }

    const handleCancelABMClick = () => { history.push('/Estacionamientos'); }

    //#endregion

    //#region Metodos Datos 

    const handleChange = ev => {
        setEstacionamiento({ ...estacionamiento, [ev.target.name]: ev.target.value });
    }

    const handleChangeCocheras = ev => {
        const isValid = ev.target.validity.valid;
        if (isValid) {
            setEstacionamiento({ ...estacionamiento, [ev.target.name]: ev.target.value });
        } else {
            ev.target.value= 0
        }
    }

    const handleEnableChange = (ev) => {
        if(editing){
            setEstacionamiento({ ...estacionamiento, [ev.target.name]: ev.target.checked })
        }
    }

    const handleAeropuertoValue = id => {
        const value = props.aeropuertos.filter(aero => aero.value === id);
        return value.length > 0 ? value[0].label : "";
    }

    const handleTerminalesValue = id => {
        const value = terminales.filter(terminal => terminal.value === id);
        return value.length > 0 ? value[0].label : "";
    }

    const handleAeropuertoChange = ev => {
        mapTerminales(ev.value);
        setEstacionamiento({ ...estacionamiento, idAeropuerto: ev.value });
    }

    const handleTerminalChange = ev => {
        setEstacionamiento({ ...estacionamiento, idTerminal: ev.value });
    }

    const handleArancelChange = (ev, index) => {
        const aranceles = [];
        for (var i = 0; i < estacionamiento.aranceles.length; i++){
            const arancel = estacionamiento.aranceles[i];
            arancel[ev.target.name] = i === index ? ev.target.value : arancel[ev.target.name];
            aranceles.push(arancel);
        }
        setEstacionamiento({ ...estacionamiento, aranceles });

    }

    const handleArancelChangeNumber = (ev, index) => {
        if(ev.target.value.includes('.')){
            let valueSplit1 = ev.target.value.split('.')[0]
            let valueSplit2 =  ev.target.value.split('.')[1]
            if(valueSplit2.length > 2){                  
                ev.target.value = valueSplit1 + '.' + valueSplit2.substring(0,2)
            }  
        }
        const aranceles = [];
        for (var i = 0; i < estacionamiento.aranceles.length; i++){
            const arancel = estacionamiento.aranceles[i];
            arancel[ev.target.name] = i === index ? ev.target.value : arancel[ev.target.name];
            aranceles.push(arancel);
        }
        setEstacionamiento({ ...estacionamiento, aranceles });
    }

    const handleLargaEstChange = (ev, index) => {
        if(ev.target.value.includes('.')){
            let valueSplit1 = ev.target.value.split('.')[0]
            let valueSplit2 =  ev.target.value.split('.')[1]
            if(valueSplit2.length > 2){                  
                ev.target.value = valueSplit1 + '.' + valueSplit2.substring(0,2)
            }  
        }
        if(ev.target.name === 'dsPeriodo' && ev.target.value.length > 3 ){
            ev.target.value = ev.target.value.substring(0,3) 
        }
            const largaEst = [];
            for (var i = 0; i < estacionamiento.largaEstadia.length; i++){
                const largaEstadia = estacionamiento.largaEstadia[i];
                largaEstadia[ev.target.name] = i === index ? ev.target.value : largaEstadia[ev.target.name];
                largaEst.push(largaEstadia);
            }
            setEstacionamiento({ ...estacionamiento, largaEstadia: largaEst });        
    }

    const handleUploadMapaClick = event => {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length && validateImage(event)) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                let img = new Image()
                img.src = reader.result;

                //VALIDA ANCHO Y LARGO DE LA IMAGEN
                img.onload = function () {
                    const nameFile = estacionamiento.dsEstacionamiento !== '' ? `${estacionamiento.dsEstacionamiento}-Mapa.${file.name.split('.').pop()}` : file.name;
                    const base64Image = reader.result.toString().split(",", 2)[1];
                    setEstacionamiento({ ...estacionamiento, lkMapa: nameFile, base64Mapa: base64Image })
                    setMapa({ ...mapa, srcImage: reader.result, hiddenRemoveImage: false });
                }
            };
        }
    }

    const removeMapa = () => {
        setEstacionamiento({ ...estacionamiento, lkMapa: null, base64Mapa: null })
        setMapa({ ...mapa, srcImage: NoFoundImage, hiddenRemoveImage: true });
    }

    //#endregion

    //#region  Metodos de Medios de Pago
    const handleEnableMedioPago = e => {
        if (!confirm.mediosPago) {
            const mediosHabilitados = [];
            estacionamiento.mediosPagoHabilitados.map(medio => {
                const m = medio;
                m.habilitado = parseInt(e.target.id) === m.idMedioPago ? e.target.checked : m.habilitado;
                mediosHabilitados.push(m);
            });
            const medios = mediosHabilitados.filter(m => m.habilitado).length > 0 ? true : false;
            setEstacionamiento({ ...estacionamiento, mediosPagoHabilitados: mediosHabilitados, mediosPago: medios });
        }
    }

    const handleUploadQRClick = event => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length && validateImage(event)) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                let img = new Image()
                img.src = reader.result;

                //VALIDA ANCHO Y LARGO DE LA IMAGEN
                img.onload = function () {
                    const nameFile = estacionamiento.dsEstacionamiento !== '' ? `${estacionamiento.dsEstacionamiento}-QR.${file.name.split('.').pop()}` : file.name;
                    const base64Image = reader.result.toString().split(",", 2)[1];

                    setEstacionamiento({ ...estacionamiento, lkQR: nameFile, base64QR: base64Image })
                    setQr({ ...qr, srcImage: reader.result, hiddenRemoveImage: false });
                }
            };
        }
    }

    const removeQR = () => {
        setEstacionamiento({ ...estacionamiento, lkQR: null, base64QR: null })
        setQr({ ...qr, srcImage: null, hiddenRemoveImage: true });
    }
    //#endregion

    //#region  Metodos de servicios
    const handleServicioChange = (ev, index) => {
        ev.preventDefault();
        const target = document.getElementById("dsServicio#" + index);
        const servicios = [];
        for (var i = 0; i < estacionamiento.servicios.length; i++){
            const servicio = estacionamiento.servicios[i];
            servicio.dsServicio = i === index ? target.value : servicio.dsServicio;
            servicios.push(servicio);
        }
        setEstacionamiento({ ...estacionamiento, servicios });
    }

    const handleAddServicioClick = () => {
        const servicios = [];

        estacionamiento.servicios.map(servicio => {
            servicios.push(servicio);
        })

        servicios.push({
            id: 0,
            idEstacionamiento: estacionamiento.id,
            dsServicio: "",
            lkImagen: null,
            base64Image: null,
            srcImage: null,
            detalles: [{id: 0, idServicio: 0, dsDetalle: ""}],
        });

        setEstacionamiento({ ...estacionamiento, servicios });
    }

    const handleDeleteServicioClick = index => {
        const item = estacionamiento.servicios[index];
        if (item.id !== 0 && estacionamiento.id !== 0) {
            swal.fire({
                title: 'Eliminar Servicio',
                text: '¿Está seguro que desea eliminar el servicio?',
                ...confirmSwal
            })
            .then(confirm => {
                if (confirm.value) {
                    DeleteServicio({ id: item.id })
                        .then(async(delServicio) => {
                            await getServicios();
                        })
                    }
            })
        } else {
            const servicios = [];
            for (var i = 0; i < estacionamiento.servicios.length; i++){
                const servicio = estacionamiento.servicios[i];
                if (i !== index) servicios.push(servicio);
                setEstacionamiento({ ...estacionamiento, servicios})
            }
        }
    }

    const handleDetalleChange = (ev, iServicio, iDetalle) => {
        ev.preventDefault();
        const target = document.getElementById("dsDetalle#" + iServicio + iDetalle);
        const servicios = [];
        for (var i = 0; i < estacionamiento.servicios.length; i++){
            const servicio = estacionamiento.servicios[i];
            if (i === iServicio) {
                const detalles = [];
                servicio.detalles.map((detalle, index) => {
                    detalle.dsDetalle = iDetalle === index ? target.value : detalle.dsDetalle;
                    detalles.push(detalle);
                })
                servicio.detalles = detalles;
            }
            servicios.push(servicio);
            setEstacionamiento({ ...estacionamiento, servicios });
        }
    }

    const handleAddDetalleClick = index => {
        const servicios = [];
        for (var i = 0; i < estacionamiento.servicios.length; i++){
            const servicio = estacionamiento.servicios[i];
            if (i === index) {
                const detalles = [];
                servicio.detalles.map(detalle => {
                    detalles.push(detalle);
                })
                
                detalles.push({
                    id: 0,
                    idServicio: servicio.id,
                    dsDetalle: ""
                })

                servicio.detalles = detalles;
            }
            servicios.push(servicio);
            setEstacionamiento({ ...estacionamiento, servicios });
        }
    }

    const handleDeleteDetalleClick = (iServicio, iDetalle) => {
        const item = estacionamiento.servicios[iServicio].detalles[iDetalle];
        if (item.id !== 0 && estacionamiento.id !== 0) {
            swal.fire({
                title: 'Eliminar detalle',
                text: '¿Está seguro que desea eliminar el detalle?',
                ...confirmSwal
            })
            .then(confirm => {
                    if (confirm.value) {
                        DeleteDetallesServicio({ id: item.id })
                            .then(async (delDetalle) => {
                                await getServicios();
                            })
                    }
            })
        } else {
            const servicios = [];
            for (var i = 0; i < estacionamiento.servicios.length; i++){
                const servicio = estacionamiento.servicios[i];
                if (i === iServicio) {
                    const detalles = [];
                    servicio.detalles.map((detalle, index) => {
                        if (index !== iDetalle) detalles.push(detalle);
                        console.log(detalles)
                    })
                    servicio.detalles = detalles;
                }
                servicios.push(servicio);
                setEstacionamiento({ ...estacionamiento, servicios})
            }
        }
    }

    const handleUploadServicio = (event, index) => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length && validateImage(event)) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                let img = new Image()
                img.src = reader.result;

                //VALIDA ANCHO Y LARGO DE LA IMAGEN
                img.onload = function () {
                    const base64Image = reader.result.toString().split(",", 2)[1];
                    const servicios = [];
                    for (var i = 0; i < estacionamiento.servicios.length; i++){
                        const servicio = estacionamiento.servicios[i];
                        if (i === index) {
                            servicio.lkImagen = (new Date().getTime()) + "_" + file.name;
                            servicio.base64Image = base64Image;
                            servicio.srcImage = reader.result;
                        }
                        servicios.push(servicio);
                        setEstacionamiento({ ...estacionamiento, servicios})
                    }
                }
            };
        }
    };

    const removeServicioImage = index => {
        const servicios = [];
        for (var i = 0; i < estacionamiento.servicios.length; i++){
            const servicio = estacionamiento.servicios[i];
            if (i === index) {
                servicio.lkImagen = null;
                servicio.base64Image = null;
                servicio.srcImage = null;
            }
            servicios.push(servicio);
            setEstacionamiento({ ...estacionamiento, servicios})
        }
    }

    const getServicios = async() => {
        await GetServiciosByEstacionamiento(estacionamiento.id)
            .then(async (resServicios) => {
                if (resServicios.data) {
                    const servicios = [];
                    for (var i = 0; i < resServicios.data.length; i++){
                        var servicio = resServicios.data[i];
                        servicio["srcImage"] = servicio.base64Image;
                        servicios.push(servicio);
                    }
                    setEstacionamiento({...estacionamiento, servicios})
                }
            })
    }

    //* Botonera Tabla Aranceles      
    const arancelAddRow = (index) => {
        if(index === 7 || isFocusOut) {
            setHiddenBtnAddRowArancel(true)
        }
        setFocusOut(false)
        setEstacionamiento({...estacionamiento,aranceles:  estacionamiento.aranceles.concat(
            { id: index++, idEstacionamiento: estacionamiento.id, dsArancel: "", dsHora: 0, dsEstadia: 0 },
        )})
    }
    
    const arancelRemoveVehicle = (arancelId, posicionRow) => {
        if(posicionRow < 8) {
            setHiddenBtnAddRowArancel(false)
        }
        setEstacionamiento({...estacionamiento,aranceles: estacionamiento.aranceles.filter(arancel => arancel.id !== arancelId)})
    }

    const arancelesFilas = (filas) => {
        
        return filas.map((fila,index) => {
          return {
            id: index,
            dsArancel: fila[0] ?? null,
            dsHora: fila[1] ?? null,
            dsEstadia0: fila[2] ?? null,
            dsEstadia1: fila[3] ?? null,
            dsEstadia2: fila[4] ?? null,
            dsEstadia3: fila[5] ?? null,
            dsEstadia4: fila[6] ?? null
          }
        })
    }

    const setArancelesColumns = columns => {
        //Se eliminan columnas por default
        columns.shift()
        columns.shift()
        columns.shift()
        // **************
        return columns.map(col => col.value)
    }

    const setArancelesRows = (rows) => {
        const filas = rows.map(row => (
          [
          	row.dsArancel ?? null,
          	row.dsHora ?? null,
          	row.dsEstadia0 ?? null,
          	row.dsEstadia1 ?? null,
          	row.dsEstadia2 ?? null,
          	row.dsEstadia3 ?? null,
          	row.dsEstadia4 ?? null
        ]))
        return filas.map(fila => fila.filter(posInterna => (posInterna !== null)))
    }
    const arancelAddCol = (columnLength, columnMax = 7) => {
        if(columnLength === columnMax){
            setHiddenBtnAddColArancel(true)
        }

        setHiddenBtnAddColArancel(true)

        setFocusOutCol(false)
        const {inputName, colName} = setInputName(columnLength)
        const newColumn = {id: columnLength, value: "", inputName, colName}
        setEstacionamiento({...estacionamiento, arancelesColumnas : [...estacionamiento.arancelesColumnas, newColumn]})
    }  

    const arancelRemoveCol = (columnSelected, posicionCol, columnMax = 8) => {
        if(posicionCol < columnMax) {
            setHiddenBtnAddColArancel(false)
        }
        const aranceles = eliminarFilaEstadia(estacionamiento.arancelesColumnas.length, estacionamiento.aranceles)
        setEstacionamiento({...estacionamiento, aranceles, arancelesColumnas : estacionamiento.arancelesColumnas.filter((column) => column.id !== columnSelected)})
    }

    const handleColumn = (e, index) => {
        const columns = []
         for (var i = 0; i < estacionamiento.arancelesColumnas.length; i++){
             const column = estacionamiento.arancelesColumnas[i];
             column[e.target.name] = i === index ? e.target.value : column[e.target.name];
             columns.push(column);
         }
         setEstacionamiento({...estacionamiento, arancelesColumnas: columns})
    }

    const eliminarFilaEstadia = (numColumna, filas) => {
        switch (numColumna) {
            case 4:
                return filas.map(row => {
                    if(row.dsEstadia0?.length > 0){
                        row.dsEstadia0 = null  
                    }
                    return row
                  })
            case 5:
                return filas.map(row => {
                    if(row.dsEstadia1?.length > 0){
                        row.dsEstadia1 = null  
                    }
                    return row
                  })
            case 6:
                return filas.map(row => {
                    if(row.dsEstadia2?.length > 0){
                        row.dsEstadia2 = null  
                    }
                    return row
                })
            case 7:
                return filas.map(row => {
                    if(row.dsEstadia3?.length > 0){
                        row.dsEstadia3 = null  
                    }
                    return row
                })
            case 8:
                return filas.map(row => {
                    if(row.dsEstadia4?.length > 0){
                        row.dsEstadia4 = null  
                    }
                    return row
                })
            default:
                break;
        }
    }
    
  //* Botonera Larga Estadía
  const [hiddenBtnAddRowLargaEst, setHiddenBtnAddRowLargaEst] = useState(false)  
  const [focusLS, setFocusLS] = useState(true)  
    const largaEstadiaAddRow = (index) => {
        if(index === 5 || focusLS){
            setHiddenBtnAddRowLargaEst(true)
        }
        
        let ultimaPos =estacionamiento?.largaEstadia[estacionamiento?.largaEstadia?.length -1]
        let id = 0;
        if(ultimaPos){
            id = ultimaPos.id;
        }
        setEstacionamiento({...estacionamiento, largaEstadia:  estacionamiento.largaEstadia.concat(
            { id: id + 1, idEstacionamiento: estacionamiento.id, dsPeriodo: 0, dsTarifa: 0 },
        )})
    }
    const largaEstRemoveRow = (largaEstId, posicionRow) => {
        if(posicionRow < 6){
            setHiddenBtnAddRowLargaEst(false)
        }
        setEstacionamiento({...estacionamiento, largaEstadia:  estacionamiento.largaEstadia.filter(largaEst => largaEst.id !== largaEstId )})
    }

    const isFocusLS = (e,index, maxRow= 4) => {
        if(e.target.value.length === 0) {  
            setFocusLS(false)
            setHiddenBtnAddRowLargaEst(false)
        }else if(index !== maxRow){
            setHiddenBtnAddRowLargaEst(false)
            setFocusLS(true)
        }else{
            setFocusLS(true)
        }
    }
    //#endregion

   
    return (
        <div className="main-content">
            <br />

            {Id > 0 ?
                <h1 className="title">Estacionamiento <b className="greentext">{estacionamiento.dsEstacionamiento}</b></h1>
                :
                <h1 className="title">Nuevo Estacionamiento</h1>
            }

            <br />
            <br />

            <div className="content">
                <div className="row form-group">
                    <div className="col-md-3 offset-md-9" hidden={Id === 0}>
                        <Button variant="outlined" className={`btnAdd ${editing ? 'd-none' : ''}`} color="primary" startIcon={<FontAwesomeIcon icon={faEdit} />} onClick={handleEditClick}>Editar</Button>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-12">
                        <div className="box">
                            
                            <div className="row form-group">
                                <div className="col-md-4">
                                    <div className="form-input">
                                        <label className="label">Aeropuerto</label>
                                        {editing && !confirm.datos ?
                                            <Select placeholder={<div>Seleccione</div>} isDisabled={estacionamiento.id > 0} options={props.aeropuertos} onChange={handleAeropuertoChange} value={props.aeropuertos.filter(obj => obj.value === estacionamiento.idAeropuerto)} />
                                            :
                                            <span className="text">{handleAeropuertoValue(estacionamiento.idAeropuerto)}</span>
                                        }
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-input">
                                        <label className="label">Nombre</label>
                                        {editing && !confirm.datos ?
                                            <input name="dsEstacionamiento" type="text" placeholder="ej. E1 Descubierto" defaultValue={estacionamiento.dsEstacionamiento} onChange={handleChange} className="form-control" />
                                            :
                                            <span className="text">{estacionamiento.dsEstacionamiento}</span>
                                        }
                                        {errors.dsEstacionamiento && (<p className="help is-danger">{errors.dsEstacionamiento}</p>)}
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-input" hidden={terminales.length === 0}>
                                        <label className="label">Terminal</label>
                                        {editing && !confirm.datos ?
                                            <Select placeholder={<div>Seleccione</div>} options={terminales} onChange={handleTerminalChange} value={terminales.filter(obj => obj.value === estacionamiento.idTerminal)} />
                                            :
                                            <span className="text">{handleTerminalesValue(estacionamiento.idTerminal)}</span>
                                        }
                                        {errors.idTerminal && (<p className="help is-danger">{errors.idTerminal}</p>)}
                                    </div>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-3">
                                    { editing ?
                                        <FormControlLabel control={<Radio name="Arancelado" checked={estacionamiento.Arancelado} onChange={() => { if (editing && !confirm.datos) setEstacionamiento({ ...estacionamiento, Arancelado: true, mediosPago: true }) }} />} label="Arancelado" />
                                        : <label className="label" hidden={!estacionamiento.Arancelado}>Arancelado</label>
                                    }
                                </div>

                                <div className="col-md-3">
                                    { editing ?
                                        <FormControlLabel control={<Radio name="Arancelado" checked={estacionamiento.Arancelado === false} onChange={() => { if(editing && !confirm.datos) setEstacionamiento({ ...estacionamiento, Arancelado: false, mediosPago: false })}} />} label="Sin Costo" />
                                        :
                                        <label className="label" hidden={estacionamiento.Arancelado}>Sin Costo</label>
                                    }
                                </div>

                                <div className="col-md-3">
                                    { editing ?
                                        <FormControlLabel control={<Checkbox checked={estacionamiento.Reserva} onChange={(e) => { if (editing && !confirm.datos) setEstacionamiento({ ...estacionamiento, Reserva: e.target.checked }) }} name="Reserva" />} label="Reservas" />
                                        :
                                        <label className="label" hidden={!estacionamiento.Reserva}>Acepta Reservas</label>
                                    }
                                </div>

                        
                            </div>

                            <div className="row form-group">
                                <div className={`col-md-12 aranceles${estacionamiento.Arancelado ? " show" : ""}`}>
                                    <table>
                                        <thead>
                                            <tr>
                                                {estacionamiento.arancelesColumnas.map((column, index) => (
                                                    <td key={index}>{
                                                        (index > 2 && ( editing && !confirm.datos) ) ? (
                                                            <div className="arancelNewColumn">
                                                                {(index === estacionamiento.arancelesColumnas.length -1) ? 
                                                                <Button 
                                                                    variant="outlined" 
                                                                    className={`btnAdd ${editing ? 'blue' : 'gray'}`} 
                                                                    color="primary" 
                                                                    style={{minWidth: '20px', padding: 0, marginBottom: '5px'}}
                                                                    onClick={() => arancelRemoveCol(column.id, index)} 
                                                                    >
                                                                    <FontAwesomeIcon icon={faMinus} />
                                                                </Button> : 
                                                                    <Button 
                                                                        variant="outlined" 
                                                                        disabled
                                                                        style={{minWidth: '20px', padding: 0, marginBottom: '5px'}}
                                                                        >
                                                                        <FontAwesomeIcon icon={faMinus} />
                                                                    </Button>
                                                                }
                                                                <input 
                                                                    type="text"
                                                                    name="value"
                                                                    placeholder="Hora, Estadía, etc" 
                                                                    className="form-control"
                                                                    defaultValue={column.value}
                                                                    style={{width: '100%'}} 
                                                                    onChange={e => handleColumn(e,index)} 
                                                                    onBlur={isFocusOutCol}
                                                                />
                                                            </div>
                                                            ) : column.value
                                                        }
                                                    </td>
                                                ))}
                                                <td>
                                                    {(estacionamiento.arancelesColumnas.length <= 7 && ( editing && !confirm.datos) ) ?<Button 
                                                        variant="outlined" 
                                                        className={`btnAdd ${editing ? 'blue' : 'gray'}`} 
                                                        color="primary" 
                                                        hidden={hiddenBtnAddColArancel}
                                                        style={{minWidth: '20px', padding: 0}}
                                                        onClick={() => arancelAddCol(estacionamiento.arancelesColumnas.length)}>
                                                              <Tooltip title="Agregar Nueva Columna" placement="bottom">
                                                                    <Add/>
                                                              </Tooltip>
                                                    </Button> : null}
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {estacionamiento.aranceles.map((arancel, index) => (
                                                <tr key={index}>
                                                    <th>
                                                    { editing && !confirm.datos ? 
                                                        <Button 
                                                            variant="outlined" 
                                                            className={`btnAdd ${editing ? 'blue' : 'gray'}`} 
                                                            color="primary" 
                                                            style={{minWidth: '20px', padding: 0}}
                                                            onClick={() => arancelRemoveVehicle(arancel.id, index)}>
                                                                <FontAwesomeIcon icon={faMinus} />
                                                        </Button> 
                                                    : null } 
                                                    </th>
                                                    <th> 
                                                        {                   
                                                         (editing && !confirm.datos) ? 
                                                             (
                                                                 <input 
                                                                 type="text" 
                                                                 name="dsArancel" 
                                                                 placeholder="Tipo de Vehiculo" 
                                                                 defaultValue={arancel.dsArancel} 
                                                                 onChange={e => handleArancelChange(e, index)} 
                                                                 className="form-control" 
                                                                 onBlur={(e) => isFocusOut(e, index)}
                                                                 />
                                                                ) : 
                                                                arancel.dsArancel
                                                        }
                                                    </th>
                                                    <th>
                                                        {editing && !confirm.datos ?
                                                            <input type='text' onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()} name="dsHora" placeholder="$" defaultValue={arancel.dsHora} onChange={e => handleArancelChangeNumber(e, index)} className="form-control" />
                                                            :
                                                            "$" + arancel.dsHora
                                                        }
                                                    </th>
                                                    {estacionamiento.arancelesColumnas.map((column, i) => (
                                                        (i > 2) && (
                                                        <th key={i}>{
                                                           ( editing && !confirm.datos) ?
                                                                <input 
                                                                    type='text' 
                                                                    onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
                                                                    name={column.inputName} 
                                                                    defaultValue={
                                                                        (i === 3) ? arancel?.dsEstadia0 : 
                                                                        (i === 4) ? arancel?.dsEstadia1 : 
                                                                        (i === 5) ? arancel?.dsEstadia2 :
                                                                        (i === 6) ? arancel?.dsEstadia3 :
                                                                        (i === 7) ? arancel?.dsEstadia4 : 0
                                                                    }
                                                                    placeholder={0} 
                                                                    onChange={e => handleArancelChangeNumber(e, index)} 
                                                                    className="form-control" 
                                                                /> : (
                                                                    (i === 3 && (arancel?.dsEstadia0 !== null && arancel?.dsEstadia0)) ? ("$" + arancel.dsEstadia0) :
                                                                    (i === 4 && (arancel?.dsEstadia1 !== null && arancel?.dsEstadia1)) ? ("$" + arancel?.dsEstadia1) :
                                                                    (i === 5 &&( arancel?.dsEstadia2 !== null && arancel?.dsEstadia2)) ? ("$" + arancel?.dsEstadia2) :
                                                                    (i === 6 && (arancel?.dsEstadia3 !== null && arancel?.dsEstadia3)) ? "$" + arancel.dsEstadia3 :
                                                                    (i === 7 && (arancel?.dsEstadia4 !== null && arancel?.dsEstadia4)) ? "$" + arancel.dsEstadia4 : "$ 0"
                                                                )
                                                            }</th>
                                                        ) 
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {(estacionamiento.aranceles.length <= 7 && (editing && !confirm.datos)) ?
                                        (<div className='addVehicleButton' hidden={hiddenBtnAddRowArancel}>
                                            <Button 
                                                variant="outlined" 
                                                className={`btnAdd ${editing ? 'blue' : 'gray'}`} 
                                                color="primary"
                                                style={{minWidth: '20px', padding: 0}} 
                                                onClick={() => arancelAddRow(estacionamiento.aranceles.length)}>
                                                <Add/>
                                            </Button>
                                            <span className="addVehicleSpan"> Agregar tipo de vehículo</span> 
                                        </div> )
                                        : null 
                                    }
                                                
                                </div>
                                           
                                <div className="col-md-12" style={{marginTop: '20px'}}>
                                    { editing ?
                                        <FormControlLabel control={<Checkbox checked={estacionamiento.largaEst} onChange={(e) => { if (editing && !confirm.datos) setEstacionamiento({ ...estacionamiento, largaEst: e.target.checked }) }} name="largaEst" />} label="Larga estadía" />
                                        :
                                        <label className="label" hidden={!estacionamiento.largaEst}>Larga estadía</label>
                                    }
                                </div>
                                <div className={`col-md-6 largaEstadias${estacionamiento.largaEst ? " show" : ""}`} style={{marginTop:'20px', maxWidth: '50%'}}>
                                <table>
                                        <thead>
                                            <tr>
                                            {editing && !confirm.datos ?
                                                <td></td> : null
                                            }
                                                <td>Período de</td>
                                                <td>Tarifa</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {estacionamiento.largaEstadia.map((largaEst, index) => (
                                                <tr key={index}>
                                                    {editing && !confirm.datos ?
                                                        <th style={{paddingLeft: 25}}>
                                                            <Button 
                                                                variant="outlined" 
                                                                className={`btnAdd ${editing ? 'blue' : 'gray'}`} 
                                                                color="primary" 
                                                                style={{minWidth: '20px', padding: 0}}
                                                                onClick={() => largaEstRemoveRow(largaEst.id, index)}>
                                                                <FontAwesomeIcon icon={faMinus} />
                                                            </Button>
                                                        </th>
                                                        :null
                                                    }
                                                    <th>
                                                        {editing && !confirm.datos ?
                                                            <input 
                                                                type='text' 
                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                name="dsPeriodo" 
                                                                defaultValue={largaEst.dsPeriodo} 
                                                                onChange={e => handleLargaEstChange(e, index)} 
                                                                className="form-control" 
                                                                onBlur={(e) => isFocusLS(e, index)}
                                                            />
                                                            :
                                                            <span className="periodo">{largaEst.dsPeriodo}</span>
                                                        }
                                                        <span className="dias"> días</span>
                                                    </th>
                                                    <th>
                                                        {editing  && !confirm.datos ?
                                                            <input   type='text' 
                                                            onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()} name="dsTarifa" placeholder="$" defaultValue={largaEst.dsTarifa} onChange={e => handleLargaEstChange(e, index)} className="form-control"/>
                                                            :
                                                            "$" + largaEst.dsTarifa
                                                        }
                                                    </th>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {(estacionamiento.largaEstadia.length <= 5 && (editing  && !confirm.datos)) ?
                                       ( <div className='addLargaEstadiaButton' hidden={hiddenBtnAddRowLargaEst}>
                                            <Button 
                                                variant="outlined" 
                                                className={`btnAdd ${editing ? 'blue' : 'gray'}`} 
                                                color="primary"
                                                style={{minWidth: '20px', padding: 0}} 
                                                onClick={() => largaEstadiaAddRow(estacionamiento.largaEstadia.length)}
                                                >
                                                <Add/>
                                            </Button>
                                            <span className="addLargaEstadiaSpan"> Agregar período</span> 
                                        </div> )
                                        : null
                                    }
                                </div>
                            </div>
                            <Divider/>
                            <div className="row form-group">
                                <div className="col-sm-12">
                                    <br />
                                    <div className="title-section">
                                        <Cochera />
                                        <h3>Cantidad de Cocheras disponibles</h3>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="cochera">
                                        <label className="label">Cubiertas</label>
                                        {editing && !confirm.datos ?
                                            <input type="number" name="coCubierta" min={0} defaultValue={estacionamiento.coCubierta} onChange={handleChangeCocheras} className="form-control" />
                                            :
                                            <span className="greenText">{estacionamiento.coCubierta}</span>
                                        }
                                    </div>
                                    {errors.coCubierta && (<p className="help is-danger">{errors.coCubierta}</p>)}
                                </div>

                                <div className="col-md-4">
                                    <div className="cochera">
                                        <label className="label">Descubiertas</label>
                                        {editing && !confirm.datos ?
                                            <input type="number" min={0} name="coDescubierta" defaultValue={estacionamiento.coDescubierta} onChange={handleChangeCocheras} className="form-control" />
                                            :
                                            <span className="greenText">{estacionamiento.coDescubierta}</span>
                                        }
                                    </div>
                                    {errors.coDescubierta && (<p className="help is-danger">{errors.coDescubierta}</p>)}
                                </div>

                                <div className="col-md-4">
                                    <div className="cochera">
                                        <label className="label">Reservadas para discapacitados</label>
                                        {editing && !confirm.datos ?
                                            <input type="number" min={0} name="coDiscapacitados" defaultValue={estacionamiento.coDiscapacitados} onChange={handleChangeCocheras} className="form-control" />
                                            :
                                            <span className="greenText">{estacionamiento.coDiscapacitados}</span>
                                        }
                                    </div>
                                    {errors.coDiscapacitados && (<p className="help is-danger">{errors.coDiscapacitados}</p>)}
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-sm-12">
                                    <br />
                                    <div className="title-section">
                                        <LocationOn />
                                        <h3>Ubicación dentro del Aeropuerto</h3>
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <br/>
                                    {editing && !confirm.datos ?
                                        <div className="form-input">
                                            <textarea name="dsUbicacion" placeholder="ej. Sector A, Arribos Internacionales" defaultValue={estacionamiento.dsUbicacion} onChange={handleChange} className="form-control"/>
                                            <br />
                                        </div>
                                        :
                                        <span className="text ubicacion">{estacionamiento.dsUbicacion}</span>
                                    }
                                    {errors.dsUbicacion && (<p className="help is-danger">{errors.dsUbicacion}</p>)}
                                </div>

                                <div className="col-md-6">
                                    <br/>
                                    <div className="title-section">
                                        <h3>Subir mapa</h3>
                                        <span className="greentext">archivo (svg)</span>
                                    </div>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <img className="abmImage" alt="complex" src={mapa.srcImage} />
                                        </Grid>
                                        <Grid item hidden={!editing || confirm.datos}>
                                            {mapa.hiddenRemoveImage ? <Fragment>
                                                <input type="file" accept="image/*" id="contained-button-mapa" onChange={handleUploadMapaClick} />
                                                <label htmlFor="contained-button-file1">
                                                    <Fab component="span" color="primary" >
                                                        <AddPhotoAlternate onClick={() => handleInputFile("contained-button-mapa")} />
                                                    </Fab>
                                                </label>
                                            </Fragment> : ""}
                                            <div className="abmImageDel">
                                                {!mapa.hiddenRemoveImage ? <IconButton color="secondary" aria-label="delete" component="span" onClick={removeMapa}>
                                                    <Delete />
                                                </IconButton> : ""}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div className="col-md-6">
                                    <div className="title-section">
                                        <PlaylistAdd />
                                        <h3>Detalles del estacionamiento</h3>
                                    </div>
                                    {editing && !confirm.datos ?
                                        <div className="form-input">
                                            <textarea name="dsDetalle" rows="5" placeholder="e.: 4 niveles, ascensor y escaleras de emergencia en casa extremo." defaultValue={estacionamiento.dsDetalle} onChange={handleChange} className="form-control"/>
                                            <br />
                                        </div>
                                        :
                                        <span className="text ubicacion">{estacionamiento.dsDetalle}</span>
                                    }
                                    {errors.dsDetalle && (<p className="help is-danger">{errors.dsDetalle}</p>)}
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-sm-12">
                                    <br />
                                    <div className="title-section">
                                        <TarifasDisc />
                                        <h3>Exención de tarifas para personas con discapacidad diferente</h3>

                                        {!editing || confirm.datos ?
                                            <span className={estacionamiento.excDiscapacitados ? "enable" : "disabled"}>{estacionamiento.excDiscapacitados ? "Habilitada" : "No Habilitada"}</span>
                                            :
                                            <div className="active-switch">
                                                <label className="label">Habilitar</label>
                                                <input type="checkbox" id="excDiscapacitados" name="excDiscapacitados" checked={estacionamiento.excDiscapacitados} onChange={(e) => handleEnableChange(e)} />
                                                <label htmlFor="excDiscapacitados" className="switch"></label>
                                            </div>
                                        }
                                    </div>
                                </div>

                             { estacionamiento.excDiscapacitados ?   <div className="col-md-12">
                                    <br />
                                    <label className="label">Descripción</label>
                                    {editing && !confirm.datos ?
                                        <div className="form-input">
                                            <textarea rows="5" name="dsDescDiscapacitado" placeholder="ej. Debe presentar el certificado de discapacidad." defaultValue={estacionamiento.dsDescDiscapacitado} onChange={handleChange} className="form-control"/>
                                            <br />
                                        </div>
                                        :
                                        <span className="text ubicacion">{estacionamiento.dsDescDiscapacitado}</span>
                                    }
                                    {errors.dsDescDiscapacitado && (<p className="help is-danger">{errors.dsDescDiscapacitado}</p>)}
                                </div> : null}
                            </div>

                            <div className="row form-group">
                                {editing ?
                                    <div className="col-md-12">
                                        <br />
                                        <br />
                                        <br />
                                        <Button 
                                            variant="outlined"
                                            className="btnAdd" 
                                            color="primary" 
                                            onClick={()=> confirmSection("datos")}>
                                                { confirm.datos ? "Editar" : "Confirmar"}
                                                </Button>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                    :
                                    <div className="col-md-12">
                                        <br />
                                        <br />
                                    </div>
                                }
                            </div>

                            <Divider hidden={!estacionamiento.Arancelado}/>

                            <div className="row form-group" hidden={!estacionamiento.Arancelado}>
                                <div className="col-sm-12">
                                    <br />
                                    <div className="title-section">
                                        <MediosDePago />
                                        <h3>Medios de pago</h3>

                                        {!editing || confirm.mediosPago ?
                                            <span className={estacionamiento.mediosPago ? "enable" : "disabled"}>{estacionamiento.mediosPago ? "Habilitada" : "No Habilitada"}</span>
                                            :
                                            <div className="active-switch">
                                                <label className="label">Habilitar</label>
                                                <input type="checkbox" id="mediosPago" name="mediosPago" checked={estacionamiento.mediosPago} onChange={(e) => handleEnableChange(e)} />
                                                <label htmlFor="mediosPago" className="switch"></label>
                                            </div>
                                        }
                                    </div>
                                    <br/>
                                </div>
                                {
                                    tiposMediosPagoFilter.map((tipo, index) => (
                                        <div className={`col-md-${columnsMedioPago}`} key={tipo.id}>
                                            <label className="label medioPago">{tipo.dsTipoMedioPago}</label>
                                            {(mediosPago.filter(medio => (medio.idTipoMedio === tipo.id ))).map((medioTipo, index) => (
                                                <MedioPago key={medioTipo.id + "-" + medioTipo.dsMedioPago} image={medioTipo.base64Image} img={medioTipo.lkImagen} id={medioTipo.id} medioPago={medioTipo.dsMedioPago} visible={editing && !confirm.mediosPago} handleEnableMedioPago={handleEnableMedioPago} medioHabilitado={estacionamiento.mediosPagoHabilitados.filter(medioPago => { return medioPago.idMedioPago === medioTipo.id })} isEnabledMedioPago={estacionamiento.mediosPago}/>
                                            ))}
                                        </div>
                                    ))
                                }
                               
                            </div>

                            <div className="row form-group" hidden={!estacionamiento.Arancelado}>
                                {otrosMediosDePago.map((medioPago, index) => (
                                    <div className={`col-md-${parseInt(12 / otrosMediosDePago.length)}`} key={medioPago.id + "-" + medioPago.dsMedioPago}>
                                        <label className="label medioPago">{medioPago.dsMedioPago}</label>
                                        <MedioPago image={medioPago.base64Image} id={medioPago.id} medioPago={medioPago.dsMedioPago} visible={editing && !confirm.mediosPago} handleEnableMedioPago={handleEnableMedioPago} medioHabilitado={estacionamiento.mediosPagoHabilitados.filter(mPago => { return mPago.idMedioPago === medioPago.id })} isEnabledMedioPago={estacionamiento.mediosPago} />
                                        {medioPago.dsMedioPago.indexOf("QR") != -1 && editing && !confirm.mediosPago ?
                                            <div className="input-check">
                                                <input type="file" accept="image/*" id="contained-button-qr" hidden onChange={handleUploadQRClick} />
                                                <input name="lkQR" type="text" placeholder="ej. Url del QR o subir el archivo" defaultValue={estacionamiento.lkQR} className="form-control" />
                                                <div className="valid-box">
                                                    {qr.hiddenRemoveImage ?
                                                        <ChevronRight className="check" onClick={() => handleInputFile("contained-button-qr")} />
                                                        :
                                                        <Delete className="wrong" onClick={removeQR} />
                                                    }
                                                </div>
                                            </div>
                                            :
                                            ""
                                        }
                                    </div>
                                ))}
                                {errors.mediosPagoHabilitados && (<p className="help is-danger">{errors.mediosPagoHabilitados}</p>)}
                            </div>

                            <div className="row form-group">
                                {!estacionamiento.Arancelado || !editing ?
                                    <div className="col-md-12">
                                        <br />
                                        <br />
                                    </div>
                                    :
                                    <div className="col-md-12">
                                        <br />
                                        <br />
                                        <br />
                                        <Button variant="outlined" className="btnAdd" color="primary" onClick={()=> confirmSection("mediosPago")}>{confirm.mediosPago ? "Editar" : "Confirmar"}</Button>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                }
                            </div>

                            <Divider />

                            <div className="row form-group">
                                <div className="col-sm-12">
                                    <br />
                                    <div className="title-section">
                                        <Servicios />
                                        <h3>Servicios Adicionales</h3>

                                        {!editing || confirm.servicios ?
                                            <span className={estacionamiento.Servicios ? "enable" : "disabled"}>{estacionamiento.Servicios ? "Habilitada" : "No Habilitada"}</span>
                                            :
                                            <div className="active-switch">
                                                <label className="label">Habilitar</label>
                                                <input type="checkbox" id="Servicios" name="Servicios" checked={estacionamiento.Servicios} onChange={(e) => handleEnableChange(e)} />
                                                <label htmlFor="Servicios" className="switch"></label>
                                            </div>
                                        }
                                    </div>
                                </div>

                              {  estacionamiento.Servicios ? <div className="col-sm-12">
                                    <table className="estacionamiento-grid">
                                        <thead>
                                            <tr>
                                                <td>Tipo de servicio</td>
                                                <td>Detalle del servicio</td>
                                                <td>Icono</td>
                                                <td hidden={!editing || confirm.servicios}></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {estacionamiento.servicios.map((servicio, index) => (
                                                <tr key={"servicio-" + index}>
                                                    <th>
                                                        {editing && !confirm.servicios ?
                                                            <input type="text" name="dsServicio" id={"dsServicio#" + index} placeholder="ej. Valet Parking" value={servicio.dsServicio} onChange={e => handleServicioChange(e, index)} className="form-control" />
                                                            :
                                                            servicio.dsServicio
                                                        }
                                                    </th>
                                                    <th>
                                                        <ul>
                                                            {servicio.detalles.map((detalle, i) => (
                                                                <li key={index + "" + i} id={index + "" + i}>
                                                                    {editing && !confirm.servicios ?
                                                                        <textarea name="dsDetalle" id={"dsDetalle#" + index + i} placeholder="ej. Punto de entrega en PB." value={detalle.dsDetalle} onChange={e => handleDetalleChange(e, index, i)} className="form-control" />
                                                                        :
                                                                        detalle.dsDetalle
                                                                    }
                                                                </li>
                                                            ))}

                                                            <li hidden={!editing || confirm.servicios}>
                                                                <span className="link" onClick={() => handleAddDetalleClick(index)}>Agregar detalle</span>
                                                            </li>
                                                        </ul>
                                                    </th>
                                                    <th>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                {!servicio.srcImage ?
                                                                    <div className="icon-text" hidden={!editing || confirm.servicios}>
                                                                        <strong>Subir icono</strong>
                                                                        <p>(Archivo SVG 60px)</p>
                                                                    </div>
                                                                    :
                                                                    <img className="abmImage" alt="ss" src={servicio.srcImage} />
                                                                }
                                                            </Grid>
                                                            <Grid item hidden={!editing || confirm.servicios}>
                                                                {!servicio.srcImage ?
                                                                    <Fragment>
                                                                        <input type="file" accept="image/*" id={`file-button-${servicio.id}`} onChange={e => handleUploadServicio(e, index)} />
                                                                        <label htmlFor={`file-button-${servicio.id}`}>
                                                                            <Fab component="span" color="primary" >
                                                                                <AddPhotoAlternate />
                                                                            </Fab>
                                                                        </label>
                                                                    </Fragment>
                                                                    :
                                                                    <div className="abmImageDel">
                                                                        <IconButton color="secondary" aria-label="delete" component="span" onClick={e => removeServicioImage(index)}>
                                                                            <Delete/>
                                                                        </IconButton>
                                                                    </div>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </th>
                                                    <th hidden={!editing || confirm.servicios}>
                                                        <Delete className="remove"  onClick={() => handleDeleteServicioClick(index)}/>
                                                    </th>
                                                </tr>
                                            ))}
                                            <tr hidden={!editing || confirm.servicios}>
                                                <th></th>
                                                <th colSpan="3">
                                                    <span className="link" onClick={handleAddServicioClick}><Add/>Agregar servicio</span>
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {errors.servicios && (<p className="help is-danger">{errors.servicios}</p>)}
                                </div> : null}
                            </div>

                            <div className="row form-group">
                                {editing ?
                                    <div className="col-md-12">
                                        <br />
                                        <br />
                                        <br />
                                        <Button variant="outlined" className="btnAdd" color="primary" onClick={()=> confirmSection("servicios")}>{confirm.servicios ? "Editar" : "Confirmar"}</Button>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                    :
                                    <div className="col-md-12">
                                        <br />
                                        <br />
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="form-row">
                            {editing ?
                                <div>
                                    <Button variant="contained" color="primary" className="button" onClick={handleSubmit} startIcon={<Save />}>Guardar</Button>
                                    <Button variant="contained" startIcon={<Cancel />} className="button" onClick={() => handleCancelABMClick()} >Cancelar</Button>
                                </div>
                                :
                                <Button variant="contained" color="primary" className="button" onClick={() => handleCancelABMClick()} startIcon={<ArrowBack />}>Volver</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const MedioPago = (props) => {
    const { image, img, id, medioPago, visible, handleEnableMedioPago, medioHabilitado,isEnabledMedioPago } = props;
    const [check, setCheck] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        let chk = medioHabilitado.length > 0 ? medioHabilitado[0].habilitado : false;
        setHidden(!chk && !visible)
        if(!isEnabledMedioPago) {
            chk = false;
        }
        setCheck(chk);
        setIsDisabled(!isEnabledMedioPago)
    });

    const handleCheckBox = e => {
        handleEnableMedioPago(e);
    }

    return (
        <div className="medioDePago" hidden={hidden}>
            <div className="active-switch" hidden={!visible}>
                <input type="checkbox" id={id} checked={check} onChange={handleCheckBox} disabled={isDisabled}/>
                <label htmlFor={id} className="switch"></label>
            </div>
            { image ?
                <div className="image" style={{backgroundImage: "url(" + image + ")"}} ></div>
            :
                <span>{medioPago}</span>
            }
        </div>
    );
}

const Cochera = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="surface1" width="32" height="32" viewBox="0 0 38.25 38.25">
            <path id="Trazado_183" data-name="Trazado 183" d="M9.525,31.5H6.537A6.544,6.544,0,0,0,0,38.037V62.69a2.8,2.8,0,0,0,2.8,2.8H13.26a2.8,2.8,0,0,0,2.8-2.8V38.037A6.544,6.544,0,0,0,9.525,31.5Zm-3.6,1.156a5.573,5.573,0,0,1-4.768,4.768A5.425,5.425,0,0,1,5.924,32.656ZM1.121,47.3a6.869,6.869,0,0,1,.622-2.865l1.473,2.1a1.668,1.668,0,0,0-.228.843v8.218a1.672,1.672,0,0,0,.16.715l-1.71,2.434a1.671,1.671,0,0,1-.318-.982Zm3.549,9.973h6.724a1.668,1.668,0,0,0,.716-.161L13.7,59.381a1.674,1.674,0,0,1-.442.06H2.8a1.674,1.674,0,0,1-.442-.06l1.594-2.268A1.668,1.668,0,0,0,4.669,57.274Zm-.56-1.681V47.375a.561.561,0,0,1,.56-.56h6.724a.561.561,0,0,1,.56.56v8.218a.561.561,0,0,1-.56.56H4.669A.561.561,0,0,1,4.109,55.593Zm7.879-9.79a1.675,1.675,0,0,0-.6-.109H4.669a1.674,1.674,0,0,0-.6.109L2.357,43.361a6.9,6.9,0,0,1,11.347,0ZM14.941,62.69a1.683,1.683,0,0,1-1.681,1.681H2.8A1.683,1.683,0,0,1,1.121,62.69V60a2.787,2.787,0,0,0,1.681.562H13.26A2.787,2.787,0,0,0,14.941,60Zm0-4.931a1.67,1.67,0,0,1-.318.982l-1.71-2.433a1.671,1.671,0,0,0,.16-.715V47.375a1.668,1.668,0,0,0-.228-.843l1.473-2.1a6.868,6.868,0,0,1,.622,2.865Zm0-14.546a8.026,8.026,0,0,0-13.821,0V38.557a6.692,6.692,0,0,0,5.936-5.936H9.229a6.695,6.695,0,0,0,5.712,5.908ZM10.367,32.687a5.427,5.427,0,0,1,4.534,4.7A5.626,5.626,0,0,1,10.367,32.687Zm0,0" transform="translate(0 -29.147)"/>
            <path id="Trazado_184" data-name="Trazado 184" d="M306.525,31.5h-2.988A6.544,6.544,0,0,0,297,38.037V62.69a2.8,2.8,0,0,0,2.8,2.8H310.26a2.8,2.8,0,0,0,2.8-2.8V38.037A6.544,6.544,0,0,0,306.525,31.5Zm5.376,5.887a5.626,5.626,0,0,1-4.534-4.7A5.427,5.427,0,0,1,311.9,37.387Zm-8.977-4.73a5.573,5.573,0,0,1-4.768,4.768A5.425,5.425,0,0,1,302.924,32.656Zm-4.8,14.644a6.868,6.868,0,0,1,.622-2.865l1.473,2.1a1.667,1.667,0,0,0-.228.843v8.218a1.671,1.671,0,0,0,.16.715l-1.71,2.434a1.671,1.671,0,0,1-.318-.982Zm3.549,9.973h6.724a1.668,1.668,0,0,0,.716-.161l1.594,2.268a1.674,1.674,0,0,1-.442.06H299.8a1.675,1.675,0,0,1-.442-.06l1.594-2.268A1.668,1.668,0,0,0,301.669,57.274Zm-.56-1.681V47.375a.561.561,0,0,1,.56-.56h6.724a.561.561,0,0,1,.56.56v8.218a.561.561,0,0,1-.56.56h-6.724A.561.561,0,0,1,301.109,55.593Zm7.879-9.79a1.675,1.675,0,0,0-.6-.109h-6.724a1.675,1.675,0,0,0-.6.109l-1.716-2.443a6.9,6.9,0,0,1,11.347,0Zm2.953,16.887a1.683,1.683,0,0,1-1.681,1.681H299.8a1.683,1.683,0,0,1-1.681-1.681V60a2.787,2.787,0,0,0,1.681.562H310.26A2.787,2.787,0,0,0,311.941,60Zm0-4.931a1.67,1.67,0,0,1-.318.982l-1.71-2.433a1.671,1.671,0,0,0,.16-.715V47.375a1.667,1.667,0,0,0-.228-.843l1.473-2.1a6.868,6.868,0,0,1,.622,2.865Zm0-14.546a8.026,8.026,0,0,0-13.821,0V38.557a6.692,6.692,0,0,0,5.936-5.936h2.172a6.695,6.695,0,0,0,5.712,5.908Zm0,0" transform="translate(-274.812 -29.147)"/>
            <path id="Trazado_185" data-name="Trazado 185" d="M249,497h1.121v1.121H249Zm0,0" transform="translate(-230.398 -459.871)"/>
            <path id="Trazado_186" data-name="Trazado 186" d="M249,30h1.121V63.768H249Zm0,0" transform="translate(-230.398 -27.759)"/>
            <path id="Trazado_187" data-name="Trazado 187" d="M249,0h1.121V1.121H249Zm0,0" transform="translate(-230.398)"/>
            <path id="Trazado_188" data-name="Trazado 188" d="M29.5,441.5h1.121v1.121H29.5Zm0,0" transform="translate(-27.296 -408.517)"/>
            <path id="Trazado_189" data-name="Trazado 189" d="M170.5,441.5h1.121v1.121H170.5Zm0,0" transform="translate(-157.762 -408.517)"/>
            <path id="Trazado_190" data-name="Trazado 190" d="M328.5,441.5h1.121v1.121H328.5Zm0,0" transform="translate(-303.959 -408.517)"/>
            <path id="Trazado_191" data-name="Trazado 191" d="M469.5,441.5h1.121v1.121H469.5Zm0,0" transform="translate(-434.425 -408.517)"/>
        </svg>
    )
}

const TarifasDisc = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="disabled" width="32" height="32" viewBox="0 0 24.968 30.643">
            <g id="Grupo_93" data-name="Grupo 93" transform="translate(0 0)">
                <path id="Trazado_82" data-name="Trazado 82" d="M17.647,20.329A6.356,6.356,0,1,1,7.5,17.05l-.641-2.392a8.667,8.667,0,1,0,13.31,7.222l-3.311-3.34A6.324,6.324,0,0,1,17.647,20.329Z" transform="translate(-2.837 0)"/>
                <circle id="Elipse_26" data-name="Elipse 26" cx="3.179" cy="3.179" r="3.179" transform="translate(1.651)"/>
                <path id="Trazado_83" data-name="Trazado 83" d="M27.755,21.209a1.444,1.444,0,0,0-1.771-1.021l-1.423.381-5.969-6.022a1.443,1.443,0,0,0-1.4-.379l-5.861,1.57-.373-1.39,5.535-2.87a1.445,1.445,0,1,0-1.33-2.565l-4.97,2.578-.95-3.548a1.444,1.444,0,1,0-2.79.748l2.463,9.191A1.444,1.444,0,0,0,10.685,18.9l6.448-1.729L23.1,23.2a1.442,1.442,0,0,0,1.4.377l2.232-.6A1.443,1.443,0,0,0,27.755,21.209Z" transform="translate(-2.837 0)"/>
            </g>
        </svg>
    )
}

const MediosDePago = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 37.17 33.133">
            <g id="credit-cards-payment" transform="translate(0 -33.234)">
                <g id="Grupo_105" data-name="Grupo 105" transform="translate(0 33.234)">
                <path id="Trazado_178" data-name="Trazado 178" d="M197.079,38.09l-22.765-4.818a1.8,1.8,0,0,0-2.122,1.381l-.432,2.779,26.267,5.558.432-2.779A1.8,1.8,0,0,0,197.079,38.09Z" transform="translate(-161.328 -33.234)"/>
                <path id="Trazado_179" data-name="Trazado 179" d="M26.178,169.344a1.812,1.812,0,0,0-2.224-1.258l-4.322,1.2L9.444,167.13l-1.12,5.294-7,1.943a1.812,1.812,0,0,0-1.258,2.223l3.945,14.217a1.812,1.812,0,0,0,2.224,1.258l22.631-6.28a1.812,1.812,0,0,0,1.258-2.224l-.677-2.441,2.538.537a1.8,1.8,0,0,0,2.122-1.38l1.606-7.588-9.142-1.935Zm2.614,7.226.56-2.645a.739.739,0,0,1,.873-.568l2.645.56a.739.739,0,0,1,.568.873l-.56,2.645A.739.739,0,0,1,32,178l-2.645-.56A.739.739,0,0,1,28.791,176.57Zm-27.074-.784,6.263-1.737,14.777-4.1,1.59-.442a.332.332,0,0,1,.086-.012.341.341,0,0,1,.325.244l.187.672.585,2.108L2.256,178.977l-.772-2.78A.339.339,0,0,1,1.717,175.786ZM28.7,183.955a.34.34,0,0,1-.232.411l-22.63,6.28a.329.329,0,0,1-.086.012.342.342,0,0,1-.325-.244l-2.3-8.293L26.4,175.662l1.419,5.114Z" transform="translate(0 -158.998)"/>
                <path id="Trazado_180" data-name="Trazado 180" d="M93.146,445.141a.739.739,0,0,0-.907-.513l-2.643.734a.738.738,0,0,0-.512.907l.733,2.643a.738.738,0,0,0,.907.512l2.643-.733a.739.739,0,0,0,.513-.907Z" transform="translate(-83.648 -419.616)"/>
                </g>
            </g>
        </svg>
    )
}

const Servicios = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_3" height="32" viewBox="0 0 64 64" width="32">
            <g>
                <path d="m39.268 43h-.292c-.86 0-1.7.3-2.364.844l-7.664 6.271c-.216-1.206-1.084-2.199-2.295-2.562l-7.1-2.13c-.933-.281-1.9-.423-2.874-.423h-.246c-1.769 0-3.511.471-5.039 1.362l-9.898 5.773c-.307.18-.496.51-.496.865v9c0 .312.146.605.393.795.176.135.39.205.607.205.088 0 .176-.012.263-.035l10.871-2.965h15.866c.236 0 .465-.084.646-.236l12.032-10.182c.84-.71 1.322-1.749 1.322-2.85 0-2.057-1.674-3.732-3.732-3.732zm1.119 5.055-11.753 9.945h-15.634c-.089 0-.177.012-.263.035l-9.737 2.655v-7.116l9.402-5.484c1.222-.713 2.616-1.09 4.031-1.09h.246c.779 0 1.552.113 2.299.338l7.1 2.13c.551.165.922.663.922 1.238 0 .714-.58 1.294-1.293 1.294h-6.707v2h8c.231 0 .455-.08.633-.226l10.246-8.383c.308-.252.697-.391 1.097-.391h.292c.955 0 1.732.777 1.732 1.732 0 .511-.224.994-.613 1.323z" />
                <path d="m62 8h-4.657l-8.729-6.789c-.176-.137-.392-.211-.614-.211h-27c-2.206 0-4 1.794-4 4s1.794 4 4 4h9.919l-2.7 3.375c-.142.178-.219.397-.219.625v2h-2c-1.654 0-3 1.346-3 3v4c0 1.654 1.346 3 3 3v2h-3c-.552 0-1 .447-1 1v6c0 .553.448 1 1 1h1v8c0 .266.105.52.293.707l2 2c.195.195.451.293.707.293s.512-.098.707-.293l2-2c.188-.187.293-.441.293-.707v-2c0-.155-.036-.309-.105-.447l-.777-1.553.776-1.553c.07-.138.106-.292.106-.447v-2h1c.552 0 1-.447 1-1v-6c0-.553-.448-1-1-1h-3v-2h10c1.654 0 3-1.346 3-3v-.142c.322.084.653.142 1 .142h20c.552 0 1-.447 1-1v-12c0-.553-.448-1-1-1zm-34 28.764-.895 1.789c-.141.281-.141.613 0 .895l.895 1.788v1.35l-1 1-1-1v-7.586h2zm2-3.764h-6v-4h6zm8-10h-12c-.551 0-1-.448-1-1v-4c0-.552.449-1 1-1h12.142c-.084.322-.142.653-.142 1 0 1.005.385 1.914 1 2.618v1.382c0 .552-.449 1-1 1zm1.382-8h-3.382v-.638l4.468-5.362h4.118l2.414 2.414v2.586h-5c-1.005 0-1.914.385-2.618 1zm21.618 5h-19c-1.103 0-2-.897-2-2s.897-2 2-2h6c.552 0 1-.447 1-1v-4c0-.266-.105-.52-.293-.707l-3-3c-.187-.188-.442-.293-.707-.293h-5c-.297 0-.578.132-.768.359l-5 6c-.15.18-.232.407-.232.641v1h-4v-1.649l3.781-4.726c.24-.301.287-.712.121-1.059-.167-.345-.518-.566-.902-.566h-12c-1.103 0-2-.897-2-2s.897-2 2-2h26.657l8.729 6.789c.176.137.392.211.614.211h4z" />
            </g>
        </svg>
    )
}

const mapStateToProps = (state) => {
    return {
        idIdioma: state.appReducer.idIdioma,
        aeropuertos: state.aeropuertoReducer.aeropuertos,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto
    }
}

export default connect(mapStateToProps)(EstacionamientosABM);