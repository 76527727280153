import React from 'react';
import './App.css';
import AppRoutes from './routes';
import { BrowserRouter as Router, Route } from 'react-router-dom';



function App(props) {
  return (

    <Router >
      <AppRoutes></AppRoutes>
    </Router>

  )
}


export default App;
