import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { ESP } from '../../environments/idioma';
import { GetBusesByAeropuerto, SaveOrUpdate, DeleteBus } from '../../services/buses.service';
import { getSessionUser } from '../../services/auth.service';

import swal from 'sweetalert2';
import { successSwal, confirmSwal } from '../../assets/resources/swal-config';
import { Button, Tooltip, withStyles } from '@material-ui/core';
import { Add, ChevronRight, Delete, Visibility, VisibilityOff } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusAlt } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../components/Pagination';
import SelectAeropuerto from '../../components/SelectAeropuerto'
import './buses.css';

const Buses = (props) => {
    const history = useHistory();
    const [state, setState] = useState({
        buses: [],
        idIdioma: ESP
    });

    const user = getSessionUser();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(16);

    const filteredRow = state.buses.filter(row => { return row.show })    
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.buses.length - page * rowsPerPage);

    useEffect(() => {
        loadBuses(props.idAeropuerto)
    }, [props.idIdioma, props.idAeropuerto]);

    const loadBuses = aeropuerto => {
        const Buses = [];
        setPage(0);
        GetBusesByAeropuerto(props.idIdioma, aeropuerto)
            .then((resBuses) => {
                if (resBuses.data) {
                    for (var i = 0; i < resBuses.data.length; i++){
                        const bus = resBuses.data[i];
                        bus["show"] = true;
                        Buses.push(bus)
                    }
                    setState({ ...state, buses: Buses, isEditing: false });
                }
            })
    }

    const handleAddClick = () => history.push("/Bus");

    const handleEditClick = id => history.push(`/Bus/${id}`);

    const handleDeleteClick = bus => {
        swal.fire({
            title: 'Eliminar Bus',
            text: '¿Está seguro que desea eliminar el transporte  ' + bus.dsTransporte + '?',
            ...confirmSwal
        })
            .then(confirm => {
                if (confirm.value) {
                    DeleteBus({ id: bus.id })
                        .then(async() => {
                            await loadBuses(props.idAeropuerto);
                            swal.fire({
                                text: 'Se ha eliminado el transporte con éxito',
                                ...successSwal
                            })
                        })
                }
            })
    }

    const handleActiveClick = bus => {
        const obj = {
            id: bus.id,
            idAeropuerto: bus.idAeropuerto,
            idIdioma: bus.idIdioma,
            dsTransporte: bus.dsTransporte,
            dsRecorrido: bus.dsRecorrido,
            dsUbicacion: bus.dsUbicacion,
            dsTelefono1: bus.dsTelefono1,
            dsTelefono2: bus.dsTelefono2,
            dsTarifa1: bus.dsTarifa1,
            dsTarifa2: bus.dsTarifa2,
            dsSube: bus.dsSube,
            dsIngresa: bus.dsIngresa,
            dsUsuario: user.DS_Nombre,
            feCreacion: bus.feCreacion,
            feModificacion: new Date().toISOString().replace("Z", ""),
            mcInactiva: !bus.mcInactiva,
        }

        SaveOrUpdate(obj)
            .then((resBuses) => {
                loadBuses(props.idAeropuerto);
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }


    return (
        <div className="main-content">
            <br />
            <h1 className="title">Transporte - <b className="green-text">BUSES TRANSPORTE URBANO DE PASAJEROS</b></h1>
            <br />
            <br />
            <div className="content">
                <div className="row form-group">
                    <div className="col-sm-6">
                        <SelectAeropuerto />
                        <br/>
                    </div>

                    <div className="col-sm-3 offset-sm-3">
                        <Button variant="contained" color="primary" className="btnAdd" startIcon={<Add />} onClick={handleAddClick}>Nuevo</Button>
                        <br/>
                    </div>
                </div>

                <div className="row form-group">
                    {(rowsPerPage > 0 ? filteredRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRow).map((row, index) => (
                        <div className="col-md-3" key={row.id}>
                            <div className="transporte-box">
                                <div className="top">
                                    {row.mcInactiva ?
                                        <LightTooltip
                                        placement="top"
                                            title={<span><strong>Mostrar: </strong>Este elemento pasará a estar visible para el lector.</span>}>
                                            <VisibilityOff className="inactivo" onClick={() => handleActiveClick(row)} />
                                        </LightTooltip>
                                        :
                                        <LightTooltip
                                        placement="top"
                                        title={<span><strong>Ocultar: </strong>Este elemento dejará de estar visible para el lector pero no se eliminará.</span>}>
                                            <Visibility className="activo" onClick={() => handleActiveClick(row)} />
                                        </LightTooltip>
                                    }
                                </div>
                                <p className="transporte-name">{row.dsTransporte}</p>
                                <div className="transporte-logo">
                                    <FontAwesomeIcon icon={faBusAlt} />
                                </div>
                                <div className="bottom">
                                    <LightTooltip title="Eliminar transporte" placement="top">
                                        <Delete className="del" onClick={() => handleDeleteClick(row)}/>
                                    </LightTooltip>
                                    <div className="details" onClick={() => handleEditClick(row.id)}>
                                        <span>VER</span>
                                        <ChevronRight/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {filteredRow.length > rowsPerPage ? <Pagination count={filteredRow.length} page={page} rowsPerPage={rowsPerPage} onChangePage={handleChangePage} /> : ""}
        </div>
    )
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        letterSpacing: 0,
    },
}))(Tooltip);

const mapStateToProps = state => {
    return {
        idIdioma: state.appReducer.idIdioma,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto
    }
}

export default connect(mapStateToProps)(Buses);