import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { GetTaxiRemisById, SaveOrUpdateTaxiRemis, DeleteTarifa } from '../../../services/taxiRemis.service';
import { getSessionUser } from '../../../services/auth.service';

import swal from 'sweetalert2';
import { successSwal, confirmSwal } from '../../../assets/resources/swal-config';
import { Button, Radio, RadioGroup, FormControlLabel, Checkbox, Grid, Fab, IconButton } from '@material-ui/core';
import { Add, AddPhotoAlternate, Delete, Edit, Save, Cancel } from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';

import NoFoundImage from '../../../assets/img/not_found.png';
import SwitcherIdioma from '../../../components/switcherIdioma';
import './taxiRemisABM.css'

const TaxiRemisABM = (props) => {
    const Id = props.match.params.id;
    const history = useHistory();
    const user = getSessionUser();
    const [taxiRemis, setTaxiRemis] = useState({
        id: 0,
        idAeropuerto: 0,
        idIdioma: props.idIdioma,
        dsTransporte: "",
        dsTipoTransporte: "",
        dsUbicacion: "",
        dsHorario: "",
        open24: "",
        dsTelefono1: "",
        dsTelefono2: "",
        dsEmail: "",
        lkImagen: null,
        dsUsuario: "",
        feCreacion: "",
        feModificacion: "",
        mcInactiva: false,
        base64Image: null,
        tarifas: [],
    });

    const validationSchema = {
        id: { required: false },
        idAeropuerto: { required: false },
        idIdioma: { required: false },
        dsTransporte: { required: true },
        dsTipoTransporte: { required: true },
        dsUbicacion: { required: false },
        dsHorario: { required: true },
        open24: { required: false },
        dsTelefono1: { required: true },
        dsTelefono2: { required: false },
        dsEmail: { required: false },
        lkImagen: { required: false },
        dsUsuario: { required: false },
        feCreacion: { required: false },
        feModificacion: { required: false },
        mcInactiva: { required: false },
        base64Image: { required: false },
        tarifas: { required: false },
    }
    const [horario, setHorario] = useState({
        from: '',
        to: ''
    });
    const [tarifa, setTarifa] = useState({
        descripcion: "",
        monto: "",
        add: false,
    });
    const [tipo1, setTipo1] = useState("");
    const [tipo2, setTipo2] = useState("");
    const [image, setImage] = useState({ name: null, srcImage: NoFoundImage, hiddenRemoveImage: true });
    const [errors, setErrors] = useState([]);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (Id){
            getTaxiRemisById(Id)
        } else{
            setTaxiRemis({ ...taxiRemis, idIdioma: props.idIdioma, idAeropuerto: props.idAeropuerto });
            setEditing(true);
        }

    }, [props.idIdioma])

    const getTaxiRemisById = (id) => {
        setEditing(false);
        GetTaxiRemisById(id)
            .then((resGetTaxiRemis) => {
                if (resGetTaxiRemis.data) {
                    const Tipo = resGetTaxiRemis.data.dsTipoTransporte.split("-"),
                        tarifas = [],
                        horario = resGetTaxiRemis.data.dsHorario.split(" ");
                    for (var i = 0; i < resGetTaxiRemis.data.tarifas.length; i++){
                        const tarifa = resGetTaxiRemis.data.tarifas[i];
                        tarifa["edit"] = false;
                        tarifas.push(tarifa);
                    }
                    if (horario.length > 1) setHorario({ from: horario[1], to: horario[3]})

                    setTipo1(Tipo[0].trim());
                    setTipo2(Tipo[1].trim());
                    setTaxiRemis({ ...resGetTaxiRemis.data, tarifas: tarifas, base64Image: resGetTaxiRemis.data.base64Image !== null ? resGetTaxiRemis.data.base64Image.toString().split(",", 2)[1] : null })
                    setImage({ ...image, name: resGetTaxiRemis.data.lkImagen, srcImage: resGetTaxiRemis.data.base64Image !== null ? resGetTaxiRemis.data.base64Image : NoFoundImage, hiddenRemoveImage: resGetTaxiRemis.data.base64Image !== null ? false : true })
                }
            })
            .catch(err => console.error(err))
    }

    const handleActivaChange = event => setTaxiRemis({ ...taxiRemis, mcInactiva: !event.target.checked });

    const handleChange = event => setTaxiRemis({ ...taxiRemis, [event.target.name]: event.target.value });

    const handleTipoClick = event => {
        switch (event.target.name) {
            case 'tipo1':
                setTipo1(event.target.value);
                setTaxiRemis({...taxiRemis, dsTipoTransporte: event.target.value + " - " + tipo2 })
                break;
            case 'tipo2':
                setTipo2(event.target.value);
                setTaxiRemis({...taxiRemis, dsTipoTransporte: tipo1 + " - " + event.target.value })
        }
    }

    const handleFromTimeChange = event => {
        setHorario({ ...horario, from: event.target.value });
        setTaxiRemis({...taxiRemis, dsHorario: event.target.value ? `de ${event.target.value} a ${horario.to}` : "" })
    }

    const handleToTimeChange = event => {
        setHorario({ ...horario, to: event.target.value });
        setTaxiRemis({...taxiRemis, dsHorario: event.target.value ? `de ${horario.from} a ${event.target.value}` : "" })
    }

    const handleOpen24Click = event => {
        if (event.target.checked) {
            setHorario({from: "", to: ""})
        }
        setTaxiRemis({ ...taxiRemis, open24: event.target.checked, dsHorario: event.target.checked ? "" : taxiRemis.dsHorario })
    }

    const handleEditClick = () => {
        if (!editing)
            setEditing(true);
    }

    const handleUploadImageClick = event => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length && validateImage(event)) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                let img = new Image()
                img.src = reader.result;

                //VALIDA ANCHO Y LARGO DE LA IMAGEN
                img.onload = function () {
                    const nameFile = taxiRemis.dsTransporte !== '' ? `${taxiRemis.dsTransporte}.${file.name.split('.').pop()}` : file.name;
                    const base64Image = reader.result.toString().split(",", 2)[1];
                    setTaxiRemis({ ...taxiRemis, lkImagen: nameFile, base64Image: base64Image })
                    setImage({ ...image, name: nameFile, srcImage: reader.result, hiddenRemoveImage: false });
                }
            };
        }
    };

    const validateImage = (event) => {
        var fileName = event.target.files[0].name;
        var fileSize = event.target.files[0].size;

        if (fileSize > 3000000) {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'La imagen no debe superar los 3MB',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
            event.target.value = '';
            return false;
        } else {
            var ext = fileName.split('.').pop();

            ext = ext.toLowerCase();

            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'gif':
                case 'svg':
                    return true;
                    break;
                default:
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'LA Imagen no tiene la extensión adecuada',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                    event.target.value = '';
                    return false;
            }
        }
    }
    
    const removeImage = () => {
        swal.fire({
            title: 'Eliminar servicio',
            text: '¿Está seguro que desea eliminar la imagen ?',
            ...confirmSwal
        })
        .then((confirm) => {
            if (confirm.value) {
                setTaxiRemis({ ...taxiRemis, lkImagen: null, base64Image: null })
                setImage({ ...image, srcImage: NoFoundImage, hiddenRemoveImage: true });
            }
        })
    }

    const handleAddTarifaClick = () => setTarifa({ ...tarifa, add: true });

    const handleEditTarifaClick = index => {
        if(editing){
            const items = [];
            for (var i = 0; i < taxiRemis.tarifas.length; i++){
                const item = taxiRemis.tarifas[i];
                item.edit = i == index ? !item.edit : item.edit;
                items.push(item);
            }
            setTaxiRemis({ ...taxiRemis, tarifas: items });
        }
    }

    const handleDeleteTarifaClick = index => {
        if (editing) {
            swal.fire({
                title: 'Eliminar tarifa',
                text: '¿Está seguro que desea eliminar la tarifa ?',
                ...confirmSwal
            })
                .then((confirm) => {
                    if (confirm.value) {
                        const tarifa = taxiRemis.tarifas[index];
                        if (tarifa.id > 0) {
                            const obj = {
                                id: tarifa.id,
                            };
                            DeleteTarifa(obj)
                                .then((resTarifa) => {
                                    if (Id)
                                        getTaxiRemisById(Id)
                                })
                        } else {
                            const items = [];
                            for (var i = 0; i < taxiRemis.tarifas.length; i++){
                                if(i != index){
                                    const item = taxiRemis.tarifas[i];
                                    items.push(item);
                                }
                            }
                            setTaxiRemis({ ...taxiRemis, tarifas: items });
                        }
                    }
                })
        }
    }

    const handleChangeTarifaClick = (e, index) => {
        const items = [];
        for (var i = 0; i < taxiRemis.tarifas.length; i++){
            const item = taxiRemis.tarifas[i];
            if (i == index) item[e.target.name] = e.target.value;
            items.push(item);
        }
        setTaxiRemis({...taxiRemis, tarifas: items });
    }

    const handleChangeTarifaNuevaClick = event => setTarifa({ ...tarifa, [event.target.name]: event.target.value });

    const handleSaveTarifaClick = () => {
        if(tarifa.descripcion && tarifa.monto){
            const items = [];
            taxiRemis.tarifas.map(tarifa => {
                items.push(tarifa)
            })

            items.push({
                id: 0,
                idTransporte: taxiRemis.id,
                dsDescripcion: tarifa.descripcion,
                dsTarifa: tarifa.monto,
                edit: false
            });

            setTaxiRemis({ ...taxiRemis, tarifas: items });
            setTarifa({ descripcion: "", monto: "", add: false });
        }
    }

    const handleCancelTarifaClick = () => setTarifa({ descripcion: "", monto: "", add: false });

    const handleSubmit = () => {
        if(isValid()){
            const obj = {
                id: taxiRemis.id,
                idAeropuerto: taxiRemis.idAeropuerto,
                idIdioma: taxiRemis.idIdioma,
                dsTransporte: taxiRemis.dsTransporte,
                dsTipoTransporte: taxiRemis.dsTipoTransporte,
                dsUbicacion: taxiRemis.dsUbicacion,
                dsHorario: taxiRemis.dsHorario,
                open24: taxiRemis.open24,
                dsTelefono1: taxiRemis.dsTelefono1,
                dsTelefono2: taxiRemis.dsTelefono2,
                dsEmail: taxiRemis.dsEmail,
                lkImagen: taxiRemis.lkImagen,
                dsUsuario: user.DS_Nombre,
                feCreacion: taxiRemis.feCreacion,
                feModificacion: null,
                mcInactiva: taxiRemis.mcInactiva,
                base64Image: taxiRemis.base64Image,
                tarifas: taxiRemis.tarifas,
            }

            for (var i = 0; i < obj.tarifas.length; i++){
                delete obj.tarifas[i].edit;
            }

            SaveOrUpdateTaxiRemis(obj)
                .then((resTaxiRemis) => {
                    console.log(resTaxiRemis.data)
                    if (!resTaxiRemis.data || !resTaxiRemis.data.ResultadoExitoso) {
                        swal.fire({
                            title: 'Error al guardar los cambios',
                            icon: 'warning',
                            text: !resTaxiRemis.data ? "Ha ocurrido un error, intente nuevamente en unos minutos." : resTaxiRemis.data.MensajeError,
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    } else {
                        swal.fire({
                            text: 'Se han guardado los cambios con éxito',
                            ...successSwal
                        }).then(() => { history.push('/TaxisRemises'); })
                    }
                })
        }
    }

    const handleCancelABMClick = () => history.push('/TaxisRemises');

    const isValid = () => {
        let email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let valid = true;
        let errors = {};
        setErrors(errors);
        
        try {
            for (let key of Object.keys(taxiRemis)) {
                let value = taxiRemis[key];
                if (key == "dsEmail" && value && !email.test(value)) {
                    errors[key] = 'El email ingresado no tiene un formato correcto.';
                    setErrors(errors);
                    valid = false;
                    
                } else if (key == 'dsHorario' && validationSchema[key].required) {
                    if ((!value || !horario.from || !horario.to) && !taxiRemis.open24) {
                        errors[key] = 'Los campos "Desde" y "Hasta" son requeridos';
                        setErrors(errors);
                        valid = false;
                    }
                } else if (validationSchema[key].required) {
                    if (value == 0) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }
                    
                }
            }
        } catch (err) {
            console.error('Error', err);
        }
        return valid;
    }

    return (
        <div className="main-content">
            <br/>
            {Id ?
                <h1 className="title">Transporte - <b className="greentext">{taxiRemis.dsTransporte}</b></h1>
                :
                <h1 className="title">Nuevo REMIS o TAXI</h1>
            }
            
            <br />
            <br />
            <div className="content">
                
                <div className="row form-group">
                    <div className="col-md-3">
                        <label className="label" hidden={Id}>Idioma: {props.idIdioma == 'ES-AR' ? 'Español' : 'Inglés'}</label>
                    </div>

                    <div className="col-md-6">
                        <div className="active-switch">
                            <label className="label">HABILITADA</label>
                            <input type="checkbox" id="check" onChange={handleActivaChange} checked={!taxiRemis.mcInactiva} />
                            <label htmlFor="check" className="switch"></label>
                        </div>
                    </div>

                    <div className="col-md-3" hidden={props.idNota}>
                        {!Id ?
                            <SwitcherIdioma />
                            :
                            <Button variant="contained" className={`btnAdd ${editing ? 'gray' : ''}`} color="primary" startIcon={<FontAwesomeIcon icon={faEdit} />} onClick={handleEditClick}>Editar</Button>
                        }
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-12">
                        
                        <div className="box">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Nombre</label>
                                        <input name="dsTransporte" type="text" placeholder="Ej: Tienda León" defaultValue={taxiRemis.dsTransporte} onChange={handleChange} className="form-control" disabled={!editing} />
                                        {errors.dsTransporte && (<p className="help is-danger">{errors.dsTransporte}</p>)}
                                        <br />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Tipo</label>
                                        <div className="tipo-aeropuerto">
                                            <RadioGroup aria-label="tipo1" name="tipo1" value={tipo1} onChange={handleTipoClick}>
                                                <FormControlLabel control={<Radio disabled={!editing} value="Público" checked={tipo1 == "Público"} />} label="Público" />
                                                <FormControlLabel control={<Radio disabled={!editing} value="Permisionario" checked={tipo1 == "Permisionario"} />} label="Permisionario" />
                                            </RadioGroup>
                                        </div>

                                        <div className="tipo-aeropuerto">
                                            <RadioGroup aria-label="tipo2" name="tipo2" value={tipo2} onChange={handleTipoClick}>
                                                <FormControlLabel control={<Radio disabled={!editing} value="Remis" checked={tipo2 == "Remis"} />} label="Remis" />
                                                <FormControlLabel control={<Radio disabled={!editing} value="Taxi" checked={tipo2 == "Taxi"} />} label="Taxi" />
                                            </RadioGroup>
                                        </div>
                                        {errors.dsTransporte && (<p className="help is-danger">{errors.dsTransporte}</p>)}
                                        <br />
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Ubicación en el Aeropuerto</label>
                                        <textarea name="dsUbicacion" placeholder="ej: Sector A, Arribos Internacionales" defaultValue={taxiRemis.dsUbicacion} onChange={event => setTaxiRemis({...taxiRemis, dsUbicacion: event.target.value})} className="form-control" disabled={!editing} />
                                        {errors.dsUbicacion && (<p className="help is-danger">{errors.dsUbicacion}</p>)}
                                        <br />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Horario</label>
                                        <div className="horario-box">
                                            <div className="horario">
                                                <span>De</span>
                                                <input name="dsHorario" type="text" placeholder="00:00" maxLength={5} value={horario.from} onChange={handleFromTimeChange} className="form-control" disabled={taxiRemis.open24 || !editing} />
                                                <span> Hasta</span>
                                                <input name="dsHorario" type="text" placeholder="00:00" maxLength={5} value={horario.to} onChange={handleToTimeChange} className="form-control" disabled={taxiRemis.open24 || !editing} />
                                                <span>hs</span>
                                            </div>
                                            <br />
                                        </div>
                                        <div className="horario-box">
                                            <br />
                                            <FormControlLabel control={<Checkbox name="open24" color="primary" checked={taxiRemis.open24 == true} />} label="Abierto las 24hs" disabled={!editing} onChange={handleOpen24Click} />
                                            <br />
                                        </div>
                                        {errors.dsHorario && (<p className="help is-danger">{errors.dsHorario}</p>)}
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label tarifa-label">Tarifas preacordadas</label>
                                        <Add className="addbtn-tarifa" hidden={!editing}  onClick={() => handleAddTarifaClick()}/>
                                        <table className="tarifas">
                                            <tbody>
                                                {taxiRemis.tarifas.map((tarifa, index) => (
                                                    <tr key={index}>
                                                        <th>{tarifa.edit ?
                                                            <input name="dsDescripcion" type="text" defaultValue={tarifa.dsDescripcion} onChange={e => handleChangeTarifaClick(e, index)} className="form-control" disabled={!editing} />
                                                            :
                                                            tarifa.dsDescripcion
                                                        }</th>
                                                        <th>{tarifa.edit ?
                                                            <input name="dsTarifa" type="text" defaultValue={tarifa.dsTarifa} onChange={e => handleChangeTarifaClick(e, index)} className="form-control" disabled={!editing} />
                                                            :
                                                            "$ " + tarifa.dsTarifa
                                                        }</th>
                                                        <th>
                                                            <div className="actions" hidden={!editing}>
                                                                <Edit className="edit" onClick={() => handleEditTarifaClick(index)}/>
                                                                <Delete className="del" onClick={() => handleDeleteTarifaClick(index)}/>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                ))}
                                                <tr hidden={!tarifa.add}>
                                                    <th>
                                                        <input name="descripcion" placeholder="Ej: 9 de Julio Obelisco" type="text" value={tarifa.descripcion} onChange={handleChangeTarifaNuevaClick} className="form-control" />
                                                    </th>
                                                    <th>
                                                        <input name="monto" placeholder="$" type="text" value={tarifa.monto} onChange={handleChangeTarifaNuevaClick} className="form-control" />
                                                    </th>
                                                    <th>
                                                        <div className="actions" hidden={!editing}>
                                                            <Save className="save" onClick={handleSaveTarifaClick} />
                                                            <Cancel className="cancel" onClick={handleCancelTarifaClick} />
                                                        </div>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Teléfono</label>
                                        <div className="telefono">
                                            <input name="dsTelefono1" placeholder="1122334455" type="text" defaultValue={taxiRemis.dsTelefono1} onChange={handleChange} className="form-control" disabled={!editing} />
                                            <input name="dsTelefono2" placeholder="1122334455" type="text" defaultValue={taxiRemis.dsTelefono2} onChange={handleChange} className="form-control" disabled={!editing} />
                                            <br />
                                        </div>
                                        {errors.dsTelefono1 && (<p className="help is-danger">{errors.dsTelefono1}</p>)}
                                        {errors.dsTelefono2 && (<p className="help is-danger">{errors.dsTelefono2}</p>)}
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <br/>
                                    <label className="label">Seleccione una Imagen</label>
                                    <br/>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <img className="abmImage" alt="complex" src={image.srcImage} />
                                        </Grid>
                                        <Grid item>
                                            {image.hiddenRemoveImage ? <Fragment>
                                                <input type="file" accept="image/*" id="contained-button-file1"
                                                    onChange={handleUploadImageClick} />

                                                <label htmlFor="contained-button-file1">
                                                    <Fab component="span" color="primary" >
                                                        <AddPhotoAlternate />
                                                    </Fab>
                                                </label>
                                            </Fragment> : ""}
                                            <div className="abmImageDel">
                                                {!image.hiddenRemoveImage ? <IconButton color="secondary" aria-label="delete" component="span" onClick={removeImage}>
                                                    <Delete />
                                                </IconButton> : ""}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label className="label">Email</label>
                                        <input name="dsEmail" placeholder="ej: reservas@empresa.com.ar" type="text" defaultValue={taxiRemis.dsEmail} onChange={handleChange} className="form-control" disabled={!editing} />
                                        {errors.dsEmail && (<p className="help is-danger">{errors.dsEmail}</p>)}
                                        <br />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row form-group"> 
                            <div className="form-row">
                                <Button variant="contained" startIcon={<Save />} className="button" onClick={handleSubmit}>Guardar</Button>
                                <Button variant="contained" startIcon={<Cancel />} className="button" onClick={handleCancelABMClick}>Cancelar</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        idIdioma: state.appReducer.idIdioma,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto
    }
}

export default connect(mapStateToProps)(TaxiRemisABM);