import Axios from "axios"
import './axiosConfig';

const GETGRUPOS_URL = 'Grupo/ObtenerGrupos';
const GETGRUPOSFULL_URL = 'Grupo/GetGruposFull';
const GETGRUPOFULL_URL = 'Grupo/GetGrupoFull';
const SAVEORUPDATE_URL = 'Grupo/SaveOrUpdateGrupoFull';
const GETGRUPO_URL = 'Grupo/GetGrupo';
const ADDGRUPOPERMISO_URL = 'Grupo/CrearGrupoyPermisos';
const DELETEGRUPO_URL = 'Grupo/Eliminar';
const DELETEGRUPOFULL_URL = 'Grupo/Delete'

export const GetGrupos = () => {
    return Axios.get(GETGRUPOS_URL)
}

export const SaveGrupoPermiso = (grupoPermiso) => {
    return Axios.post(ADDGRUPOPERMISO_URL, grupoPermiso);
}

export const GetGrupo=(idGrupo) => {
    const params = {id: idGrupo}
    return Axios.get(GETGRUPO_URL, {params});
}

export const DeleteGrupo = (grupo) => {
    return Axios.post(DELETEGRUPO_URL, grupo);
}

export const GetGruposFull = () => {
    return Axios.get(GETGRUPOSFULL_URL)
}

export const GetGrupoFull = (idGrupo) => {
    const params = {id: idGrupo}
    return Axios.get(GETGRUPOFULL_URL, { params });
}

export const SaveOrUpdate = (grupoPermiso) => {
    return Axios.post(SAVEORUPDATE_URL, grupoPermiso);
}

export const DeleteGrupoFull = (grupoPermiso) => {
    return Axios.post(DELETEGRUPOFULL_URL, grupoPermiso);
}

