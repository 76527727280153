import Axios from 'axios';
import './axiosConfig';

const GETMEDIOSDEPAGO_URL = 'MediosDePago/GetMediosDePago';
const GETMEDIOSDEPAGOBYID_URL = 'MediosDePago/GetMediosDePagoByID';
const SAVEORUPDATEMEDIODEPAGO_URL = 'MediosDePago/SaveOrUpdate';
const DELETEMEDIODEPAGO_URL = 'MediosDePago/Delete';
const GETTIPOMEDIOSDEPAGO_URL = 'MediosDePago/GetTiposMediosDePago';
const SAVEORUPDATETIPOMEDIODEPAGo_URL = 'MediosDePago/SaveOrUpdateTipoMedioPago';
const DELETETIPOMEDIODEPAGO_URL = 'MediosDePago/DeleteTipoMedioPago';

export const GetMediosDePago = (todos) => {
    return Axios.get(GETMEDIOSDEPAGO_URL, { params: { todos } });
}

export const GetMediosDePagoByID = (id) => {
    return Axios.get(GETMEDIOSDEPAGOBYID_URL, { params: { id } });
}

export const SaveOrUpdate = (mediodepago) => {
    return Axios.post(SAVEORUPDATEMEDIODEPAGO_URL, mediodepago);
}

export const Delete = (mediodepago) => {
    return Axios.post(DELETEMEDIODEPAGO_URL, mediodepago);
}

export const GetTipoMediosDePago = () => {
    return Axios.get(GETTIPOMEDIOSDEPAGO_URL)
}

export const SaveOrUpdateTipoMedioPago = (tipomediopago) => {
    return Axios.post(SAVEORUPDATETIPOMEDIODEPAGo_URL, tipomediopago);
}

export const DeleteTipoMedioPago = (tipomediopago) => {
    return Axios.post(DELETETIPOMEDIODEPAGO_URL, tipomediopago);
}