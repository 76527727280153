import store from '../redux/store';

export const isAuthenticated = () => {
    const stateSession = store.getState()
    if (stateSession.userReducer.Token !== '')
        return true;
    else
        return false;
}

export const logout = (history) => {
    sessionStorage.clear();
    history.push("/Login");
    window.location.reload();
}

export const getSessionUser = () => {

    const stateSession = store.getState().userReducer;
    return stateSession;
}

export const setSessionUserLocalStorage = (user) => {

    try {
        if (localStorage.getItem('state') === null) {

            const serialState = JSON.stringify(user);
            sessionStorage.setItem('state', serialState);
        }

    } catch (e) { console.log(e); }

}




