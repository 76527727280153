import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ESP } from '../../environments/idioma';
import { GetAlquilerAutoByAeropuerto, SaveOrUpdate, DeleteAlquilerAuto } from '../../services/alquilerautos.service';
import { getSessionUser } from '../../services/auth.service';

import Select from 'react-select';
import swal from 'sweetalert2';
import { successSwal, confirmSwal } from '../../assets/resources/swal-config';
import { Button, Tooltip, withStyles } from '@material-ui/core';
import { Add, ChevronRight, Delete, Visibility, VisibilityOff } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faSearch } from '@fortawesome/free-solid-svg-icons';

import Pagination from '../../components/Pagination';
import SwitcherIdioma from '../../components/switcherIdioma';
import SelectAeropuerto from '../../components/SelectAeropuerto';
import AlquilerAutosABM from './alquilerdeautosABM/alquilerdeautosABM';
import './alquilerdeautos.css';

const AlquilerAutos = (props) => {
    const [state, setState] = useState({
        alquileres: [],
        idIdioma: ESP
    });

    const history = useHistory();
    const user = getSessionUser();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(16);

    const filteredRow = state.alquileres.filter(row => { return row.show })    
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.alquileres.length - page * rowsPerPage);

    useEffect(() => {
        loadAlquileres(props.idAeropuerto)
    }, [props.idIdioma, props.idAeropuerto]);

    const loadAlquileres = aeropuerto => {
        const alquileres = [];
        setPage(0);
        document.getElementById("searchInput").value = "";
        GetAlquilerAutoByAeropuerto(props.idIdioma, aeropuerto)
            .then((resAlquileres) => {
                if (resAlquileres.data) {
                    for (var i = 0; i < resAlquileres.data.length; i++){
                        const alquiler = resAlquileres.data[i];
                        alquiler["show"] = true;
                        alquileres.push(alquiler)
                    }
                    setState({ ...state, alquileres: alquileres, isEditing: false });
                }
            })
    }

    const handleAddClick = () => history.push("/Alquiler");

    const handleEditClick = id => history.push(`/Alquiler/${id}`);

    const handleDeleteClick = alquiler => {
        swal.fire({
            title: 'Eliminar Empresa',
            text: '¿Está seguro que desea eliminar la empresa  ' + alquiler.dsTransporte + '?',
            ...confirmSwal
        })
            .then(confirm => {
                if (confirm.value) {
                    DeleteAlquilerAuto({ id: alquiler.id })
                        .then(async() => {
                            await loadAlquileres(props.idAeropuerto);
                            swal.fire({
                                text: 'Se ha eliminado el transporte con éxito',
                                ...successSwal
                            })
                        })
                }
            })
    }

    const handleActiveClick = alquiler => {
        const obj = {
            id: alquiler.id,
            idAeropuerto: alquiler.idAeropuerto,
            idIdioma: alquiler.idIdioma,
            dsTransporte: alquiler.dsTransporte,
            dsUbicacion: alquiler.dsUbicacion,
            dsTelefono1: alquiler.dsTelefono1,
            dsTelefono2: alquiler.dsTelefono2,
            dsEmail: alquiler.dsEmail,
            dsWeb: alquiler.dsWeb,
            dsURL: alquiler.dsURL,
            lkImagen: alquiler.lkImagen,
            dsUsuario: user.DS_Nombre,
            feCreacion: alquiler.feCreacion,
            feModificacion: new Date().toISOString().replace("Z", ""),
            base64Image: null,
            mcInactiva: !alquiler.mcInactiva,
        }

        SaveOrUpdate(obj)
            .then((resAlquileres) => {
                if (resAlquileres.data.ResultadoExitoso) {
                    loadAlquileres(props.idAeropuerto);
                } else {
                    swal.fire({
                        title: 'Error al guardar los cambios',
                        icon: 'warning',
                        text: resAlquileres.data.MensajeError,
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                }
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleSearch = (e) => {
        const search = e ? e.target.value.toLowerCase().trim() : document.getElementById("searchInput").value.toLowerCase().trim();
        const keys = ["dsTransporte"];
        const newRows = [];

        if(search.length >= 2) {
            for(var i = 0; i < state.alquileres.length; i++) {
                let bool,
                    row = state.alquileres[i];
                for(var index = 0; index < keys.length; index++) {
                    let value = row[keys[index]];
                    if(value) {
                        value = value.toLowerCase();
                        bool = index == 0 ? value.indexOf(search) != -1 :  bool || value.indexOf(search) != -1;
                    }
                }
                row.show = bool;
                newRows.push(row);
            }
        } else {
            for(var i = 0; i < state.alquileres.length; i++) {
                state.alquileres[i].show = true;
                newRows.push(state.alquileres[i]);
            }
        }
        setPage(0)
        setState({ ...state, alquileres: newRows})
    }

    return (
        <div className="main-content">
            <br />
            <h1 className="title">Transporte - <b className="green-text">ALQUILERES DE VEHÍCULO</b></h1>
            <br />
            <br />
            <div className="content">
                <div className="row form-group">
                    <div className="col-sm-12 col-md-5 col-lg-6">
                        <SelectAeropuerto />
                        <br/>
                    </div>

                    <div className="col-sm-6 col-md-2 col-lg-2 offset-md-2 offset-lg-1">
                        <SwitcherIdioma />
                        <br/>
                    </div>

                    <div className="col-sm-6 col-md-3 col-lg-3">
                        <Button variant="contained" color="primary" className="btnAdd" startIcon={<Add />} onClick={handleAddClick}>Nuevo</Button>
                        <br/>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-4">
                        <div className="search-box">
                            <br />
                            <br />
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="type" name="search" id="searchInput" placeholder="Buscar por empresa" onKeyUp={handleSearch} />
                        </div>
                        <br/>
                    </div>
                </div>

                <div className="row form-group">
                    {(rowsPerPage > 0 ? filteredRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRow).map((row, index) => (
                        <div className="col-md-3" key={row.id}>
                            <div className="transporte-box">
                                <div className="top">
                                    {row.mcInactiva ?
                                        <LightTooltip
                                        placement="top"
                                            title={<span><strong>Mostrar: </strong>Este elemento pasará a estar visible para el lector.</span>}>
                                            <VisibilityOff className="inactivo" onClick={() => handleActiveClick(row)} />
                                        </LightTooltip>
                                        :
                                        <LightTooltip
                                        placement="top"
                                        title={<span><strong>Ocultar: </strong>Este elemento dejará de estar visible para el lector pero no se eliminará.</span>}>
                                            <Visibility className="activo" onClick={() => handleActiveClick(row)} />
                                        </LightTooltip>
                                    }
                                </div>
                                <p className="transporte-name">{row.dsTransporte}</p>
                                <div className="transporte-logo">
                                    <FontAwesomeIcon icon={faCar} />
                                </div>
                                <div className="bottom">
                                    <LightTooltip title="Eliminar transporte" placement="top">
                                        <Delete className="del" onClick={() => handleDeleteClick(row)}/>
                                    </LightTooltip>
                                    <div className="details" onClick={() => handleEditClick(row.id)}>
                                        <span>VER</span>
                                        <ChevronRight/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {filteredRow.length > rowsPerPage ? <Pagination count={filteredRow.length} page={page} rowsPerPage={rowsPerPage} onChangePage={handleChangePage} /> : ""}
        </div>
    )
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        letterSpacing: 0,
    },
}))(Tooltip);

const mapStateToProps = state => {
    return {
        idIdioma: state.appReducer.idIdioma,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto
    }
}

export default connect(mapStateToProps)(AlquilerAutos);