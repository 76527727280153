import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { switchIdioma, selectAeropuerto } from '../redux/action'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    'switch-idioma': {
        fontSize: 12,
        color: '#ffffff',
        fontWeight: 'bold',
    },
    'switch-idioma-inner': {
        margin: 0,
        width: 145,
        height: 35,
        background: '#E0E0E0',
        borderRadius: 25,
        position: 'relative',
        transition: 'all 0.3s ease',
        display: 'block',
        '&::before': {
            content: 'attr(data-on)',
            position: 'absolute',
            right: 8,
            padding: 0,
            color: '#8D8D8D',
            display: 'grid',
            textAlign: 'center',
            alignItems: 'center',
            height: 35,
            fontWeight: 'bold'
        },
        '&::after': {
            content: 'attr(data-off)',
            width: 80,
            height: 35,
            background: '#ff9800',
            borderRadius: 26,
            position: 'absolute',
            left: -1,
            top: 0,
            textAlign: 'center',
            transition: 'all 0.3s ease',
            boxShadow: '0px 0px 6px -2px #111',
            display: 'grid',
            alignItems: 'center',
            textAlign: 'center',
            fontWeight: 'bold',
            color: 'white',
        }
    },
    'input-idioma': {
        cursor: 'pointer',
        opacity: 0,
        position: 'absolute',
        // top: 4,
        // left: 14,
        zIndex: 1,
        margin: 0,
        width: 145,
        height: 35,
        '&:checked + label:after': {
            content: 'attr(data-on)',
            left: 66,
            background: '#59C576'
        },
        '&:checked + label:before': {
            content: 'attr(data-off)',
            right: 'auto',
            left: 15,
            background: '#DFDBDB',
            fontWeight: 'bold',
        }
    },
}))

const SwitcherIdioma = (props) => {
    const [localState, setState] = useState({
        idIdioma: props.idIdioma
    })
    
    const handleIdiomaChange = (e) => {
        let idIdioma = e.target.checked ? 'ES-AR' : 'EN-US';
        props.switchIdioma(idIdioma);
        setState({ ...localState, idIdioma: idIdioma });
        let IATA = props.listAeropuertos.find(aero => aero.value == props.idAeropuerto).label;
        let arpt = props.listAeropuertos.filter(aero => aero.idIdioma == idIdioma);
        let idAeropuerto = arpt.find(x => x.label == IATA).value
        const data = {
            aeropuertos: arpt,
            idAeropuerto: idAeropuerto
        }
        props.selectAeropuerto(data);
    }

    const classes = useStyles();

    return (
        <Grid item className="pull-right">
            <label className={classes['switch-idioma']}>
                <input type="checkbox" name="idioma" className={classes['input-idioma']} checked={localState.idIdioma === 'ES-AR' ? true : false} onChange={handleIdiomaChange} />
                <label htmlFor="idioma" data-on="ESPAÑOL" data-off="INGLÉS" className={classes['switch-idioma-inner']} />
            </label>
        </Grid>
    );
}

function mapStateProps(state) {
    return {
        listAeropuertos: state.aeropuertoReducer.listAeropuertos,
        idAeropuerto: state.aeropuertoReducer.idAeropuerto,
        idIdioma: state.appReducer.idIdioma
    }
}

export default connect(mapStateProps, { switchIdioma, selectAeropuerto })(SwitcherIdioma)