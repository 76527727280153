import axios from 'axios'
import './axiosConfig'

const GET_NOVEDADES_URL = 'Novedad/GetNovedades'
const GET_NOVEDADES_BY_IDIOMA_URL = 'Novedad/GetNovedadesByIdioma'
const GET_NOVEDADES_BY_ID_URL = 'Novedad/GetNovedadById'
const SAVE_NOVEDAD_URL = 'Novedad/SaveNovedad'
const SAVE_NOVEDAD_FILE_URL = 'Novedad/SaveFileNovedad'
const DELETE_NOVEDAD_URL = 'Novedad/DeleteNovedad'
const DELETE_NOVEDAD_FILE_URL = 'Novedad/DeleteFileNovedad';
const GET_PERIODOS_URL = 'Novedad/GetPeriodos'

export const GetNovedades = () => {
    return axios.get(GET_NOVEDADES_URL)
}

export const GetNovedadesByIdioma = (IdIdioma) => {
    return axios.get(GET_NOVEDADES_BY_IDIOMA_URL, { params: { IdIdioma } })
}

export const GetNovedadById = id => {
    return axios.get(GET_NOVEDADES_BY_ID_URL, { params: { id } })
}

export const GetPeriodos = () => {
    return axios.get(GET_PERIODOS_URL)
}

export const SaveNovedad = (novedad)  => {
    return axios.post(SAVE_NOVEDAD_URL, novedad);
}

export const SaveNovedadFile = (FileZip, FileArchivo)  => {

    const formData = new FormData();  
    if(FileZip){
        formData.append("FileZip", FileZip) 
    } 
    formData.append("FileArchivo", FileArchivo)     
    const config = {    
        headers: {'content-type': 'multipart/form-data',}}; 

    return axios.post(SAVE_NOVEDAD_FILE_URL, formData, config);
}

export const DeleteNovedadFile = name  => {
    return axios.get(DELETE_NOVEDAD_FILE_URL, { params: { name } })
}






export const DeleteNovedad = novedad => {
    return axios.post(DELETE_NOVEDAD_URL, novedad);
}
