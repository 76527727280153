import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { DeleteNovedadFile, GetNovedadById, SaveNovedad, /*,GetPeriodos*/ SaveNovedadFile} from '../../../services/novedad.service';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';

import swal from 'sweetalert2';
import { successSwal } from '../../../assets/resources/swal-config';
import ReactQuill from 'react-quill';
import { Button, TextField, Grid, makeStyles, Fab, IconButton } from '@material-ui/core';
import { Delete, NoteAdd } from '@material-ui/icons';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Publish, Cancel, AddPhotoAlternate } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import SwitcherIdioma from '../../../components/switcherIdioma';
import 'react-quill/dist/quill.snow.css';
import '../noticias.css';
import NotFile  from '../../../assets/img/NotFile.png';
import WordFile from '../../../assets/img/WordFile.png';
import ZipFile from '../../../assets/img/ZipFile.png';
import NoFoundImage from '../../../assets/img/not_found.png';

const Styles = makeStyles((theme) => ({
    del: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    img: {
        width: '100px',
    },
    button: {
        color: '#FEF',
        backgroundColor: '#109ADD',
        '&:hover': {
            backgroundColor: '#0080FF',
            color: '#FEF'
        }
    }
}));

const NovedadABM = (props) => {
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            ['link', 'image', 'video'],
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ]
    }

    

    const classes = Styles();

    const [errors, setErrors] = useState([]);
    // const [periodos, setPeriodos] = useState([])
    const [novedad, setNovedad] = useState({
        Id: 0,
        Activa: true,
        Fecha:  new Date(),
        Periodo: '',
        Titulo: '',
        Cuerpo: '',
        FechaBaja: null,
        IdIdioma: props.idIdioma,
        Orden: 0,
        LinkArchivo: null,
        FileArchivo: null,
        LinkZip: null,
        FileZip: null,
        LinkPortada: null,
        Base64Portada: null,
    })
    const [editing, setEditing] = useState(false);
    const Id = props.match.params.id;
    const history = useHistory();
    const [archivo, setArchivo] = useState({ srcArchivo: NotFile, name:'', hiddenRemoveArchivo: true });
    const [zip, setZip] = useState({ srcZip: NotFile, name:'', hiddenRemoveZip: true });
    const [portada, setPortada] = useState({ srcPortada: NoFoundImage, name:'', hiddenRemovePortada: true });

    const validationSchema = {
        Id: { required: false },
        Activa: { required: false },
        Fecha: { required: true },
        Periodo: { required: false },
        Titulo: { required: true, maxLength: 500 },
        Cuerpo: { required: true },
        FechaBaja: { required: false },
        IdIdioma: { required: false },
        Orden: { required: false },
        LinkArchivo: { required: true },
        LinkZip: { required: false },
        FileArchivo: { required: true },
        FileZip: { required: false },
        LinkPortada: { required: true },
        Base64Portada: { required: true },
        FilePortada: {  required: false}
    }

    useEffect(() => {
        // GetPeriodos().then((resGetPeriodos) => {
        //     if(resGetPeriodos.data){
        //         const periodos = ["", (new Date().getFullYear() + 1).toString(), (new Date().getFullYear()).toString() ,(new Date().getFullYear() - 1).toString()];
        //         for (var i = 0; i < resGetPeriodos.data.length; i++){
        //             if (periodos.indexOf(resGetPeriodos.data[i]) == -1)
        //                 periodos.push(resGetPeriodos.data[i]);
        //         }
        //         setPeriodos(periodos)
        //     }
        // })
        if (Id) {
            GetNovedadById(Id)
                .then(response => {
                    if (response.data) {
                        setNovedad({...response.data, Base64Portada: response.data.Base64Portada !== null ? response.data.Base64Portada.toString().split(",", 2)[1] : null});
                        var LinkArchivo = response.data.LinkArchivo ;
                        var LinkZip = response.data.LinkZip;
                        var LinkPortada = response.data.LinkPortada;

                        setArchivo({ ...archivo, srcArchivo: LinkArchivo != "" ? WordFile: NotFile, name:LinkArchivo, hiddenRemoveArchivo: LinkArchivo != "" ? false : true });
                        setZip({ ...zip, srcZip: LinkZip != "" ? ZipFile: NotFile, name:LinkZip, hiddenRemoveZip: LinkZip != "" ? false: true });
                        setPortada({ ...portada, srcPortada: LinkPortada !== "" ? response.data.Base64Portada : NoFoundImage, name:LinkPortada, hiddenRemovePortada: LinkPortada !== "" ? false : true  });
                    }
                })
                .catch(err => console.error(err))
        } else {
            setNovedad({ ...novedad, IdIdioma: props.idIdioma });
            setEditing(true)
        }
    }, [props.idIdioma])

    const isValid = () => {
        let valid = true;
        let errors = {};

        try {
            for (let key of Object.keys(novedad)) {
                let value = novedad[key]
                if (validationSchema[key].required) {

                    if (!value) {
                        errors[key] = 'Requerido';
                        setErrors(errors);
                        valid = false;
                    }
                    if (key === 'Titulo' && !errors[key] && validationSchema[key].maxLength && value.length > validationSchema[key].maxLength) {
                        errors[key] = `el valor máximo es ${validationSchema[key].maxLength} caracteres`;
                        setErrors(errors);
                        valid = false;
                    }
                    if (!errors[key] && key === 'Cuerpo') {
                        if (novedad.Cuerpo.trim() === '') {                            
                            errors[key] = 'Requerido';
                            setErrors(errors);
                            valid = false;
                        }
                    }
                    if (!errors[key] && key === 'Fecha' && value.toString() === 'Invalid Date') {
                        errors[key] = 'formato de fecha no válido';
                        setErrors(errors);
                        valid = false;
                    }
                }
            }
        } catch (err) {
            console.error('Error', err);
        }

        return valid;
    }

    const handleSubmit = () => {
        if (isValid()) {
            SaveNovedad(novedad)
                .then((resNovedad) => {
                    if (resNovedad.data) {
                        SaveNovedadFile(novedad.FileZip, novedad.FileArchivo).then((resNovedad)=>{
                        console.log(resNovedad.data);
                        swal.fire({
                            text: 'Se han guardado los cambios con éxito',
                            ...successSwal
                        }).then(() => { history.push('/Novedades'); })
                    });
                    } else {
                        swal.fire({
                            title: 'Error al guardar los cambios',
                            icon: 'warning',
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                });
        }
    }

    const handleCancelClick = () => history.push('/Novedades');

    const handleChange = e => {
        setNovedad({ ...novedad, [e.target.name]: e.target.value})
    }

    const handleFechaChange = value => {
        setNovedad({ ...novedad, Fecha: value })
    }

    const handleActivaChange = event => {
        setNovedad({ ...novedad, Activa: event.target.checked })
    }

    const handleEditClick = () => {
        if (!editing){
            setEditing(true);
        }
            
    }

    const handleBodyChange = value => {
        if (editing)
            setNovedad({ ...novedad, Cuerpo: value })
    }

    const handleUploadArchivoClick = event => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            reader.readAsDataURL(file);

            if (validateFileGral(event, 10000000)){
                reader.onload = () => {

                    // const base64Archivo = reader.result.toString().split(",", 2)[1];
                    setNovedad({ ...novedad, LinkArchivo: file.name, FileArchivo: file })
                    setArchivo({ ...archivo, srcArchivo: WordFile, name:file.name, hiddenRemoveArchivo: false });
                    if ( file.name != "")
                        setErrors({...errors, ["LinkArchivo"] : ""});
                };
            }
        }
        event.target.value = "";
    };

    const handleUploadZipClick = event => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            reader.readAsDataURL(file);

             if (validateFileGral(event, 50000000)){
                reader.onload = () => {

                    // const base64Archivo = reader.result.toString().split(",", 2)[1];
                    setNovedad({ ...novedad, LinkZip: file.name, FileZip: file })
                    setZip({ ...zip, srcZip: ZipFile, name:file.name, hiddenRemoveZip: false });
                    if ( file.name != "")
                        setErrors({...errors, ["LinkZip"] : ""});
                };
             }
            
        }
        event.target.value = "";
    };

    const handleUploadPortadaClick = event => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length && validateImageGral(event)) {
            const file = event.target.files[0];
            reader.readAsDataURL(file);

             reader.onload = () => {
                console.log("Entro reader")
                let img = new Image()
                img.src = reader.result;

                img.onload = () => {
                    console.log("Entro img")
                    const base64Portada = reader.result.toString().split(",", 2)[1];
                    setNovedad({ ...novedad, LinkPortada: file.name, FilePortada: file, Base64Portada: base64Portada })
                    setPortada({ ...portada, srcPortada: reader.result, name:file.name, hiddenRemovePortada: false });
                    if ( file.name != "")
                        setErrors({...errors, ["LinkPortada"] : ""});
                    }
            };
            
        }
        event.target.value = "";
    };

    const validateFileGral = (event, maxFileSize) => {
        var fileName = event.target.files[0].name;
        var fileSize = event.target.files[0].size;
        var maxFileSizeMB =  maxFileSize == 50000000 ? '50' : 10000000 ? '10' : ' ';

        if (fileSize > maxFileSize) {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'El archivo no debe superar los '+ maxFileSizeMB +'MB',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
            event.target.value = '';
            return false;
        } else {
            var ext = fileName.split('.').pop();

            ext = ext.toLowerCase();

            switch (ext) {
                case 'doc':
                case 'docx':
                case 'zip':
                case 'rar':
                    return true;
                    break;
                default:
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'El archivo no tiene la extensión adecuada',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                    event.target.value = '';
                    return false;
            }
            
        }
    }

    const validateImageGral = (event) => {
        var fileName = event.target.files[0].name;
        var fileSize = event.target.files[0].size;

        if (fileSize > 5000000) {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'La imagen no debe superar los 5MB',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
            event.target.value = '';
            return false;
        } else {
            var ext = fileName.split('.').pop();

            ext = ext.toLowerCase();

            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'svg':
                case 'gif':
                    return true;
                    break;
                default:
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'LA imagen no tiene la extensión adecuada',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                    event.target.value = '';
                    return false;
            }
        }
    }

    const removeArchivo = () => {
        DeleteNovedadFile(novedad.LinkArchivo);
        setNovedad({ ...novedad, LinkArchivo: null, FileArchivo: null })
        setArchivo({ ...archivo, srcArchivo: NotFile, name:'', hiddenRemoveArchivo: true });
    }

    const removeZip = () => {
        DeleteNovedadFile(novedad.LinkZip);
        setNovedad({ ...novedad, LinkZip: null, FileZip: null })
        setZip({ ...zip, srcZip: NotFile, name:'', hiddenRemoveZip: true });
    }

    const removePortada = () => {
        setNovedad({ ...novedad, LinkPortada: null, Base64Portada: null })
        setPortada({ ...portada, srcPortada: NoFoundImage, name:'', hiddenRemovePortada: true });
    }

    return (
        <div className="main-content">
            {Id ?
                <h1 className="title">Noticia: <b className="greentext">{novedad.Titulo}</b></h1>
                :
                <h1 className="title">Nueva Noticia</h1>
            }

            <br />
            <br />
            
            <div className="content">
                <div className="row form-group">
                    <div className="col-md-6">
                        <label className="label">Idioma: {props.idIdioma == 'ES-AR' ? 'Español' : 'Inglés'}</label>
                    </div>

                    <div className={`col-md-${Id ? '6' : '3'}`}>
                        <div className="active-switch">
                            <label className="label">HABILITADA</label>
                            <input type="checkbox" id="check" checked={novedad.Activa} onChange={handleActivaChange} />
                            <label htmlFor="check" className="switch"></label>
                        </div>
                    </div>

                    <div className="col-md-3" hidden={Id}>
                        <SwitcherIdioma />
                    </div>
                </div>

                <div className="row form-group" hidden={!Id}>
                        <div className="col-md-12">
                            <Button variant="contained" className={`btnAdd ${editing ? 'gray' : ''}`} color="primary" startIcon={<FontAwesomeIcon icon={faEdit} />} onClick={handleEditClick}>Editar</Button>
                        </div>
                </div>
                
                <div className="row form-group">
                    <div className="col-md-12">
                        
                        <div className="box">
                            <div className="row form-group">
                                <div className="col-md-8">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            disabled={!editing}
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            id="Fecha"
                                            label="Fecha de publicación"
                                            value={novedad.Fecha}
                                            onChange={(e) => handleFechaChange(e)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            helperText={errors.Fecha}
                                            error={errors.Fecha ? true : false}
                                            size="small"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <TextField
                                        disabled={!editing}
                                        label="Título"
                                        variant="outlined"
                                        name="Titulo"
                                        value={novedad.Titulo}
                                        onChange={(e) => handleChange(e)}
                                        helperText={errors.Titulo}
                                        error={errors.Titulo ? true : false}
                                        size="small"
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <div className="editor">
                                        <ReactQuill
                                            readOnly={!editing}
                                            name='Cuerpo'
                                            value={novedad.Cuerpo}
                                            theme='snow'
                                            placeholder='Cuerpo'
                                            modules={modules}
                                            onChange={handleBodyChange}
                                        />
                                    </div>
                                    {errors.Cuerpo && (<p className="help is-danger">{errors.Cuerpo}</p>)}
                                </div>
                            </div>
                            <div className="row form-group">
                            <div className="col-md-4">
                                        <label className="label">Seleccione una Imágen</label>
                                        <Grid container spacing={2}>
                                            <br/>
                                            <Grid item>
                                                <img className={classes.img} alt="complex" src={portada.srcPortada} />
                                            </Grid>

                                            <Grid item xs container direction="column" spacing={2}>
                                                {portada.hiddenRemovePortada ? <Fragment>
                                                    <input type="file" accept=".jpg, .jpeg, .png, .gif, .svg" disabled={!editing} className={classes.input} id="contained-button-file1"
                                                        onChange={handleUploadPortadaClick} />

                                                    <label className="buttonImg" htmlFor="contained-button-file1">
                                                        <Fab component="span" color="primary" disabled={!editing}>
                                                            <AddPhotoAlternate />
                                                        </Fab>
                                                    </label>
                                                </Fragment> : ""}
                                                <div className={classes.del}>
                                                    {!portada.hiddenRemovePortada ? <IconButton color="secondary" aria-label="delete" component="span" disabled={!editing} onClick={removePortada}>
                                                        <Delete />
                                                    </IconButton> : ""}
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <label>{portada.name}</label>
                                        {errors.LinkPortada && (<p className="help is-danger">{errors.LinkPortada}</p>)}
                                    </div>
                                <div className="col-md-4">
                                    <label className="label">Seleccione archivo zip</label>
                                    <Grid container spacing={2}>
                                    <br/>
                                        <Grid item>
                                            <img className={classes.img} alt="complex" src={zip.srcZip} />
                                        </Grid>
                                        <Grid item xs container direction="column" spacing={2}>
                                            {zip.hiddenRemoveZip ? <Fragment>
                                                <input type="file" accept=".rar, .zip" className={classes.input} id="contained-button-file2" disabled={!editing} onChange={handleUploadZipClick} />

                                                <label className="buttonImg" htmlFor="contained-button-file2">
                                                    <Fab component="span" color="primary" disabled={!editing} >
                                                        <NoteAdd />
                                                    </Fab>
                                                </label>
                                            </Fragment> : ""}
                                            <div className={classes.del}>
                                                {!zip.hiddenRemoveZip ? <IconButton color="secondary" aria-label="delete" component="span" disabled={!editing} onClick={removeZip}>
                                                    <Delete />
                                                </IconButton> : ""}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <label>{zip.name}</label>
                                    {errors.LinkZip && (<p className="help is-danger">{errors.LinkZip}</p>)}
                                </div>
                                <div className="col-md-4">
                                    <label className="label">Seleccione archivo word</label>
                                    <Grid container spacing={3}>
                                        <br/>
                                        <Grid item>
                                            <img className={classes.img} alt="complex" src={archivo.srcArchivo} />
                                        </Grid>
                                        <Grid item xs container direction="column" spacing={2}>
                                            {archivo.hiddenRemoveArchivo ? <Fragment>
                                                <input type="file" accept=".doc, .docx" className={classes.input} id="contained-button-file3" disabled={!editing} onChange={handleUploadArchivoClick} />

                                                <label className="buttonImg" htmlFor="contained-button-file3">
                                                    <Fab component="span" color="primary" disabled={!editing}> 
                                                        <NoteAdd />
                                                    </Fab>
                                                </label>
                                            </Fragment> : ""}
                                            <div className={classes.del}>
                                                {!archivo.hiddenRemoveArchivo ? <IconButton color="secondary" aria-label="delete" component="span" disabled={!editing} onClick={removeArchivo}>
                                                    <Delete />
                                                </IconButton> : ""}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <label>{archivo.name}</label>
                                    {errors.LinkArchivo && (<p className="help is-danger">{errors.LinkArchivo}</p>)}
                                </div>
                                <div className="col-md-4">
                                    <br/>
                                    <label className="label">Requerimientos de las imágenes:</label>
                                    <ul className="validImages">
                                        {/* <li>Tamaño de la imagen de listado 300px/350px ancho 200px/250px alto</li>
                                        <li>Tamaño de la imagen máx. 1000px de ancho</li>*/}
                                        <li>Extensiones válidas: JPG, JPEG, PNG, GIF, SVG</li> 
                                        <li>Max. 5MB</li>
                                    </ul>
                                </div>                   
                                <div className="col-md-4">
                                    <br/>
                                    <label className="label">Requerimientos de los archivos:</label>
                                    <ul className="validImages">
                                        {/* <li>Tamaño de la imagen de listado 300px/350px ancho 200px/250px alto</li>
                                        <li>Tamaño de la imagen máx. 1000px de ancho</li>*/}
                                        <li>Extensiones válidas: ZIP, RAR</li> 
                                        <li>Max. 50MB</li>
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <br/>
                                    <label className="label">Requerimientos de los archivos:</label>
                                    <ul className="validImages">
                                        {/* <li>Tamaño de la imagen de listado 300px/350px ancho 200px/250px alto</li>
                                        <li>Tamaño de la imagen máx. 1000px de ancho</li>*/}
                                        <li>Extensiones válidas: DOC, DOCX</li> 
                                        <li>Max. 10MB</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <Button variant="contained" color="primary" className="button" onClick={handleSubmit} startIcon={<Publish />}>Publicar</Button>
                            <Button variant="contained" startIcon={<Cancel />} className="button" onClick={handleCancelClick}>Cancelar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        idIdioma: state.appReducer.idIdioma
    }
}

export default connect(mapStateToProps)(NovedadABM);