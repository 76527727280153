import Axios from 'axios';
import './axiosConfig';

const GETTERMINALES_URL = 'Estacionamiento/GetTerminalesByAeropuerto';
const GETESTACIONAMIENTOBYID_URL = 'Estacionamiento/GetEstacionamientoById';
const GETESTACIONAMIENTO_URL = 'Estacionamiento/GetEstacionamiento';
const GETSERVICIOSBYESTACIONAMIENTO_URL = 'Estacionamiento/GetServiciosByEstacionamiento';
const GETDETALLESBYSERVICIO_URL = 'Estacionamiento/GetDetallesByServicio';
const SAVEORUPDATEESTACIONAMIENTO_URL = 'Estacionamiento/SaveOrUpdate';
const DELETEESTACIONAMIENTO_URL = 'Estacionamiento/Delete';
const DELETEARANCEL_URL = 'Estacionamiento/DeleteArancelById';
const DELETELARGAESTADIA_URL = 'Estacionamiento/DeleteLargaEstadias';
const DELETEMEDIOSDEPAGOHABILITADOS_URL = 'Estacionamiento/DeleteMedioPagoHabilitados';
const DELETESERVICIO_URL = 'Estacionamiento/DeleteServicio';
const DELETEDETALLESERVICIO_URL = 'Estacionamiento/DeleteDetallesServicio';

export const GetTerminales = (idAeropuerto) => {
    return Axios.get(GETTERMINALES_URL, { params: { idAeropuerto } });
}

export const GetEstacionamientoById = (id) => {
    return Axios.get(GETESTACIONAMIENTOBYID_URL, { params: { id } });
}

export const GetEstacionamientos = (idAeropuerto, idIdioma) => {
    return Axios.get(GETESTACIONAMIENTO_URL, { params: { idAeropuerto, idIdioma } });
}

export const GetDetallesByServicio = (idServicio) => {
    return Axios.get(GETDETALLESBYSERVICIO_URL, { params: { idServicio } });
}

export const GetServiciosByEstacionamiento = (idEstacionamiento) => {
    return Axios.get(GETSERVICIOSBYESTACIONAMIENTO_URL, { params: { idEstacionamiento } });
}

export const SaveOrUpdate = (estacionamiento) => {
    return Axios.post(SAVEORUPDATEESTACIONAMIENTO_URL, estacionamiento);
}

export const DeleteEst = (estacionamiento) => {
    return Axios.post(DELETEESTACIONAMIENTO_URL, estacionamiento);
}

export const DeleteArancel = (arancel) => {
    return Axios.post(DELETEARANCEL_URL, arancel);
}

export const DeleteLargaEstadia = (largaestadia) => {
    return Axios.post(DELETELARGAESTADIA_URL, largaestadia);
}

export const DeleteMedioPagoHabilitados = (mediodepago) => {
    return Axios.post(DELETEMEDIOSDEPAGOHABILITADOS_URL, mediodepago);
}

export const DeleteServicio = (servicio) => {
    return Axios.post(DELETESERVICIO_URL, servicio);
}

export const DeleteDetallesServicio = (detalle) => {
    return Axios.post(DELETEDETALLESERVICIO_URL, detalle);
}