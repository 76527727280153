import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { SearchUsers, GetRoles, SaveUserFull, GetUserFull } from '../../../services/usuario.service';
import { getSessionUser } from '../../../services/auth.service'
import { GetGrupos } from '../../../services/grupo.service';

import swal from 'sweetalert2';
import { successSwal } from '../../../assets/resources/swal-config';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Done, Cancel, AddPhotoAlternate, Save, Delete } from '@material-ui/icons'
import { faEdit } from '@fortawesome/free-regular-svg-icons'

import NoFoundImage from '../../../assets/img/not_found.png';

import 'react-widgets/dist/css/react-widgets.css';
import './usuarioAbm.css';

const UsuarioAbm = (props) => {

    var selectRef;
    const Id = props.match.params.id;
    const history = useHistory();
    const user = getSessionUser();

    //SETEO DE ERRORES VALIDACIONES
    const [errors, setErrors] = useState("");

    //USESTATE CARGA DE COMBO USUARIOS AD
    const [userAd, setUserAd] = useState("");

    const [users, setUsers] = useState([]);

    const [usersFull, setUsersFull] = useState([]);

    //USESTATE CARGA DE COMBOS
    const [roles, setRoles] = useState([]);
    const [aeropuertos, setAeropuertos] = useState([]);
    const [grupos, setGrupos] = useState([]);

    //SETEO VALORES CONTROLES
    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    const [nombreUsuario, setNombreUsuario] = useState("");
    const [rolSelect, setRolSelect] = useState(0);
    const [image, setImage] = useState({ name: "", base64Image: "", srcImage: "", hiddenRemoveImage: false });
    const [aeropuertosSelect, setAeropuertosSelect] = useState([]);
    const [grupoSelect, setGrupoSelect] = useState([]);

    const [grupoInitial, setGrupoInitial] = useState([]);

    const [ editing , setEditing] = useState(false);

    useEffect(() => { cargaInicial(); }, []);

    const cargaInicial = async () => {
        return await GetRoles()
            .then(async (resGetRoles) => {
                if(resGetRoles.data){
                    if (resGetRoles.data.length > 0) {
                        mapRoles(resGetRoles.data);
                        return await GetGrupos();
                    } else {
                        swal.fire({
                            title: 'Información',
                            icon: 'warning',
                            text: 'No se han encontrado roles disponibles',
                            allowOutsideClick: false,
                            confirmButtonText: 'Ok'
                        });
                        return Promise.reject();
                    }
                }
            })
            .then(async (resGetGrupos) => {
                if (resGetGrupos.data.length > 0) mapGrupos(resGetGrupos.data);
                if (Id) {
                    getUsuarioById();
                } else {
                    setImage({ ...image, name: null, srcImage: NoFoundImage, base64Image: null, hiddenRemoveImage: true });
                }
                // } else {
                //     swal.fire({
                //         title: 'Información',
                //         icon: 'warning',
                //         text: 'No se han encontrado grupos disponibles',
                //         allowOutsideClick: false,
                //         confirmButtonText: 'Ok'
                //     });
                //     return Promise.reject();
                // }
            })
            .catch(() => { });
    }

    const getUsuarioById = () => {
        GetUserFull(Id)
            .then((resGetUserFull) => {
                setUserAd(resGetUserFull.data.DS_NombreUsuario)
                setEmail(resGetUserFull.data.DS_Email);
                setNombreUsuario(resGetUserFull.data.DS_NombreUsuario);
                setNombre(resGetUserFull.data.DS_Nombre)
                setRolSelect(resGetUserFull.data.IdRol);
                setImage({...image, name: resGetUserFull.data.LkImagen, srcImage: resGetUserFull.data.base64Image ? resGetUserFull.data.base64Image : NoFoundImage, hiddenRemoveImage: resGetUserFull.data.base64Image ? false : true});
                setAeropuertosSelect(resGetUserFull.data.IdAeropuertoList);
                setGrupoInitial(resGetUserFull.data.IdGrupoList);
                if(resGetUserFull.data.IdRol !== 1) setGrupoSelect(resGetUserFull.data.IdGrupoList);
            });
    }

    const mapRoles = (rolesData) => {
        rolesData.map(item => {
            let obj = { label: item.DS_Nombre, value: item.Id, description: item.DS_Descripcion }
            roles.push(obj);
            setRoles(roles);
        });
    }

    const mapGrupos = (gruposData) => {
        gruposData.map(item => {
            let obj = { label: item.Nombre, value: item.Id }
            grupos.push(obj);
            setGrupos(grupos);
        });
    }

    const mapUsuarios = (usuariosData) => {
        let usersALlenar = [];
        usuariosData.map(item => {
            let obj = { label: item.DS_Nombre, value: item.DS_NombreUsuario }
            usersALlenar.push(obj);
            setUsers(usersALlenar);
        })

        if (usuariosData.length === 1) {
            setNombre(usuariosData[0].DS_Nombre);
            setNombreUsuario(usuariosData[0].DS_NombreUsuario);
            setEmail(usuariosData[0].DS_Email);
        }
    }

    const focusUsuarios = () => {
        selectRef.focus();
    };

    const searchUsersADHandler = () => {
        setUsers([]);
        setNombre("");
        setNombreUsuario("");
        setEmail("");

        if (validarUsuarioABuscar()) {
            SearchUsers(userAd)
                .then(
                    (resSearchUsers) => {
                        if (resSearchUsers.data.length > 0) {
                            mapUsuarios(resSearchUsers.data);
                            setUsersFull(resSearchUsers.data);
                        }
                        else {
                            swal.fire({
                                title: 'Información',
                                icon: 'warning',
                                text: 'No se han encontrado el usuario ingresado',
                                allowOutsideClick: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                    }
                );
        }
        focusUsuarios();
    };

    const handleChangeUserSelect = user => {
        usersFull.forEach(element => {
            if (element.DS_NombreUsuario === user.value) {
                setNombre(element.DS_Nombre);
                setNombreUsuario(element.DS_NombreUsuario);
                setEmail(element.DS_Email);
            }
        });
    }

    const handleChangeEmail = event => { setEmail(event.target.value); };

    const handleChangeRol = event => {
        setRolSelect(event.target.value);
        if(event.target.value == 1) setGrupoSelect([])
    };

    const handleChangeAeropuertos = aeropuertosValue => {
        console.log(aeropuertosValue)
        if(editing || !Id){
            let NewAeropuertos = [];

            if (aeropuertosValue == 'TODOS' && aeropuertosSelect.length == props.aeropuertos.length) {
                setAeropuertosSelect([]);
            } else if (aeropuertosValue == 'TODOS' && aeropuertosSelect.length != props.aeropuertos.length) {
                props.aeropuertos.map(item => {
                    NewAeropuertos.push(item.value);
                    setAeropuertosSelect(NewAeropuertos);
                });
            } else {
                var userArpts = props.aeropuertos.filter(aero => aero.label == aeropuertosValue.label), Arpt = [], bool = false;
                for (var i = 0; i < userArpts.length; i++){
                    Arpt.push(userArpts[i].value);
                    bool = bool || aeropuertosSelect.indexOf(userArpts[i].value) != -1;
                }

                if (!bool && Arpt.length > 0)
                    for (var i = 0; i < Arpt.length; i++){
                        NewAeropuertos.push(Arpt[i]);
                    }

                for (var i = 0; i < aeropuertosSelect.length; i++) {
                    if (Arpt.indexOf(aeropuertosSelect[i]) == -1) NewAeropuertos.push(aeropuertosSelect[i]);
                }

                setAeropuertosSelect(NewAeropuertos);
            }
        }
    };

    const handleChangeGroups = groups => {
        let value = []
        if (groups)
            groups.map(group => {
                value.push(group.value);
                setGrupoSelect(value)
            })
        else
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'Para el rol seleccionado el usuario debe tener al menos un grupo asignado.',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
    };

    const validarUsuarioABuscar = () => {
        let errors = {};

        let valido = true;

        if (!userAd) {
            errors.userAd = 'El Usuario a buscar es requerido';
            setErrors(errors);
            valido = false;
        }
        else if (userAd.length < 3) {
            errors.userAd = 'El Usuario a buscar tiene que ser mayor a 3 caracteres';
            setErrors(errors);
            valido = false;
        }
        return valido;
    };

    const validarGuardar = values => {
        let errors = {}

        let valido = true;

        if (!values.DS_Nombre) {
            errors.nombre = 'El Nombre y Apellido del usuario es requerido';
            setErrors(errors);
            valido = false;
        }

        if (!values.DS_Email) {
            errors.email = 'El Email es requerido';
            setErrors(errors);
            valido = false;
        } else if (!/\S+@\S+\.\S+/.test(values.DS_Email)) {
            errors.email = 'El Email es inválido';
            setErrors(errors);
            valido = false;
        }

        if (values.IdAeropuertoList.length === 0) {
            errors.IdAeropuertosList = 'Al menos un aeropuerto debe ser seleccionado.';
            setErrors(errors);
            valido = false;
        }

        if (values.IdRol === 0) {
            errors.IdRol = 'El Rol es requerido';
            setErrors(errors);
            valido = false;
        }

        if (values.IdGrupoList.length === 0 && values.IdRol != 1) {
            errors.IdGrupoList = 'Debe seleccionar al menos un grupo.';
            setErrors(errors);
            valido = false;
        }

        return valido;
    };

    const handleImageClick = () => {
        document.getElementById("contained-button-file1").click();
    }

    const handleUploadImageClick = event => {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length && validateImage(event)) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                let img = new Image()
                img.src = reader.result;

                //VALIDA ANCHO Y LARGO DE LA IMAGEN
                img.onload = function () {

                    const nameFile = file.name;
                    const base64Image = reader.result.toString().split(",", 2)[1];
                    setImage({ ...image, name: nameFile, base64Image: base64Image, srcImage: reader.result, hiddenRemoveImage: false });
                }
            };
        }
    };

    const validateImage = (event) => {
        var fileName = event.target.files[0].name;
        var fileSize = event.target.files[0].size;

        if (fileSize > 3000000) {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: 'La imagen no debe superar los 3MB',
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
            event.target.value = '';
            return false;
        } else {
            var ext = fileName.split('.').pop();

            ext = ext.toLowerCase();

            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'gif':
                case 'svg':
                    return true;
                    break;
                default:
                    swal.fire({
                        title: 'Información',
                        icon: 'warning',
                        text: 'LA Imagen no tiene la extensión adecuada',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    });
                    event.target.value = '';
                    return false;
            }
        }
    }

    const removeImage = () => {
        setImage({...image, name: null, base64Image: null, srcImage: NoFoundImage, hiddenRemoveImage: true});
    }
    
    const handleformatUserName = (name) => {
        if(name){
            var Snames = name.split(", ");
            var firstNames = Snames[1].split(" "), lastNames = Snames[0].split(" ");

            firstNames = firstNames.length > 1 ? firstNames[0].charAt(0).toUpperCase() + firstNames[0].slice(1).toLowerCase() + " " + firstNames[1].charAt(0).toUpperCase() + firstNames[1].slice(1).toLowerCase() : firstNames[0].charAt(0).toUpperCase() + firstNames[0].slice(1).toLowerCase();
            lastNames = lastNames.length > 1 ? lastNames[0].charAt(0).toUpperCase() + lastNames[0].slice(1).toLowerCase() + " " + lastNames[1].charAt(0).toUpperCase() + lastNames[1].slice(1).toLowerCase() : lastNames[0].charAt(0).toUpperCase() + lastNames[0].slice(1).toLowerCase();

            return firstNames + " " + lastNames;
        }
    }

    const handleformatRol = (key) => {
        const rol = roles.filter(rol => { return rol.value == rolSelect });

        return rol.length == 0 ? "" : rol[0][key].charAt(0).toUpperCase() + rol[0][key].slice(1).toLowerCase();
    }

    const handleformatGroups = () => {
        var str;
        for (var i = 0; i < grupoSelect.length; i++){
            var grupo = grupos.filter(grupo => { return grupo.value == grupoSelect[i] })
            str = i == 0 && grupo.length > 0 ? grupo[0].label : i == grupoSelect.length - 1 && grupo.length > 0 ? str + " y " + grupo[0].label + "." : grupo.length == 0 ? str : str + ", " + grupo[0].label;
        }

        return str ? str : "No pertenece a ningun grupo."
    }

    const handleGroupsValue = () => {
        let groups = [];
        for (var i = 0; i < grupoSelect.length; i++){
            const grupo = grupos.filter(grupo => { return grupo.value == grupoSelect[i] });
            if(grupo.length > 0)
                groups.push(grupo[0])
        }

        return groups;
    }

    const handleEditClick = () => {
        if (!editing)
            setEditing(true);
    }

    const onSubmit = () => {
        let userSave = {
            Id: Id,
            DS_Nombre: nombre,
            DS_Email: email,
            DS_NombreUsuario: nombreUsuario,
            IdRol: rolSelect,
            DS_NombreRol: handleformatRol('label'),
            IdAeropuertoList: aeropuertosSelect,
            IdGrupoList: grupoSelect,
            LkImagen: image.name,
            base64Image: image.base64Image
        }
        if (userAd) {
            if (validarGuardar(userSave)) {
                SaveUserFull(userSave)
                    .then(
                        (resSaveUserFull) => {
                            if (resSaveUserFull.data.ResultadoExitoso) {
                                swal.fire({
                                    text: 'Se han guardado los cambios con éxito',
                                    ...successSwal
                                }).then(() => { history.push('/Usuarios'); })
                            } else {
                                swal.fire({
                                    title: 'Error al guardar los cambios',
                                    icon: 'warning',
                                    text: resSaveUserFull.data.MensajeError,
                                    allowOutsideClick: false,
                                    confirmButtonText: 'Ok'
                                });
                            }
                        });
            }
        }
        else {
            swal.fire({
                title: 'Información',
                icon: 'warning',
                text: "Para continuar debe buscar el usuario, completar los datos y guardar los cambios",
                allowOutsideClick: false,
                confirmButtonText: 'Ok'
            });
        }
    };

    const cancelaABM = () => history.push('/Usuarios');

    return (
        <div className="main-content">
            <br />
            <h1 className="title">{!Id ? 'Agregar Usuario' :'Permisos de usuario de '}<span className="userName" hidden={!Id}>{handleformatUserName(nombre)}</span></h1>
            <br />

            <div className="content">
                <div className="row form-group" hidden={user.IdRol == 3 || user.IdRol == 4 || !Id}>
                    <div className="col-md-12">
                        <Button variant="contained" className={`btnAdd ${editing ? 'gray' : ''}`} color="primary" startIcon={<FontAwesomeIcon icon={faEdit} />} onClick={handleEditClick}>Editar</Button>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-4">
                        <div className="box">
                            <div className="form" hidden={Id}>
                                <label className="label">Buscar usuario</label>
                                <div className="user-search">
                                    <div>
                                        <input name="usuario" type="text" className="form-control" value={userAd} onChange={e => setUserAd(e.target.value)} />
                                    </div>
                                    <button type="button" onClick={searchUsersADHandler} className="button is-block is-info">Buscar</button>
                                </div>
                                {errors.userAd && (<p className="help is-danger">{errors.userAd}</p>)}
                            </div>
                            
                            <div className="form">
                                <label className="label">Nombre y apellido</label>
                                { !Id ?
                                    <div>
                                        <Select ref={ref => { selectRef = ref; }} placeholder={<div>Seleccione</div>} value={users.filter(obj => obj.value === nombreUsuario)} options={users} onChange={handleChangeUserSelect} />
                                        {errors.nombre && (<p className="help is-danger">{errors.nombre}</p>)} 
                                    </div>:
                                    <span>{handleformatUserName(nombre)}</span>
                                }
                            </div>

                            <div className="form">
                                <label className="label">Usuario</label>
                                { !Id ?
                                    <div>
                                        <input name="nombreUsuario" type="text" value={nombreUsuario} className="form-control" disabled />
                                        {errors.nombreUsuario && (<p className="help is-danger">{errors.nombreUsuario}</p>)} 
                                    </div>:
                                    <span>{nombreUsuario}</span>
                                }
                            </div>

                            <div className="form">
                                <label className="label">E-mail</label>
                                {!Id || editing ?
                                    <div>
                                        <input name="email" type="text" defaultValue={email} onChange={handleChangeEmail} className="form-control" />
                                        {errors.email && (<p className="help is-danger">{errors.email}</p>)} 
                                    </div>:
                                    <span>{email}</span>
                                }
                            </div>

                            <div className="form">
                                <label className="label">Foto</label>

                                <div className="picture">
                                    <br />
                                    <div className="img" style={{backgroundImage: "url(" + image.srcImage + ")"}}></div>

                                    <div className="btns">
                                        {image.hiddenRemoveImage ?
                                            <div className="btn" >
                                                <input type="file" accept=".jpg, .jpeg, .png, .gif, .svg" id="contained-button-file1" onChange={handleUploadImageClick} />
                                                <AddPhotoAlternate onClick={handleImageClick} />
                                            </div> :
                                            <div className="btn remove" onClick={removeImage}>
                                                <Delete />
                                            </div>
                                        }
                                    </div>

                                    <br/>
                                    <label className="label">Requerimientos de la imagen:</label>
                                    <ul className="validImages">
                                        <li>Extensiones validas: JPG, JPEG, PNG, GIF, SVG</li>
                                        <li>Max. 3MB</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8">
                        <div className="box">
                            <div className="row form-goup">
                                <div className="col-md-5">
                                    <label className="label">Rol</label>

                                    {!editing && Id ?
                                        <div className="user-rol">
                                            <span>{handleformatRol('label')}</span>
                                            <p>Sus permisos son:</p>
                                            <p>{handleformatRol('description')}</p>
                                        </div> :
                                        <RadioGroup aria-label="Rol" name="Rol" value={rolSelect} onChange={handleChangeRol}>
                                            {roles.map((rol) => (
                                                <div className="form-check" key={rol.value}>
                                                    <FormControlLabel value={rol.value} control={<Radio checked={rol.value == rolSelect} />} label={rol.label.charAt(0).toUpperCase() + rol.label.slice(1).toLowerCase()} />
                                                    <p>Sus permisos son:</p>
                                                    <p>{rol.description}</p>
                                                </div>
                                            ))}
                                            {errors.IdRol && (<p className="help is-danger">{errors.IdRol}</p>)}
                                        </RadioGroup>
                                    }
                                </div>

                                <div className="col-md-7">
                                    <label className="label">Aeropuertos que puede administrar</label>

                                    <div className="aeropuertos">
                                        <div className={`aeropuerto TODOS ${editing || !Id ? 'cursor' : ''} ${aeropuertosSelect.length == props.listAeropuertos.length || aeropuertosSelect.length == props.aeropuertos.length ? 'active' : ''}`} onClick={() => handleChangeAeropuertos('TODOS')}>
                                            <Done />
                                            <span>TODOS</span>
                                        </div>

                                        {props.aeropuertos.map((aero) => (
                                            <div className={`aeropuerto ${editing || !Id ? 'cursor' : ''} ${aeropuertosSelect.indexOf(aero.value) != -1 ? 'active' : ''}`} key={aero.value} onClick={() => handleChangeAeropuertos(aero)}>
                                                <Done />
                                                <span>{aero.label}</span>
                                            </div>
                                        ))}
                                    </div>
                                    {errors.IdAeropuertosList && (<p className="help is-danger">{errors.IdAeropuertosList}</p>)}
                                </div>

                                <div className="col-md-12" hidden={rolSelect == 1}>
                                    <br />
                                    <label className="label">{Id && !editing ? 'Grupo al que pertenece' : 'Asignar un grupo'}</label>
                                    {!editing && Id ? 
                                        <span className="group">{handleformatGroups()}</span> :
                                        <Select isMulti placeholder={<div>Seleccione</div>} value={handleGroupsValue()} closeMenuOnSelect={false} name="Grupos" options={grupos} className="basic-multi-select" classNamePrefix="select" onChange={handleChangeGroups} />
                                    }
                                    {errors.IdGrupoList && (<p className="help is-danger">{errors.IdGrupoList}</p>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="form-row">
                        <Button variant="contained" color="primary" onClick={onSubmit} startIcon={<Save />} className="button">Guardar</Button>
                        <Button variant="contained" startIcon={<Cancel />} onClick={cancelaABM} className="button">Cancelar</Button>
                    </div>
                </div>
            </div>
        </div>)
}

const mapStateToProps = state => {
    return {
        aeropuertos: state.aeropuertoReducer.aeropuertos,
        listAeropuertos: state.aeropuertoReducer.listAeropuertos
    }
}

export default connect(mapStateToProps)(UsuarioAbm);